import React, { useEffect, useState } from "react";
import Rating from "@material-ui/lab/Rating";
import Modal from "@material-tailwind/react/Modal";
import { MdOutlineCancel } from "react-icons/md";
import Button from "@material-tailwind/react/Button";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import ModalBody from "@material-tailwind/react/ModalBody";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { RiShareForwardLine, RiEditLine } from "react-icons/ri";

import BackboneLogo from "../../../assets/backbone-logo.svg";

import {
    BsHeartFill,
    BsHeart,
    BsReply,
    BsPencil,
    BsTrash,
    BsLink,
    BsLink45Deg,
} from "react-icons/bs";
import link from "../../../assets/link.svg";
import { useToast } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import ellipseIcon from "../../../assets/Ellipse.png";
import "./EventCard.css";
import {
    deleteEvent,
    getUserRegisteredEventById,
    likeEvent,
    unlikeEvent,
    updateEvent,
} from "../../../data/reducers/events/events";
import { useDispatch, useSelector } from "react-redux";
import AddEvent from "./AddEvent";
import { ENDORSE_BASE_URL } from "../../../config/api";
import moment from "moment";
import CommonModalBackboneEvent from "./CommonModalBackboneEvent";
import { contentActions } from "../../../data/reducers/home/content";

const EventCard = ({ event, hasEditIcon }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [evenetDate, setEventDate] = useState(null);
    const [eventType,setEventType]=useState("Register")
    const [selecetedEventId,setSelectedEventId]=useState(undefined)
    const [isVisible, setIsVisible] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [getLink, setGetLink] = useState(false);
    const { myCompany } = useSelector((state) => state.content);
    const navigate=useNavigate()
    const handleClose2 = () => {
        setVisible2(false);
        setOpen(false);
    };
    useEffect(() => {
        setGetLink(false);
    }, [showShareModal]);

    const handleLikeEvent = (data) => {
        console.log(data);
        let payload = {
            id: event.id,
            liked: true,
        };
        dispatch(updateEvent(payload));
        dispatch(likeEvent(data.id));
    };

    const handleUnlikeEvent = (data) => {
        let payload = {
            id: event.id,
            liked: false,
        };
        dispatch(updateEvent(payload));
        dispatch(unlikeEvent(data.id));
    };

    const handleDeleteEvent = (e) => {
        e.preventDefault();
        dispatch(deleteEvent(event.id));
        handleClose();
    };

    const handleClose = () => {
        setShowConfirmModal(false);
    };

    const handleCloseShareModal = () => {
        setShowShareModal(false);
    };

    return (
        <>
            <div className="card event-card mx-auto mt-3">
                <div className="card-body top">
                    <div className="row">
                        <div className="col-xl-11 col-lg-11 col-md-11 col-12  borderEnd">
                            <div className="row">
                                <div className="col-3 col-md-2 col-lg-2">
                                    <div className="d-flex align-items-start">
                                        <div
                                            className="date-box"
                                            style={{
                                                color: "#565656",
                                                height: "5rem",
                                                padding: "1rem",
                                            }}
                                        >
                                            <p
                                                className="text-4xl font-semibold poppins"
                                                style={{ color: "#555555" }}
                                            >
                                                {event &&
                                                    moment(
                                                        new Date(
                                                            event?.eventDate
                                                        )
                                                    ).format("DD")}
                                            </p>
                                            <span
                                                style={{
                                                    marginTop: "-4px",
                                                }}
                                            >
                                                {event &&
                                                    moment(
                                                        new Date(
                                                            event?.eventDate
                                                        )
                                                    ).format("MMM")}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-9 col-md-10 col-lg-10">
                                    <p
                                        className=" poppins font-semibold text-xl "
                                        style={{
                                            color: "#555555",
                                            display: "-webkit-box",
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                        }}
                                    >
                                        {event && event.eventName + "  "}

                                        <span
                                            className=" font-normal text-base"
                                            style={{
                                                color: "#8D8D8D",
                                            }}
                                        >
                                            {event &&
                                                "(" + event.eventType + ")"}
                                        </span>
                                    </p>
                                    <hr className="mt-3" />
                                    <div className="my-2">
                                        <b className="me-4 font-semibold text-base">
                                            Time{" "}
                                        </b>{" "}
                                        <span className="font-light text-base poppins timeSpan">
                                            {event &&
                                                moment(
                                                    new Date(event?.eventDate)
                                                ).format("hh:mm a")}
                                        </span>
                                    </div>

                                    {event && event.streetName && (
                                        <div
                                            className="my-3"
                                            style={{
                                                display: "content",
                                            }}
                                        >
                                            <b className="me-4 font-semibold text-base">
                                                Location{" "}
                                            </b>{" "}
                                            <span
                                                className="font-light text-justify"
                                                style={{
                                                    fontSize: "14px",
                                                }}
                                            >
                                                {event &&
                                                    (event.streetName
                                                        ? event.streetName
                                                        : "") +
                                                        " " +
                                                        (event.subUrb
                                                            ? event.subUrb
                                                            : "") +
                                                        " " +
                                                        (event.city
                                                            ? event.city + ", "
                                                            : "") +
                                                        (event.pincode
                                                            ? event.pincode
                                                            : "")}
                                            </span>
                                        </div>
                                    )}
                                    <p
                                        className="text-xs"
                                        style={{
                                            color: "#4E4E4E",
                                            lineHeight: "18px",
                                            display: "-webkit-box",
                                            WebkitLineClamp: 4,
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                        }}
                                    >
                                        {event && event.eventDescription}
                                    </p>
                                    <div className="d-flex align-items-center mt-2">
                                        {hasEditIcon ? (
                                            <img
                                                src={
                                                    myCompany?.company_logo !=
                                                    null
                                                        ? myCompany?.company_logo
                                                        : "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"
                                                }
                                                alt=""
                                                height={31}
                                                width={31}
                                                className="me-2"
                                            />
                                        ) :event?.createdBy=="ADMIN" && !event?.User?.id && !event?.Company?.id ? (
                                            <img
                                                src={
                                                    BackboneLogo
                                                }
                                                alt=""
                                                height={31}
                                                width={31}
                                                className="me-2"
                                            />
                                        ): (
                                            <img
                                                src={
                                                    event?.Company
                                                        ?.company_logo != null
                                                        ? event?.Company
                                                              ?.company_logo
                                                        : "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"
                                                }
                                                alt=""
                                                height={31}
                                                width={31}
                                                className="me-2"
                                            />
                                        )
                                    }
                                       { event?.createdBy=="ADMIN" && !event?.User?.id && !event?.Company?.id  ?<p
                                            className="font-medium poppins text-base"
                                            style={{ color: "#606060" }}
                                        >
                                            Backbone Event
                                        </p>: <p
                                            className="font-medium poppins text-base"
                                            style={{ color: "#606060" }}
                                        >
                                            Uploaded by{" "}
                                            {event && event?.Company
                                                ? event?.Company?.company_name
                                                : ""}
                                        </p>}
                                    </div>
                                    {event?.createdBy=="ADMIN" && !event?.User?.id && !event?.Company?.id ? <span
                                         onClick={()=>{
                                            // console.log(event,">>>>>>>>>>> event >>>>>>>>>..")
                                            setEventDate(event)
                                            dispatch(
                                                contentActions.reset()
                                            );
                                            setSelectedEventId(event?.id)
                                            dispatch(getUserRegisteredEventById(event?.id))
                                            setOpen(true)
                                         }}
                                        style={{
                                            backgroundColor: "#039BE5",
                                            padding: "0.3rem 2rem",
                                            borderRadius: "8px",
                                            border: "0px",
                                        }}
                                        className="btn btn-primary mt-4 rounded-lg text-base px-8"
                                    >
                                        Register Here
                                    </span>:
                                    <a
                                        href={`${event.registrationLink}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{
                                            backgroundColor: "#039BE5",
                                            padding: "0.3rem 2rem",
                                            borderRadius: "8px",
                                            border: "0px",
                                            pointerEvents:
                                                event.registrationLink
                                                    ? "initial"
                                                    : "none",
                                            opacity: event.registrationLink
                                                ? "1"
                                                : "0.7",
                                        }}
                                        className="btn btn-primary mt-4 rounded-lg text-base px-8"
                                    >
                                        Register Here
                                    </a>}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-1 col-lg-1 col-md-1 col-12 d-flex action-items">
                            <div className="d-flex w-full ">
                                {!hasEditIcon && (
                                    <div className="action-icon">
                                        {event && event.liked ? (
                                            // <a
                                            //     // href={service?.company_website}
                                            //     onClick={() => setIsLiked(!isLiked)}
                                            //     target="_blank"
                                            // >
                                            //     <BsHeartFill color="#C11717" />
                                            // </a>
                                            <button
                                                onClick={() =>
                                                    handleUnlikeEvent(event)
                                                }
                                            >
                                                <BsHeartFill color="#C11717" />
                                            </button>
                                        ) : (
                                            <button
                                                onClick={() =>
                                                    handleLikeEvent(event)
                                                }
                                            >
                                                <BsHeart color="#C11717" />
                                            </button>
                                        )}
                                    </div>
                                )}
                                <div className="action-icon">
                                    <button
                                        target="_blank"
                                        onClick={() => setShowShareModal(true)}
                                    >
                                        <RiShareForwardLine />
                                    </button>
                                </div>
                                {!hasEditIcon && (
                                    <div className="action-icon">
                                        <span
                                            // to={`/company/${event?.company_id}`}
                                            onClick={()=>{
                                                if(event?.company_id){
                                                    navigate(`/company/${event?.company_id}`)
                                                }
                                            }}
                                            className="company_visit"
                                        >
                                            <BsLink />
                                        </span>
                                    </div>
                                )}
                                {hasEditIcon && (
                                    <>
                                        <div className="action-icon">
                                            <button
                                                onClick={() =>
                                                    setIsVisible(true)
                                                }
                                            >
                                                <RiEditLine />
                                            </button>
                                        </div>
                                        <div className="action-icon icon-danger">
                                            <button
                                                onClick={() =>
                                                    setShowConfirmModal(true)
                                                }
                                            >
                                                <BsTrash color="red" />
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-wrapper">
                <Modal
                    size="md"
                    backdrop
                    active={showConfirmModal}
                    toggler={handleClose}
                >
                    <ModalBody>
                        <h1 className="text-gray-900 text-2xl font-bold mt-0 mb-3 text-center">
                            Do you want to delete event?
                        </h1>
                        <form onSubmit={handleDeleteEvent} className="row">
                            <div className="col-12">
                                <div className="d-flex justify-between btn-group">
                                    <Button
                                        color="gray"
                                        buttonType="filled"
                                        onClick={() => {
                                            setShowConfirmModal(false);
                                        }}
                                        type="button"
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        color="blue"
                                        buttonType="filled"
                                        size="regular"
                                        type="submit"
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                </Modal>
                <Modal
                    className="eventModal"
                    // backdrop
                    active={showShareModal}
                    toggler={handleCloseShareModal}
                >
                    <div className="d-flex flex-row justify-between">
                        <div
                            className="poppins font-semibold text-3xl"
                            style={{ color: "#565656" }}
                        >
                            Share event
                        </div>
                        <div
                            className="cursor-pointer font-bold text-xl"
                            onClick={handleCloseShareModal}
                        >
                            <MdOutlineCancel />
                        </div>
                    </div>
                    <div className="mb-0 mt-4">
                        <div className="card event-card share-event mb-0">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-12 ">
                                        <div className="top">
                                            <div className="d-flex align-items-start px-2 py-3">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="d-flex align-items-start">
                                                        <div
                                                            className="date-box"
                                                            style={{
                                                                color: "#565656",
                                                                height: "5rem",
                                                            }}
                                                        >
                                                            <p
                                                                className="text-4xl font-semibold poppins"
                                                                style={{
                                                                    color: "#555555",
                                                                }}
                                                            >
                                                                {new Date(
                                                                    event?.eventDate
                                                                ).getDate()}
                                                            </p>
                                                            <span
                                                                style={{
                                                                    marginTop:
                                                                        "-4px",
                                                                }}
                                                            >
                                                                Jan
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <div>
                                                        <p
                                                            className="poppins font-semibold text-xl"
                                                            style={{
                                                                color: "#565656",
                                                                display:
                                                                    "-webkit-box",
                                                                WebkitLineClamp: 2,
                                                                WebkitBoxOrient:
                                                                    "vertical",
                                                                overflow:
                                                                    "hidden",
                                                            }}
                                                        >
                                                            {event &&
                                                                event.eventName +
                                                                    "  "}

                                                            <span className="text-muted font-normal text-base ">
                                                                {event &&
                                                                    "(" +
                                                                        event.eventType +
                                                                        ")"}
                                                            </span>
                                                        </p>
                                                        <hr className="mt-3" />
                                                        <div className="my-2">
                                                            <b className="me-4">
                                                                Time{" "}
                                                            </b>{" "}
                                                            <span
                                                                className="font-light text-base poppins timeSpan"
                                                                style={{
                                                                    color: "#4E4E4E",
                                                                }}
                                                            >
                                                                {event &&
                                                                    new Date(
                                                                        event.eventDate
                                                                    ).getHours() +
                                                                        ":" +
                                                                        new Date(
                                                                            event.eventDate
                                                                        ).getMinutes() +
                                                                        " " +
                                                                        (new Date(
                                                                            event.eventDate
                                                                        ).getHours() >=
                                                                        12
                                                                            ? "PM"
                                                                            : "AM")}
                                                            </span>
                                                        </div>
                                                        {event &&
                                                            event.streetName && (
                                                                <div
                                                                    className="my-3"
                                                                    style={{
                                                                        display:
                                                                            "content",
                                                                    }}
                                                                >
                                                                    <b className="me-4 font-semibold text-base">
                                                                        Location{" "}
                                                                    </b>{" "}
                                                                    <span
                                                                        className="font-light text-justify"
                                                                        style={{
                                                                            fontSize:
                                                                                "14px",
                                                                        }}
                                                                    >
                                                                        {event &&
                                                                            (event.streetName
                                                                                ? event.streetName
                                                                                : "") +
                                                                                " " +
                                                                                (event.subUrb
                                                                                    ? event.subUrb
                                                                                    : "") +
                                                                                " " +
                                                                                (event.city
                                                                                    ? event.city +
                                                                                      ", "
                                                                                    : "") +
                                                                                (event.pincode
                                                                                    ? event.pincode
                                                                                    : "")}
                                                                    </span>
                                                                </div>
                                                            )}

                                                        <p
                                                            className="poppins text-xs"
                                                            style={{
                                                                color: "#4E4E4E",
                                                                display:
                                                                    "-webkit-box",
                                                                WebkitLineClamp: 4,
                                                                WebkitBoxOrient:
                                                                    "vertical",
                                                                overflow:
                                                                    "hidden",
                                                               wordBreak: "break-word"
                                                            }}
                                                        >
                                                            {event &&
                                                                event.eventDescription}
                                                        </p>
                                                        <div className="d-flex align-items-center mt-2">
                                                            <img
                                                                src={
                                                                    ellipseIcon
                                                                }
                                                                alt=""
                                                                height={31}
                                                                width={31}
                                                                className="me-2"
                                                            />
                                                            <p
                                                                className="font-medium poppins text-base"
                                                                style={{
                                                                    color: "#606060",
                                                                }}
                                                            >
                                                                Uploaded by{" "}
                                                                {event &&
                                                                    event?.Company &&
                                                                    event
                                                                        ?.Company
                                                                        ?.company_name}
                                                            </p>
                                                        </div>
                                                        {/* {!showShareModal && (
                                                            <a
                                                                href={`${event.registrationLink}`}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                style={{
                                                                    backgroundColor:
                                                                        "#039BE5",
                                                                    padding:
                                                                        "0.3rem 2rem",
                                                                    borderRadius:
                                                                        "8px",
                                                                    border: "0px",
                                                                    // pointerEvents:
                                                                    //     event.registrationLink
                                                                    //         ? "initial"
                                                                    //         : "none",
                                                                    opacity:
                                                                        event.registrationLink
                                                                            ? "1"
                                                                            : "0.7",
                                                                }}
                                                                className="btn btn-primary rounded-lg text-base px-8 mt-4"
                                                            >
                                                                Register Here
                                                            </a>
                                                        )} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-4">
                                        <div className="d-flex items-center">
                                            <div className="borderIcon mr-2">
                                                <button>
                                                    <img src={link} alt="" />
                                                </button>
                                            </div>
                                            <p
                                                className="poppins font-normal text-2xl"
                                                style={{ color: "#5B5B5B" }}
                                            >
                                                Get link
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-row justify-between">
                                        <div className=" mt-2">
                                            <p
                                                className="poppins font-normal text-base para"
                                                style={{ color: "#5B5B5B" }}
                                            >
                                                Use this unique link to have
                                                another business sign up to
                                                BackBone and earn 1 month free
                                                subscription credit, when they
                                                do
                                            </p>
                                        </div>
                                        <div className="">
                                            <CopyToClipboard
                                                // text="abcd"
                                                text={`${ENDORSE_BASE_URL}/upcoming-events?id=${event?.id}`}
                                            >
                                                <button
                                                    
                                                >
                                                    {!getLink ? (
                                                        <span
                                                            onClick={() =>
                                                                setGetLink(!getLink)
                                                            }
                                                            className="poppins font-semibold text-base span-event"
                                                            style={{
                                                                color: "#03A9F4",
                                                            }}
                                                        >
                                                            Copy Link
                                                        </span>
                                                    ) : (
                                                        <span style={{cursor:"auto"}}>Link Copied</span>
                                                    )}
                                                </button>
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
            <AddEvent
                visible={isVisible}
                setVisible={setIsVisible}
                data={event}
            />






            {open && (
                <CommonModalBackboneEvent
                    setOpen={setOpen}
                    open={open}
                    visible2={visible2}
                    setEventType={setEventType}
                    setVisible2={setVisible2}
                    setEventDate={setEventDate}
                    selecetedEventId={selecetedEventId}
                />
            )}
            <Modal
                size="md"
                backdrop
                active={visible2}
                toggler={handleClose2}
                className="bg-success"
            >
                <ModalBody>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div
                                        className="col-12 pb-0"
                                        style={{ width: "300px" }}
                                    >
                                        <div>
                                            <div className="d-flex justify-content-start align-items-center">
                                                <span>
                                                    <img
                                                        style={{
                                                            width: "45px",
                                                            height: "45px",
                                                            borderRadius:
                                                                "100%",
                                                        }}
                                                        src="https://cdn-icons-png.flaticon.com/512/4315/4315445.png"
                                                    />
                                                </span>
                                               {eventType=="Register" &&  <h3 className="ms-4 text-center fs-5 fw-bold">
                                                    You have been <br />{" "}
                                                    registered!{" "}
                                                </h3>}
                                                {eventType=="Edit" &&  <h4 className="ms-4 text-center fs-5 fw-bold">
                                                    You event detail  <br />{" "}
                                                    has been updated!{" "}
                                                </h4>}

                                            </div>
                                            <div className="mt-3">
                                                <h5
                                                    className="fw-bold"
                                                    style={{ fontSize: "15px" }}
                                                >
                                                    Power Hour on{" "}
                                                    {/* {formatDateTime(
                                                        evenetDate?.eventDate
                                                    )} */}
                                                    {
                                                        console.log(evenetDate ,"EventDate>>>>>>>>>>>>>>>>>>>>")
                                                    }
                                                    
                                                    {moment(evenetDate?.eventDate).format('MMMM D')} at {moment(evenetDate?.eventDate).format('h:mm a')}
                                                </h5>
                                                <p
                                                    style={{
                                                        textAlign: "center",
                                                        fontSize: "11px",
                                                        marginTop: "15px",
                                                    }}
                                                >
                                                    You will receive a Calendar
                                                    Invite shortly with the zoom
                                                    link for the event. 100
                                                    BizBucks will be deposited
                                                    in your account AFTER you
                                                    have attended the event. You
                                                    may edit or cancel
                                                    registration by clicking the
                                                    event on the calendar
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default EventCard;
