import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GoPrimitiveDot } from "react-icons/go";
import Button from "@material-tailwind/react/Button";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";

import "./ChangedPassword.css";
import Login_Left_Frame_2 from "../../../assets/signup-left-frame-2.svg";

import logo from "../../../assets/backbone-logo.svg";
import { useToast } from "@chakra-ui/react";
import {
    authActions,
    changePasswordAction,
    getUserProfile,
    resetPasswordAction,
} from "../../../data/reducers/auth/auth";

const ChangedPassword = () => {
    const { token } = useParams();
    const toast = useToast();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { error, message, success, isReset } = useSelector(
        (state) => state.auth
    );

    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);

    const [passwordError, setPasswordError] = useState(false);
    const [newPasswordError, setNewPasswordError] = useState(false);
    const [conPasswordError, setConPasswordError] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const [visible, setVisible] = useState({
        password1: false,
        password2: false,
    });

    const [min8, setMin8] = useState(false);
    const [upp1, setUpp1] = useState(false);
    const [low1, setLow1] = useState(false);
    const [num1, setNum1] = useState(false);
    const [special1, setSpecial1] = useState(false);

    useEffect(() => {
        if (isReset) {
            navigate("/details");
        }
    }, [isReset]);

    useEffect(() => {
        if (success && message) {
            dispatch(getUserProfile());
            navigate("/details");
            toast({
                title: "Password Reset Successfully",
                position: "top-right",
                isClosable: true,
                duration: 3000,
                status: "success",
            });
            // window.location.href = "/details";
            dispatch(authActions.setIsReset());
            dispatch(authActions.reset());
        }
        if (error) {
            toast({
                title: "Password Reset Failed",
                position: "top-right",
                isClosable: true,
                duration: 3000,
                status: "error",
            });
            dispatch(authActions.reset());
        }
    }, [success, error, message, toast]);

    const onSubmit = async (e) => {
        e.preventDefault();
        if (password !== user.password) {
            setPasswordError(true);
        } else {
            setPasswordError(false);
        }

        if (!(min8 && upp1 && low1 && num1 && special1)) {
            setNewPasswordError(true);
        } else {
            setNewPasswordError(false);
        }
        if (newPassword !== confirmPassword || !confirmPassword) {
            setConPasswordError(true);
        } else {
            setConPasswordError(false);
        }

        const isValidated =
            min8 &&
            upp1 &&
            low1 &&
            num1 &&
            special1 &&
            newPassword === confirmPassword;

        if (isValidated) {
            dispatch(authActions.reset());
            await dispatch(
                changePasswordAction({
                    user_id: user.id,
                    password,
                    newPassword,
                    confirmPassword,
                })
            );
        } else {
            toast({
                title: "New password and Confirm password not matched",
                position: "top-right",
                isClosable: true,
                duration: 3000,
                status: "error",
            });
        }
    };

    const checkValidation = (e) => {
        // For length
        if (e.target.value?.length >= 8) {
            setMin8(true);
        } else {
            setMin8(false);
        }
        // For Upper
        if (e.target.value?.toLowerCase() !== e.target.value) {
            setUpp1(true);
        } else {
            setUpp1(false);
        }
        // For Lower
        if (e.target.value?.toUpperCase() !== e.target.value) {
            setLow1(true);
        } else {
            setLow1(false);
        }
        // For Number
        const regexNum = /\d/;
        if (regexNum.test(e.target.value)) {
            setNum1(true);
        } else {
            setNum1(false);
        }
        // For Number
        const regexSpecial = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
        if (regexSpecial.test(e.target.value)) {
            setSpecial1(true);
        } else {
            setSpecial1(false);
        }
    };

    useEffect(() => {
        if (error) {
            console.log("Error = " + error);
            toast({
                title: "Error",
                description: error,
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
            dispatch(authActions.reset());
        }
    }, [dispatch, toast, error]);

    return (
        <div className="change-container">
            <div className="change-left">
                <img src={Login_Left_Frame_2} alt="Login Frame" />
            </div>
            <div className="change-right">
                <div className="logo-main">
                    <img src={logo} alt="" />
                </div>
                <div className="rest-body">
                    <div className="change-password-heading my-4">
                        <h1>Reset Password</h1>
                    </div>
                    <form onSubmit={onSubmit}>
                        <div className="change-right-form">
                            <div className="form-group">
                                <div className="change-form-input">
                                    {show1 ? (
                                        <AiOutlineEye
                                            fontSize={24}
                                            color="#8d8d8d"
                                            onClick={() => setShow1(!show1)}
                                        />
                                    ) : (
                                        <AiOutlineEyeInvisible
                                            fontSize={24}
                                            color="#8d8d8d"
                                            onClick={() => setShow1(!show1)}
                                        />
                                    )}
                                    <label htmlFor="password">
                                        Current Password*
                                    </label>
                                    <input
                                        type={show1 ? "text" : "password"}
                                        name="password"
                                        className={
                                            passwordError ? "error-input" : ""
                                        }
                                        placeholder="********"
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="password">New Password*</label>
                                <div className="change-form-input">
                                    <input
                                        type={show2 ? "text" : "password"}
                                        name="newPassword"
                                        placeholder="New Password"
                                        className={
                                            newPasswordError
                                                ? "error-input"
                                                : ""
                                        }
                                        onChange={(e) => {
                                            setNewPassword(e.target.value);
                                            checkValidation(e);
                                        }}
                                    />
                                    {show2 ? (
                                        <AiOutlineEye
                                            fontSize={24}
                                            color="#8d8d8d"
                                            onClick={() => setShow2(!show2)}
                                        />
                                    ) : (
                                        <AiOutlineEyeInvisible
                                            fontSize={24}
                                            color="#8d8d8d"
                                            onClick={() => setShow2(!show2)}
                                        />
                                    )}
                                </div>
                                <div className="password-validation">
                                    <p>
                                        <GoPrimitiveDot
                                            color={min8 ? "#5AB659" : "#A6A6A6"}
                                            fontSize={16}
                                        />
                                        Minimum 8 characters
                                    </p>
                                    <p>
                                        <GoPrimitiveDot
                                            color={upp1 ? "#5AB659" : "#A6A6A6"}
                                            fontSize={16}
                                        />
                                        Atleast 1 uppercase
                                    </p>
                                    <p>
                                        <GoPrimitiveDot
                                            color={low1 ? "#5AB659" : "#A6A6A6"}
                                            fontSize={16}
                                        />
                                        Atleast 1 lower case
                                    </p>
                                    <p>
                                        <GoPrimitiveDot
                                            color={num1 ? "#5AB659" : "#A6A6A6"}
                                            fontSize={16}
                                        />
                                        Atleast 1 number
                                    </p>
                                    <p>
                                        <GoPrimitiveDot
                                            color={
                                                special1 ? "#5AB659" : "#A6A6A6"
                                            }
                                            fontSize={16}
                                        />
                                        Atleast 1 special character
                                    </p>
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="password">
                                    Confirm New Password*
                                </label>
                                <div className="change-form-input">
                                    <input
                                        type={show3 ? "text" : "password"}
                                        placeholder="Confirm New Password"
                                        className={
                                            conPasswordError
                                                ? "error-input"
                                                : ""
                                        }
                                        onChange={(e) => {
                                            setConfirmPassword(e.target.value);
                                        }}
                                    />
                                    {show3 ? (
                                        <AiOutlineEye
                                            fontSize={24}
                                            color="#8d8d8d"
                                            onClick={() => setShow3(!show3)}
                                        />
                                    ) : (
                                        <AiOutlineEyeInvisible
                                            fontSize={24}
                                            color="#8d8d8d"
                                            onClick={() => setShow3(!show3)}
                                        />
                                    )}
                                </div>
                            </div>
                            <div
                                className="change-submit-button"
                                style={{
                                    paddingBottom: "1rem",
                                }}
                            >
                                <Button
                                    color="blue"
                                    buttonType="filled"
                                    size="regular"
                                    rounded={false}
                                    type="submit"
                                    onClick={() => onSubmit()}
                                >
                                    Continue
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ChangedPassword;
