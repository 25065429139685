import React, { useEffect, useState } from "react";
import {
    AiFillPlusCircle,
    AiOutlineFilePdf,
    AiOutlineLink,
} from "react-icons/ai";
import { IoIosArrowDropright, IoIosArrowDropleft } from "react-icons/io";
import { BiText, BiImage } from "react-icons/bi";
import "react-multi-carousel/lib/styles.css";
import Modal from "@material-tailwind/react/Modal";
import ModalBody from "@material-tailwind/react/ModalBody";
import Button from "@material-tailwind/react/Button";
import { ScrollMenu } from "react-horizontal-scrolling-menu";

import "./OtherInfo.css";
import { useDispatch, useSelector } from "react-redux";
import {
    addImagesAction,
    addLinkAction,
    addPdfLinkAction,
    addTitleAction,
    deleteImageAction,
    deleteLinkAction,
    deletePdfAction,
    deleteTitleAction,
    getImagesAction,
    getLinksAction,
    getPdfLinksAction,
    getTitleAction,
    updateTitleAction,
} from "../../../data/reducers/profile/profile";
import { profileActions } from "../../../data/reducers/profile/profile";
import { useToast } from "@chakra-ui/react";
import { Routes as Switch, Route, useNavigate } from "react-router-dom";

import LinkView from "./LinkView";

const OtherInfo = ({
    isMine,
    images,
    pdfLinks,
    title,
    comp,
    links,
    myProfile = true,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [isHoveringPdf, setIsHoveringPdf] = useState(false);
    const [isHoveringLink, setIsHoveringLink] = useState(false);
    // const { title } = useSelector((state) => state.profile);
    // const { images } = useSelector((state) => state.profile);
    // const { links } = useSelector((state) => state.profile);
    // const { pdfLinks } = useSelector((state) => state.profile);
    const [index, setIndex] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();
    const { success, message, error } = useSelector((state) => state.profile);
    const [linkLoading, setLinkLoading] = useState(false);
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 5,
            slidesToSlide: 1,
        },
        tablet: {
            breakpoint: { max: 1200, min: 768 },
            items: 4,
            slidesToSlide: 1,
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 4,
            slidesToSlide: 1,
        },
    };

    // const [titleText, setTitleText] = useState(title[0]?.title);
    const [tempTitle, setTempTitle] = useState(title[0]?.title);
    const [tempImage, setTempImage] = useState("");
    const [tempLink, setTempLink] = useState("");
    const [tempPdf, setTempPdf] = useState("");
    const [LinkId, setLinkId] = useState("");
    const [imageId, setImageId] = useState(null);
    const [PdfId, setPdfId] = useState(null);
    const [tempPdfName, setTempPdfName] = useState("");
    const [isError, setIsError] = useState(false);
    const [modalImage, setModalImage] = useState("");
    const [carouselImg, setCarouselImg] = useState([]);
    const [currIndex, setCurrIndex] = useState(null);
    const onChange = (e) => {
        setTempTitle(e.target.value);
    };
    const updateText = () => {
        // if (!tempTitle) {
        //     toast({
        //         title: "Please a text!",
        //         status: "error",
        //         position: "top-right",
        //         isClosable: true,
        //         duration: 2000,
        //     });

        //     return;
        // }
        if (title[0]?.title == null) {
            dispatch(addTitleAction({ title: tempTitle }));
            toast({
                title: "Success",
                description: "Added Title Successfully",
                status: "success",
                position: "top-right",
                isClosable: true,
            });
        } else {
            dispatch(updateTitleAction({ title: tempTitle }));
            toast({
                title: "Success",
                description: "Updated Title Successfully",
                status: "success",
                position: "top-right",
                isClosable: true,
            });
        }
        setShowModal(false);
        setIndex(0);
    };

    const handleImages = () => {
        if (!tempImage) {
            // setIsError(true);
            toast({
                title: "Please add an image",
                status: "error",
                position: "top-right",
                isClosable: true,
                duration: 2000,
            });
            return;
        }
        let img = new FormData();
        img.append("file", tempImage);
        if (!isError) {
            dispatch(addImagesAction(img));
            setShowModal(false);
            setIndex(0);
            toast({
                title: "Success",
                description: "Added Image Successfully",
                status: "success",
                position: "top-right",
                isClosable: true,
            });
        }
        setTempImage("");
    };

    const handleLinks = () => {
        if (!tempLink) {
            toast({
                title: "Please add a link",
                status: "error",
                position: "top-right",
                isClosable: true,
                duration: 2000,
            });

            return;
        }
        let link = new URLSearchParams();
        link.append("link", tempLink);
        dispatch(addLinkAction(link));
        setShowModal(false);
        setIndex(0);
        toast({
            title: "Success",
            description: "Added Link Successfully",
            status: "success",
            position: "top-right",
            isClosable: true,
        });
    };
    const handlePdfLinks = () => {
        if (!tempPdf) {
            // setIsError(true);
            toast({
                title: "Please add a file!",
                status: "error",
                position: "top-right",
                isClosable: true,
                duration: 2000,
            });

            return;
        }
        let link = new FormData();
        link.append("file", tempPdf);
        link.append("pdf_title", tempPdfName);
        if (!isError) {
            dispatch(addPdfLinkAction(link));
            setLinkLoading(false);
            setShowModal(false);
            setIndex(0);
            toast({
                title: "Success",
                description: "Added Doc Successfully",
                status: "success",
                position: "top-right",
                isClosable: true,
            });
        }
    };

    const handleDelete = (type, id) => {
        if (type === "img") {
            dispatch(deleteImageAction(id));
        } else if (type === "pdf") {
            dispatch(deletePdfAction(id));
        } else if (type === "title") {
            dispatch(deleteTitleAction(id));
        } else {
            dispatch(deleteLinkAction(id));
        }
        toast({
            title: "Success",
            description: "Deleted Successfully",
            status: "success",
            position: "top-right",
            isClosable: true,
        });
    };
    const onFileSelect = (e) => {
        if (e.target.name === "other-image") {
            const reader = new FileReader();
            if (e.target.files[0].size > 512000) {
                setIsError(true);
                return;
            }
            setIsError(false);
            setTempImage(e.target.files[0]);
            reader.readAsDataURL(e.target.files[0]);
        }
        if (e.target.name === "other-pdf") {
            const reader = new FileReader();
            if (e.target.files[0].size > 5120000) {
                setIsError(true);
                return;
            }
            setIsError(false);
            const name = e.target.files[0].name.replace(".pdf", "");
            setTempPdf(e.target.files[0]);
            setTempPdfName(name);
            reader.readAsDataURL(e.target.files[0]);
        }
    };
    const handlePDFDownload = (pdfLink) => {
        let link = document.createElement("a");
        link.href = pdfLink;
        link.click();
    };

    const handleCarousel = (name) => {
        if (name === "forward") {
            setCurrIndex(currIndex + 1);
            setModalImage(carouselImg[currIndex]);
        } else {
            currIndex - 1 < 0
                ? setCurrIndex(carouselImg.length)
                : setCurrIndex(currIndex - 1);
            setModalImage(carouselImg[currIndex]);
        }
    };

    const editLinkFunction = (e) => {
        const http = "http://";
        const https = "https://";
        var value = e.target.value;
        if (value.startsWith(http) || value.startsWith(https)) {
            setTempLink(e.target.value);
        } else {
            setTempLink(https.concat(e.target.value));
        }
    };

    // useEffect(() => {
    //     dispatch(getTitleAction());
    //     dispatch(getImagesAction());
    //     dispatch(getLinksAction());
    //     dispatch(getPdfLinksAction());
    //     // setTitleText(title[0]?.title);
    //     dispatch(getMyCompanyAction());
    // }, [dispatch]);

    useEffect(() => {
        if (success && message) {
            dispatch(profileActions.reset());
            // dispatch(getTitleAction());
            // dispatch(getImagesAction());
            // dispatch(getLinksAction());
            setLinkLoading(false);
            // dispatch(getPdfLinksAction());
        }
        dispatch(profileActions.reset());
    }, [dispatch, success, message, toast, links, index]);

    useEffect(() => {
        images?.map((data, i) => {
            carouselImg.push(data.img_link);
        });
    });

    return (
        <>
            <div className="profile-all-other-info">
                <div className="profile-all-other-info-top">
                    <h1>All Other Info</h1>
                    {isMine && (
                        <AiFillPlusCircle
                            className="add-other-info"
                            onClick={() => setShowModal(true)}
                        />
                    )}
                </div>

                <div className="all-other-info">
                    <div className="grey-background" />
                    <div
                        className={
                            comp?.OtherInfos?.length === 0
                                ? "pdf-section-none"
                                : "title-section"
                        }
                    >
                        {title.length > 0 && (
                            <div className="w-full d-flex align-items-center justify-content-between">
                                <h5>Title</h5>
                                <div className="cursor-pointer">
                                    <svg
                                        width="14"
                                        height="17"
                                        viewBox="0 0 14 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        onClick={() => {
                                            handleDelete("title", title[0]?.id);
                                        }}
                                    >
                                        <path
                                            d="M4.41396 12.5177C4.55523 12.5177 4.69077 12.4586 4.79137 12.3532C4.89197 12.2478 4.94952 12.1046 4.95147 11.9544V7.36659C4.95147 7.16256 4.84903 6.97393 4.68266 6.87197C4.5164 6.76989 4.31153 6.76989 4.14516 6.87197C3.9789 6.97392 3.87646 7.16255 3.87646 7.36659V11.9544C3.87841 12.1046 3.93596 12.2478 4.03656 12.3532C4.13705 12.4586 4.27269 12.5177 4.41397 12.5177H4.41396Z"
                                            fill="#7c7c7c"
                                        />
                                        <path
                                            d="M7.00038 12.5177C7.14166 12.5177 7.27719 12.4586 7.3778 12.3532C7.47839 12.2478 7.53594 12.1046 7.53789 11.9544V7.36659C7.53789 7.16256 7.43545 6.97393 7.26908 6.87197C7.10282 6.76989 6.89796 6.76989 6.7317 6.87197C6.56533 6.97392 6.46289 7.16255 6.46289 7.36659V11.9544C6.46484 12.1046 6.52238 12.2478 6.62298 12.3532C6.72358 12.4586 6.85912 12.5177 7.00039 12.5177H7.00038Z"
                                            fill="#7c7c7c"
                                        />
                                        <path
                                            d="M9.58339 12.5177C9.72467 12.5177 9.86031 12.4586 9.96081 12.3532C10.0614 12.2478 10.119 12.1046 10.1209 11.9544V7.36659C10.1209 7.16256 10.0185 6.97393 9.8522 6.87197C9.68583 6.76989 9.48096 6.76989 9.3147 6.87197C9.14834 6.97392 9.0459 7.16255 9.0459 7.36659V11.9544C9.04785 12.1046 9.10539 12.2478 9.20599 12.3532C9.30659 12.4586 9.44213 12.5177 9.5834 12.5177H9.58339Z"
                                            fill="#7c7c7c"
                                        />
                                        <path
                                            d="M13.4623 2.76548H10.0224L9.5799 1.19014C9.48189 0.847828 9.28351 0.54771 9.01363 0.333576C8.74385 0.119547 8.41662 0.00264836 8.07989 0H5.92241C5.58568 0.00264377 5.25856 0.119544 4.98867 0.333576C4.71889 0.547719 4.5204 0.847857 4.4224 1.19014L3.97989 2.76548H0.537515C0.345511 2.76548 0.167995 2.87433 0.0720431 3.051C-0.0240144 3.22779 -0.0240144 3.44549 0.0720431 3.62216C0.16799 3.79895 0.3455 3.9078 0.537515 3.9078H1.17756V14.9032C1.18015 15.3194 1.33743 15.7176 1.61512 16.0112C1.89278 16.3047 2.26837 16.4699 2.66004 16.4706H11.3575C11.7505 16.4699 12.1271 16.3037 12.405 16.0084C12.6829 15.7131 12.8393 15.3128 12.84 14.8953V3.88897H13.4625C13.6051 3.88897 13.7418 3.82885 13.8426 3.72172C13.9433 3.6146 14 3.4693 14 3.31779C13.9994 3.16825 13.942 3.02525 13.8408 2.92121C13.7396 2.81719 13.6032 2.76098 13.4625 2.76523L13.4623 2.76548ZM5.4624 1.51692H5.46229C5.4929 1.4114 5.55359 1.31864 5.63601 1.25151C5.71855 1.18438 5.81861 1.14622 5.92234 1.14231H8.09485C8.20107 1.14139 8.30459 1.17783 8.38919 1.24611C8.47378 1.31439 8.53468 1.4106 8.56237 1.51957L8.90733 2.76547H5.08973L5.4624 1.51692ZM11.7648 15.0203H11.7272H11.7274C11.6993 15.1057 11.6474 15.1799 11.5786 15.2331C11.5098 15.2863 11.4274 15.3158 11.3423 15.3178H2.65977C2.55073 15.3157 2.44678 15.2685 2.37019 15.1861C2.29361 15.1037 2.25034 14.9927 2.24969 14.8769V3.8894H11.7646L11.7648 15.0203Z"
                                            fill="#7c7c7c"
                                        />
                                    </svg>
                                </div>
                            </div>
                        )}
                        {title?.slice(0, 1)?.map((text, i) => {
                            return (
                                <div key={1}>
                                    <p>{text.title}</p>
                                </div>
                            );
                        })}
                    </div>
                    <hr />
                    <div className="images-section-cont">
                        <div
                            className={
                                images?.length === 0
                                    ? "pdf-section-none"
                                    : "images-section"
                            }
                        >
                            <ScrollMenu
                                options={{
                                    ratio: 0.9,
                                    rootMargin: "5px",
                                    threshold: [0.01, 0.05, 0.5, 0.75, 0.95, 1],
                                }}
                            >
                                {images?.map((data, i) => {
                                    return (
                                        <div id={data.id} key={i}>
                                            <div className="pic-cont">
                                                <img
                                                    src={data.img_link}
                                                    alt="Lorem"
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        borderRadius: "10px",
                                                    }}
                                                    onMouseEnter={() => {
                                                        setIsHovering(true);
                                                        setImageId(data.id);
                                                    }}
                                                    onMouseLeave={() =>
                                                        setIsHovering(false)
                                                    }
                                                    id={i}
                                                    onClick={() => {
                                                        setModalImage(
                                                            data.img_link
                                                        );
                                                        setShowImageModal(true);
                                                        setCurrIndex(i);
                                                    }}
                                                />
                                                {myProfile && (
                                                    <div>
                                                        <div
                                                            className={
                                                                isHovering &&
                                                                imageId ===
                                                                    data.id
                                                                    ? "triangle"
                                                                    : ""
                                                            }
                                                            onMouseEnter={() => {
                                                                setIsHovering(
                                                                    true
                                                                );
                                                                setImageId(
                                                                    data.id
                                                                );
                                                            }}
                                                            onMouseLeave={() =>
                                                                setIsHovering(
                                                                    false
                                                                )
                                                            }
                                                        ></div>
                                                    </div>
                                                )}

                                                {myProfile && (
                                                    <div>
                                                        <div
                                                            className={
                                                                isHovering &&
                                                                imageId ===
                                                                    data.id
                                                                    ? "delete-img-show"
                                                                    : "delete-img-hide"
                                                            }
                                                            onMouseEnter={() =>
                                                                setIsHovering(
                                                                    true
                                                                )
                                                            }
                                                            onMouseLeave={() =>
                                                                setIsHovering(
                                                                    false
                                                                )
                                                            }
                                                        >
                                                            <div
                                                                style={{
                                                                    margin: "10px 0 0 80px",
                                                                }}
                                                            >
                                                                <svg
                                                                    width="14"
                                                                    height="17"
                                                                    viewBox="0 0 14 17"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    onClick={() =>
                                                                        handleDelete(
                                                                            "img",
                                                                            data.id
                                                                        )
                                                                    }
                                                                >
                                                                    <path
                                                                        d="M4.41396 12.5177C4.55523 12.5177 4.69077 12.4586 4.79137 12.3532C4.89197 12.2478 4.94952 12.1046 4.95147 11.9544V7.36659C4.95147 7.16256 4.84903 6.97393 4.68266 6.87197C4.5164 6.76989 4.31153 6.76989 4.14516 6.87197C3.9789 6.97392 3.87646 7.16255 3.87646 7.36659V11.9544C3.87841 12.1046 3.93596 12.2478 4.03656 12.3532C4.13705 12.4586 4.27269 12.5177 4.41397 12.5177H4.41396Z"
                                                                        fill="white"
                                                                    />
                                                                    <path
                                                                        d="M7.00038 12.5177C7.14166 12.5177 7.27719 12.4586 7.3778 12.3532C7.47839 12.2478 7.53594 12.1046 7.53789 11.9544V7.36659C7.53789 7.16256 7.43545 6.97393 7.26908 6.87197C7.10282 6.76989 6.89796 6.76989 6.7317 6.87197C6.56533 6.97392 6.46289 7.16255 6.46289 7.36659V11.9544C6.46484 12.1046 6.52238 12.2478 6.62298 12.3532C6.72358 12.4586 6.85912 12.5177 7.00039 12.5177H7.00038Z"
                                                                        fill="white"
                                                                    />
                                                                    <path
                                                                        d="M9.58339 12.5177C9.72467 12.5177 9.86031 12.4586 9.96081 12.3532C10.0614 12.2478 10.119 12.1046 10.1209 11.9544V7.36659C10.1209 7.16256 10.0185 6.97393 9.8522 6.87197C9.68583 6.76989 9.48096 6.76989 9.3147 6.87197C9.14834 6.97392 9.0459 7.16255 9.0459 7.36659V11.9544C9.04785 12.1046 9.10539 12.2478 9.20599 12.3532C9.30659 12.4586 9.44213 12.5177 9.5834 12.5177H9.58339Z"
                                                                        fill="white"
                                                                    />
                                                                    <path
                                                                        d="M13.4623 2.76548H10.0224L9.5799 1.19014C9.48189 0.847828 9.28351 0.54771 9.01363 0.333576C8.74385 0.119547 8.41662 0.00264836 8.07989 0H5.92241C5.58568 0.00264377 5.25856 0.119544 4.98867 0.333576C4.71889 0.547719 4.5204 0.847857 4.4224 1.19014L3.97989 2.76548H0.537515C0.345511 2.76548 0.167995 2.87433 0.0720431 3.051C-0.0240144 3.22779 -0.0240144 3.44549 0.0720431 3.62216C0.16799 3.79895 0.3455 3.9078 0.537515 3.9078H1.17756V14.9032C1.18015 15.3194 1.33743 15.7176 1.61512 16.0112C1.89278 16.3047 2.26837 16.4699 2.66004 16.4706H11.3575C11.7505 16.4699 12.1271 16.3037 12.405 16.0084C12.6829 15.7131 12.8393 15.3128 12.84 14.8953V3.88897H13.4625C13.6051 3.88897 13.7418 3.82885 13.8426 3.72172C13.9433 3.6146 14 3.4693 14 3.31779C13.9994 3.16825 13.942 3.02525 13.8408 2.92121C13.7396 2.81719 13.6032 2.76098 13.4625 2.76523L13.4623 2.76548ZM5.4624 1.51692H5.46229C5.4929 1.4114 5.55359 1.31864 5.63601 1.25151C5.71855 1.18438 5.81861 1.14622 5.92234 1.14231H8.09485C8.20107 1.14139 8.30459 1.17783 8.38919 1.24611C8.47378 1.31439 8.53468 1.4106 8.56237 1.51957L8.90733 2.76547H5.08973L5.4624 1.51692ZM11.7648 15.0203H11.7272H11.7274C11.6993 15.1057 11.6474 15.1799 11.5786 15.2331C11.5098 15.2863 11.4274 15.3158 11.3423 15.3178H2.65977C2.55073 15.3157 2.44678 15.2685 2.37019 15.1861C2.29361 15.1037 2.25034 14.9927 2.24969 14.8769V3.8894H11.7646L11.7648 15.0203Z"
                                                                        fill="white"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </ScrollMenu>
                        </div>
                    </div>
                    <hr />
                    <div
                        className={
                            pdfLinks?.length === 0
                                ? "pdf-section-none"
                                : "pdf-section"
                        }
                        style={{
                            display: "flex",
                            overflow: "scroll",
                        }}
                    >
                        <ScrollMenu
                            options={{
                                ratio: 0.9,
                                rootMargin: "5px",
                                threshold: [0.01, 0.05, 0.5, 0.75, 0.95, 1],
                            }}
                        >
                            {pdfLinks?.map((val, ind) => (
                                <div
                                    key={ind}
                                    className="each-Plink"
                                    onMouseEnter={() => {
                                        setIsHoveringPdf(true);
                                        setPdfId(val.id);
                                    }}
                                    onMouseLeave={() => setIsHoveringPdf(false)}
                                >
                                    <div className="each-svg">
                                        <svg
                                            width="22"
                                            height="20"
                                            viewBox="0 0 22 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M1.51234 7.13281C0.679799 7.13281 0 7.75569 0 8.5128V14.3128C0 15.0699 0.679799 15.6927 1.51234 15.6927H14.2018C15.0344 15.6927 15.7142 15.0699 15.7142 14.3128V8.5128C15.7142 7.75569 15.0344 7.13281 14.2018 7.13281H1.51234ZM2.75 9.2728H3.92857C4.79232 9.2728 5.5 9.91528 5.5 10.6995C5.5 11.4836 4.79232 12.1261 3.92857 12.1261H3.14286V13.1961C3.14286 13.2907 3.10146 13.3814 3.0278 13.4483C2.95414 13.5152 2.85418 13.5528 2.75 13.5528C2.53305 13.5528 2.35714 13.3931 2.35714 13.1961V9.62946C2.35714 9.4325 2.53305 9.2728 2.75 9.2728ZM6.67857 9.2728H7.01548C8.33979 9.2728 9.42856 10.228 9.42856 11.4128C9.42856 12.5976 8.33979 13.5528 7.01548 13.5528H6.67857C6.46162 13.5528 6.28571 13.3931 6.28571 13.1961V9.62946C6.28571 9.4325 6.46162 9.2728 6.67857 9.2728ZM10.6071 9.2728H12.9643C13.0685 9.2728 13.1684 9.31037 13.2421 9.37725C13.3157 9.44412 13.3571 9.53488 13.3571 9.62946C13.3571 9.72404 13.3157 9.8148 13.2421 9.88167C13.1684 9.94855 13.0685 9.98612 12.9643 9.98612H11V11.0094H12.9643C13.0685 11.0094 13.1684 11.047 13.2421 11.1139C13.3157 11.1808 13.3571 11.2715 13.3571 11.3661C13.3571 11.4607 13.3157 11.5514 13.2421 11.6183C13.1684 11.6852 13.0685 11.7228 12.9643 11.7228H11V13.1961C11 13.2907 10.9586 13.3814 10.8849 13.4483C10.8113 13.5152 10.7113 13.5528 10.6071 13.5528C10.3902 13.5528 10.2143 13.3931 10.2143 13.1961V9.62946C10.2143 9.4325 10.3902 9.2728 10.6071 9.2728ZM3.14286 9.98612V11.4128H3.92857C4.37071 11.4128 4.71428 11.1009 4.71428 10.6995C4.71428 10.298 4.37071 9.98612 3.92857 9.98612H3.14286ZM7.07142 9.99584V12.8297C7.95695 12.8026 8.64285 12.1866 8.64285 11.4128C8.64285 10.6389 7.95695 10.0229 7.07142 9.9958V9.99584Z"
                                                fill="#64808E"
                                            />
                                            <path
                                                d="M7.46429 0C6.38287 0 5.5 0.801534 5.5 1.78332V6.41994H14.2021C15.4663 6.41994 16.5002 7.36846 16.5002 8.51325V14.3132C16.5002 15.458 15.4663 16.4065 14.2021 16.4065H5.5V18.1898C5.5 19.1716 6.38287 19.9731 7.46429 19.9731H20.0357C21.1171 19.9731 22 19.1716 22 18.1898V5.70661H17.2857C16.422 5.70661 15.7143 5.06413 15.7143 4.27996V0H7.46429ZM16.5 0.167823V4.27985C16.5 4.68125 16.8436 4.99317 17.2857 4.99317H21.7399C21.6997 4.9427 21.6584 4.89302 21.6101 4.84828L16.7809 0.359211C16.6974 0.28167 16.601 0.220846 16.5001 0.167664L16.5 0.167823Z"
                                                fill="#64808E"
                                            />
                                        </svg>
                                        {myProfile && (
                                            <div
                                                className={
                                                    isHoveringPdf &&
                                                    PdfId === val.id
                                                        ? "triangle1"
                                                        : ""
                                                }
                                                onMouseEnter={() => {
                                                    setIsHoveringPdf(true);
                                                    setPdfId(val.id);
                                                }}
                                            ></div>
                                        )}
                                        {myProfile && (
                                            <div
                                                id={val.id}
                                                className={
                                                    isHoveringPdf &&
                                                    PdfId === val.id
                                                        ? "delete-img-show-pdf"
                                                        : "delete-img-hide"
                                                }
                                            >
                                                <div>
                                                    <svg
                                                        width="14"
                                                        height="17"
                                                        viewBox="0 0 14 17"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        onClick={() =>
                                                            handleDelete(
                                                                "pdf",
                                                                val.id
                                                            )
                                                        }
                                                    >
                                                        <path
                                                            d="M4.41396 12.5177C4.55523 12.5177 4.69077 12.4586 4.79137 12.3532C4.89197 12.2478 4.94952 12.1046 4.95147 11.9544V7.36659C4.95147 7.16256 4.84903 6.97393 4.68266 6.87197C4.5164 6.76989 4.31153 6.76989 4.14516 6.87197C3.9789 6.97392 3.87646 7.16255 3.87646 7.36659V11.9544C3.87841 12.1046 3.93596 12.2478 4.03656 12.3532C4.13705 12.4586 4.27269 12.5177 4.41397 12.5177H4.41396Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M7.00038 12.5177C7.14166 12.5177 7.27719 12.4586 7.3778 12.3532C7.47839 12.2478 7.53594 12.1046 7.53789 11.9544V7.36659C7.53789 7.16256 7.43545 6.97393 7.26908 6.87197C7.10282 6.76989 6.89796 6.76989 6.7317 6.87197C6.56533 6.97392 6.46289 7.16255 6.46289 7.36659V11.9544C6.46484 12.1046 6.52238 12.2478 6.62298 12.3532C6.72358 12.4586 6.85912 12.5177 7.00039 12.5177H7.00038Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M9.58339 12.5177C9.72467 12.5177 9.86031 12.4586 9.96081 12.3532C10.0614 12.2478 10.119 12.1046 10.1209 11.9544V7.36659C10.1209 7.16256 10.0185 6.97393 9.8522 6.87197C9.68583 6.76989 9.48096 6.76989 9.3147 6.87197C9.14834 6.97392 9.0459 7.16255 9.0459 7.36659V11.9544C9.04785 12.1046 9.10539 12.2478 9.20599 12.3532C9.30659 12.4586 9.44213 12.5177 9.5834 12.5177H9.58339Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M13.4623 2.76548H10.0224L9.5799 1.19014C9.48189 0.847828 9.28351 0.54771 9.01363 0.333576C8.74385 0.119547 8.41662 0.00264836 8.07989 0H5.92241C5.58568 0.00264377 5.25856 0.119544 4.98867 0.333576C4.71889 0.547719 4.5204 0.847857 4.4224 1.19014L3.97989 2.76548H0.537515C0.345511 2.76548 0.167995 2.87433 0.0720431 3.051C-0.0240144 3.22779 -0.0240144 3.44549 0.0720431 3.62216C0.16799 3.79895 0.3455 3.9078 0.537515 3.9078H1.17756V14.9032C1.18015 15.3194 1.33743 15.7176 1.61512 16.0112C1.89278 16.3047 2.26837 16.4699 2.66004 16.4706H11.3575C11.7505 16.4699 12.1271 16.3037 12.405 16.0084C12.6829 15.7131 12.8393 15.3128 12.84 14.8953V3.88897H13.4625C13.6051 3.88897 13.7418 3.82885 13.8426 3.72172C13.9433 3.6146 14 3.4693 14 3.31779C13.9994 3.16825 13.942 3.02525 13.8408 2.92121C13.7396 2.81719 13.6032 2.76098 13.4625 2.76523L13.4623 2.76548ZM5.4624 1.51692H5.46229C5.4929 1.4114 5.55359 1.31864 5.63601 1.25151C5.71855 1.18438 5.81861 1.14622 5.92234 1.14231H8.09485C8.20107 1.14139 8.30459 1.17783 8.38919 1.24611C8.47378 1.31439 8.53468 1.4106 8.56237 1.51957L8.90733 2.76547H5.08973L5.4624 1.51692ZM11.7648 15.0203H11.7272H11.7274C11.6993 15.1057 11.6474 15.1799 11.5786 15.2331C11.5098 15.2863 11.4274 15.3158 11.3423 15.3178H2.65977C2.55073 15.3157 2.44678 15.2685 2.37019 15.1861C2.29361 15.1037 2.25034 14.9927 2.24969 14.8769V3.8894H11.7646L11.7648 15.0203Z"
                                                            fill="white"
                                                        />
                                                    </svg>
                                                </div>
                                                {/* </div> */}
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className="each-title"
                                        onClick={() =>
                                            handlePDFDownload(val.pdf_link)
                                        }
                                    >
                                        {/* {val.pdf_title} */}
                                        <p
                                            style={{
                                                color: "white",
                                                paddingLeft: "8px",
                                            }}
                                        >
                                            {val.pdf_title}
                                        </p>
                                    </div>
                                    {/* <div> */}
                                </div>
                            ))}
                        </ScrollMenu>
                    </div>
                    <hr />
                    <div
                        className={
                            links?.length === 0
                                ? "pdf-section-none"
                                : "links-section"
                        }
                        style={{
                            display: "flex",
                            overflow: "scroll",
                        }}
                    >
                        <ScrollMenu
                            options={{
                                ratio: 0.9,
                                rootMargin: "5px",
                                threshold: [0.01, 0.05, 0.5, 0.75, 0.95, 1],
                            }}
                        >
                            {!linkLoading &&
                                links?.map((val, ind) => (
                                    <div
                                        key={ind}
                                        className="each-link"
                                        onMouseEnter={() => {
                                            setIsHoveringLink(true);
                                            setLinkId(val.id);
                                        }}
                                        onMouseLeave={() =>
                                            setIsHoveringLink(false)
                                        }
                                    >
                                        <div>
                                            <LinkView
                                                {...val?.metaData}
                                                link={val?.link}
                                            />

                                            {myProfile && (
                                                <div
                                                    className={
                                                        isHoveringLink &&
                                                        LinkId === val.id
                                                            ? "triangle2"
                                                            : ""
                                                    }
                                                    onMouseEnter={() => {
                                                        setIsHoveringLink(true);
                                                        setLinkId(val.id);
                                                    }}
                                                ></div>
                                            )}
                                            {myProfile && (
                                                <div
                                                    id={val.id}
                                                    className={
                                                        isHoveringLink &&
                                                        LinkId === val.id
                                                            ? "delete-img-show"
                                                            : "delete-img-hide"
                                                    }
                                                    onMouseEnter={() => {
                                                        setIsHoveringLink(true);
                                                        setLinkId(val.id);
                                                    }}
                                                >
                                                    {/* <div
                                                style={{
                                                    margin: "10px 0 0 173px",
                                                    cursor: "pointer",
                                                }}
                                            > */}
                                                    <svg
                                                        width="14"
                                                        height="17"
                                                        viewBox="0 0 14 17"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        onClick={() => {
                                                            setLinkLoading(
                                                                true
                                                            );
                                                            handleDelete(
                                                                "link",
                                                                val.id
                                                            );
                                                        }}
                                                    >
                                                        <path
                                                            d="M4.41396 12.5177C4.55523 12.5177 4.69077 12.4586 4.79137 12.3532C4.89197 12.2478 4.94952 12.1046 4.95147 11.9544V7.36659C4.95147 7.16256 4.84903 6.97393 4.68266 6.87197C4.5164 6.76989 4.31153 6.76989 4.14516 6.87197C3.9789 6.97392 3.87646 7.16255 3.87646 7.36659V11.9544C3.87841 12.1046 3.93596 12.2478 4.03656 12.3532C4.13705 12.4586 4.27269 12.5177 4.41397 12.5177H4.41396Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M7.00038 12.5177C7.14166 12.5177 7.27719 12.4586 7.3778 12.3532C7.47839 12.2478 7.53594 12.1046 7.53789 11.9544V7.36659C7.53789 7.16256 7.43545 6.97393 7.26908 6.87197C7.10282 6.76989 6.89796 6.76989 6.7317 6.87197C6.56533 6.97392 6.46289 7.16255 6.46289 7.36659V11.9544C6.46484 12.1046 6.52238 12.2478 6.62298 12.3532C6.72358 12.4586 6.85912 12.5177 7.00039 12.5177H7.00038Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M9.58339 12.5177C9.72467 12.5177 9.86031 12.4586 9.96081 12.3532C10.0614 12.2478 10.119 12.1046 10.1209 11.9544V7.36659C10.1209 7.16256 10.0185 6.97393 9.8522 6.87197C9.68583 6.76989 9.48096 6.76989 9.3147 6.87197C9.14834 6.97392 9.0459 7.16255 9.0459 7.36659V11.9544C9.04785 12.1046 9.10539 12.2478 9.20599 12.3532C9.30659 12.4586 9.44213 12.5177 9.5834 12.5177H9.58339Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M13.4623 2.76548H10.0224L9.5799 1.19014C9.48189 0.847828 9.28351 0.54771 9.01363 0.333576C8.74385 0.119547 8.41662 0.00264836 8.07989 0H5.92241C5.58568 0.00264377 5.25856 0.119544 4.98867 0.333576C4.71889 0.547719 4.5204 0.847857 4.4224 1.19014L3.97989 2.76548H0.537515C0.345511 2.76548 0.167995 2.87433 0.0720431 3.051C-0.0240144 3.22779 -0.0240144 3.44549 0.0720431 3.62216C0.16799 3.79895 0.3455 3.9078 0.537515 3.9078H1.17756V14.9032C1.18015 15.3194 1.33743 15.7176 1.61512 16.0112C1.89278 16.3047 2.26837 16.4699 2.66004 16.4706H11.3575C11.7505 16.4699 12.1271 16.3037 12.405 16.0084C12.6829 15.7131 12.8393 15.3128 12.84 14.8953V3.88897H13.4625C13.6051 3.88897 13.7418 3.82885 13.8426 3.72172C13.9433 3.6146 14 3.4693 14 3.31779C13.9994 3.16825 13.942 3.02525 13.8408 2.92121C13.7396 2.81719 13.6032 2.76098 13.4625 2.76523L13.4623 2.76548ZM5.4624 1.51692H5.46229C5.4929 1.4114 5.55359 1.31864 5.63601 1.25151C5.71855 1.18438 5.81861 1.14622 5.92234 1.14231H8.09485C8.20107 1.14139 8.30459 1.17783 8.38919 1.24611C8.47378 1.31439 8.53468 1.4106 8.56237 1.51957L8.90733 2.76547H5.08973L5.4624 1.51692ZM11.7648 15.0203H11.7272H11.7274C11.6993 15.1057 11.6474 15.1799 11.5786 15.2331C11.5098 15.2863 11.4274 15.3158 11.3423 15.3178H2.65977C2.55073 15.3157 2.44678 15.2685 2.37019 15.1861C2.29361 15.1037 2.25034 14.9927 2.24969 14.8769V3.8894H11.7646L11.7648 15.0203Z"
                                                            fill="white"
                                                        />
                                                    </svg>
                                                    {/* </div> */}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                        </ScrollMenu>
                    </div>
                </div>
            </div>

            {/* Modal */}
            <div className="profile-modal add-other-profile modal-wrapper">
                <Modal
                    size="lg"
                    active={showModal}
                    toggler={() => {
                        setShowModal(false);
                        setIndex(0);
                    }}
                    className="profile-tailwind-modal"
                >
                    <ModalBody>
                        {index === 0 && (
                            <div className="add-other-box">
                                <div className="top-bar">Add other info</div>
                                <div
                                    className="each-button d-flex"
                                    onClick={() => setIndex(1)}
                                >
                                    <div className="icon-box me-4 d-flex justify-content-center align-items-center">
                                        <BiText fontSize={24} />
                                    </div>
                                    <div className="each-heading d-flex align-items-center">
                                        Text
                                    </div>
                                </div>
                                <div
                                    className="each-button d-flex"
                                    onClick={() => setIndex(2)}
                                >
                                    <div className="icon-box me-4 d-flex justify-content-center align-items-center">
                                        <BiImage fontSize={24} />
                                    </div>
                                    <div className="each-heading d-flex align-items-center">
                                        Image
                                    </div>
                                </div>
                                <div
                                    className="each-button d-flex"
                                    onClick={() => setIndex(3)}
                                >
                                    <div className="icon-box me-4 d-flex justify-content-center align-items-center">
                                        <AiOutlineFilePdf fontSize={24} />
                                    </div>
                                    <div className="each-heading d-flex align-items-center">
                                        Doc
                                    </div>
                                </div>
                                <div
                                    className="each-button d-flex"
                                    onClick={() => setIndex(4)}
                                >
                                    <div className="icon-box me-4 d-flex justify-content-center align-items-center">
                                        <AiOutlineLink fontSize={24} />
                                    </div>
                                    <div className="each-heading d-flex align-items-center">
                                        Link
                                    </div>
                                </div>
                            </div>
                        )}
                        {index === 1 && (
                            <div className="p-3">
                                <h1 className="modal-head">
                                    Add Text <span>(upto 50 words)</span>
                                </h1>
                                <div className="form-group mb-4">
                                    <input
                                        type="text"
                                        value={tempTitle}
                                        className="modal-input"
                                        placeholder="Write here..."
                                        onChange={(e) => onChange(e)}
                                    />
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="close-button">
                                        <Button
                                            color="red"
                                            buttonType="link"
                                            onClick={() => {
                                                setShowModal(false);
                                                setIndex(0);
                                            }}
                                            ripple="dark"
                                        >
                                            Close
                                        </Button>
                                    </div>
                                    <div className="save-button">
                                        <Button
                                            color="green"
                                            onClick={() => {
                                                updateText();
                                            }}
                                            ripple="light"
                                        >
                                            Save Changes
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {index === 2 && (
                            <div className="add-other-image-container">
                                <div className="p-3">
                                    <h1 className="modal-head">Add Image</h1>
                                    <div className="add-other-image">
                                        <div className="other-image-label">
                                            <label>
                                                <input
                                                    name="other-image"
                                                    type="file"
                                                    className="modal-input"
                                                    accept=".jpg,.jpeg"
                                                    onChange={onFileSelect}
                                                />
                                                Choose File
                                            </label>
                                        </div>
                                        <div className="other-image-span">
                                            <span>
                                                {tempImage
                                                    ? tempImage.name
                                                    : "No file selected"}
                                            </span>
                                            <span
                                                style={{
                                                    justifyContent: "center",
                                                    display: !isError
                                                        ? "inline"
                                                        : "none",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                Max 500 KB(Jpeg, JPG)
                                            </span>
                                            <span
                                                style={{
                                                    justifyContent: "center",
                                                    display: isError
                                                        ? "inline"
                                                        : "none",
                                                    color: "red",
                                                    fontWeight: "bold",
                                                    fontSize: "15px",
                                                }}
                                            >
                                                Max 500 KB (Jpeg, JPG)
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="close-button">
                                            <Button
                                                color="red"
                                                buttonType="link"
                                                onClick={() => {
                                                    setShowModal(false);
                                                    setIndex(0);
                                                }}
                                                ripple="dark"
                                            >
                                                Close
                                            </Button>
                                        </div>
                                        <div className="save-button">
                                            <Button
                                                color="green"
                                                onClick={() => {
                                                    handleImages();
                                                }}
                                                ripple="light"
                                            >
                                                Save Changes
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {index === 3 && (
                            <div className="add-other-pdf-container">
                                <div className="p-3">
                                    <h1 className="modal-head">Add Doc</h1>
                                    <div className="add-other-pdf">
                                        <div className="other-pdf-label">
                                            <label>
                                                <input
                                                    name="other-pdf"
                                                    type="file"
                                                    className="modal-input"
                                                    accept=".pdf"
                                                    onChange={onFileSelect}
                                                />
                                                Choose File
                                            </label>
                                        </div>
                                        <div className="other-pdf-span">
                                            <span>
                                                {tempPdf
                                                    ? tempPdf.name
                                                    : "No file selected"}
                                            </span>
                                            <span
                                                style={{
                                                    justifyContent: "center",
                                                    display: !isError
                                                        ? "inline"
                                                        : "none",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                Max 5 MB
                                            </span>
                                            <span
                                                style={{
                                                    justifyContent: "center",
                                                    display: isError
                                                        ? "inline"
                                                        : "none",
                                                    color: "red",
                                                    fontWeight: "bold",
                                                    fontSize: "15px",
                                                }}
                                            >
                                                Max 5 MB
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="close-button">
                                            <Button
                                                color="red"
                                                buttonType="link"
                                                onClick={() => {
                                                    setShowModal(false);
                                                    setIndex(0);
                                                }}
                                                ripple="dark"
                                            >
                                                Close
                                            </Button>
                                        </div>
                                        <div className="save-button">
                                            <Button
                                                color="green"
                                                onClick={() => {
                                                    handlePdfLinks();
                                                }}
                                                ripple="light"
                                            >
                                                Save Changes
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {index === 4 && (
                            <div className="p-3">
                                <h1 className="modal-head">Add Link</h1>
                                <div className="form-group mb-4">
                                    <input
                                        type="text"
                                        className="modal-input"
                                        placeholder="Paste link here..."
                                        onChange={(e) => {
                                            editLinkFunction(e);
                                        }}
                                    />
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="close-button">
                                        <Button
                                            color="red"
                                            buttonType="link"
                                            onClick={() => {
                                                setShowModal(false);
                                                setIndex(0);
                                            }}
                                            ripple="dark"
                                        >
                                            Close
                                        </Button>
                                    </div>
                                    <div className="save-button">
                                        <Button
                                            color="green"
                                            onClick={() => {
                                                handleLinks();
                                            }}
                                            ripple="light"
                                        >
                                            Save Changes
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </ModalBody>
                </Modal>
            </div>
            <div
                className="profile-modal add-other-profile modal-wrapper"
                onClick={() => {
                    setShowImageModal(false);
                }}
            >
                <Modal
                    size="lg"
                    active={showImageModal}
                    toggler={() => {
                        setShowModal(false);
                        setIndex(0);
                    }}
                    className="profile-tailwind-modal"
                >
                    <ModalBody>
                        <div className="carousel-img">
                            <img
                                src={modalImage}
                                alt="Lorem"
                                style={{
                                    width: "100%",
                                    height: "105%",
                                    borderRadius: "10px",
                                }}
                                onClick={() => {
                                    setShowImageModal(false);
                                }}
                            />
                        </div>
                        <div className="slide-cont">
                            <div onClick={() => handleCarousel("backward")}>
                                <IoIosArrowDropleft height="2em" width="2em" />
                            </div>
                            <div onClick={() => handleCarousel("forward")}>
                                <IoIosArrowDropright />
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        </>
    );
};

export default OtherInfo;
