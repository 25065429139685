import React from "react";
import Logo from "../../assets/backbone-logo.svg";
import insta from "../../assets/Group 193.svg";
import linkedin from "../../assets/Group 190.svg";
import facebook from "../../assets/Group 255.svg";
import youtube from "../../assets/Group 251.svg";
import copyright from "../../assets/copyright.svg";
import { HashLink as Link } from "react-router-hash-link";

import "./Footer.css";
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();
    return (
        <div className="footer">
            <div className="container py-4">
                <div className="row">
                    <div className="updfooter">
                        <div className="socials">
                            <img
                                className="socio"
                                src={insta}
                                alt="1"
                                onClick={() => {
                                    window.open(
                                        "https://www.instagram.com/bizbackbone/",
                                        "_blank"
                                    );
                                }}
                            />

                            <img
                                className="socio"
                                src={linkedin}
                                alt="2"
                                onClick={() => {
                                    window.open(
                                        "https://www.linkedin.com/company/bizbackbone/",
                                        "_blank"
                                    );
                                }}
                            />
                            <img
                                className="socio"
                                src={facebook}
                                alt="3"
                                onClick={() => {
                                    window.open(
                                        "https://www.facebook.com/BizBackBone/",
                                        "_blank"
                                    );
                                }}
                            />
                            <img
                                className="socio"
                                src={youtube}
                                alt="4"
                                onClick={() => {
                                    window.open(
                                        "https://www.youtube.com/channel/UCqesRLmmh3ATqioJwtVtG3Q",
                                        "_blank"
                                    );
                                }}
                            />
                        </div>
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <img
                                    style={{
                                        width: "4rem",
                                        justifyContent: "center",
                                    }}
                                    src={Logo}
                                    alt="logo"
                                    onClick={() => navigate("/")}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                padding: "0.4rem",
                            }}
                        >
                            <span
                                style={{
                                    color: "#fff",
                                    fontFamily: "Lobster",
                                    fontSize: "30px",
                                }}
                            >
                                We've got your back!
                            </span>
                            &nbsp;
                            <div className="copyright-image">
                                <img src={copyright} alt="copyright" />
                            </div>
                        </div>
                        <div className="footer-div font-semibold">
                            <ul className="footer-list">
                                <Link to="#contact" style={{ color: "white" }}>
                                    <li className="active1">Contact Us</li>
                                </Link>

                                <li
                                    className="active2"
                                    onClick={() => {
                                        window.open(
                                            "/terms-conditions",
                                            "_blank"
                                        );
                                    }}
                                >
                                    Website T&amp;C's
                                </li>
                                <li
                                    className="active2"
                                    onClick={() => {
                                        window.open("/faqfooter", "_blank");
                                    }}
                                >
                                    FAQ
                                </li>
                                <li
                                    className="active3"
                                    onClick={() => {
                                        window.open(
                                            "/privacy-policy",
                                            "_blank"
                                        );
                                    }}
                                >
                                    Privacy Policy
                                </li>
                                <li
                                    className="active2"
                                    onClick={() => {
                                        window.open("/pricing", "_blank");
                                    }}
                                >
                                    Pricing
                                </li>
                                <li
                                    className="active4"
                                    onClick={() => {
                                        window.open(
                                            "/cookies-policy",
                                            "_blank"
                                        );
                                    }}
                                >
                                    Cookie Policy
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
