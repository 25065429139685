import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import Rating from "@material-ui/lab/Rating";
// import { BsHeart, BsLink, BsChatLeftText, BsHeartFill } from "react-icons/bs";
// import Radio from "@material-ui/core/Radio";
import { useDispatch, useSelector } from "react-redux";
// import Modal from "@material-tailwind/react/Modal";
// import ModalBody from "@material-tailwind/react/ModalBody";
// import BlueCheck from "../../assets/blue-check.svg";
// import { BsPeople } from "react-icons/bs";
import "./Favourites.css";
import { useToast } from "@chakra-ui/react";
import Button from "@material-tailwind/react/Button";
import { Link, useSearchParams } from "react-router-dom";
import {
    contentActions,
    getAllFavouriteAction,
    getSearchedCompaniesAction,
    getInTouchAction,
    markFavouriteAction,
    UnmarkFavouriteAction,
    getCompaniesAction,
    getIndustriesAction,
} from "../../data/reducers/home/content";
import Fav from "./Components/Fav";
import EmptyState from "../Errors/EmptyState";
import Pagination from "../../components/Pagination";
// import { getUserProfile } from "../../../../data/reducers/auth/auth";
const Favourites = () => {
    // const [value, setValue] = useState(fav?.avg_ratings);
    // const text = `Hi ${service?.company_name}`
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const { user } = useSelector((state) => state.auth);
    // const [isMarked, setisMarked] = useState(false);
    const [page, setPage] = useState(1);
    // const [openModel, setOpenModel] = useState(false);
    // const [sentEmailModel, setSentEmailModel] = useState(false);
    const [empty, setEmpty] = useState(false);
    const [search, setSearch] = useState("");
    const [location, setLocation] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [type, setType] = useState(null);
    const [sort, setSort] = useState(null);
    const [refresh, setRefresh] = useState(false);
    // const [content, setContent] = useState(text);

    const {
        companies,
        allfavourites,
        allReviews,
        favCount,
        allUniqueLocation,
        industries,
    } = useSelector((state) => state.content);

    const count = 5;
    const services = favCount;
    const pageCount = new Array(Math.ceil(services / count) || 1).fill(0);
    const pageLength = pageCount?.length;
    const toast = useToast();
    const { success, message, error } = useSelector((state) => state.content);

    let options = {
        page,
        company_name: search,
        location,
        industry_name: type,
        sort,
    };

    useEffect(() => {
        dispatch(getCompaniesAction());
        dispatch(getIndustriesAction());
        // dispatch(UnmarkFavouriteAction());
    }, [dispatch]);

    useEffect(() => {
        if (success && message) {
            dispatch(contentActions.reset());
            dispatch(getAllFavouriteAction(options));
        }
        dispatch(contentActions.reset());
    }, [dispatch, success, message]);

    useEffect(() => {
        let getPage = searchParams.get("page") || 0;
        setPage(getPage);
        dispatch(getAllFavouriteAction(options));
    }, [dispatch, searchParams]);

    const handlePagination = (val) => {
        setPage(val);
        navigate(`/liked-businesses/?page=${val}`);
    };

    useEffect(() => {
        if (!favCount) {
            setEmpty(true);
        } else {
            setEmpty(false);
        }
    }, [favCount]);

    useEffect(() => {
        if (refresh) {
            console.log("5");
            console.log(options);
            dispatch(getAllFavouriteAction(options));
            setRefresh(false);
        }
    }, [refresh]);

    const searchCompanies = async (e, r) => {
        e?.preventDefault();
        // if (search === "") {

        //     !user && dispatch(getUserProfile());
        //     let getPage = searchParams.get("page") || 1;
        //     dispatch(getCompaniesAction(getPage));
        //     dispatch(getIndustriesAction());

        //     setPage(getPage);
        // } else {

        let q = { getPage: page };
        if (search && search !== "") {
            q["search"] = search;
        }
        if (location) {
            q["location"] = location;
        }
        if (sort) {
            q["sort"] = sort;
        }
        if (type) {
            q["industry_name"] = type;
        }
        if (refresh || r) {
            q.getPage = 1;
            setPage(1);
            setSearchParams(`?page=1`);
            setRefresh(false);
        }

        console.log(q, location, sort);
        dispatch(getSearchedCompaniesAction(q));
        // }
        if (!allfavourites) {
            dispatch(contentActions.isNotFound(true));
        }
    };

    console.log("allfavourites", allfavourites);
    return (
        <>
            <div className="fav-wrapper">              
                <form className="ml-auto mr-auto mb-4 text-sm col-xl-7 col-lg-8 col-md-12 col-sm-12 col-12 main-top-form">
                    <div className="noti-headings mb-3 mt-0">
                        <p className="noti-heading-title">Favourites</p>
                    </div>
                    <div className="w-100">
                        <div className="header-form-fav">
                            <div className="flex flex-col w-full relative">
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    value={search}
                                    onChange={(e) => {
                                        setSearch(e.target.value);
                                    }}
                                    onKeyUp={(e) => {
                                        if (e.key === "Enter") {
                                            setPage(1);
                                            setRefresh(true);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-100 d-flex mt-3 items-center">
                        <div className="w-40">
                            <div className="header-form-fav rounded-lg bg-white items-center">
                                <select
                                    name="location"
                                    id="location"
                                    onChange={(e) => {
                                        setLocation(
                                            e.target.value === "main"
                                                ? null
                                                : e.target.value
                                        );
                                        setRefresh(true);
                                    }}
                                >
                                    <option value="main">
                                        Sort by Location
                                    </option>

                                    {allUniqueLocation?.map((location,index) => {
                                        return location?.trim()!="" && location?.trim()!="undefined" && location?.trim()!="null" && location!=null && location!= undefined && typeof location !="null" && typeof location!="undefined" &&(
                                            <option value={location} key={index}>
                                                {location}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="w-40">
                            <div className="header-form-fav rounded-lg bg-white items-center">
                                <select
                                    name="type"
                                    id="type"
                                    onChange={(e) => {
                                        setType(
                                            e.target.value === "main"
                                                ? null
                                                : e.target.value
                                        );
                                        setRefresh(true);
                                    }}
                                >
                                    <option value="main">Sort by Type</option>

                                    {industries?.map((industry,index) => {
                                        return (
                                            <option
                                               key={index}
                                                value={industry.industry_name}
                                            >
                                                {industry.industry_name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="w-40">
                            <div className="header-form-fav rounded-lg bg-white items-center">
                                <select
                                    name="order"
                                    id="order"
                                    onChange={(e) => {
                                        setSort(
                                            e.target.value === ""
                                                ? null
                                                : e.target.value
                                        );
                                        setRefresh(true);
                                    }}
                                >
                                    <option value="">Sort by order</option>
                                    <option value={"DESC"}>Recent</option>
                                    <option value={"ASC"}>Old</option>
                                </select>
                            </div>
                        </div>
                        <div className="w-20 mobile-hide">
                            <div className="submit-button">
                                <Button
                                    color={"blue"}
                                    buttonType="filled"
                                    size="regular"
                                    rounded={false}
                                    onClick={(e) => {
                                        searchCompanies(e, true);
                                        setRefresh(true);
                                    }}
                                >
                                    Search
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
                {empty ? (
                    <EmptyState />
                ) : (
                    <div className="row justify-content-center">
                        <div className="col-xl-11 col-lg-12 col-md-12 col-12">
                            {search
                                ? companies?.map((fav, i) => {
                                      return (
                                          <Fav
                                              key={i}
                                              fav={fav}
                                              i={i}
                                              // allReviews={allReviews}
                                          />
                                      );
                                  })
                                : allfavourites?.map((fav, i) => {
                                      return (
                                          <Fav
                                              fav={fav}
                                              i={i}
                                              key={i}
                                              // allReviews={allReviews}
                                          />
                                      );
                                  })}
                        </div>
                        <div className="col-xl-11 col-lg-12 col-md-12 col-12">
                            {pageCount?.length > 1 && (
                                <Pagination
                                    pages={pageCount}
                                    page={page}
                                    handlePagination={handlePagination}
                                    pageLength={pageLength}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Favourites;
