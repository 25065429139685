import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    getEventsService,
    getSearchEventsService,
    addEventService,
    updateEventService,
    deleteEventService,
    likeEventService,
    unlikeEventService,
    getLikedEventsService,
    getLikedEventsByCompanyService,
    getMyEventsService,
    getInvitedEventsService,
    getLikedSearchEventsService,
    userRegistredEvents,
    getUserRegistredEventsById,
    editUserRegistredEventsById,
    deleteAttendeesData,
    registerPodcastUser,
    registerSpotLiteUser,
    userRegistredPodcastEvents,
    userRegistredSpotlightEvents,
} from "../../services/events/events";

const initialState = {
    success: false,
    error: null,
    loading: true,
    message: null,
    events: [],
    searchEvents: [],
    likedSearchEvents: [],
    myEvents: [],
    invitedEvent: {},
    addEvent: {},
    updateEvent: {},
    deleteEvent: {},
    likeEvent: {},
    unlikeEvent: {},
    likedEvents: [],
    likedEventByCompany: {},
    eventsCount: 0,
    myEventsCount: 0,
    likedEventsCount: 0,
    registeredEvent:[],
    totalRegisteredEvent:[],
    registeredUserEvent:undefined,
    backABusinessStatus:false,
    backingCompanyId:undefined,
    registeredPodcastEvent:[],
    totalCountPodcastEvent:0,
    totalCountSpotlightEvent:0,
    registeredSpotlightEvent:[]
};

export const getEvents = createAsyncThunk("get/events", async (page) => {
    const response = await getEventsService(page);
    if (response?.error) {
        return { error: response };
    }
    return response;
});

export const getSearchEvents = createAsyncThunk(
    "get/searchEvents",
    async (value) => {
        const response = await getSearchEventsService(value);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const getLikedSearchEvents = createAsyncThunk(
    "get/likedSearchEvents",
    async (value) => {
        const response = await getLikedSearchEventsService(value);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const getMyEvents = createAsyncThunk("get/myevents", async (data) => {
    const response = await getMyEventsService(data);
    if (response?.error) {
        return { error: response };
    }
    return response;
});

export const getInvitedEvent = createAsyncThunk(
    "get/invitedEvents",
    async (page) => {
        const response = await getInvitedEventsService(page);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const addEvent = createAsyncThunk("add/event", async (event) => {
    const response = await addEventService(event);
    if (response?.error) {
        return { error: response };
    }
    return response;
});

export const updateEvent = createAsyncThunk("update/event", async (event) => {
    const response = await updateEventService(event);
    if (response?.error) {
        return { error: response };
    }
    return response;
});

export const deleteEvent = createAsyncThunk("delete/event", async (event) => {
    const response = await deleteEventService(event);
    if (response?.error) {
        return { error: response };
    }
    return response;
});

export const likeEvent = createAsyncThunk("like/event", async (event) => {
    const response = await likeEventService(event);
    if (response?.error) {
        return { error: response };
    }
    return response;
});

export const unlikeEvent = createAsyncThunk("unlike/event", async (event) => {
    const response = await unlikeEventService(event);
    if (response?.error) {
        return { error: response };
    }
    return response;
});

export const getLikedEvents = createAsyncThunk(
    "get/likedEvents",
    async (page) => {
        const response = await getLikedEventsService(page);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const getLikedEventsByCompany = createAsyncThunk(
    "get/likedEventsByCompany",
    async (leader) => {
        const response = await getLikedEventsByCompanyService(leader);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const getUserRegistredEvents = createAsyncThunk(
    "get/registeredEvent",
    async (search) => {
        const response = await userRegistredEvents(search);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);


export const getUserRegistredPodcastEvents = createAsyncThunk(
    "get/registeredPodcastEvent",
    async (search) => {
        const response = await userRegistredPodcastEvents(search);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);


export const getUserRegistredSpotlightEvents = createAsyncThunk(
    "get/registeredSpotlightEvent",
    async (search) => {
        const response = await userRegistredSpotlightEvents(search);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const getUserRegisteredEventById = createAsyncThunk(
    "get/registeredUserEventById",
    async (id) => {
        const response = await getUserRegistredEventsById(id);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const registerPodCastData = createAsyncThunk(
    "post/registerPodCast",
    async (data) => {
        const response = await registerPodcastUser(data);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const registerSpotLiteData = createAsyncThunk(
    "post/registerSpotLite",
    async (data) => {
        const response = await registerSpotLiteUser(data);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

const eventSlice = createSlice({
    name: "events",
    initialState,
    reducers: {
        reset: (state, action) => {
            state.error = null;
            state.message = null;
            state.loading = false;
            state.success = false;
        },
        backABusinessStatusFunc:(state, action)=>{
            if(action?.payload)
             state.backABusinessStatus=true
            else
            state.backABusinessStatus=false
       },
       setBackingCompanyId:(state, action)=>{
        if(action?.payload)
         state.backingCompanyId=action.payload
        else
        state.backingCompanyId=undefined
       }
    },

    extraReducers: {
        [getEvents.pending]: (state, action) => {
            state.loading = true;
        },
        [getEvents.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.events = action.payload?.events;
                state.eventsCount = action.payload?.totalEvents;
            }
        },
        [getInvitedEvent.pending]: (state, action) => {
            state.loading = true;
        },
        [getInvitedEvent.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.invitedEvent = action.payload?.InvitedEvent;
                state.eventsCount = action.payload?.length;
            }
        },
        [getMyEvents.pending]: (state, action) => {
            state.loading = true;
        },
        [getMyEvents.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.myEvents = action.payload?.events;
                state.myEventsCount = action.payload?.length;
            }
        },
        [getSearchEvents.pending]: (state, action) => {
            state.loading = true;
        },
        [getSearchEvents.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.searchEvents = action.payload?.events;
            }
        },


        [registerPodCastData.pending]: (state, action) => {
            state.loading = true;
        },
        [registerPodCastData.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
            }
            if (!action.payload?.success) {
                state.success = false;
                state.message = action.payload?.message;
            }
        },
        [registerPodCastData.rejected]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            state.success = false;
            state.message = action.payload?.message;
        },

        [registerSpotLiteData.pending]: (state, action) => {
            state.loading = true;
        },
        [registerSpotLiteData.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
            }
            if (!action.payload?.success) {
                state.success = false;
                state.message = action.payload?.message;
            }
        },
        [registerSpotLiteData.rejected]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            state.success = false;
            state.message = action.payload?.message;
        },
        [getLikedSearchEvents.pending]: (state, action) => {
            state.loading = true;
        },
        [getLikedSearchEvents.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                console.log(action.payload?.events);
                state.success = action.payload?.success;
                state.likedEvents = action.payload?.events;
                state.likedEventsCount = action.payload?.eventslength;
            }
        },
        [addEvent.pending]: (state, action) => {
            state.loading = true;
        },
        [addEvent.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [updateEvent.pending]: (state, action) => {
            state.loading = true;
        },
        [updateEvent.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [deleteEvent.pending]: (state, action) => {
            state.loading = true;
        },
        [deleteEvent.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [likeEvent.pending]: (state, action) => {
            state.loading = true;
        },
        [likeEvent.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [unlikeEvent.pending]: (state, action) => {
            state.loading = true;
        },
        [unlikeEvent.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [getLikedEvents.pending]: (state, action) => {
            state.loading = true;
        },
        [getLikedEvents.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.likedEvents = action.payload?.likedEvents;
                state.likedEventsCount = action.payload?.likedLength;
            }
        },
        [getLikedEventsByCompany.pending]: (state, action) => {
            state.loading = true;
        },
        [getLikedEventsByCompany.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.socials = action.payload?.result;
            }
        },
        [getUserRegistredEvents.pending]: (state, action) => {
            state.loading = true;
        },
        [getUserRegistredEvents.fulfilled]: (state, action) => {
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.registeredEvent = action.payload._list
                state.totalRegisteredEvent=action.payload.length
            }
        },



        [getUserRegistredPodcastEvents.pending]: (state, action) => {
            state.loading = true;
        },
        [getUserRegistredPodcastEvents.fulfilled]: (state, action) => {
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.registeredPodcastEvent=action.payload?.podcastRequests;
                state.totalCountPodcastEvent=action.payload?.length
            }
        },


        [getUserRegistredSpotlightEvents.pending]: (state, action) => {
            state.loading = true;
        },
        [getUserRegistredSpotlightEvents.fulfilled]: (state, action) => {
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.registeredSpotlightEvent=action.payload?.spotlightRequests;
                state.totalCountSpotlightEvent=action.payload?.length
            }
        },


        [getUserRegisteredEventById.pending]: (state, action) => {
            state.loading = true;
        },
        [getUserRegisteredEventById.fulfilled]: (state, action) => {
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.registeredUserEvent = action.payload._list
            }
        }
    },
});

export const eventActions = eventSlice.actions;
export default eventSlice.reducer;
