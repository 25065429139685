import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNewCompaniesAction } from "../../../data/reducers/home/content";
import RedirectIcon from "../../../assets/Redirect-Icon.svg";
import { Link } from "react-router-dom";
import "./NewOnBackbone.css";

const NewCard = ({ company }) => {
    const text =
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus praesentium cum tempore natus suscipit architecto impedit sunt laboriosam maxime repellat.";

    const content = text.length > 28 ? `${text.substring(0, 28)}...` : text;

    return (
            <div className="new-card" key={company?.id}>
                <div className="d-flex justify-content-space-between">
                    <div className="d-flex">
                        <figure className="new-card-logo">
                            <img
                                src={
                                    company?.company_logo
                                        ? company?.company_logo
                                        : "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"
                                }
                                alt="Logo"
                            />
                        </figure>
                        <div className="new-card-title">
                            <h4>{company?.company_name}</h4>
                        </div>
                    </div>
                    <div className="new-card-title">
                        <Link
                            to={`/company/${company?.id}`}
                            className="company_visit"
                        >
                            <img src={RedirectIcon} />
                        </Link>
                    </div>
                </div>
                <div className="new-card-title-body pt-3">
                    {company?.about_company}
                </div>
            </div>
    );
};

const NewOnBackbone = () => {
    const dispatch = useDispatch();
    const { newCompanies } = useSelector((state) => state.content);
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // if (user) {
        dispatch(getNewCompaniesAction());
        // }
    }, [dispatch]);

    return (
        <div className="new-ob-backbone">
            <h1 className="new-heading">New On Backbone</h1>
            {newCompanies?.map((company,index) => (
                <NewCard key={index} company={company} />
            ))}
        </div>
    );
};

export default NewOnBackbone;
