import React, { useEffect } from "react";
import { useState } from "react";
import "./AdPayment.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    CardElement,
} from "@stripe/react-stripe-js";
import { payAdvertisementAPI } from "../../data/api";
import {
    addAdvertisementAction,
    updateAdvertisementAction,
} from "../../data/reducers/advertisement/advertisement";
import { useToast } from "@chakra-ui/react";
import { getUserProfile } from "../../data/reducers/auth/auth";
import { getBizBucksByForCompanyById } from "../../data/reducers/home/content";
const AdPayment = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth);
    const [availCredit, setAvailCredit] = useState(false);
    const [showDiv, setShowDiv] = useState(false);
    const { launchForm } = useSelector((state) => state.details);
    const {
        myCompany,
        myBizBucks
    } = useSelector((state) => state?.content);
    const stripe = useStripe();
    const elements = useElements();
    const [cardDetails, setcardDetails] = useState({});
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const [promo, setpromo] = useState("");
    
    useEffect(() => {
        if (myCompany && myCompany?.id) {
            dispatch(getBizBucksByForCompanyById(myCompany?.id));
        }
    }, [myCompany, dispatch]);

    const submitHandler = async (e) => {
        e.preventDefault();

        if (!stripe || !elements || !CardNumberElement) {
            console.log("return");
            return;
        }
        document.querySelector("#pay_btn").disabled = true;

        setLoading(true);
        let res;
        try {
            let paymentData = {};

            // if (availCredit) {
            //     paymentData.amount = launchForm?.budget * 100;
            // } else {
            //     paymentData.amount =
            //         (launchForm?.budget - (user?.adCredit || 0)) * 100;
            // }

            if (availCredit) {
                if (launchForm.budget <  myBizBucks?.balance) {
                    paymentData.amount = 0;
                } else {
                    paymentData.amount =
                        (launchForm.budget -  myBizBucks?.balance) * 100;
                }
            } else {
                paymentData.amount = launchForm.budget * 100;
            }
            if (paymentData.amount === 0) {
                const data1 = new FormData();
                data1.append("budget", launchForm?.budget);
                data1.append("target_market", launchForm?.targetMarket);
                data1.append("type", launchForm?.campaignType);
                data1.append("advertisement_image", launchForm?.campaign);
                data1.append("payment_completed", true);
                data1.append("creditUsed", availCredit);
                data1.append(
                    "usedAmount",
                    launchForm.budget <  myBizBucks?.balance
                        ? launchForm.budget
                        :  myBizBucks?.balance || 0
                );
                await dispatch(addAdvertisementAction(data1));
                // dispatch(detailsActions.advertisementReset());
                navigate("/paymentconfirm", {
                    state: {
                        creditUsed: availCredit,
                        usedAmount: launchForm.budget || 0,
                        budget: launchForm?.budget,
                        paymentMethod: "Not Needed",
                    },
                });
            } else {
                res = await payAdvertisementAPI(paymentData);

                const clientSecret = res.data.clientSecret;

                const result = await stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: elements.getElement(CardNumberElement),
                        billing_details: {
                            name: user.name,
                            email: user.email,
                        },
                    },
                });

                // elements.create("cardCvc").mount(".cvv-input");
                // elements.create("cardExpiry").mount("#4");
                // elements.create("cardExpiry").mount("#e");

                console.log(CardNumberElement);
                console.log(result.paymentIntent.payment_method);

                if (result.error) {
                    document.querySelector("#pay_btn").disabled = false;
                    setLoading(false);
                    navigate("/paymentfailed", {
                        state: {
                            creditUsed: availCredit,
                            usedAmount:  myBizBucks?.balance || 0,
                            budget: launchForm?.budget,
                            // paymentMethod:,
                        },
                    });
                } else {
                    if (result.paymentIntent.status === "succeeded") {
                        if (launchForm?.isOld) {
                            await dispatch(
                                updateAdvertisementAction({
                                    budget: launchForm?.budget,
                                    target_market: launchForm?.targetMarket,
                                    type: launchForm?.campaignType,
                                    payment_completed: true,
                                    oldId: launchForm?.oldId,
                                    creditUsed: availCredit,
                                    usedAmount:  myBizBucks?.balance || 0,
                                })
                            );
                            // dispatch(detailsActions.advertisementReset());
                            navigate("/paymentconfirm", {
                                state: {
                                    creditUsed: availCredit,
                                    usedAmount:  myBizBucks?.balance || 0,
                                    budget: launchForm?.budget,
                                    paymentMethod:
                                        result.paymentIntent.payment_method,
                                },
                            });
                        } else {
                            const data1 = new FormData();
                            data1.append("budget", launchForm?.budget);
                            data1.append(
                                "target_market",
                                launchForm?.targetMarket
                            );
                            data1.append("type", launchForm?.campaignType);
                            data1.append(
                                "advertisement_image",
                                launchForm?.campaign
                            );
                            data1.append("payment_completed", true);
                            data1.append("creditUsed", availCredit);
                            data1.append("usedAmount",  myBizBucks?.balance || 0);
                            await dispatch(addAdvertisementAction(data1));
                            // dispatch(detailsActions.advertisementReset());
                            navigate("/paymentconfirm", {
                                state: {
                                    creditUsed: availCredit,
                                    usedAmount:  myBizBucks?.balance || 0,
                                    budget: launchForm?.budget,
                                    paymentMethod:
                                        result.paymentIntent.payment_method,
                                },
                            });
                        }
                    } else {
                        navigate("/paymentfailed", {
                            state: {
                                creditUsed: availCredit,
                                usedAmount:  myBizBucks?.balance || 0,
                                budget: launchForm?.budget,
                            },
                        });
                    }
                }
            }
        } catch (error) {
            toast({
                title: "Card details cannot be empty!",
                position: "top-right",
                isClosable: true,
                duration: 3000,
                status: "error",
            });
            setLoading(false);
            document.querySelector("#pay_btn").disabled = false;
        }
        dispatch(getUserProfile());
    };

    return (
        <>
            <div className="adpayment">
                <div className="adpayment-head-panel">
                    <b>Select payment mode{" >>"}</b>
                </div>
                <div className="main-card-panel">
                    <div className="payment-left-panel">
                        <div className="cvv-card-number">
                            <div className="add-cvv-card-number flex-col space-y-4">
                                <div className="add-card-number">
                                    <div className="card-number-label font-medium">
                                        <label>Card number</label>
                                    </div>
                                    {console.log(cardDetails)}
                                    <div className="card-number-input">
                                        <CardNumberElement
                                            type="text"
                                            id="card_num_field"
                                            className="add-card-number-input"
                                            placeholder="adds"
                                            onChange={(e) => {
                                                console.log(e);
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="add-name">
                                    <div className="name-label font-medium">
                                        <label>Name of card holder</label>
                                    </div>
                                    <div className="name-input">
                                        <input
                                            name="name"
                                            type="text"
                                            placeholder="Name on card"
                                            id="a"
                                        />
                                    </div>
                                </div>

                                <div className="d-flex flex-row justify-between w-full">
                                    <div className="add-card-number">
                                        <div className="card-number-label font-medium">
                                            <label>Valid till</label>
                                        </div>
                                        <div
                                            className="card-number-input"
                                            style={{ width: "80%" }}
                                        >
                                            <CardExpiryElement
                                                type="text"
                                                id="card_exp_field"
                                                className="add-card-number-input"
                                            />
                                        </div>
                                    </div>

                                    <div className="add-cvv">
                                        <div className="cvv-label font-medium">
                                            <label>CVV</label>
                                        </div>
                                        <div className="cvv-input">
                                            <CardCvcElement
                                                type="text"
                                                id="card_cvc_field"
                                                className="add-cvv-input"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="available-credits"
                            onClick={() => {
                                setAvailCredit(true);
                            }}
                        >
                            <div className="avaiable-credit-text">
                                <b className="font-medium text-xl poppins">
                                    Available BixBucks
                                </b>
                                <br />
                                {myBizBucks?.balance > 0 && (
                                    <span className="text-sm font-normal cursor-pointer">
                                        (Click to redeem now)
                                    </span>
                                )}
                            </div>
                            <div className="avaiable-credit font-medium text-xl poppins">
                                $ {myBizBucks?.balance>0 ? myBizBucks?.balance : 0}
                            </div>
                        </div>
                    </div>

                    <div className="payment-right-panel">
                        <div className="payment-detail-head">
                            <b className="poppins font-semibold text-2xl">
                                Payment details
                            </b>
                        </div>
                        <div className="selected-criteria">
                            <div
                                className="selected-criteria-text poppins text-xl font-normal"
                                style={{ color: "#555555" }}
                            >
                                Selected Criteria
                            </div>
                            <div
                                className="selected-criteria-value poppins text-xl font-normal"
                                style={{ color: "#555555" }}
                            >
                                {launchForm.campaignType}
                            </div>
                        </div>
                        <div className="total-price mb-4 mt-0">
                            <div
                                className="total-price-text poppins text-xl font-normal"
                                style={{ color: "#555555" }}
                            >
                                Total price
                            </div>
                            <div
                                className="total-price-value poppins text-xl font-normal"
                                style={{ color: "#555555" }}
                            >
                                ${launchForm.budget}
                            </div>
                        </div>
                        {availCredit && (
                            <div className="free-credit">
                                <div className="free-credit-text">
                                    Free credit
                                </div>
                                <div className="free-credit-value">
                                    -$
                                    {launchForm.budget < myBizBucks?.balance
                                        ? launchForm.budget
                                        : myBizBucks?.balance}
                                </div>
                            </div>
                        )}
                        <hr />
                        <div className="total-amount mt-0">
                            <div className="total-amount-text poppins font-medium text-2xl">
                                <b> Total Amount</b>
                            </div>
                            <div className="total-amount-value poppins font-medium text-2xl">
                                <b>
                                    {availCredit
                                        ? launchForm.budget <  myBizBucks?.balance
                                            ? 0
                                            : `$${
                                                  launchForm.budget -
                                                  myBizBucks?.balance
                                              }`
                                        : `$${launchForm.budget}`}
                                </b>
                            </div>
                        </div>
                    </div>
                </div>{" "}
                <div className="adPaymentBtn">
                    <button id="backBtn" onClick={(e) => navigate(-1)}>
                        Back
                    </button>{" "}
                    <div className="ad-payment-button">
                        <button id="pay_btn" onClick={(e) => submitHandler(e)}>
                            {!loading ? " Make Payment" : "Processing"}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdPayment;
