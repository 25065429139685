import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import SearchIcon from "../../assets/search-icon.svg";
import ArrowIcon from "../../assets/Arrow-icon.svg";
import {
    contentActions,
    getBlogsAction,
} from "../../data/reducers/home/content";
import "./AllBlogs.css";
const ArrowImg = styled.img`
    margin-right: 30px;
    @media (max-width: 770px) {
        max-width: 20px;
        margin-right: 10px;
    }
`;
const Container = styled.div`
    position: relative;
    bottom: 60px;
    margin-left: 40px;
    margin-right: 40px;
    @media (max-width: 770px) {
        margin-left: 20px;
        margin-right: 20px;
    }
`;
const AllBlogsConatainer = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    color: #000000;
    margin-bottom: 20px;
    margin-top: 80px;
`;

const SimilarBlogs = styled.div`
    display: flex;
    margin-bottom: 30px;

    @media (max-width: 770px) {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
`;
const SimilarBlogContain = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    @media only screen and (max-width: 1024px) and (min-width: 770px) {
        margin-top: 140px;
        margin-left: -140px;
    }
    @media (min-width: 1025px) {
        margin-top: 140px;
        margin-left: -140px;
    }
`;

const MiniBlogTitle = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #555555;
`;

const MiniDescription = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #4e4e4e;
`;

const BlogsContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 20px;
    @media (max-width: 770px) {
        width: 100%;
        display: grid;
        grid-template-columns: auto;
        row-gap: 20px;
        align-items: center;
    }
    @media only screen and (max-width: 1024px) and (min-width: 770px) {
        grid: auto / auto auto;
    }
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    @media (max-width: 770px) {
        flex-direction: column;
        width: 100%;
        align-items: space-between;
    }
`;

const BackButton = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 770px) {
        width: 100%;
        justify-content: flex-start;
    }
`;

const SearchInput = styled.div`
    margin-top: 20px;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 7px;
    font-size: 16px;
    display: flex;
    @media (max-width: 770px) {
        width: 100%;
        justify-content: space-between;
    }
`;

const SearchImg = styled.img`
    height: 20px;
`;

export const AllBlogs = () => {
    const brand = ["software", "Branding"];
    const [searchItem, setSearchItem] = useState("");
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const { totalBlogsCount, blogs } = useSelector((state) => state.content);
    const count = 9;
    const blogsCount = totalBlogsCount;
    const pageCount = new Array(Math.ceil(blogsCount / count) || 1).fill(0);
    const pageLength = pageCount?.length;
    useEffect(() => {
        dispatch(getBlogsAction({ page, searchItem }));
    }, [dispatch, page, searchItem]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [page]);
    const handlePagination = (val) => {
        setPage(val);
    };

    const SmallBlogDetail = ({ blog, ind }) => {
        let desc = {};
        try {
            desc = blog?.blog_description && JSON.parse(blog?.blog_description);
        } catch (error) {
            console.log(error);
        }
        return (
            <div className="Blog-card">
                <Link
                    onClick={() => {
                        dispatch(contentActions.setCurrentBlogId(blog.id));
                    }}
                    to={`/Blogs?id=${blog.id}`}
                >
                    <SimilarBlogs>
                        <img
                            src={blog?.blog_image}
                            alt=""
                            className="otherImg object-cover"
                        />
                        <SimilarBlogContain className="">
                            <MiniBlogTitle>{blog?.blog_title}</MiniBlogTitle>
                            <MiniDescription>
                                {desc?.blocks?.[0]?.text?.length > 200
                                    ? `${desc?.blocks?.[0]?.text?.substring(
                                        0,
                                        200
                                    )}...`
                                    : desc?.blocks?.[0]?.text}
                            </MiniDescription>
                        </SimilarBlogContain>
                    </SimilarBlogs>
                </Link>
                <div className="service-tags-blog-card">
                    <div>
                        {brand.map((service) => {
                            return (
                                <div>
                                    <span>{service}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Container>
            <AllBlogsConatainer>
                <Header>
                    <BackButton>
                        <Link to={"/?page=1"}>
                            <ArrowImg src={ArrowIcon} />
                        </Link>
                        <p style={{ fontSize: "36px" }}>All blogs</p>
                    </BackButton>
                    <SearchInput>
                        <input
                            style={{
                                backgroundColor: "#f5f5f5",
                            }}
                            type="text"
                            placeholder="Search By Keywords"
                            value={searchItem}
                            onChange={(e) => setSearchItem(e.target.value)}
                        />
                        <SearchImg src={SearchIcon} />
                    </SearchInput>
                </Header>
            </AllBlogsConatainer>
            <BlogsContainer>
                {blogs?.map((blog, i) => (
                    <SmallBlogDetail blog={blog} ind={i} />
                ))}
            </BlogsContainer>
            <div className="d-flex justify-content-evenly">
                { }{" "}
                <button
                    className="pagination-pre"
                    disabled={page == 1}
                    onClick={() => {
                        if (page == 1) {
                            return;
                        } else {
                            handlePagination(page - 1);
                        }
                    }}
                >
                    {"<"} &nbsp; Previous
                </button>
                <div className="pagination-buttons d-flex">
                    {pageCount.map((val, ind) => (
                        <button
                            className={
                                page == parseInt(ind) + 1 ? "active-button" : ""
                            }
                            key={ind}
                            onClick={() => handlePagination(ind + 1)}
                        >
                            {ind + 1}
                        </button>
                    ))}
                </div>
                <button
                    key={page}
                    className="pagination-pre"
                    disabled={page == pageLength}
                    onClick={() => {
                        if (page == pageLength) {
                            return;
                        } else {
                            handlePagination(parseInt(page) + 1);
                        }
                    }}
                >
                    Next &nbsp; {">"}
                </button>
            </div>
        </Container>
    );
};
