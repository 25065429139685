import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { ImLink } from "react-icons/im";
import openSocket from "socket.io-client";
import debounce from "lodash.debounce";
import Modal from "@material-tailwind/react/Modal";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import ModalBody from "@material-tailwind/react/ModalBody";
import triangle from "../../../assets/triangle.png";
import { useDispatch, useSelector } from "react-redux";
import {
    backedBizBucks,
    contentActions,
    getAllCompaniesAction,
    getBizBucksByForCompanyById,
    getCompaniesAction,
    getIndustriesAction,
    getSearchedCompaniesAction,
    getTotalNumberOfBackedCompany,
} from "../../../data/reducers/home/content";
import { eventActions } from "../../../data/reducers/events/events";
import bgvector from "../../../assets/CustomIcons/6.png";
import { useToast } from "@chakra-ui/react";
import { API_END_POINT } from "../../../config/api";
const HomeCardOne = ({ getLatestCredentials }) => {
    const toast = useToast();
    // const socket = openSocket(API_END_POINT, {
    //     secure: true, transports: ['websocket', 'polling']
    //   })
    const inputRef = useRef(undefined);
    const { backABusinessStatus } = useSelector((state) => state.events);
    const [search, setSearch] = useState("");
    const [visible, setVisible] = useState(false);
    const [showSuggestion, setShowSuggestions] = useState(false);
    const [backedBizData, setBackedBizData] = useState({
        backed_id: undefined,
        backed_company_id: undefined,
        interaction_type: "",
        free_text: "",
        backType:"NEW"
    });
    const dispatch = useDispatch();
    const {
        allCompanies,
        loading,
        message,
        success,
        error,
        type,
        totalBackedBizBuck,
        myCompany,
        bizBucksConfig
    } = useSelector((state) => state.content);
    //   useEffect(() => {
    //     socket.on('notification', (data) => {
    //         console.log(data, "data New Updated")
    //     })
    //   },[socket])

    const handleClose = () => {
        setVisible(false);
        setSearch("")
    };

    useEffect(() => {
        if (search) dispatch(getAllCompaniesAction(`?search=${search}`));
    }, [dispatch, search]);
    useEffect(() => {
        if (backABusinessStatus) {
            inputRef.current.focus();
            setTimeout(() => {
                dispatch(eventActions.backABusinessStatusFunc(false));
            }, 1000);
        }
    }, [backABusinessStatus]);
    useEffect(() => {
        dispatch(getTotalNumberOfBackedCompany("?id="));
        // dispatch(getBannersAction());
    }, [dispatch]);

    const handleSubmitBackedBiz = async () => {
        if (
            !backedBizData?.backed_id ||
            !backedBizData?.backed_company_id ||
            !backedBizData?.interaction_type?.trim()
        ) {
            toast({
                title: "All fields are required",
                position: "top-right",
                duration: 3000,
                status: "error",
                isClosable: true,
            });
            return;
        } else if (
            backedBizData?.interaction_type ==
                "Other: Enter Free Text (200 max)" &&
            !backedBizData?.free_text?.trim()
        ) {
            toast({
                title: "All fields are required",
                position: "top-right",
                duration: 3000,
                status: "error",
                isClosable: true,
            });
            return;
        } else {
            if (myCompany?.id == backedBizData?.backed_company_id) {
                toast({
                    title: "You can't backed own company",
                    position: "top-right",
                    duration: 3000,
                    status: "error",
                    isClosable: true,
                });
                return;
            } else {
                let response = await dispatch(backedBizBucks(backedBizData));
                if (response?.payload?.success) {
                    setVisible(true);
                    setBackedBizData({
                        backed_id: undefined,
                        backed_company_id: undefined,
                        interaction_type: "",
                        free_text: "",
                        backType:"NEW"
                    });
                    if(myCompany && myCompany?.id)
                       dispatch(getBizBucksByForCompanyById(myCompany?.id));
                    getLatestCredentials();
                } else {
                    toast({
                        title: `${response?.payload?.message}`,
                        position: "top-right",
                        duration: 3000,
                        status: "error",
                        isClosable: true,
                    });
                }
            }
        }
    };

    return (
        <div className="home_card">
            <div className="d-flex gap-2 gap-md-5 align-items-center">
                <div
                    className="text-center"
                    style={{
                        backgroundImage: `url(${bgvector})`,
                        width: "140px",
                        height: "140px",
                        backgroundSize: "cover",
                        borderRadius: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        lineHeight: "29px",
                    }}
                >
                    <span style={{ fontSize: "14px" }}>Backed By</span>
                    <div className="fs-2 fw-bolder">
                        {totalBackedBizBuck > 0 ? totalBackedBizBuck : 0}
                    </div>
                    <span style={{ fontSize: "14px" }}>Businesses</span>
                </div>
                <div className="text-center">
                    <p style={{ fontSize: "14px", color: "#0078BE" }}>
                        We want to hear <br /> about your experiences!
                    </p>
                    <h4 className="fs-3 fw-bolder">Back a Biz</h4>
                    <p style={{ fontSize: "14px", color: "#0078BE" }}>
                        or be backed by a biz to <br /> earn 100 BizBucks!
                    </p>
                </div>
            </div>
            <div className="my-3">
                <input
                    type="text"
                    className="form-control p-2"
                    placeholder="Enter a Business Name"
                    ref={inputRef}
                    onChange={(e) => {
                        if (e.target.value === "") {
                            setShowSuggestions(false);
                        } else {
                            setShowSuggestions(true);
                        }

                        setSearch(e.target.value);
                    }}
                    value={search}
                />
                {showSuggestion && (
                    <div
                        className="w-full"
                        style={{ maxHeight: "200px", overflowY: "scroll" }}
                    >
                        {allCompanies
                            ?.filter(
                                (item) =>
                                    item?.User != null &&
                                    item?.User?.id != null &&
                                    item?.User != undefined &&
                                    item?.User?.id != undefined
                            )
                            .map((company) => (
                                <div
                                    onClick={() => {
                                        setSearch(company?.company_name);
                                        setBackedBizData({
                                            ...backedBizData,
                                            backed_company_id: company?.id,
                                            backed_id: company?.User?.id,
                                        });
                                        setShowSuggestions(false);
                                    }}
                                    className="suggestion-box cursor-pointer"
                                >
                                    <p className="px-4 py-2">
                                        {company?.company_name}
                                    </p>
                                </div>
                            ))}
                    </div>
                )}
            </div>
            <div className="mx-auto text-center">
                <div className="mx-auto text-center">
                    <ImLink
                        style={{
                            transform: "scale(1.4)",
                            margin: "auto",
                            transform: "rotate(135deg)",
                        }}
                    />
                </div>
            </div>
            <div className="my-3">
                <select
                    className="form-control p-2"
                    value={backedBizData?.interaction_type}
                    onChange={(e) => {
                        setBackedBizData({
                            ...backedBizData,
                            interaction_type: e?.target?.value,
                        });
                    }}
                >
                    <option value="" disabled>Interaction</option>
                    <option value="Met at an event & highly rate">
                        Met at an event & highly rate
                    </option>
                    <option value="Partnered together & highly rate">
                        Partnered together & highly rate
                    </option>
                    <option value="Outstanding Partnership Outcome">
                        Outstanding Partnership Outcome
                    </option>
                    <option value="Other: Enter Free Text (200 max)">
                        Other: Enter Free Text (200 max)
                    </option>
                </select>
            </div>

            {backedBizData?.interaction_type?.trim() ==
                "Other: Enter Free Text (200 max)" && (
                <div>
                    <textarea
                        rows="2"
                        className="form-control p-2"
                        placeholder="Enter Text"
                        value={backedBizData?.free_text}
                        onChange={(e) => {
                            if(e?.target?.value?.length<200){
                                setBackedBizData({
                                    ...backedBizData,
                                    free_text: e?.target?.value,
                                });
                            }
                        }}
                    />
                </div>
            )}
            <div className="mx-auto my-2 w-100 text-end">
                <button
                    className="post_btn"
                    onClick={() => {
                        // setVisible(true)4
                        handleSubmitBackedBiz();
                    }}
                >
                    Post
                </button>
            </div>

            <Modal
                size="xl"
                backdrop
                className="mb-0"
                active={visible}
                toggler={handleClose}
            >
                <ModalBody className="mb-0">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div
                                    className="row mb-0"
                                    style={{
                                        border: "2px solid #03b0fb",
                                        borderRadius: "10px",
                                        padding: "10px 15px",
                                    }}
                                >
                                    <div className="col-10">
                                        <div className="">
                                            <h3
                                                className="fs-4 fw-bolder"
                                                style={{ color: "#162452" }}
                                            >
                                                You have backed {search}!
                                            </h3>
                                            <p
                                                className="fw-bold my-2 w-100"
                                                style={{
                                                    fontSize: "12px",
                                                    color: "#162452",
                                                }}
                                            >
                                                Thank you for backing a
                                                businesses
                                            </p>
                                        </div>
                                        <div
                                            className="d-flex justify-content-between align-items-center"
                                            style={{
                                                background: "#ebffe4",
                                                padding: "10px",
                                                borderRadius: "15px",
                                            }}
                                        >
                                            <div className="d-flex justify-content-start align-items-center">
                                                <span
                                                    style={{
                                                        fontSize: "45px",
                                                        color: "#162452",
                                                        fontWeight: "900",
                                                    }}
                                                >
                                                    +
                                                </span>
                                                <span
                                                    className="ms-2"
                                                    style={{
                                                        color: "#ffd79c",
                                                        fontSize: "50px",
                                                        fontWeight: "900",
                                                    }}
                                                >
                                                    {
                                                        bizBucksConfig?.find((item)=>item?.configKey=="back_a_business")?.configValue
                                                    }
                                                </span>
                                            </div>
                                            <div>
                                                <h3 className="fs-6 fw-bolder text-center pe-5">
                                                    BizBucks credited to your{" "}
                                                    <br /> account
                                                </h3>
                                            </div>
                                        </div>

                                        <div className="my-2 w-100">
                                            <p style={{ fontSize: "10px" }}>
                                                Your backing will be posted on
                                                the Dashboard under “Success
                                                Stories”
                                            </p>
                                            <p style={{ fontSize: "10px" }}>
                                                To edit or delete, go to “My
                                                Backings” on your profile
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div
                                            style={{
                                                display: "flex",
                                                height: "100%",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <img src={triangle} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default memo(HomeCardOne);
