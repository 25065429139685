import React from "react";
import "./Mission.css";
import Mission from "../../../assets/mission-v2.png";
import Image from "../../../assets/unsplash_ejhjSZKTeeg.svg";
import Button from "@material-tailwind/react/Button";
import { Navigate, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";

const Misson = () => {
    const navigate = useNavigate();
    return (
        <div className="mission py-5">
            <div className="container">
                <div className="row">
                    <div className="col-xxl-6 col-xl-7 col-lg-8 mx-auto">
                        <div className="col-padding">
                            <h1 className="mission-title-v2">
                                MEMBER BENEFITS&nbsp;
                            </h1>
                            <p className="mission-points">
                                • Refer a friend and earn 1 month free
                                subscription credit* for every successful
                                referral <br />
                                • Promote upcoming events for free <br /> • Ask
                                your customers to endorse your business and both
                                of you earn advertising* credit
                                <br /> • Leverage this free credit to advertise
                                to other business owners in a targeted fashion{" "}
                                <br /> • Browse the business directory to find
                                relevant business partner to suit current/future
                                needs <br /> • Leverage customer endorsements to
                                boost your ranking on searches <br />
                                • Access our partner subscriptions as a value
                                add (coming soon) <br />
                                <span className="conditions-apply">
                                    *Conditions apply
                                </span>
                            </p>
                        </div>
                        <div className="d-flex justify-content-center image-box-mission">
                            <img src={Mission} alt="Mission" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Misson;
