import React from "react";
import styled from "styled-components";
import SettingPage from "./SettingPage";

const BoilerPlate = styled.div`
    display: flex;
    position: relative;
    bottom: 5.6rem;
`;
const Setting = () => {
    return (
        <BoilerPlate>
            <SettingPage />
        </BoilerPlate>
    );
};
export default Setting;
