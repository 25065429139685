import React from "react";
import "./Why.css";

import Why3 from "../../../assets/why-v2-1.png";
import Why1 from "../../../assets/why-v2-2.png";
import Why2 from "../../../assets/why-v2-3.png";

const Why = () => {
    return (
        <div className="why-v2" id="why">
            <div className="why-box-v2">
                <div className="container py-5">
                    <h1 className="why-v2-title">WHY JOIN US</h1>
                    <div className="row row-v2">
                        <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-12 mx-auto why-col">
                            <div className="d-flex justify-content-center image-box-2">
                                <img src={Why1} alt="Why 1" />
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-12 mx-auto why-col">
                            <div className="d-flex justify-content-center image-box-2">
                                <img src={Why2} alt="Why 1" />
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-12 mx-auto why-col">
                            <div className="d-flex justify-content-center image-box-2">
                                <img src={Why3} alt="Why 1" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Why;
