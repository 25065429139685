import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllAdvertisementAction } from "../../../data/reducers/advertisement/advertisement";
import "./AllAdvertisements.css";
import EmptyState from "../../Errors/EmptyState";

function AllAdvertisements() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { advertisement } = useSelector((state) => state.advertisement);
    const [activeAd, setActiveAd] = useState([]);
    const [closedAd, setClosedAd] = useState([]);
    useEffect(() => {
        dispatch(getAllAdvertisementAction());
    }, [dispatch]);
    console.log("advertisement", advertisement);

    useEffect(() => {
        let activeAd1 = advertisement?.filter((item) => item?.currbudget > 0);

        let closedAd2 = advertisement?.filter((item) => {
            return item?.currbudget <= 0;
        });

        setActiveAd(activeAd1);

        setClosedAd(closedAd2);
    }, [advertisement]);

    return (
        <>
            <div className="active-campaign">
                <div className="active-image-head">
                    <p>Active campaign</p>
                </div>
                <div className="active-images">
                    {/* {console.log(advertisement?.length)} */}
                    {/* {} */}

                    {activeAd?.length === 0 ? (
                        // <div className="ads-empty">
                        <EmptyState active={true} />
                    ) : (
                        // </div>
                        <>
                            {advertisement?.map((item) => {
                                return (
                                    item.currbudget > 0 && (
                                        <div className="ads-imgs">
                                            {/* {console.log(item?.advertisement_image)} */}
                                            <img
                                                src={item?.advertisement_image}
                                                alt="advertisement"
                                                style={{
                                                    width: "156px",
                                                    height: "156px",
                                                }}
                                            />
                                        </div>
                                    )
                                    // <h1>aa</h1>
                                );
                            })}
                        </>
                    )}
                </div>
            </div>
            <div className="panel-closed-campaign">
                <div className="panel-closed-image-head">
                    <p> Closed campaign</p>
                </div>
                <div className="panel-closed-images">
                    {closedAd?.length === 0 ? (
                        // <div className="ads-empty">
                        <EmptyState showBtn={false} />
                    ) : (
                        // </div>
                        <>
                            {advertisement.map(
                                (item, i) =>
                                    item.currbudget <= 0 && (
                                        <div className="ads-imgs">
                                            <img
                                                src={item.advertisement_image}
                                                alt="advertisement"
                                            />
                                        </div>
                                    )
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default AllAdvertisements;
