import axios from "axios";
import { API_END_POINT } from "../config/api";

const API = axios.create({ baseURL: `${API_END_POINT}` });

API.interceptors.request.use((req) => {
    if (localStorage.getItem("token")) {
        req.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    }
    return req;
});

// Authentication APIs
export const loginServiceAPI = async (payload) =>
    API.post("/api/auth/login", payload);
export const verifyUserAPI = (payload) =>
    API.post("/api/auth/verify-email", { token: payload?.token });
export const signUpUserAPI = (payload) => API.post("/api/auth/signup", payload);

export const resetPasswordAPI = (payload) =>
    API.post(`/api/auth/reset-password/${payload?.token}`, {
        password: payload?.password,
        confirmPassword: payload?.conPassword,
    });
export const forgotPasswordAPI = (payload) =>
    API.post("/api/auth/forgot-password", payload);

export const changePasswordAPI = (payload) =>
    API.post("/api/auth/change-password", payload);

//Notification APIs
export const allNotificationApi = (search) => API.get(`/api/notifications/all${search}`);
export const updateNotificationApi = (payload) =>
    API.put(`/api/notifications/update`, payload);
// User APIs
export const getUserAPI = () => API.get(`/api/user/profile`);
export const getAllUserAPI = () => API.get(`/api/user/getAllUser`);
export const getEarlyUserAPI = () => API.get(`/api/user/getEarlyUser`);
export const getUserInvoices = () => API.get(`/api/user/getUserInvoices`);
export const getUserSubscriptionCreditService = () =>
    API.get(`/api/user/getUserSubscriptionCredit`);
export const upgradeUserPlan = (body) =>
    API.put(`/api/user/subscriptionchanged`, body);
// Company Details APIs

export const getStripeKeyAPI = () => API.get("/api/payment/stripeapikey");
export const getIndustriesAPI = () => API.get(`/api/industry/get-industries`);
export const getAllReviewsAPI = (id) =>
    API.get(`/api/company/getAllReviews/${id}`);
export const makePaymentAPI = (payload) =>
    API.post("/api/payment/process", payload);
export const makePaymentAPIWithCredits = (payload) =>
    API.post("/api/payment/withCredits", payload);
export const startFreeTrail = (payload) =>
    API.post("/api/payment/free", payload);
export const savePaymentAPI = (payload) =>
    API.post(`/api/payment/savepayment`, payload);
export const getSubscriptionsAPI = () => API.get(`/api/plans/getPlans`);
export const editCompanyInfoAPI = (payload) =>
    API.put(`/api/company/updateCompanyInfo`, payload);
export const addCompanyDetailsAPI = (payload) => {
    return API.post(`/api/company/add/company`, payload, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

export const deleteSubscription = (id) =>
    API.delete(`/api/plans/deletePlan/${id}`);

// Contact Us APIs
export const sendContactDetailsAPI = (payload) => {
    return API.post(`/api/contact/contactus/send`, {
        name: payload?.name,
        email: payload?.email,
        message: payload?.message,
    });
};

// Home APIs
export const getFAQAPI = () => API.get(`/api/faq/getFAQ`);
export const getCompaniesAPI = (page = 1) =>
    API.get(`/api/company/get/company?page=${page}`);
export const getAllCompaniesAPI = (search) => API.get(`/api/company/get/allcompany${search}`);
export const getNewCompaniesAPI = () => API.get(`/api/company/getNew`);
export const getMyCompanyAPI = () => API.get(`/api/company/getMyCompany`);
export const getMyCompanyIndustryPreference = () => API.get(`/api/mypreference/get`);

export const getCompanyByIdAPI = (id) =>
    API.get(`/api/company/getCompanyById/${id}`);
export const getSearchedCompaniesAPI = (keyword) =>
    API.post(
        `/api/browse/search?page=${keyword.getPage}&search=${
            keyword.search || "undefined"
        }${keyword?.location ? `&location=${keyword.location}` : ""}${
            keyword?.sort ? `&sort=${keyword.sort}` : ""
        }`,
        keyword
    );
export const markFavouriteAPI = (id) =>
    API.put(`/api/company/mark-favourite/${id}`);
export const unmarkFavouriteAPI = (id) =>
    API.put(`/api/company/unmark-favourite/${id}`);

// ${options?.location ? `&location=${options.location}` : ""}${options?.sort ? `&sort=${options.sort}` : ""}${options?.type ? `&type=${options.type}` : ""}
export const getFavouriteAPI = (options) =>
    API.get(`/api/company/get-all-favourites`, {
        params: { ...options },
    });

export const getInTouchAPI = (data) =>
    API.post(`/api/company/getInTouch`, data);
export const getBlogsAPI = (page = 1, searchItem) => {
    return API.get(`/api/blogs/getBlogs?page=${page}&search=${searchItem}`);
};

export const getBlogsPaginatedAPI = (page) =>
    API.get(`/api/blogs/getBlogsPaginated?page=${page}`);

export const getBlogByIdAPI = (id) => API.get(`/api/blogs/getBlogById/${id}`);

//Profile APIs
export const updateIndustryTypeAPI = (industry) =>
    API.put(`/api/company/updateIndustry/${industry}`);

export const addLeaderAPI = (leader) =>
    API.post(`/api/leader/addLeader`, leader, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
export const deleteLeaderAPI = (id) =>
    API.delete(`/api/profile/leader/delete-leader/${id}`);

export const addReviewAPI = (review) =>
    API.post(`api/feedback/add-review`, review);

//  Banner
export const addBannerAPI = (banner) =>
    API.post(`/api/profile/addBanner`, banner, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
export const getBanner = () => API.get(`/api/profile/getBanner`);
export const updateBannerAPI = (banner) =>
    API.put(`/api/profile/updateBanner`, banner, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });

// api for add and update demo banner

export const updateDemoBannerAPI = (bannerLink) =>
    API.put(`/api/profile/updateDemoBanner`, bannerLink, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });

export const addDemoBannerAPI = (bannerLink) =>
    API.post(`/api/profile/addDemoBanner`, bannerLink, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });

export const editProfileAPI = (profile) =>
    API.put(`/api/company/updateCompany`, profile, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });

// Social
export const addSocialAPI = (social) =>
    API.post(`/api/profile/social/add-social`, social, {
        // headers: {
        //     "Content-Type": "multipart/form-data",
        // },
    });
export const getSocial = () => API.get(`/api/profile/social/get-all-socials`);
export const updateSocialAPI = (updatedSocial) => {
    console.log("updatedSocial", updatedSocial);
    API.put(`/api/profile/social/update-social/${updatedSocial.id}`, {
        socialName: updatedSocial.socialName,
        socialLink: updatedSocial.socialLink,
    });
};

//Service
export const addServicesAPI = (services) =>
    API.post(`/api/services/add/services`, services, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
    
export const getServices = () => API.get(`/api/services/get/services`);


export const addPrefernceAPI = (id,services) =>API.post(`/api/mypreference/add?company_id=${id}`, {industry:services});
// Endorsement
export const sendEndorseAPI = (data) =>
    API.post(`/api/profile/endorse/request-endorsement`, data, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });

// OtherInfo
export const addTitleAPI = (data) =>
    API.post(`/api/profile/otherinfo/add-title`, data);

export const updateTitleAPI = (data) =>
    API.put(`/api/profile/otherinfo/update-title`, data);

export const deleteTitleAPI = (id) =>
    API.delete(`/api/profile/otherinfo/delete-title/${id}`);

export const getTitleAPI = () => API.get(`/api/profile/otherinfo/get-title`);

export const addImagesAPI = (img) =>
    API.post(`/api/profile/otherinfo/addImg`, img, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
export const getImagesAPI = () => API.get(`/api/profile/otherinfo/get-images`);

export const addLinkAPI = (link) =>
    API.post(`/api/profile/otherinfo/addLink`, link, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
export const getLinksAPI = () => API.get(`/api/profile/otherinfo/get-links`);

export const addPdfLinkAPI = (link) =>
    API.post(`/api/profile/otherinfo/addPdf`, link, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });

export const getPdfLinksAPI = () => API.get(`/api/profile/otherinfo/get-pdfs`);

export const deleteImageAPI = (id) =>
    API.delete(`/api/profile/otherinfo/delete-image/${id}`);

export const deletePdfAPI = (id) =>
    API.delete(`/api/profile/otherinfo/delete-pdf/${id}`);

export const deleteLinkAPI = (id) =>
    API.delete(`/api/profile/otherinfo/delete-link/${id}`);

// OtherInfo
export const deleteServicesAPI = (id) =>
    API.delete(`/api/services/deleteServices/${id}`);
export const deletePrefernceAPI = (id) =>
    API.delete(`/api/mypreference/delete/${id}`);

export const deleteSocialAPI = (id) =>
    API.delete(`/api/profile/social/delete-social/${id}`);

//Events
export const getEventsAPI = (page) =>
    API.get(`/api/events/getEvents?page=${page.page}&upcoming=true`);
export const getMyEventsAPI = (data) =>
    API.get(
        `/api/events/getMyEvents?page=${data.page}&company_id=${data.company_id}`
    );
export const getInvitedEventAPI = (event) =>
    API.get(`/api/events/get-invited-event/${event.id}`);
export const addEventAPI = (event) => API.post(`/api/events/addEvent`, event);
export const updateEventAPI = (data) =>
    API.put(`/api/events/updateEvent/${data.id}`, data);
export const deleteEventAPI = (id) => API.post(`/api/events/deleteEvent/${id}`);
export const likeEventAPI = (id) => API.put(`/api/events/like-event/${id}`);
export const unlikeEventAPI = (id) => API.put(`/api/events/unlike-event/${id}`);
export const getLikedEventsAPI = (page) =>
    API.get(`/api/events/get-all-likedevents?page=${page.page}`);
export const getLikedEventsByCompanyAPI = (id) =>
    API.get(`/api/events/get-all-likedevents-company/${id}`);
export const getSearchEventsAPI = (value) =>
    API.get(`/api/events/search?search=${value.searchText}`);
export const getLikedSearchEventsAPI = (value) =>
    API.get(`/api/events/like-search?search=${value.searchText}`);

// advertisement

export const addAdvertisementAPI = (ad) =>
    API.post(`/api/advertisement/add/advertisement`, ad, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });

export const updateAdvertisementAPI = (ad) =>
    API.put(`/api/advertisement/update/advertisement`, ad);

export const getAdvertisementAPI = () =>
    API.get(`/api/advertisement/get-my/advertisements`);
export const getHomeAdvertisementAPI = () =>
    API.get("/api/advertisement/get/advertisements");

export const reduceAdvertisementBudgetAPI = (id) =>
    API.put(`/api/advertisement/reduceBudget/${id}`);

export const payAdvertisementAPI = (paymentData) =>
    API.post("/api/advertisement/pay", paymentData);

// Feedback API

// Feedback API
export const sendUserFeedbackAPI = (text) =>
    API.post(`/api/feedback/addFeedback`, {
        feedback_text: text,
    });

// refer a friend
export const referAFriendAPI = (token) =>
    API.post(`/api/user/referAFriend`, token);

// Update User
export const updateUser = (id, payload) =>
    API.put(`/api/user/updateUser/${id}`, payload);

//cancel Subscription
export const cancelSubscriptionAPI = () => API.get("/api/plans/cancelPlans");

// promo codes api
export const applyPromoCodeAPI = (payload) =>
    API.post("/api/promocode/applyPromoCode", payload);

export const getUserInvoice = (id) => API.get(`/api/user/invoice/${id}`);
export const getSubsCheck = (id) =>
    API.get(`/api/v1/stripe/checkActive?id=${id}`);


export const getBizBucksByCompanyId = (id) => API.get(`/api/company/getBizBucksById/${id}`);
export const getBizBucksConfig = () => API.get(`/api/user/getBizBucksConfig`);


export const backedBizBucks = (data) => API.post(`/api/backaBiz/back-a-biz`,data);
export const getTotalBackedBizBucks = (search) => API.get(`/api/backaBiz/getTotalBacked${search}`);

export const getAdminEvent=()=>API.get(`/api/events/getAdminEvents?upcoming=true`)

export const registeredEvent=(data)=>API.post(`/api/events/registration`,data)
export const userRegistartionEvent=(search)=>API.get(`/api/events/user-registration-events${search}`)
export const userRegistartionPodcastEvent=(search)=>API.get(`/api/podcast/getMyPodcastList${search}`)
export const userRegistartionSpotlightEvent=(search)=>API.get(`/api/spotlight/getMySpotLightList${search}`)

export const getUserRegistartionEventById=(id)=>API.get(`/api/events/user-registration-events/${id}`)
export const editUserRegistartionEventById=(id,data)=>API.put(`/api/events/edit-registration/${id}`,{
    ...data
})
export const deleteAttendees=(id)=>API.delete(`/api/events/delete-attendees/${id}`)
export const cancelEvent=(id)=>API.delete(`/api/events/cancel-event-registration/${id}`)
export const registerPodCastUserdata=(data)=>API.post(`/api/podcast/request`,data)
export const registerSpotliteUser=(data)=>API.post(`/api/spotlight/book-spot`,data)
export const delteBackingDetail=(id)=>API.delete(`/api/backaBiz/delete/${id}`)
export const editBackingDetail=(id,data)=>API.put(`/api/backaBiz/update/${id}`,data)
export const addCompanysearchInfo=(data)=>API.post(`/api/browse/add`,data)

export const getRecentstories=()=>API.get(`/api/backaBiz/getRecentStories`)

export const getTopFiveBacked=()=>API.get(`/api/user/most-backed`)
export const getTopFiveViewed=()=>API.get(`/api/user/most-viewed`)
export const getTopFiveMatched=()=>API.get(`/api/user/top5-company`)
export const getBadgeTier=()=>API.get(`/api/v1/admin/badgeTier/get-badge-tiers`)

