import React, { useEffect, useState } from "react";
import Button from "@material-tailwind/react/Button";
import { BsCircle, BsCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "@material-ui/core";

import "./ChoosePlan.css";
//import Main from "../../../../components/Main";
import ComingSoon from "../../../assets/coming-soon.svg";
import { Carousel } from "react-responsive-carousel";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { getSubscriptions } from "../../../data/reducers/company/subscription";
import { getUserProfile } from "../../../data/reducers/auth/auth";
import { color, useToast } from "@chakra-ui/react";
import { addSocialAction } from "../../../data/reducers/profile/profile";
import { toast } from "@chakra-ui/react";
import ConfirmModal from "./ConfirmModal";
import styled from "styled-components";
import { ArrowBack, ArrowBackIos } from "@material-ui/icons";

const ImgContainer = styled.div`
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const SettingDiv = styled.div`
    display: flex;
    gap: 45px;
    align-items: center;
`;

const LogoutDiv = styled.button`
    background: #ffffff;
    border: 1px solid #00aaf5;
    border-radius: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #00aaf5;
    padding: 5px 30px 5px 30px;
`;

const Setting = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    color: #333d43;
`;

const ChoosePlan = ({ activeStep, setActiveStep, setType, updateTypeStep }) => {
    const toast = useToast();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedType, setSelectedType] = useState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const { basicDetails2, basicDetails1 } = useSelector(
        (state) => state.details
    );
    const { user } = useSelector((state) => state.auth);

    const { subscriptions } = useSelector((state) => state.subscription);

    const onFreeTrial = (type) => {
        setType(type);
        setActiveStep(2);
    };

    const onNext = (type) => {
        setSelectedType(type);
        setShowConfirmation(true);
    };

    useEffect(() => {
        dispatch(getSubscriptions());
    }, [dispatch]);

    return (
        <>
            {showConfirmation && (
                <ConfirmModal
                    setModal={setShowConfirmation}
                    modal={showConfirmation}
                    onUpgrade={() => {
                        setType(selectedType);
                        setActiveStep(2);
                        if (updateTypeStep) updateTypeStep(selectedType, 2);
                    }}
                />
            )}
            <div>
                {/* <p
                    style={{
                        fontSize: "2rem",
                        textAlign: "center",
                        fontWeight: "bold",
                    }}
                >
                    BackBone registration
                </p>
                <DetailSteps activeStep={activeStep} /> */}
                <div>
                    <ImgContainer>
                        <SettingDiv>
                            <ArrowBack
                                onClick={() => navigate("/Setting")}
                                fontSize="large"
                                cursor="pointer"
                            />
                            <Setting>Settings</Setting>
                        </SettingDiv>
                        <LogoutDiv
                            onClick={(e) => {
                                localStorage.clear();
                                navigate("/auth");
                                window.location.reload();
                            }}
                        >
                            Logout
                        </LogoutDiv>
                    </ImgContainer>
                    <div className="payment">
                        <div className="row">
                            <div className="subscription my-3">
                                <h1 className="subs-heading">
                                    Select subscription plan
                                </h1>
                                <p
                                    className="details py-3"
                                    style={{
                                        fontSize: "1rem",
                                    }}
                                >
                                    Simple pricing, no hidden fees, and great
                                    value
                                </p>
                            </div>
                            {/* <div className="col-lg-8 col-md-10 col sm-10 col-12 mx-auto free-trial">
                                <div className="w-100">
                                    <p className="free-top">
                                 
                                        <span
                                            onClick={() => {
                                                if (check) {
                                                    onFreeTrial("free");
                                                } else {
                                                    toast({
                                                        title: "Accept Terms and Condtions",
                                                        description:
                                                            "You need to accept terms and conditions",
                                                        status: "error",
                                                        duration: 3000,
                                                        isClosable: true,
                                                        position: "top-right",
                                                    });
                                                }
                                            }}
                                        >
                                            Click here, &nbsp;
                                        </span>
                                        to get free access for&nbsp;
                                        <strong
                                            style={{
                                                color: "navy",
                                            }}
                                        >
                                            6 months*
                                        </strong>
                                    </p>
                                </div>
                                <p className="text-center pt-2">
                                    *includes 3 months free trial + 3 months
                                    discount off the annual subscription
                                </p>
                            </div> */}
                        </div>

                        <div className="row plans">
                            {!isMobile ? (
                                <>
                                    
                                    {
                                        user?.subscription=="free" ? subscriptions?.map((subscription) => (
                                                        <div className="col-lg-4 col-md-12 col-sm-12 mx-auto">
                                                            <PlanCard
                                                                check={true}
                                                                subscription={subscription}
                                                                onNext={() =>
                                                                    onNext(
                                                                        subscription?.subscription_name
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    ))
                                       : <div>
                                              
                                            {subscriptions
                                            ?.filter(
                                                (p) =>
                                                    p?.subscription_name !== "Free"
                                            )
                                            ?.map((subscription) => (
                                                <div className="col-lg-4 col-md-12 col-sm-12 mx-auto">
                                                    <PlanCard
                                                        check={true}
                                                        subscription={subscription}
                                                        onNext={() =>
                                                            onNext(
                                                                subscription?.subscription_name
                                                            )
                                                        }
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    










                                    {/* {subscriptions
                                        ?.filter(
                                            (p) =>
                                                p?.subscription_name !== "Free"
                                        )
                                        ?.map((subscription) => (
                                            <div className="col-lg-4 col-md-12 col-sm-12 mx-auto">
                                                <PlanCard
                                                    check={true}
                                                    subscription={subscription}
                                                    onNext={() =>
                                                        onNext(
                                                            subscription?.subscription_name
                                                        )
                                                    }
                                                />
                                            </div>
                                        ))} */}
                                    <div
                                        className="col-lg-4 col-md-12 col-sm-12 mx-auto plan-card"
                                        style={{
                                            marginTop: "1rem",
                                        }}
                                    >
                                        <h1>Platinum</h1>
                                        <div className="coming-soon">
                                            <img
                                                src={ComingSoon}
                                                alt="Platinum"
                                            />
                                        </div>
                                        <div className="coming-soon-text">
                                            <h3 className="text-center">
                                                Coming Soon
                                            </h3>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <Carousel
                                    showIndicators={true}
                                    showArrows={false}
                                    showStatus={false}
                                    infiniteLoop={true}
                                    interval={5000}
                                >

                                    {
                                        user?.subscription=="free" ?subscriptions?.map((subscription) => (
                                                    <div>
                                                        <PlanCard
                                                            check={true}
                                                            subscription={subscription}
                                                            onNext={() =>
                                                                onNext(
                                                                    subscription.subscription_name
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                )): <div>
                                              
                                    {subscriptions
                                        ?.filter(
                                            (p) =>
                                                p?.subscription_name !== "Free"
                                        )
                                        ?.map((subscription) => (
                                            <div>
                                                <PlanCard
                                                    check={true}
                                                    subscription={subscription}
                                                    onNext={() =>
                                                        onNext(
                                                            subscription.subscription_name
                                                        )
                                                    }
                                                />
                                            </div>
                                        ))}
                                        </div>
                                    }




                                    {/* {subscriptions
                                        ?.filter(
                                            (p) =>
                                                p?.subscription_name !== "Free"
                                        )
                                        ?.map((subscription) => (
                                            <div>
                                                <PlanCard
                                                    check={true}
                                                    subscription={subscription}
                                                    onNext={() =>
                                                        onNext(
                                                            subscription.subscription_name
                                                        )
                                                    }
                                                />
                                            </div>
                                        ))} */}

                                    <div>
                                        <h1>Platinum</h1>
                                        <div className="coming-soon">
                                            <img
                                                src={ComingSoon}
                                                alt="Platinum"
                                            />
                                        </div>
                                        <div className="coming-soon-text">
                                            <h3 className="text-center">
                                                Coming Soon
                                            </h3>
                                        </div>
                                    </div>
                                </Carousel>
                            )}
                        </div>
                    </div>
                </div>
                <div className="d-flex w-100 justify-content-between buttons">
                    <div className="back-button">
                        {/* <Button
                            onClick={onBack}
                            color="blue"
                            buttonType="filled"
                            size="regular"
                            rounded={false}
                            block={false}
                            iconOnly={false}
                            ripple="light"
                        >
                            Back
                        </Button> */}
                    </div>
                    {/* <div className="continue-button">
                        <Button
                            onClick={() => {
                                if (check) {
                                    addDetails();
                                }
                            }}
                            color="blue"
                            buttonType="filled"
                            size="regular"
                            rounded={false}
                            block={false}
                            iconOnly={false}
                            ripple="light"
                        >
                            Continue
                        </Button>
                    </div> */}
                </div>
            </div>
        </>
    );
};

const PlanCard = ({ subscription, onNext, check }) => {
    const { user } = useSelector((state) => state.auth);
    const toast = useToast();
    var desc = subscription?.subscription_description.split(",");
    const [userSubscription, setUserSubscription] = useState("");
    useEffect(() => {
        if (user?.subscription === "free") {
            setUserSubscription("Free");
        } else {
            setUserSubscription(user?.subscription);
        }
    }, []);
    return (
        <>
            <div className="plan-card mx-auto">
                {subscription && (
                    <div>
                        <h1>{subscription?.subscription_name}</h1>
                        <h2 className="d-flex justify-content-center align-items-center">${subscription?.subscription_price} {subscription?.subscription_name!="Free" && <span className="text-secondary fs-6 fw-bold ms-2">+ GST</span>}</h2>
                        <div className="features py-4">
                            <div>
                                {desc.map((val, ind) => (
                                    <div key={ind}>
                                        {/* <div
                                            style={{
                                                display: "flex",
                                                width: "15%",
                                                justifyContent: "center",
                                                padding: "8px",
                                                // alignItems: "center",
                                            }}
                                        >
                                            <BsCircleFill
                                                fontSize={20}
                                                style={{
                                                    width: "25%",
                                                }}
                                            />
                                        </div> */}
                                        <div
                                            style={{
                                                color: "#646464",
                                            }}
                                        >
                                            • {val}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="select-plan-button">
                            {
                           userSubscription=="Annual" && subscription?.subscription_name == "Monthly"? 
                           <div className="current-plan-text">
                                    <h3 className="text-center">
                                        Not Available
                                    </h3>
                            </div>:
                            userSubscription ===
                            subscription?.subscription_name ? (
                                <div className="current-plan-text">
                                    <h3 className="text-center">
                                        Current Plan
                                    </h3>
                                </div>
                            ) : (
                                <Button
                                    color="blue"
                                    buttonType="filled"
                                    size="regular"
                                    rounded={false}
                                    block={false}
                                    iconOnly={false}
                                    ripple="light"
                                    onClick={() => {
                                        if (check) {
                                            onNext();
                                        } else {
                                            toast({
                                                title: "Accept Terms and Condtions",
                                                description:
                                                    "You need to accept terms and conditions",
                                                status: "error",
                                                duration: 3000,
                                                isClosable: true,
                                                position: "top-right",
                                            });
                                        }
                                    }}
                                >
                                    Select Plan
                                </Button>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ChoosePlan;
