import React, { useEffect, useState } from "react";
import { Routes as Switch, Route, Outlet, Navigate } from "react-router-dom";
import { BiMenu } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { AiOutlineHome } from "react-icons/ai";
import {
    BsCalendarCheck,
    BsBell,
    BsHeart,
    BsQuestionCircle,
} from "react-icons/bs";
import { MdPeople } from "react-icons/md";
import "./Home.css";
import Navbar, { MobileNavItem } from "./Navbar/Navbar.jsx";
import Main from "./Main/Main.jsx";
import BackboneLogo from "../../assets/backbone-logo.svg";
import Profile from "../Profile/Profile";
import UserProfile from "./UserProfile/UserProfile";
import AllReviews from "./Reviews/AllReviews";
import AdvertisementPanel from "../AdvertisementPanel/AdvertisementPanel";
import LaunchForm from "../LaunchForm/LaunchForm";
import AdPayment from "../AdPayment/AdPayment";
import Auth from "../Auth/Auth";
import { UnmarkFavouriteAction } from "../../data/reducers/home/content";
import Favourites from "../Favourites/Favourites";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import FAQ from "../FAQ/FAQs";
import UpcomingEvents from "../UpcomingEvents/UpcomingEvents";
import PaymentSuccess from "../PaymentSuccess/PaymentSuccess";
import PaymentReject from "../PaymentReject/PaymentReject";
import PaymentConfirm from "../PaymentConfirm/PaymentConfirm";
import PaymentFailed from "../PaymentFailed/PaymentFailed";
import LikedEvents from "../UpcomingEvents/Likedevents";
import Setting from "../Setting";
import AllAdvertisements from "../AdvertisementPanel/Components/AllAdvertisements";
import NotFound from "../Errors/NotFound";
import UpgradeMembership from "../Setting/UpgradeMembership/UpgradeMembership";
import Notifications from "../Notifications/Notifications";
import Blogs from "../Blogs/Blogs";
import UpgradePayment from "../UpgradePayment/UpgradePayment";
import { FiSettings } from "react-icons/fi";
import { AllBlogs } from "../AllBlogs/AllBlogs";
import Dashboard from "../dashboard/index.jsx";

const MobileNavbar = ({ setShow }) => {
    return (
        <>
            <div className="mobile-navbar">
                <AiOutlineClose
                    className="mobile-close"
                    onClick={() => setShow(false)}
                />
                <div className="position-relative">
                    <div className="mobile-nav-items">
                        <MobileNavItem
                            Icon={AiOutlineHome}
                            title="Home"
                            link="/dashboard"
                            setShow={setShow}
                        />
                        <MobileNavItem
                            Icon={AiOutlineHome}
                            title="Browse Services"
                            link="/?page=1"
                            setShow={setShow}
                        />
                        <MobileNavItem
                            Icon={BsCalendarCheck}
                            title="Upcoming events"
                            link="/upcoming-events"
                            setShow={setShow}
                        />
                        <MobileNavItem
                            Icon={BsHeart}
                            title="Favourites"
                            link="/liked-businesses/?page=1"
                            setShow={setShow}
                        />
                        <MobileNavItem
                            Icon={BsBell}
                            title="Notification"
                            link="/notification"
                            setShow={setShow}
                        />
                        <MobileNavItem
                            Icon={BsQuestionCircle}
                            title="FAQ"
                            link="/faq"
                            setShow={setShow}
                        />
                        <MobileNavItem
                            Icon={HiOutlineSpeakerphone}
                            title="Advertisements"
                            link="/ad"
                            setShow={setShow}
                        />
                        <MobileNavItem
                            Icon={MdPeople}
                            title="My Business Profile"
                            link="/profile"
                            setShow={setShow}
                        />
                        <MobileNavItem
                            Icon={FiSettings}
                            title="Settings"
                            link="/Setting"
                            setShow={setShow}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

const Home = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [show, setShow] = useState(false);
    const isHome = window.location.pathname === "/";
    const isSetting = window.location.pathname === "/Setting";
    const isProfile = window.location.pathname === "/profile";
    const isNotification = window.location.pathname === "/notification";
    const isCompany = window.location.pathname.includes("/company");
    const isUpgradeMembership =
        window.location.pathname.includes("/UpgradeMembership");
    const isBlogs = window.location.pathname.includes("/Blogs");
    const isUpgradePayment =
        window.location.pathname.includes("/upgradePayment");
    const isAllBlogs = window.location.pathname.includes("/allblogs");
    useEffect(() => {
        if (window.screen.width <= 1200) {
            setIsExpanded(false);
        }
    }, [setIsExpanded]);
    return (
        <>
            <nav className={isExpanded ? "nav-expanded" : ""}>
                <Navbar isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
            </nav>
            <div className="mobile-nav">
                <img src={BackboneLogo} alt="Backbone Logo" />
                <BiMenu
                    className="mobile-menu"
                    color="#01579B"
                    onClick={() => setShow(!show)}
                />
            </div>

            <main
                className={
                    (isExpanded ? "main-expanded" : "") +
                    (show ? " fixed " : "")
                }
            >
                <section className="main-section">
                    {isNotification && (
                        <header
                            className={`other-header ${
                                isExpanded ? "" : "other-expanded"
                            }`}
                            style={{ zIndex: "1" }}
                        >
                            <h1>We've got your back</h1>
                        </header>
                    )}
                    {!isHome &&
                        !isProfile &&
                        !isCompany &&
                        !isSetting &&
                        !isUpgradeMembership &&
                        !isBlogs &&
                        !isUpgradePayment &&
                        !isNotification &&
                        !isAllBlogs && (
                            <header
                                className={`other-header ${
                                    isExpanded ? "" : "other-expanded"
                                }`}
                                style={{ zIndex: "1" }}
                            >
                                <h1>We've got your back</h1>
                            </header>
                        )}

                    {/* <div className="header-height" /> */}
                    <div className="d-flex">
                        <div className="home-section">
                            <Switch>
                                <Route
                                    exact
                                    path="/dashboard"
                                    element={<Dashboard />}
                                />
                                <Route
                                    exact
                                    path="/"
                                    element={<Main isExpanded={isExpanded} />}
                                />
                                <Route
                                    exact
                                    path="/upcoming-events"
                                    element={<Outlet />}
                                >
                                    <Route index element={<UpcomingEvents />} />
                                    <Route
                                        path="liked"
                                        element={<LikedEvents />}
                                    />
                                </Route>
                                <Route
                                    exact
                                    path="/liked-businesses"
                                    element={<Favourites />}
                                />
                                <Route exact path="/faq" element={<FAQ />} />
                                <Route
                                    exact
                                    path="/notification"
                                    element={
                                        <>
                                            <Notifications />
                                        </>
                                    }
                                />

                                <Route
                                    exact
                                    path="/ad"
                                    element={<AdvertisementPanel />}
                                />
                                <Route
                                    exact
                                    path="/profile"
                                    element={<Profile />}
                                />
                                <Route
                                    exact
                                    path="/company/:id"
                                    element={<UserProfile />}
                                />
                                <Route
                                    exact
                                    path="/reviews/:id"
                                    element={<AllReviews />}
                                />
                                <Route
                                    exact
                                    path="/launchform"
                                    element={<LaunchForm />}
                                />
                                <Route
                                    exact
                                    path="/adpayment"
                                    element={<AdPayment />}
                                />
                                <Route
                                    exact
                                    path="/all-ads"
                                    element={<AllAdvertisements />}
                                />
                                <Route
                                    exact
                                    path="/upgradePayment"
                                    element={<UpgradePayment />}
                                />
                                <Route
                                    exact
                                    path="/paymentconfirm"
                                    element={<PaymentConfirm />}
                                />
                                <Route
                                    exact
                                    path="/paymentfailed"
                                    element={<PaymentFailed />}
                                />
                                <Route
                                    exact
                                    path="/paymentsuccess"
                                    element={<PaymentSuccess />}
                                />
                                <Route
                                    exact
                                    path="/paymentreject"
                                    element={<PaymentReject />}
                                />
                                <Route
                                    exact
                                    path="/Setting"
                                    element={<Setting />}
                                />
                                <Route
                                    exact
                                    path="/UpgradeMembership"
                                    element={<UpgradeMembership />}
                                />
                                <Route
                                    exact
                                    path="/Blogs"
                                    element={<Blogs />}
                                />
                                <Route
                                    path="/not-found"
                                    element={
                                        <div className="mt-5">
                                            <NotFound showBtn={true} />
                                        </div>
                                    }
                                />
                                <Route
                                    path="/allblogs"
                                    element={<AllBlogs />}
                                />
                                <Route
                                    path="/auth"
                                    element={<Navigate replace to="/" />}
                                />
                                <Route
                                    path="*"
                                    element={
                                        <Navigate replace to="/not-found" />
                                    }
                                />
                            </Switch>
                        </div>
                    </div>
                </section>
            </main>

            {show && <MobileNavbar setShow={setShow} />}
        </>
    );
};

export default Home;
