import React, { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import { authActions } from "../../data/reducers/auth/auth";

import "./Landing.css";
import favi from "../../assets/backbone-logo.svg";
import Contact from "./Contact/Contact";
import Hero from "./Hero/Hero";
import Why from "./Why/WhyV2";
import LandVideo from "./LandVideo/LandVideo";
import Testimonials from "./Testimonial/Testimonial";
import Mission from "./Mission/MissionV2";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import { WORDPRESS_URL } from "../../config/api";

const Landing = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { message } = useSelector((state) => state.auth);

    useEffect(() => {
        if (message) {
            toast({
                title: "Success",
                description: message,
                status: "success",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
            navigate("/details");
            dispatch(authActions.reset());
        }
    }, [dispatch, navigate, toast, message]);

    useEffect(() => {
        if (WORDPRESS_URL !== "") {
            navigate("/auth");
        }
    }, [WORDPRESS_URL]);

    return (
        <>
            <Helmet>
                <link rel="icon" type="image/png" href={favi} sizes="16x16" />
            </Helmet>

            <Navbar />
            <div className="landing-page">
                <LandVideo />
                <Testimonials />
                <Why />
                <Mission /> <Hero />
                <Contact />
            </div>
            <Footer />
        </>
    );
};

export default Landing;
