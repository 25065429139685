import React from "react";
import BackBoneLoader from "react-loader-spinner";

import "./Loader.css";

const Loader = () => {
    return (
        <div className="load">
            <BackBoneLoader
                height={100}
                width={100}
                type="ThreeDots"
                color="blue"
            />
        </div>
    );
};

export default Loader;
