import React, { useState } from "react";
import Button from "@material-tailwind/react/Button";
import { MdOutlineCancel } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, useToast } from "@chakra-ui/react";
import "./BasicDetails2.css";
import DetailSteps from "../../../shared/Details/DetailSteps";
import {
    addCompany,
    detailsActions,
} from "../../../data/reducers/details/details";
import { getUserProfile } from "../../../data/reducers/auth/auth";
import BackBoneLoader from "react-loader-spinner";
import { useNavigate } from "react-router";

const BasicDetails2 = ({ activeStep, setPage }) => {
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth);
    const [isError, setIsError] = useState(false);
    const toast = useToast();
    const dispatch = useDispatch();

    const { basicDetails2, basicDetails1 } = useSelector(
        (state) => state.details
    );

    const [service, setService] = useState("");
    const [tempServices, setServices] = useState(basicDetails2?.services || []);
    const [websiteError, setWebsiteError] = useState(false);
    const [creating, setCreating] = useState(false);
    const [skip, setSkip] = useState(false);

    const [data, setData] = useState({
        numberOfEmployees: basicDetails2?.numberOfEmployees,
        companyWebsite: basicDetails2?.companyWebsite,
        services: basicDetails2?.services || [],
    });
    const [logo, setLogo] = useState(basicDetails2?.logo);
    const [logoPreview, setLogoPreview] = useState(basicDetails2?.logoPreview);

    const onSetService = (e) => {
        const val = e.target.value;
        if (e.key === "Enter") {
            setService("");
            if (tempServices.length <= 7) setServices([...tempServices, val]);
        }
    };

    const onDelete = (ind) => {
        const services = tempServices?.filter((v, idx) => {
            return idx !== ind;
        });
        setServices(services);
    };

    const onSubmit = async () => {
        if (data?.companyWebsite) {
            let x = data?.companyWebsite.toString().split(".");
            if (!x[1]) {
                toast({
                    title: "Please add a correct website url!",
                    position: "top-right",
                    duration: 3000,
                    status: "error",
                    isClosable: true,
                });
                console.log("Website Error");
                setWebsiteError(true);
                return;
            }
        }

        setData({ ...data, services: tempServices });

        const payload = {
            numberOfEmployees: data?.numberOfEmployees,
            companyWebsite: data?.companyWebsite,
            services: tempServices || [],
            logo: logo,
            logoPreview: logoPreview,
        };

        dispatch(detailsActions.setBasicDetails2(payload));
        saveCompany();
    };

    const saveCompany = async () => {
        setCreating(true);
        const data1 = new FormData();
        data1.append("about", basicDetails1.about);
        data1.append("numberofEmployees", data?.numberOfEmployees);
        data1.append("services", tempServices || []);
        data1.append("name", basicDetails1.companyName);
        data1.append("website", data?.companyWebsite);
        data1.append("industryType", basicDetails1.industryType);
        data1.append("location", basicDetails1.location);
        data1.append("abn", basicDetails1.abn);
        data1.append("file", logo);
        data1.append("otherLocation", basicDetails1.otherLocation);
        data1.append("otherIndustry", basicDetails1.otherIndustry);
        await dispatch(addCompany(data1));
        await dispatch(getUserProfile());

        dispatch(detailsActions.setActiveStep(2));
        setCreating(false);
    };

    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };
    const onFileSelect = (e) => {
        //console.log(e.target.files[0]);
        if (e.target.name === "companyLogo") {
            const reader = new FileReader();
            if (e.target.files[0].size > 512000) {
                setIsError(true);
                return;
            }
            setIsError(false);
            setLogo(e.target.files[0]);
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setLogoPreview(reader.result);
                }
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    return (
        <div>
            <DetailSteps activeStep={activeStep} />
            <div className="container">
                <div className="skip-button">
                    {creating && skip ? (
                        <BackBoneLoader
                            height={15}
                            width={15}
                            type="ThreeDots"
                            color="blue"
                        />
                    ) : (
                        <Button
                            color="blue"
                            buttonType="link"
                            onClick={() => {
                                setSkip(true);
                                onSubmit();
                            }}
                            ripple="dark"
                            type="reset"
                        >
                            Skip
                        </Button>
                    )}
                </div>
                <div className="basic">
                    <div className="basic-left">
                        <div className="company-logo my-5">
                            <div>
                                <label htmlFor="logo">Add Company Logo</label>
                            </div>
                            <div>
                                <label className="file-box">
                                    <center>
                                        {logoPreview ? (
                                            <img
                                                src={logoPreview}
                                                alt="Logo"
                                                className="object-cover"
                                            />
                                        ) : (
                                            <div className="blank-image"></div>
                                        )}
                                    </center>
                                </label>
                            </div>

                            <div>
                                <label
                                    htmlFor="company-logo"
                                    className="browse-files"
                                >
                                    <div>Upload Logo</div>
                                </label>
                                <input
                                    type="file"
                                    name="companyLogo"
                                    id="company-logo"
                                    accept=".jpg, .png, .jpeg"
                                    onChange={onFileSelect}
                                />
                            </div>
                            <span
                                className="error-message-span"
                                style={{
                                    justifyContent: "center",
                                    display: !isError ? "flex" : "none",
                                    paddingLeft: "9rem",
                                    fontSize: "12px",
                                }}
                            >
                                Max 500KB
                            </span>
                            <span
                                style={{
                                    justifyContent: "center",
                                    display: isError ? "flex" : "none",
                                    paddingLeft: "9rem",
                                    color: "red",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                }}
                            >
                                Max 500KB
                            </span>
                        </div>
                    </div>
                    <div className="basic-right">
                        <div className="company-form">
                            <div className="form-group my-3">
                                <label htmlFor="companyWebsite">
                                    Company Website
                                </label>
                                <input
                                    type="url"
                                    name="companyWebsite"
                                    id="companyWebsite"
                                    className={
                                        websiteError ? "error-input" : ""
                                    }
                                    value={data?.companyWebsite}
                                    onChange={(e) => onChange(e)}
                                />
                            </div>
                            <div className="form-group my-3">
                                <label htmlFor="numberOfEmployees">
                                    Number of Employees
                                </label>
                                <input
                                    type="number"
                                    name="numberOfEmployees"
                                    id="numberOfEmployees"
                                    value={data?.numberOfEmployees}
                                    onChange={(e) => onChange(e)}
                                />
                            </div>
                            <div className="form-group my-3">
                                <label htmlFor="services">
                                    Offered Product and Services
                                </label>
                                <input
                                    type="text"
                                    name="services"
                                    placeholder="eg. Legal"
                                    id="services"
                                    value={service}
                                    onChange={(e) => setService(e.target.value)}
                                    onKeyDown={(e) => onSetService(e)}
                                />
                                <div className="services">
                                    {tempServices?.map((val, ind) => (
                                        <div key={ind}>
                                            <p>{val}</p>{" "}
                                            <MdOutlineCancel
                                                color="#007893"
                                                fontSize={18}
                                                onClick={() => onDelete(ind)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex w-100 justify-content-between buttons">
                    <div className="back-button">
                        <Button
                            onClick={() => setPage(1)}
                            color="blue"
                            buttonType="filled"
                            size="regular"
                            rounded={false}
                            block={false}
                            iconOnly={false}
                            ripple="light"
                        >
                            Back
                        </Button>
                    </div>
                    <div className="continue-button">
                        {creating && !skip ? (
                            <Button
                                onClick={() => {}}
                                color="blue"
                                buttonType="filled"
                                size="regular"
                                rounded={false}
                                block={false}
                                iconOnly={false}
                                ripple="light"
                            >
                                <BackBoneLoader
                                    height={15}
                                    width={15}
                                    type="ThreeDots"
                                    color="white"
                                />{" "}
                            </Button>
                        ) : (
                            <Button
                                onClick={onSubmit}
                                color="blue"
                                buttonType="filled"
                                size="regular"
                                rounded={false}
                                block={false}
                                iconOnly={false}
                                ripple="light"
                            >
                                Continue
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BasicDetails2;
