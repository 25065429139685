import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllSubscriptions } from "../../services/company/subscriptions";

const initialState = {
    success: false,
    loading: false,
    error: null,
    subscriptions: [],
};

export const getSubscriptions = createAsyncThunk(
    "get/subscriptions",
    async () => {
        const response = await getAllSubscriptions();
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

const getSubscriptionsSlice = createSlice({
    name: "getsubscriptions",
    initialState,
    reducers: {
        reset: (state, action) => {
            state.error = null;
            state.message = null;
        },
    },
    extraReducers: {
        [getSubscriptions.pending]: (state, action) => {
            state.loading = true;
        },
        [getSubscriptions.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                let free = action.payload?.plans?.find(
                    (p) => p?.subscription_name == "Free"
                )
                    ? [
                          action.payload?.plans?.find(
                              (p) => p?.subscription_name == "Free"
                          ),
                      ]
                    : [];
                let rest = action.payload?.plans?.filter(
                    (p) => p?.subscription_name !== "Free"
                );
                console.log(free, rest);

                state.subscriptions = free.concat(rest);
            }
        },
    },
});

export const getSubscriptionActions = getSubscriptionsSlice.actions;
export default getSubscriptionsSlice.reducer;
