import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Login from "./Components/Login";
import Signup from "./Components/Signup";
import Helmet from "react-helmet";
import favi from "../../assets/backbone-logo.svg";

const Auth = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { user, isAuthenticated, loading } = useSelector(
        (state) => state.auth
    );

    const [searchParams, setSearchParams] = useSearchParams();
    const signup = searchParams.get("signup");
    const [isLogin, setIsLogin] = useState(signup ? false : true);
    const Reset = searchParams.get("reset");
    const redirect = location.search ? location.search.split("=")[1] : "/";

    // useEffect(() => {
    //     if (isAuthenticated) {
    //         navigate(redirect);
    //     }
    // }, [isAuthenticated, navigate]);

    useEffect(() => {
        if (user && isAuthenticated) {
            // navigate("/?=");
            if (user?.reset === true) {
                navigate("/auth/change-password");
            } else {
                navigate("/");
            }
        }
    }, [user, isAuthenticated, navigate]);

    return (
        <>
            <Helmet>
                <link rel="icon" type="image/png" href={favi} sizes="16x16" />
            </Helmet>

            {isLogin ? (
                <Login setIsLogin={setIsLogin} isLogin={isLogin} />
            ) : (
                <Signup setIsLogin={setIsLogin} isLogin={isLogin} />
            )}
        </>
    );
};

export default Auth;
