import React, { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import Modal from "@material-tailwind/react/Modal";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import ModalBody from "@material-tailwind/react/ModalBody";
import Button from "@material-tailwind/react/Button";

import "./ForgotPassword.css";
import { useDispatch } from "react-redux";
import {
    authActions,
    forgotPasswordAction,
} from "../../../data/reducers/auth/auth";
import { useSelector } from "react-redux";

const ForgotPassword = ({ visible, setVisible }) => {
    const toast = useToast();
    const dispatch = useDispatch();

    const { error, message } = useSelector((state) => state.auth);

    const [email, setEmail] = useState("");

    const handleClose = () => {
        setVisible(false);
        setEmail("");
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (!email?.trim()) {
            return toast({
                title: "Validation Failed",
                description: "Email is required.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
        }
        dispatch(forgotPasswordAction({ email: email?.trim() }));
    };

    useEffect(() => {
        if (message) {
            toast({
                title: "Success",
                description: message,
                status: "success",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
            setEmail("");
            setVisible(false);
        }
        dispatch(authActions.reset());
    }, [dispatch, toast, error, message, setVisible]);

    return (
        <div className="modal-wrapper">
            <Modal size="md" active={visible} toggler={handleClose}>
                <ModalHeader toggler={handleClose}>
                    Forgot Your Password
                </ModalHeader>
                <ModalBody>
                    <p>
                        Please enter your email address. If you have an account
                        with us, you will get a link to reset your password.
                    </p>

                    <form onSubmit={onSubmit} className="forgot-password">
                        <div className="form-group">
                            <label htmlFor="email">Email ID*</label>
                            <input
                                type="email"
                                value={email}
                                placeholder="Email Address"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="button-group">
                            <Button
                                color="red"
                                buttonType="link"
                                onClick={handleClose}
                                ripple="dark"
                                type="reset"
                            >
                                Close
                            </Button>

                            <Button
                                color="blue"
                                buttonType="filled"
                                size="regular"
                                rounded={false}
                                type="submit"
                            >
                                Reset
                            </Button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default ForgotPassword;
