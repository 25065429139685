import React, { useState } from "react";
import "./Navbar.css";

import { HiMenu } from "react-icons/hi";
import Logo from "../../assets/backbone-logo.svg";

import Button from "@material-tailwind/react/Button";
import { Offcanvas } from "react-bootstrap";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../data/reducers/auth/auth";

const Navbar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const logout = () => {
        localStorage.clear();
        dispatch(authActions.logout());
        navigate("/auth");
    };

    return (
        <>
            <div className="container navbar d-flex justify-content-between">
                <div>
                    <div onClick={() => navigate("/?page=1")}>
                        <img
                            style={{
                                width: "4rem",
                            }}
                            src={Logo}
                            alt="logo"
                        />
                    </div>
                </div>
                <div className="navbar-buttons">
                    <div className="d-flex justify-content-center">
                        {!user?.email ? (
                            <div className="button mr-2 join-button launch-button">
                                <button
                                    className="launch-buttons-login"
                                    onClick={() => navigate("/auth")}
                                >
                                    Log in
                                </button>
                                <button
                                    className="launch-buttons-signup"
                                    onClick={() =>
                                        navigate(`/auth?signup=${true}`)
                                    }
                                >
                                    Sign up for a 3 Month Free Trial
                                </button>
                            </div>
                        ) : (
                            <div className="button mr-2">
                                <Button
                                    color="pink"
                                    buttonType="filled"
                                    size="regular"
                                    rounded={false}
                                    onClick={logout}
                                >
                                    Logout
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="navbar-hamburger">
                    <HiMenu color="black" fontSize={28} onClick={handleShow} />
                </div>
            </div>

            <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Body>
                    <div className="px-3">
                        <div className="menu-box">
                            <Link
                                onClick={handleClose}
                                activeClass="active"
                                to="home"
                                spy={true}
                                hashSpy={true}
                                duration={1000}
                                isDynamic={true}
                                ignoreCancelEvents={false}
                                style={{ color: "white" }}
                            >
                                <span>Home</span>
                            </Link>
                        </div>
                        <div className="menu-box">
                            <Link
                                onClick={() => navigate("/auth")}
                                activeClass="active"
                                to="/auth"
                                spy={true}
                                hashSpy={true}
                                duration={1000}
                                isDynamic={true}
                                ignoreCancelEvents={false}
                                style={{ color: "white" }}
                            >
                                <span>Login</span>
                            </Link>
                        </div>
                        <div className="menu-box">
                            <Link
                                onClick={() => navigate(`/auth?signup=${true}`)}
                                activeClass="active"
                                to="/auth"
                                spy={true}
                                hashSpy={true}
                                duration={1000}
                                isDynamic={true}
                                ignoreCancelEvents={false}
                                style={{ color: "white" }}
                            >
                                <span>Signup</span>
                            </Link>
                        </div>
                        <div className="menu-box">
                            <Link
                                onClick={handleClose}
                                activeClass="active"
                                to="contact"
                                spy={true}
                                hashSpy={true}
                                duration={1000}
                                isDynamic={true}
                                ignoreCancelEvents={false}
                                style={{ color: "white" }}
                            >
                                <span>Contact Us</span>
                            </Link>
                        </div>
                        <div className="menu-box">
                            <Link
                                onClick={() => navigate("/pricing")}
                                activeClass="active"
                                to="pricing"
                                spy={true}
                                hashSpy={true}
                                duration={1000}
                                isDynamic={true}
                                ignoreCancelEvents={false}
                                style={{ color: "white" }}
                            >
                                <span>Pricing</span>
                            </Link>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default Navbar;
