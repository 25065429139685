import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    addCompanyDetails,
    getAllReviews,
    addReviewService,
} from "../../services/company/add";

const initialState = {
    success: false,
    loading: false,
    error: null,
    message: "",
    reviews: [],
    feedbackGiven: false,
};

export const addCompany = createAsyncThunk("add/company", async (data1) => {
    const response = await addCompanyDetails(data1);
    if (response?.error) {
        return { error: response };
    }
    return response;
});

export const addReview = createAsyncThunk("add/review", async (dataReview) => {
    const response = await addReviewService(dataReview);
    if (response?.error) {
        return { error: response };
    }
    return response;
});

export const getAllReviewsAction = createAsyncThunk(
    "get/reviews",
    async (id) => {
        const response = await getAllReviews(id);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

const addCompanySlice = createSlice({
    name: "addCompany",
    initialState,
    reducers: {
        reset: (state, action) => {
            state.error = null;
            state.message = null;
            state.reviews = [];
            state.success = false;
            state.loading = false;
        },
    },
    extraReducers: {
        [addReview.pending]: (state, action) => {
            state.loading = true;
        },
        [addReview.fulfilled]: (state, action) => {
            state.loading = false;
            state.success = action.payload?.success;
            if (action.payload?.error) {
                console.log(action.payload);
                state.error = action.payload?.error?.error;
                state.feedbackGiven = true;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.feedbackGiven = true;
            }
        },

        [addCompany.pending]: (state, action) => {
            state.loading = true;
        },
        [addCompany.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                console.log(action?.payload?.error);
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
            }
        },
        [getAllReviewsAction.pending]: (state, action) => {
            state.loading = true;
        },
        [getAllReviewsAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.reviews = action.payload?.reviews;
            }
        },
    },
});

export const addCompanyActions = addCompanySlice.actions;
export default addCompanySlice.reducer;
