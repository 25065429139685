import React, { useEffect, useState } from "react";

import Logo from "../../assets/backbone-logo.svg";
import "@material-tailwind/react/tailwind.css";

import ChoosePlan from "./Components/ChoosePlan";
import Payment from "./Components/Payment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { authActions } from "../../data/reducers/auth/auth";
import { upgradeUserPlan } from "../../data/api";

const UpgradePayment = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    // const [activeStep, setActiveStep] = useState(1);
    const activeStep = useSelector((state) => state.auth.upgradePaymentStep);
    const type = useSelector((state) => state.auth.upgradePaymentType);
    // const [type, setType] = useState();

    const { user } = useSelector((state) => state.auth);

    return (
        <div>
            <div className="container py-2">
                {activeStep === 1 && (
                    <ChoosePlan
                        activeStep={2}
                        updateTypeStep={(val, step) => {
                            dispatch(
                                authActions.setUpgradePaymentStep({
                                    step: step,
                                    type: val,
                                })
                            );
                        }}
                        setActiveStep={(val) => {
                            // dispatch(
                            //     authActions.setUpgradePaymentStep({ step: val })
                            // );
                        }}
                        setType={(val) => {
                            // dispatch(
                            //     authActions.setUpgradePaymentStep({ type: val })
                            // );
                        }}
                    />
                )}
                {activeStep === 2 && (
                    <div className="company-details upgrade-payment-page">
                        <Payment
                            activeStep={3}
                            setActiveStep={(val) => {
                                dispatch(
                                    authActions.setUpgradePaymentStep({
                                        step: val,
                                        type: type,
                                    })
                                );
                            }}
                            type={type}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default UpgradePayment;
