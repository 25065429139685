import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    addAdvertisement,
    getAllAdvertisement,
    getHomeAdvertisement,
    reduceAdvertisementBudget,
    updateAdvertisement,
} from "../../services/advertisement/advertisement";

const initialState = {
    success: false,
    loading: false,
    error: null,
    message: "",
    advertisement: [],
    homeAdvertisements: [],
};

export const addAdvertisementAction = createAsyncThunk(
    "add/advertisement",
    async (ad) => {
        const response = await addAdvertisement(ad);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const updateAdvertisementAction = createAsyncThunk(
    "update/advertisement",
    async (ad) => {
        const response = await updateAdvertisement(ad);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const getAllAdvertisementAction = createAsyncThunk(
    "get/advertisement",
    async () => {
        const response = await getAllAdvertisement();
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const getHomeAdvertisementAction = createAsyncThunk(
    "getHome/advertisement",
    async () => {
        const response = await getHomeAdvertisement();
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const reduceBudgetAction = createAsyncThunk(
    "reduce/budget",
    async (id) => {
        const response = await reduceAdvertisementBudget(id);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

const addAdvertisementSlice = createSlice({
    name: "addAdvertisement",
    initialState,
    reducers: {
        reset: (state, action) => {
            state.error = null;
            state.message = null;
        },
    },
    extraReducers: {
        [addAdvertisementAction.pending]: (state, action) => {
            state.loading = true;
        },
        [addAdvertisementAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
            }
        },
        [updateAdvertisementAction.pending]: (state, action) => {
            state.loading = true;
        },
        [updateAdvertisementAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
            }
        },
        [getAllAdvertisementAction.pending]: (state, action) => {
            state.loading = true;
        },
        [getAllAdvertisementAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                console.log(action?.payload);
                state.success = action.payload?.success;
                state.advertisement = action.payload?.advertisement;
            }
        },
        [getHomeAdvertisementAction.pending]: (state, action) => {
            state.loading = true;
        },
        [getHomeAdvertisementAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                console.log(action?.payload);
                state.success = action.payload?.success;
                state.homeAdvertisements = action.payload?.advertisement;
            }
        },
    },
});

export const addAdvertisementActions = addAdvertisementSlice.actions;
export default addAdvertisementSlice.reducer;
