import React, { useEffect, useState } from "react";
import Button from "@material-tailwind/react/Button";
import { BsCircle } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";

import "./Pricing.css";

import ComingSoon from "../../../assets/coming-soon.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { isMobile } from "react-device-detect";
import Logo from "../../../assets/backbone-logo.svg";
import { color } from "@chakra-ui/react";
import Footer from "../../../shared/Footer/Footer";
import { getSubscriptions } from "../../../data/reducers/company/subscription";
import { useNavigate } from "react-router-dom";

const ChoosePlan = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { subscriptions } = useSelector((state) => state.subscription);
    useEffect(() => {
        dispatch(getSubscriptions());
    }, [dispatch]);
    return (
        <>
            <div onClick={() => navigate("/")}>
                <img
                    style={{
                        width: "4rem",
                        marginLeft: "3.5rem",
                        marginTop: "1rem",
                    }}
                    src={Logo}
                    alt="logo"
                    className="logo"
                />
            </div>
            <div className="container">
                <div className="payment">
                    <div className="pricing-row">
                        <div className="subscription my-5">
                            <h1 className="subs-heading">Pricing</h1>
                            <p
                                className="details py-3"
                                style={{
                                    fontSize: "1rem",
                                }}
                            >
                                Simple pricing, no hidden fees, and great value
                            </p>
                        </div>
                        {/* <div className="col-lg-8 col-md-10 col sm-10 col-12 mx-auto pricing-free-trial">
                            <div className="w-100">
                                <p className="free-top">
                                    <span>Free Access for </span>
                                    <strong>3 Months*</strong>
                                </p>
                            </div>
                            <p className="text-center pt-2">
                                *includes further 3 months discount off the
                                annual subscription post trial
                            </p>
                        </div> */}
                    </div>

                    <div className="row my-5 pricing-plans">
                        {!isMobile ? (
                            <>
                                {subscriptions?.map((subscription) => (
                                    <div className="col-lg-4 col-md-12 col-sm-12 mx-auto pricing-plan-cards">
                                        <PlanCard subscription={subscription} />
                                    </div>
                                ))}
                                {/* <div className="col-lg-4 col-md-12 col-sm-12 mx-auto pricing-plan-cards">
                                    <div className="pricing-plan-card mx-auto">
                                        <h1>Platinum</h1>
                                        <div className="coming-soon">
                                            <img
                                                src={ComingSoon}
                                                alt="Platinum"
                                            />
                                        </div>
                                        <div
                                            className="coming-soon-text"
                                            style={{
                                                marginTop: "8rem",
                                                color: "#0E3854",
                                                fontWeight: "bold",
                                                fontSize: "1.3rem",
                                            }}
                                        >
                                            <h3 className="text-center">
                                                Coming Soon
                                            </h3>
                                        </div>
                                    </div>
                                </div> */}
                            </>
                        ) : (
                            <Carousel
                                showIndicators={true}
                                showArrows={false}
                                showStatus={false}
                                infiniteLoop={true}
                                interval={5000}
                            >
                                {subscriptions?.map((subscription) => (
                                    <div>
                                        <PlanCard subscription={subscription} />
                                    </div>
                                ))}

                                {/* <div className="pricing-plan-card mx-auto">
                                    <h1>Platinum</h1>
                                    <div className="coming-soon">
                                        <img src={ComingSoon} alt="Platinum" />
                                    </div>
                                    <div className="coming-soon-text">
                                        <h3 className="text-center">
                                            Coming Soon
                                        </h3>
                                    </div>
                                </div> */}
                            </Carousel>
                        )}
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

const PlanCard = ({ subscription, onNext, check }) => {
    var desc = subscription?.subscription_description.split(",");
    console.log(subscription);
    return (
        <>
            <div className="pricing-plan-card mx-auto">
                {subscription && (
                    <div>
                        <h1>{subscription?.subscription_name}</h1>
                        <h2 className="d-flex justify-content-center align-items-center">${subscription?.subscription_price} {subscription?.subscription_name!="Free" && <span className="text-secondary fs-6 fw-bold ms-2">+ GST</span>}</h2>
                        <div className="features py-4">
                            <div>
                                {desc.map((val, ind) => (
                                    <div key={ind}>
                                        {/* <div
                                            style={{
                                                display: "flex",
                                                width: "15%",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <BsCircle
                                                fontSize={20}
                                                style={{
                                                    width: "25%",
                                                }}
                                            />
                                        </div> */}
                                        <div
                                            style={{
                                                width: "100%",
                                                color: "#646464",
                                            }}
                                        >
                                            • {val}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ChoosePlan;
