import React, { useEffect, useState } from "react";

import Modal from "@material-tailwind/react/Modal";
import ModalBody from "@material-tailwind/react/ModalBody";
import Button from "@material-tailwind/react/Button";
import { useToast } from "@chakra-ui/react";
import "./IndustryType.css";
import { useDispatch, useSelector } from "react-redux";
import profile, {
    profileActions,
    updateIndustryTypeAction,
} from "../../../data/reducers/profile/profile";
import { getIndustries } from "../../../data/reducers/details/details";
import { getMyCompanyAction } from "../../../data/reducers/home/content";

const IndustryType = ({ companyIndustry, isMine }) => {
    const [showModal, setShowModal] = useState(false);
    const [haveChanged, setHaveChanged] = useState(false);
    const previousIndustry = companyIndustry?.id;
    const [industry, setIndustry] = useState(companyIndustry?.id);
    const [otherIndustry, setOtherIndustry] = useState(companyIndustry?.id);
    const dispatch = useDispatch();
    const { industries } = useSelector((state) => state.details);
    const { success, error, loading, message } = useSelector(
        (state) => state.profile
    );

    const [suggestionSearch, setSuggestionSearch] = useState("");
    const [showSuggestions, setShowSuggestions] = useState(false);
    const toast = useToast();
    const onSuggestionClick = (val) => {
        setOtherIndustry(val);
        setSuggestionSearch(val);
        setShowModal(true);
        setShowSuggestions(false);
    };

    const onSearchSuggestion = (e) => {
        setOtherIndustry(e.target.value);
        setSuggestionSearch(e.target.value);
        setShowSuggestions(true);
    };

    useEffect(() => {
        if (companyIndustry?.id && industry && isMine && haveChanged) {
            if (industry?.toString() !== companyIndustry?.id?.toString()) {
                if (industry == "Other") return;
                setShowModal(true);
            }
        }
    }, [industry, companyIndustry?.id]);

    const setNewIndustry = async () => {
        setShowModal(false);
        dispatch(
            updateIndustryTypeAction(
                industry == "Other" ? otherIndustry : industry
            )
        );
        toast({
            title: "Success",
            description: "Industry Updated",
            status: "success",
            position: "top-right",
            isClosable: true,
        });
    };

    const changeIndustry = (e) => {
        setIndustry(e.target.value);

        setHaveChanged(true);
    };

    // useEffect(() => {
    //     if(industries?.length<=0)
    //        dispatch(getIndustries());
    // }, []);

    useEffect(() => {
        if (success && message) {
            dispatch(profileActions.reset());
            dispatch(getMyCompanyAction());
        }
        dispatch(profileActions.reset());
    }, [success, message, toast, loading]);

    return (
        <>
            <div className="profile-industry-type-section">
                <h1>Industry Type</h1>
                {isMine && (
                    <>
                        <div className="form-group my-3">
                            <select
                                name="industry"
                                id="industry"
                                value={industry?.industry_name}
                                onChange={(e) => changeIndustry(e)}
                            >
                                <option value={companyIndustry?.id}>
                                    {companyIndustry?.industry_name}
                                </option>
                                {industries?.map(
                                    (industry,index) =>
                                        industry?.id !== previousIndustry && (
                                            <option value={industry?.id} key={index}>
                                                {industry?.industry_name}
                                            </option>
                                        )
                                )}
                                <option value="Other">Other</option>
                            </select>
                        </div>

                        {industry === "Other" && (
                            <div className="form-group my-3 w-100 position-relative">
                                <label htmlFor="otherIndustry">
                                    Choose Your Industry*
                                </label>
                                <input
                                    name="otherIndustry"
                                    autoComplete="on"
                                    className={
                                        industry == "Other" && !otherIndustry
                                            ? "error-input"
                                            : ""
                                    }
                                    placeholder="Start Typing to see Suggestions"
                                    value={otherIndustry}
                                    onChange={onSearchSuggestion}
                                />
                                {showSuggestions && suggestionSearch?.length ? (
                                    <div className="datalist">
                                        {industries?.map(
                                            (val, ind) =>
                                                val.industry_name
                                                    .toString()
                                                    .toLocaleLowerCase()
                                                    .includes(
                                                        suggestionSearch.toLocaleLowerCase()
                                                    ) &&
                                                val.industry_name.toLocaleLowerCase() !==
                                                    suggestionSearch.toLocaleLowerCase() && (
                                                    <p
                                                        key={ind}
                                                        onClick={() =>
                                                            onSuggestionClick(
                                                                val?.industry_name
                                                            )
                                                        }
                                                    >
                                                        {val.industry_name}
                                                    </p>
                                                )
                                        )}
                                        <p
                                            onClick={() =>
                                                onSuggestionClick(
                                                    suggestionSearch
                                                )
                                            }
                                        >
                                            {suggestionSearch}
                                        </p>
                                    </div>
                                ) : null}
                            </div>
                        )}
                    </>
                )}
                {!isMine && (
                    <div className="profile-selected-industry">
                        {companyIndustry?.industry_name}
                    </div>
                )}
            </div>
            <div className="profile-modal modal-wrapper">
                <Modal
                    size="regular"
                    active={showModal}
                    toggler={() => setShowModal(false)}
                    className="profile-tailwind-modal"
                >
                    <ModalBody>
                        <p className="modal-text">
                            Do you want to save changes?
                        </p>
                    </ModalBody>
                    <div className="d-flex justify-content-between">
                        <div className="close-button">
                            <Button
                                color="red"
                                buttonType="link"
                                onClick={(e) => setShowModal(false)}
                                ripple="dark"
                            >
                                Close
                            </Button>
                        </div>
                        <div className="save-button">
                            <Button
                                color="green"
                                onClick={(e) => setNewIndustry()}
                                ripple="light"
                            >
                                Save Changes
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default IndustryType;
