import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@material-tailwind/react/Button";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";

import "./VerifyAccount.css";

import Logo from "../../../assets/favicon.ico";
import { authActions, verifyUser } from "../../../data/reducers/auth/auth";
import { detailsActions } from "../../../data/reducers/details/details";

const VerifyAccount = () => {
    const { token } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();

    const { error, message } = useSelector((state) => state.auth);

    const verifyEmail = () => {
        dispatch(verifyUser({ token }));
    };

    useEffect(() => {
        if (error) {
            toast({
                title: "Error",
                description: error,
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
        }
        if (message) {
            toast({
                title: "Success",
                description: message,
                status: "success",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
            navigate("/auth");
            dispatch(authActions.reset());
            dispatch(detailsActions.setActiveStep(1));
        }
    }, [dispatch, navigate, error, token, message, toast]);

    return (
        <div className="container py-5">
            <div className="verify-heading">
                <div className="d-flex justify-content-center">
                    <img src={Logo} alt="Logo" />
                </div>
                <h1 className="mt-3">Backbone</h1>
            </div>
            <div className="verify-account-heading my-5">
                <h1>Verify Your Account</h1>
            </div>

            <div className="verify-account-button my-5">
                <Button
                    color="blue"
                    buttonType="filled"
                    size="regular"
                    rounded={false}
                    onClick={() => verifyEmail()}
                >
                    Click here to Verify
                </Button>
            </div>
        </div>
    );
};

export default VerifyAccount;
