import React from "react";
import { useNavigate } from "react-router-dom";
import Empty from "../../assets/empty.svg";
import advertisements from "../../assets/advertisements.png";
import events from "../../assets/events.png";
import "./Errors.css";
const EmptyState = ({ active, type }) => {
    const navigate = useNavigate();
    return (
        <>
            <div
                className="cont"
                style={active ? { height: "22rem", gap: "30px" } : {}}
            >
                <div>
                    {type === "ads" ? (
                        <img src={advertisements} alt="EmptyState" />
                    ) : type === "events" ? (
                        <img src={events} alt="EmptyState" />
                    ) : (
                        <img src={Empty} alt="EmptyState" />
                    )}
                </div>
                <p>Hey! Nothing to show here.</p>
                {/* {showBtn.showBtn ? (
                    <div className="back-btn" onClick={() => navigate(-1)}>
                        <button>Back to Previous Screen</button>
                    </div>
                ) : null} */}
            </div>
        </>
    );
};

export default EmptyState;
