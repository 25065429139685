import {
    getFAQAPI,
    getCompaniesAPI,
    markFavouriteAPI,
    getSearchedCompaniesAPI,
    getInTouchAPI,
    unmarkFavouriteAPI,
    getBlogsAPI,
    getNewCompaniesAPI,
    getMyCompanyAPI,
    getCompanyByIdAPI,
    getSocial,
    getBanner,
    getFavouriteAPI,
    getBlogsPaginatedAPI,
    getIndustriesAPI,
    getUserAPI,
    getBlogByIdAPI,
    getAllCompaniesAPI,
    getBizBucksByCompanyId,
    getBizBucksConfig,
    backedBizBucks,
    getTotalBackedBizBucks,
    getAdminEvent,
    registeredEvent,
    userRegistartionEvent,
    delteBackingDetail,
    editBackingDetail,
    addCompanysearchInfo,
    getRecentstories,
    getTopFiveBacked,
    getTopFiveViewed,
    getTopFiveMatched,
    getBadgeTier,
} from "../../api";

export const getFAQ = async () => {
    const response = await getFAQAPI();
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getBanners = async () => {
    const response = await getBanner();
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getBlogs = async (page = 1, searchItem) => {
    const response = await getBlogsAPI(page, searchItem);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getBlogsPaginated = async (page) => {
    const response = await getBlogsPaginatedAPI(page);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getBlogById = async (id) => {
    const response = await getBlogByIdAPI(id);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getCompanies = async (page) => {
    const response = await getCompaniesAPI(page);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getNewCompanies = async () => {
    const response = await getNewCompaniesAPI();
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getAllCompanies = async (search) => {
    const response = await getAllCompaniesAPI(search);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getMyCompany = async () => {
    const response = await getMyCompanyAPI();
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getCompanyById = async (id) => {
    const response = await getCompanyByIdAPI(id);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getSearchCompanies = async (keyword) => {
    const response = await getSearchedCompaniesAPI(keyword);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const markFavourite = async (id) => {
    const response = await markFavouriteAPI(id);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const unmarkFavourite = async (id) => {
    const response = await unmarkFavouriteAPI(id);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getAllFavourite = async (options) => {
    const response = await getFavouriteAPI(options);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getInTouch = async (data) => {
    const response = await getInTouchAPI(data);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getIndustries = async () => {
    const response = await getIndustriesAPI();
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};


export const getBizBucksById = async (id) => {
    const response = await getBizBucksByCompanyId(id);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getTotalBackedBizBuck = async (search) => {
    const response = await getTotalBackedBizBucks(search);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getBizBucksConfigDetails = async () => {
    const response = await getBizBucksConfig();
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};


export const backedBizBuck=async (data)=>{
    const response = await backedBizBucks(data);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
}

export const getAdminEvents=async ()=>{
    const response = await getAdminEvent();
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
}
export const registerEventData=async (data)=>{
    const response =await registeredEvent(data);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
}

export const deleteBackingDetailsData=async(id)=>{
    const response =await delteBackingDetail(id);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
}

export const editBackingDetailsData=async(id,data)=>{
    const response =await editBackingDetail(id,data);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
}


export const postCompanySearchInfo=async(data)=>{
    const response =await addCompanysearchInfo(data);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
}



export const getRecentStoriesData = async () => {
    const response = await getRecentstories();
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};


export const mostTopFiveBackedData = async () => {
    const response = await getTopFiveBacked();
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const mostTopFiveViewedData = async () => {
    const response = await getTopFiveViewed();
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const topFiveHaveBeenMatched = async () => {
    const response = await getTopFiveMatched();
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};
export const getBadgeTierData = async () => {
    const response = await getBadgeTier();
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

