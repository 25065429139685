import React from "react";
import { useEffect, useState } from "react";
import mastercard from "../../assets/logos_mastercard.svg";
import addcard from "../../assets/Group 159.svg";
import greentick from "../../assets/green-tick.svg";
import { AiOutlineCreditCard, AiOutlineIdcard } from "react-icons/ai";
import "./PaymentConfirm.css";
import { useNavigate } from "react-router-dom";
import success from "../../assets/paymentconfirm.svg";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { detailsActions } from "../../data/reducers/details/details";
import Loader from "../../shared/Loader/Loader";
import axios from "axios";

const PaymentConfirm = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [showDiv, setShowDiv] = useState(false);
    const dispatch = useDispatch();

    const navigateUser = () => {
        dispatch(detailsActions.advertisementReset());
        navigate("/ad");
    };

    const [cardNo, setCardNo] = useState("");

    const { stripeAPIKey } = useSelector((state) => state.details);

    const getCardData = async () => {
        if (stripeAPIKey && state?.paymentMethod) {
            const response = await axios.get(
                `https://api.stripe.com/v1/payment_methods/${state?.paymentMethod}`,
                {
                    headers: {
                        Authorization:
                            "Bearer sk_test_51KXJ9tKqqVhIFFOHsYivWp8JEmpAxrySRIJlTxIsHCEFFqFwxZz9Vzo02TzCv6VXIaJ3juCLhSdvG04pYFCS2Fj600iA7ujofE",
                    },
                }
            );
            console.log(response.data.card.last4);
            setCardNo(response.data.card.last4);
        }
    };

    useEffect(() => {
        if (state?.paymentMethod !== "Not Needed") {
            getCardData();
        }
    }, [state, stripeAPIKey]);

    return (
        <>
            {cardNo === "" && state?.paymentMethod !== "Not Needed" ? (
                <Loader />
            ) : (
                <div className="payment-confirm">
                    <div className="pay-main-card-panel">
                        <div className="payment-confirm-left-panel">
                            <div className="payment-confirm-image">
                                <img
                                    src={success}
                                    alt="confirm"
                                    className="mx-auto"
                                />
                            </div>
                            <div className="payment-confirm-card mx-auto">
                                <div className="payment-tick">
                                    <img src={greentick} alt="" />
                                </div>
                                <div className="payment-confirm-card-head">
                                    Payment Successful
                                </div>
                                <div className="payment-confirm-card-body">
                                    <span>
                                        Your payment of
                                        <b>
                                            $
                                            {state?.creditUsed
                                                ? state?.budget -
                                                  state.usedAmount
                                                : state?.budget}
                                        </b>
                                        <br />
                                        was successfully completed
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="payment-confirm-right-panel mx-auto">
                            <div className="payment-confirm-detail-head">
                                <b>Payment details</b>
                            </div>
                            <div className="payment-amount">
                                <div className="payment-amount-text">
                                    Payment amount
                                </div>
                                <div className="payment-amount-value">
                                    $
                                    {state?.creditUsed
                                        ? state?.budget - state.usedAmount
                                        : state?.budget}
                                </div>
                            </div>
                            <div className="payment-date">
                                <div className="payment-date-text">
                                    Payment date
                                </div>
                                <div className="payment-date-value">
                                    {new Date().toDateString()}
                                </div>
                            </div>
                            <div className="payment-method">
                                <div className="payment-method-text">
                                    Payment method
                                </div>
                                <div className="payment-method-value">
                                    card - xxxx xxxx xxxx {cardNo}
                                </div>
                            </div>
                            {state?.creditUsed && (
                                <div className="pay-free-credit">
                                    <div className="pay-free-credit-text">
                                        Free credit
                                    </div>
                                    <div className="pay-free-credit-value">
                                        ${state?.usedAmount}
                                    </div>
                                </div>
                            )}
                            <div className="dashboard-button">
                                <button onClick={() => navigateUser()} style={{width:"16rem"}}>
                                    Go back to Advertisements
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PaymentConfirm;
