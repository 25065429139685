import React, { Fragment, memo, useEffect, useState } from "react";
import { IoEllipsisHorizontal } from "react-icons/io5";
import Modal from "@material-tailwind/react/Modal";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import ModalBody from "@material-tailwind/react/ModalBody";
import Button from "@material-tailwind/react/Button";
import { SlCalender } from "react-icons/sl";
import { CiClock2 } from "react-icons/ci";
import { IoLocationOutline } from "react-icons/io5";
import { MdFavoriteBorder } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { MdOutlineEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
    deleteAttendeesDetail,
    getUserRegisteredEventById,
    updateEvent,
} from "../../../data/reducers/events/events";
import validator from "validator";
import { useToast } from "@chakra-ui/react";
import {
    cancelEventRegistration,
    deleteAttendeesData,
    editUserRegistredEventsById,
} from "../../../data/services/events/events";
import { BsHeartFill } from "react-icons/bs";
import moment from "moment";

const RegisteredEvent = ({ item, index, getUserRegisteredEventFunction }) => {
    const toast = useToast();
    const [visible, setVisible] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [editEnable, setEditEnable] = useState(-1);
    const [visible3, setVisible3] = useState(false);
    const [numberOfEventAttendes, setNumberOfEventAttendes] = useState();
    const [eventIdDetail,setEventIdDetail]=useState(undefined)
    const { registeredUserEvent, success, message, error, loading } =
        useSelector((state) => state.events);
        const {
            bizBucksConfig,
            myCompany
        } = useSelector((state) => state.content);
    const [userEvent, setUserEvent] = useState({
        event_id: undefined,
        attendees: [],
    });

    const handleLikeEvent = async (data) => {
        let payload = {
            id: data?.id,
            liked: true,
        };
        let response = await dispatch(updateEvent(payload));
        if (response?.payload?.success) {
            dispatch(getUserRegisteredEventById(registeredUserEvent?.id));
        }
    };

    const handleUnlikeEvent = async (data) => {
        let payload = {
            id: data.id,
            liked: false,
        };
        let response = await dispatch(updateEvent(payload));
        if (response?.payload?.success) {
            dispatch(getUserRegisteredEventById(registeredUserEvent?.id));
        }
    };

    const dispatch = useDispatch();

    // useEffect(()=>{
    //   if(registeredUserEvent){
    //     let temp=[]
    //     registeredUserEvent?.eventRegistrations?.map((item)=>{
    //        let temp2={
    //           registration_id:item?.registration_id,
    //           event_id:item?.event_id,
    //           user_id:item?.user_id,
    //           company_id:item?.company_id,
    //           first_name:item?.first_name,
    //           last_name:item?.last_name,
    //           email:item?.email,
    //           phone_number:item?.phone_number,
    //           job_title:item?.job_title,
    //           company_name:item?.company_name
    //        }
    //        temp.push(temp2)
    //     })

    //         setUserEvent({
    //             ...userEvent,
    //             event_id: temp[0]?.event_id,
    //             attendees: temp,
    //         });
    //     }
    // }, [registeredUserEvent, dispatch, useSelector]);
    let attendeedObject = {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        job_title: "",
    };

    useEffect(()=>{
        if(registeredUserEvent){
            let temp=[]
            registeredUserEvent?.eventRegistrations?.map((item)=>{
                let temp2={
                    company_id:item?.company_id,
                    company_name:myCompany?.company_name,
                    email:item?.email,
                    event_id:item?.event_id,
                    first_name:item?.first_name,
                    job_title:item?.job_title,
                    last_name:item?.last_name,
                    phone_number:item?.phone_number,
                    registration_id:item?.registration_id,
                    user_id:item?.user_id
                }
                temp.push(temp2) 
                setUserEvent({
                    ...userEvent,
                    event_id:item?.event_id
                })
            })
            if(Number(bizBucksConfig.find((item)=>item.configKey=="number_of_event_attendees" && item?.configType=="event")?.configValue)==temp?.length){
              setUserEvent({
                ...userEvent,attendees:temp
              })
            }else{
              setNumberOfEventAttendes(Number(bizBucksConfig.find((item)=>item.configKey=="number_of_event_attendees" && item?.configType=="event")?.configValue))
              let temp3=[]
              for(let i=0;i<Number(bizBucksConfig.find((item)=>item.configKey=="number_of_event_attendees" && item?.configType=="event")?.configValue);i++){
                 if(temp[i]?.event_id){
                   temp3.push(temp[i])
                 }else{
                  temp3.push(attendeedObject)
                 }
              }
              setUserEvent({
                 ...userEvent,attendees:temp3
             })
            }
        }
        
      },[useSelector,registeredUserEvent])

    const handleClose = () => {
        setVisible(false);
        // setVisible2(true)
    };
    const handleClose2 = () => {
        setVisible2(false);
    };
    const handleClose3 = () => {
        setVisible3(false);
    };

    const options = {
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        timeZone: "Australia/Sydney",
    };
    const option2 = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        timeZone: "Australia/Sydney", // AEST time zone
    };
    const handleInputChange = (index, field, value) => {
        const updatedAttendees = userEvent.attendees?.map((attendee, i) =>
            i === index ? { ...attendee, [field]: value } : attendee
        );
        setUserEvent({ ...userEvent, attendees: updatedAttendees });
    };
    const validateAndSubmitRequest = async () => {
        try{
            
              let flag=true
              userEvent?.attendees?.map((item,i)=>{
                  if(i==0){
                    if(!item?.first_name?.trim() || !item?.last_name?.trim()  || !item?.job_title?.trim()){
                      toast({
                        title: `Attendees ${i+1} is required`,
                        position: "top-right",
                        duration: 3000,
                        status: "error",
                        isClosable: true,
                      })
                      flag=false
                      return
                    }else if(item?.email?.trim()==""){
                      toast({
                        title: `Email is Required for Attendees ${i+1}`,
                        position: "top-right",
                        duration: 3000,
                        status: "error",
                        isClosable: true,
                      })
                      flag=false
                      return
                    }
                    else if(!validator.isEmail(item?.email?.trim())){
                      toast({
                        title: `Invalid Email for Attendees ${i+1}`,
                        position: "top-right",
                        duration: 3000,
                        status: "error",
                        isClosable: true,
                      })
                      flag=false
                      return
                    }
                  }else{
                    if(item?.first_name?.trim() || item?.last_name?.trim()  || item?.job_title?.trim() || validator.isEmail(item?.email?.trim())){
                      if(!item?.first_name?.trim() || !item?.last_name?.trim()  || !item?.job_title?.trim()){
                        toast({
                          title: `Filled all details for Attendees ${i+1}`,
                          position: "top-right",
                          duration: 3000,
                          status: "error",
                          isClosable: true,
                        })
                        flag=false
                        return
                      }
                      else if(item?.email?.trim()==""){
                        toast({
                          title: `Email is Required for Attendees ${i+1}`,
                          position: "top-right",
                          duration: 3000,
                          status: "error",
                          isClosable: true,
                        })
                        flag=false
                        return
                      }
                      else if(!validator.isEmail(item?.email?.trim())){
                        toast({
                          title: `Invalid Email for Attendees ${i+1}`,
                          position: "top-right",
                          duration: 3000,
                          status: "error",
                          isClosable: true,
                        })
                        flag=false
                        return
                      }
                    }
                  }
              })
    
              if(flag){
                    let filterdata=userEvent?.attendees?.filter((item)=>{
                    if(item?.first_name?.trim() && item?.last_name?.trim()  && item?.job_title?.trim() && validator.isEmail(item?.email?.trim())){
                        return item
                    }
                    })
                     let finalAttendeed=[]
                     filterdata?.map((item)=>{
                         if(item?.registration_id && item?.company_id && item?.event_id && item?.user_id){
                            finalAttendeed.push({
                              ...item,company_name:myCompany?.company_name
                            })
                         }else{
                          let temp={
                            company_id:myCompany?.User?.company_id,
                            company_name:myCompany?.company_name,
                            email:item?.email,
                            event_id:Number(eventIdDetail),
                            first_name:item?.first_name,
                            job_title:item?.job_title,
                            last_name:item?.last_name,
                            phone_number:item?.phone_number,
                            user_id:myCompany?.User?.id
                           }
                           finalAttendeed.push(temp)
                         }
                     })
                     let finalPayload={
                      event_id:Number(eventIdDetail),
                      attendees:finalAttendeed
                    }

                    const response=await editUserRegistredEventsById(finalPayload?.event_id,finalPayload)
                    if(response?.success){
                      setVisible(false)
                      setVisible2(true)
                      setUserEvent({
                        event_id:undefined,
                        attendees:[]
                      })
                    }else{
                      toast({
                        title: `${response.message}`,
                        position: "top-right",
                        duration: 3000,
                        status: "error",
                        isClosable: true,
                     })
                    }
              }
          }catch(err){
    
          }
    };
    const cancelEvent = async () => {
        try {
            if (eventIdDetail) {
                const data = await cancelEventRegistration(eventIdDetail);
                if (data?.success) {
                    toast({
                        title: `Registration Cancelled`,
                        position: "top-right",
                        duration: 3000,
                        status: "success",
                        isClosable: true,
                    });
                    getUserRegisteredEventFunction();
                    setVisible2(false);
                    setVisible3(false);
                    setVisible(false);
                } else {
                    toast({
                        title: `${data.message}`,
                        position: "top-right",
                        duration: 3000,
                        status: "error",
                        isClosable: true,
                    });
                }
            }
        } catch (err) {
            toast({
                title: `${err.message}`,
                position: "top-right",
                duration: 3000,
                status: "error",
                isClosable: true,
            });
        }
    };

    function formatDateTime(dateStr) {
        // Create a Date object from the given date string
        const date = new Date(dateStr);

        // Define the options for the date part
        const dateOptions = { month: "long", day: "numeric" };

        // Define the options for the time part
        const timeOptions = {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            timeZone: "Australia/Sydney", // Use the appropriate time zone
        };

        // Get the date and time strings
        const dateString = date.toLocaleString("en-US", dateOptions);
        const timeString = date.toLocaleString("en-US", timeOptions);

        // Combine date and time parts
        return `${dateString} @ ${timeString}`;
    }
    return (
        <div >
            <div
                className="my-2 w-100 cursor-pointer"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px 25px",
                    borderRadius: "30px",
                    background: "#0078BE",
                }}
                onClick={() => {
                    // setVisible(true)
                    setEventIdDetail(item?.id)
                    dispatch(getUserRegisteredEventById(item?.id));
                    setVisible(true);
                }}
            >
                <div>
                    <h4 className="text-white fs-5">{item?.eventName}</h4>
                   
                    <h6 className="text-white" style={{ fontSize: "12px" }}>
                        {moment(item?.eventDate).format('MMMM D')} at {moment(item?.eventDate).format('h:mm a')}
                    </h6>
                </div>
                <div>
                    <IoEllipsisHorizontal
                        className="text-white"
                        style={{ fontSize: "25px" }}
                    />
                </div>
            </div>

            <Modal size="md" backdrop active={visible} toggler={handleClose}>
                <ModalBody>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="row position-relative">
                                    {/* <div>
                                           <h2 className='fw-bolder fs-4'>Power Hour Registration from Events</h2>
                                       </div> */}
                                    <div
                                        className="my-4 d-flex justify-content-between align-items-center flex-wrap flex-md-nowrap"
                                        style={{
                                            background: "#142252",
                                            padding: "15px 25px",
                                            borderRadius: "5px",
                                        }}
                                    >
                                        <div>
                                            <h2
                                                className="fw-bold text-white"
                                                style={{ fontSize: "45px" }}
                                            >
                                                Power Hour
                                            </h2>
                                            <div className="event_popup_banner_section">
                                                <span>
                                                    <SlCalender
                                                        style={{
                                                            transform:
                                                                "scale(1.3)",
                                                        }}
                                                    />
                                                </span>
                                                <span className="ms-2">
                                                  {moment(item?.eventDate).format('MMMM D')}
                                                </span>
                                            </div>
                                            <div className="event_popup_banner_section">
                                                <span>
                                                    <CiClock2
                                                        style={{
                                                            transform:
                                                                "scale(1.5)",
                                                        }}
                                                    />
                                                </span>
                                                <span className="ms-2">
                                                   {moment(item?.eventDate).format('h:mm a')}
                                                </span>
                                            </div>
                                            <div className="event_popup_banner_section">
                                                <span>
                                                    <IoLocationOutline
                                                        style={{
                                                            transform:
                                                                "scale(1.5)",
                                                        }}
                                                    />
                                                </span>
                                                <span className="ms-2">
                                                    Zoom
                                                </span>
                                                <span
                                                    className="ms-2"
                                                    style={{ fontSize: "10px" }}
                                                >
                                                    (link sent after registered)
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className="mt-3 mt-md-0"
                                            style={{
                                                background: "white",
                                                width: "145px",
                                                height: "145px",
                                                borderRadius: "100%",
                                                padding: "10px",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <h4
                                                style={{ color: "#142252" }}
                                                className="fs-4 fw-bolder"
                                            >
                                                Earn
                                            </h4>
                                            <h3
                                                style={{
                                                    fontSize: "30px",
                                                    color: "#f4c58f",
                                                }}
                                            >
                                                100
                                            </h3>
                                            <h2
                                                style={{ color: "#142252" }}
                                                className="fs-5 fw-bolder"
                                            >
                                                BizBucks
                                            </h2>
                                            <p
                                                style={{
                                                    color: "#142252",
                                                    fontSize: "10px",
                                                }}
                                                className=""
                                            >
                                                *when you attend
                                            </p>
                                        </div>
                                    </div>

                                    <div className="w-100 my-3 text-center mx-auto">
                                        <p className="fw-bolder">
                                            Description: 1 hour virtual session
                                            full of business networking and
                                            presentations from industry
                                            professionals!
                                        </p>
                                    </div>

                                    <div className="col-12 my-2 d-flex justify-content-start align-items-center">
                                        <h3 className="fs-4 fw-bolder">
                                            You are Registered!
                                        </h3>
                                        <img
                                            style={{
                                                marginLeft: "15px",
                                                width: "30px",
                                                height: "30px",
                                                borderRadius: "100%",
                                            }}
                                            src="https://cdn-icons-png.flaticon.com/512/5610/5610944.png"
                                        />
                                    </div>
                                    <div className="col-12 my-2">
                                        {userEvent?.attendees?.length > 0 &&
                                            userEvent?.attendees?.map(
                                                (item, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className="row"
                                                        >
                                                            <div className="col-12 fs-6 fw-bold d-flex justify-content-between align-items-center my-2">
                                                                <div>
                                                                    Attendee{" "}
                                                                    {index + 1}{" "}
                                                                    {index ==
                                                                        0 && (
                                                                        <span className="text-danger">
                                                                            *
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                <div className="d-flex justify-content-end align-items-center">
                                                                    <button
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            justifyContent:
                                                                                "space-evenly",
                                                                            alignItems:
                                                                                "center",
                                                                            border: "1px solid black",
                                                                            borderRadius:
                                                                                "10px",
                                                                            padding:
                                                                                "2px 10px",
                                                                        }}
                                                                        onClick={() => {
                                                                            setEditEnable(
                                                                                item?.registration_id
                                                                            );
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                fontSize:
                                                                                    "13px",
                                                                                fontWeight:
                                                                                    "700",
                                                                            }}
                                                                        >
                                                                            Edit
                                                                        </span>
                                                                        <span className="ms-1">
                                                                            <MdOutlineEdit
                                                                                style={{
                                                                                    transform:
                                                                                        "scale(0.8)",
                                                                                }}
                                                                            />
                                                                        </span>
                                                                    </button>
                                                                    <button
                                                                        className="ms-2"
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            justifyContent:
                                                                                "space-evenly",
                                                                            alignItems:
                                                                                "center",
                                                                            border: "1px solid black",
                                                                            borderRadius:
                                                                                "10px",
                                                                            padding:
                                                                                "2px 10px",
                                                                        }}
                                                                        onClick={async () => {
                                                                            try {
                                                                                let temp2 =
                                                                                    registeredUserEvent?.eventRegistrations?.filter(
                                                                                        (
                                                                                            item
                                                                                        ) =>
                                                                                            validator.isEmail(item?.email)
                                                                                    );
                                                                                if (
                                                                                    temp2?.length <=
                                                                                    1
                                                                                ) {
                                                                                    setEventIdDetail(registeredUserEvent?.eventRegistrations[0]?.event_id)
                                                                                    setVisible3(
                                                                                        true
                                                                                    );
                                                                                } else {
                                                                                    const data =
                                                                                        await deleteAttendeesData(
                                                                                            item?.registration_id
                                                                                        );

                                                                                    if (
                                                                                        data?.success
                                                                                    ) {
                                                                                        dispatch(
                                                                                            getUserRegisteredEventById(
                                                                                                item?.event_id
                                                                                            )
                                                                                        );
                                                                                        toast(
                                                                                            {
                                                                                                title: `Attendees deleted Successfully`,
                                                                                                position:
                                                                                                    "top-right",
                                                                                                duration: 3000,
                                                                                                status: "success",
                                                                                                isClosable: true,
                                                                                            }
                                                                                        );
                                                                                    }else{
                                                                                        toast(
                                                                                            {
                                                                                                title: `${data?.message}`,
                                                                                                position:
                                                                                                    "top-right",
                                                                                                duration: 3000,
                                                                                                status: "error",
                                                                                                isClosable: true,
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }
                                                                            } catch (err) {
                                                                                toast(
                                                                                    {
                                                                                        title: `${err.message}`,
                                                                                        position:
                                                                                            "top-right",
                                                                                        duration: 3000,
                                                                                        status: "error",
                                                                                        isClosable: true,
                                                                                    }
                                                                                );
                                                                            }
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                fontSize:
                                                                                    "13px",
                                                                                fontWeight:
                                                                                    "700",
                                                                            }}
                                                                        >
                                                                            Remove
                                                                        </span>
                                                                        <span className="ms-1">
                                                                            <RxCross1
                                                                                style={{
                                                                                    transform:
                                                                                        "scale(0.8)",
                                                                                }}
                                                                            />
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="col-4 my-1 p-1">
                                                                <input
                                                                    type="text"
                                                                    className="form-control p-2 col-4"
                                                                    placeholder="First Name"
                                                                    value={
                                                                        item?.first_name
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleInputChange(
                                                                            index,
                                                                            "first_name",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        editEnable !=
                                                                        item?.registration_id
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-4 my-1 p-1">
                                                                <input
                                                                    type="text"
                                                                    className="form-control p-2 col-4"
                                                                    placeholder="Last Name"
                                                                    value={
                                                                        item?.last_name
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleInputChange(
                                                                            index,
                                                                            "last_name",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        editEnable !=
                                                                        item?.registration_id
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-4 my-1 p-1">
                                                                <input
                                                                    type="text"
                                                                    className="form-control p-2 col-4"
                                                                    placeholder="Email ID"
                                                                    value={
                                                                        item?.email
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleInputChange(
                                                                            index,
                                                                            "email",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        editEnable !=
                                                                        item?.registration_id
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-4 my-1 p-1">
                                                                <input
                                                                    type="text"
                                                                    className="form-control p-2 col-4"
                                                                    placeholder="Job Title"
                                                                    value={
                                                                        item?.job_title
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleInputChange(
                                                                            index,
                                                                            "job_title",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        editEnable !=
                                                                        item?.registration_id
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}

                                        <div className="row my-3 w-100">
                                            <div className="col-12 d-flex justify-content-between align-items-center">
                                                <button
                                                    className="bg-primary text-white"
                                                    style={{
                                                        borderRadius: "30px",
                                                        padding: "10px 25px",
                                                    }}
                                                    onClick={() => {
                                                        validateAndSubmitRequest();
                                                    }}
                                                >
                                                    Save Changes
                                                </button>
                                                <span>
                                                    {registeredUserEvent?.liked ? (
                                                        <BsHeartFill
                                                            style={{
                                                                transform:
                                                                    "scale(3)",
                                                                cursor: "pointer",
                                                            }}
                                                            color="#C11717"
                                                            onClick={() => {
                                                                handleUnlikeEvent(
                                                                    registeredUserEvent
                                                                );
                                                            }}
                                                        />
                                                    ) : (
                                                        <MdFavoriteBorder
                                                            style={{
                                                                transform:
                                                                    "scale(3)",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                handleLikeEvent(
                                                                    registeredUserEvent
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <span
                                        style={{
                                            position: "absolute",
                                            top: "0px",
                                            left: "95%",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            handleClose();
                                        }}
                                    >
                                        <RxCross1
                                            style={{ transform: "scale(1.2)" }}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <Modal
                size="md"
                backdrop
                active={visible2}
                toggler={handleClose2}
                className="bg-success"
            >
                <ModalBody>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div
                                        className="col-12 pb-0"
                                        style={{ width: "300px" }}
                                    >
                                        <div>
                                            <div className="d-flex justify-content-start align-items-center">
                                                <span>
                                                    <img
                                                        style={{
                                                            width: "45px",
                                                            height: "45px",
                                                            borderRadius:
                                                                "100%",
                                                        }}
                                                        src="https://cdn-icons-png.flaticon.com/512/4315/4315445.png"
                                                    />
                                                </span>
                                                <h3
                                                    className="ms-4 text-center fw-bold"
                                                    style={{ fontSize: "17px" }}
                                                >
                                                    Registration Updated{" "}
                                                </h3>
                                            </div>
                                            <div className="mt-3">
                                                <h5
                                                    className="fw-bold"
                                                    style={{ fontSize: "15px" }}
                                                >
                                                    Power Hour on{" "}
                                                    {/* {formatDateTime(
                                                        registeredUserEvent?.eventDate
                                                    )} */}
                                                    {moment(registeredUserEvent?.eventDate).format('MMMM D')} at 
                                                    {moment(registeredUserEvent?.eventDate).format('h:mm a')}
                                                </h5>
                                                <p
                                                    style={{
                                                        textAlign: "center",
                                                        fontSize: "11px",
                                                        marginTop: "15px",
                                                    }}
                                                >
                                                    You will receive a Calendar
                                                    Invite shortly with the zoom
                                                    link for the event. 100
                                                    BizBucks will be deposited
                                                    in your account AFTER you
                                                    have attended the event. You
                                                    may edit or cancel
                                                    registration by clicking the
                                                    event on the calendar
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <Modal
                size="md"
                backdrop
                active={visible3}
                toggler={handleClose3}
                className="bg-success"
            >
                <ModalBody>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div
                                        className="col-12 pb-0"
                                        style={{ width: "300px" }}
                                    >
                                        <div>
                                            <div className="d-flex justify-content-start align-items-center">
                                                <h3
                                                    className="ms-4 text-center fw-bold"
                                                    style={{ fontSize: "17px" }}
                                                >
                                                    Your event will be
                                                    cancelled!!
                                                </h3>
                                            </div>
                                            <div className="mt-3">
                                                <h5
                                                    className="fw-bold text-center"
                                                    style={{ fontSize: "15px" }}
                                                >
                                                    Are you sure??
                                                </h5>
                                                <p
                                                    style={{
                                                        textAlign: "center",
                                                        fontSize: "11px",
                                                        marginTop: "15px",
                                                    }}
                                                >
                                                    You want to cancel the
                                                    event!
                                                </p>
                                            </div>

                                            <div className="row my-3 w-100">
                                                <div className="col-12 d-flex justify-content-center align-items-center">
                                                    <button
                                                        className="bg-primary text-white"
                                                        style={{
                                                            borderRadius:
                                                                "30px",
                                                            padding:
                                                                "10px 25px",
                                                        }}
                                                        onClick={() => {
                                                            cancelEvent();
                                                        }}
                                                    >
                                                        Cancel Event
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default memo(RegisteredEvent);
