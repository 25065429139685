import React, { useEffect, useState } from "react";
import "./RequestEndorse.css";

import Modal from "@material-tailwind/react/Modal";
import ModalBody from "@material-tailwind/react/ModalBody";
import Button from "@material-tailwind/react/Button";

import { BsPeople } from "react-icons/bs";
import { AiOutlineLink } from "react-icons/ai";
import ChipInput from "material-ui-chip-input";
import { useDispatch, useSelector } from "react-redux";
import { sendEndorseAction } from "../../../data/reducers/profile/profile";
import { profileActions } from "../../../data/reducers/profile/profile";
import { useToast } from "@chakra-ui/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ENDORSE_BASE_URL } from "../../../config/api";
import { validateEmail } from "../../../shared/utils/checkRegex";

const RequestEndorse = ({ id, showModal, setShowModal }) => {
    // const [showModal, setShowModal] = useState(false);
    const toast = useToast();
    const [emails, setEmails] = useState([]);
    const [modifiedTxt, setModifiedTxt] = useState(false);
    const [newText, setNewText] = useState("");
    const [content, setContent] = useState("");

    const dispatch = useDispatch();
    const { myCompany } = useSelector((state) => state.content);
    const { success, message, error } = useSelector((state) => state.profile);

    const handleAddChip = (email) => {
        if (validateEmail(email)) setEmails([...emails, email]);
        else
            dispatch(
                toast({
                    title: `${email} is not a valid email!`,
                    status: "error",
                    position: "top-right",
                    isClosable: true,
                    duration: 3000,
                })
            );
    };

    const handleDeleteChip = (chipToDelete) => {
        setEmails(emails.filter((email) => email !== chipToDelete));
    };
    const [getLink, setGetLink] = useState(false);

    const text = `Hi there, 


    We'd like to request an endorsement for ${myCompany?.company_name}, based on your experience partnering with us! The review will be featured on the B2B business growth platform-BackBone.\n\nTo complete the rating, you'll need to simply register your company details with BackBone. They do this only to ensure legitimate businesses provide ratings. We appreciate your patience in this matter!\n
As a gesture of goodwill, Backbone will offer you a $100 bizbucks note that can be used should you sign up for a trial.
\nThanks in advance for your cooperation!\n
${myCompany?.company_name}
`;

    useEffect(() => {
        setContent(text);
    }, [myCompany]);

    const handleEndorseRequest = () => {
        let data = new URLSearchParams();
        for (let i = 0; i < emails.length; i++) {
            if (validateEmail(emails[i]))
                data.append(`emailList[${i}]`, emails[i]);
            else {
                dispatch(
                    toast({
                        title: `${emails[i]} is not a valid email!`,
                        status: "error",
                        position: "top-right",
                        isClosable: true,
                        duration: 3000,
                    })
                );
                return;
            }
        }
        data.append(`url`, `/company/${id}?review=true`);

        if (modifiedTxt) {
            data.append("newText", newText);
            data.append("hasModified", modifiedTxt);
        } else {
            data.append("text", content);
        }
        setGetLink(false);
        if (emails.length > 0) {
            dispatch(sendEndorseAction(data));
            setShowModal(false);
            toast({
                title: "Success",
                description: "Requested Successfully",
                status: "success",
                position: "top-right",
                isClosable: true,
            });
            setContent(text);
            setEmails([]);
        }
    };

    const onChange = (e) => {
        setNewText(e.target.value);
        setContent(e.target.value);
        setModifiedTxt(true);
    };

    useEffect(() => {
        if (success && message) {
            dispatch(profileActions.reset());
        }
        dispatch(profileActions.reset());
        setGetLink(false);
    }, [dispatch, success, message, toast, showModal]);

    return (
        <>
            {/* <div className="request-other-to-endorse">
                <button onClick={() => setShowModal(true)}>
                    Request customer endorsements
                </button>
            </div> */}

            {/* Modal */}

            <div className="endorse-modal modal-wrapper">
                <Modal
                    size="lg"
                    active={showModal}
                    toggler={() => {
                        setShowModal(false);
                    }}
                >
                    <ModalBody>
                        <div className="endorse-body">
                            <div className="top-heading">
                                <div className="icon-wrapper">
                                    <BsPeople />
                                </div>
                                <h1>Request an endorsement</h1>
                            </div>
                            <p className="get-free-ad-credit">
                                Earn $100 bizbucks to use on BackBone
                                for every successful endorsement.
                            </p>

                            <div className="input-section">
                                <div className="mt-4 mb-3" style={{ position: 'relative', margin: '0 0.5rem' }}>
                                    <ChipInput
                                        placeholder="Add email id"
                                        style={{ margin: "0 0.5rem" }}
                                        required
                                        value={emails}
                                        blurBehavior="add"
                                        onAdd={(chip) => handleAddChip(chip)}
                                        fullWidth
                                        onDelete={(chip) =>
                                            handleDeleteChip(chip)
                                        }
                                        label=""
                                        variant="outlined"
                                    />
                                    {/* {emails.length === 0 && (
                                        <span className="chip-input-placeholder">Add email id</span>
                                    )} */}
                                </div>
                                <div className="mail-content">
                                    <textarea
                                        name="emailcontent"
                                        id="emailcontent"
                                        cols="30"
                                        rows="8"
                                        value={content}
                                        readOnly
                                    />
                                </div>
                                <div className="max-length-email-content">
                                    {/* <span>You can write up to 250 words</span> */}
                                </div>
                            </div>
                            <div className="d-flex justify-content-end mt-3">
                                <div className="save-button">
                                    <Button
                                        color="green"
                                        onClick={() => {
                                            handleEndorseRequest();
                                        }}
                                        ripple="light"
                                    >
                                        Send
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {/*<hr />
                        <div className="endorse-body mt-3">
                            <div className="top-heading">                            
                                <div className="icon-wrapper">
                                    <AiOutlineLink />
                                </div>
                                 <h1>Get link</h1>
                            </div>
                            <div className="bottom-share d-flex justify-content-between">
                                <p className="share-this-link">
                                    Anyone on the internet with this link can
                                    view
                                </p>
                                <CopyToClipboard
                                    text={`${ENDORSE_BASE_URL}/company/${id}`}
                                >
                                    <button
                                        onClick={() => setGetLink(!getLink)}
                                    >
                                        {!getLink ? (
                                            <h1>Copy Link</h1>
                                        ) : (
                                            <h1>Link Copied</h1>
                                        )}
                                    </button>
                                </CopyToClipboard>
                            </div> 
                        </div>*/}
                    </ModalBody>
                </Modal>
            </div>
        </>
    );
};

export default RequestEndorse;
