import React, { useEffect } from "react";

import Logo from "../../assets/backbone-logo.svg";

import "./CompanyDetails.css";
import "@material-tailwind/react/tailwind.css";

import ChoosePlan from "./Components/ChoosePlan";
import Details from "./Components/Details";
import Payment from "./Components/Payment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { detailsActions } from "../../data/reducers/details/details";
import { authActions } from "../../data/reducers/auth/auth";
import { getSubsCheck } from "../../data/api";

const CompanyDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { activeStep } = useSelector((state) => state.details);
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        if (location?.state?.choseSubs) {
        } else if (user?.hasCompany) {
            if (getCheck() == true) {
                console.log("in check", getCheck());
                dispatch(authActions.setIsAuthenticated(true));
                navigate("/");
            }
            console.log("hello i am in companydetails");
            if (moment(user?.subscriptionValidity).diff(moment(), "days") < 0) {
                dispatch(detailsActions.setActiveStep(2));
                navigate("/details", {
                    state: { choseSubs: true },
                });
            }
            // check if the user doesn't have a subscription plan
            else if (!user?.subscription) {
                console.log("2");
                dispatch(detailsActions.setActiveStep(2));
                navigate("/details", {
                    state: { choseSubs: true },
                });
            } else {
                dispatch(authActions.setIsAuthenticated(true));
                console.log("hello can you see me");
            }
        }
    }, [user?.hasCompany, navigate]);
    const getCheck = async () => {
        try {
            let res = await getSubsCheck(user?.id);
            let check = res?.data?.success;
            console.log("check", check);
            return check;
        } catch (e) {
            console.log(e);
            return false;
        }
    };

    return (
        <div className="company-details">
            <div className="logo">
                <img src={Logo} alt="Logo" onClick={() => navigate("/")} />
            </div>
            <div className="container py-3">
                {activeStep === 1 && <Details activeStep={1} />}

                {activeStep === 2 && <ChoosePlan activeStep={2} />}

                {activeStep === 3 && <Payment activeStep={3} />}
            </div>
        </div>
    );
};

export default CompanyDetails;
