import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sendContactDetails } from "../../services/contact/contact";

const initialState = {
    success: false,
    loading: false,
    error: null,
    message: "",
};

export const sendContact = createAsyncThunk("send/contact", async (data) => {
    const response = await sendContactDetails(data);
    if (response?.error) {
        return { error: response?.error };
    }
    return response;
});

export const contactSlice = createSlice({
    name: "contact",
    initialState,
    reducers: {
        reset: (state, action) => {
            state.error = null;
            state.message = null;
        },
    },
    extraReducers: {
        [sendContact.pending]: (state, action) => {
            state.loading = true;
        },
        [sendContact.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error;
            }
            if (action.payload?.success) {
                state.message = action.payload?.message;
            }
        },
    },
});

export const contactUsActions = contactSlice.actions;
export default contactSlice.reducer;
