import React from "react";
import greentick from "../../assets/green-tick.svg";
import "./PaymentSuccess.css";
import { useNavigate } from "react-router-dom";
import success from "../../assets/paymentconfirm.svg";
import DetailSteps from "../../shared/Details/DetailSteps";
import { useDispatch, useSelector } from "react-redux";
import { detailsActions } from "../../data/reducers/details/details";
import { reset } from "../../data/reducers/payment/payment";

const PaymentSuccess = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { activeStep } = useSelector((state) => state.details);

    dispatch(detailsActions.setActiveStep(3));
    return (
        <>
            <div className="payment-success">
                <p
                    style={{
                        fontSize: "2rem",
                        textAlign: "center",
                        fontWeight: "700",
                        fontFamily: "Roboto",
                        marginTop: "2rem",
                    }}
                >
                    BackBone registration
                </p>
                <DetailSteps activeStep={activeStep} />
                <div className="pays-main-card-panel">
                    <div className="payment-success-panel">
                        <div className="payment-confirm-image">
                            {" "}
                            <img src={success} alt="confirm" />
                        </div>
                        <div className="payment-success-card">
                            <div className="payment-success-tick">
                                <img src={greentick} alt="" />
                            </div>
                            <div className="payment-success-card-head">
                                Payment Successful
                            </div>
                            <div className="payment-success-card-body">
                                <span>
                                    Your payment was
                                    <br /> successfully completed.
                                </span>
                            </div>
                        </div>
                        <div className="success-retry-button">
                            <button
                                onClick={() => {
                                    dispatch(reset());
                                    navigate("/dashboard");
                                }}
                            >
                                Continue to home screen
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentSuccess;
