import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
    getBlogsAction,
    contentActions,
} from "../../../data/reducers/home/content";
import "./Blog.css";

const CustomDot = ({ active }) => {
    return (
        <div
            className={active ? "custom-dot-active" : "custom-dot-notactive"}
        ></div>
    );
};

const Blog = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { blogs } = useSelector((state) => state.content);

    useEffect(() => {
        dispatch(getBlogsAction({ page: 1, searchItem: "" }));
    }, [dispatch]);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 3,
            slidesToSlide: 1,
        },
        tablet: {
            breakpoint: { max: 1200, min: 768 },
            items: 2,
            slidesToSlide: 1,
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1,
            slidesToSlide: 1,
        },
    };
    return (
        <div className="home-blog-container">
            <h1 className="blog-title">Learning Lounge</h1>
            <div className="home-blog">
                <div className="blog-see-all">
                    <Link to="/allblogs">
                        <button className="blog-see-all-text">See All</button>
                    </Link>
                </div>
                <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    customDot={<CustomDot />}
                    responsive={responsive}
                    ssr={true}
                    autoPlay={true}
                    infinite={true}
                    autoPlaySpeed={2000}
                    keyBoardControl={true}
                    customTransition="all 1s"
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                >
                    {blogs?.slice(0, 5).map((val, ind) => {
                        try {
                            let desc = JSON.parse(val?.blog_description);
                            console.log(desc.blocks[0].text);
                            // console.log(desc.block);
                            console.log("hereee");
                            return (
                                <div key={ind} className="blog-card">
                                    <figure>
                                        <img
                                            className="object-cover"
                                            src={val?.blog_image}
                                            alt=""
                                        />
                                    </figure>
                                    <div className="blog-content">
                                        <h1 className="blog-card-title">
                                            {val?.blog_title}
                                        </h1>
                                        <div className="blog-card-content">
                                            {desc?.blocks[0]?.text?.length > 200
                                                ? `${desc?.blocks[0]?.text?.substring(
                                                      0,
                                                      200
                                                  )}...`
                                                : desc?.blocks[0]?.text}
                                            <div
                                                onClick={() => {
                                                    dispatch(
                                                        contentActions.setCurrentBlogId(
                                                            val.id
                                                        )
                                                    );
                                                    navigate("/Blogs");
                                                }}
                                            >
                                                <p className="text-right">
                                                    {val?.blog_description
                                                        ?.length > 200 && (
                                                        <button className="blog-read-more">
                                                            Read Further
                                                        </button>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        } catch (e) {}
                    })}
                </Carousel>
            </div>
        </div>
    );
};

export default Blog;
