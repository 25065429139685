import { useEffect } from "react";
import React, { useState } from "react";
import { GoPrimitiveDot } from "react-icons/go";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { BsInfoCircleFill } from "react-icons/bs";
import { useToast } from "@chakra-ui/react";
import Button from "@material-tailwind/react/Button";
import Modal from "@material-tailwind/react/Modal";
import ReCAPTCHA from "react-google-recaptcha";

import Signup_Left_Frame_1 from "../../../assets/signup-left-frame-2.svg";
import logo from "../../../assets/backbone-logo.svg";

import "./Signup.css";

import { useDispatch, useSelector } from "react-redux";
import DetailSteps from "../../../shared/Details/DetailSteps";
import { authActions, signUpUser } from "../../../data/reducers/auth/auth";
import { GOOGLE_CAPTCHA_KEY, WORDPRESS_URL } from "../../../config/api";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

const Signup = ({ setIsLogin }) => {
    const toast = useToast();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { signupsuccess, signuperror, signuploading } = useSelector(
        (state) => state.auth
    );
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("referToken") || "";
    const [showModal, setShowModal] = useState(false);
    const [passed, setPassed] = useState(false);
    const [dataError, setDataError] = useState("");
    const [visible, setVisible] = useState({
        password1: false,
        password2: false,
    });

    const [data, setData] = useState({
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        conPassword: "",
        referToken: token,
    });

    const [min8, setMin8] = useState(false);
    const [upp1, setUpp1] = useState(false);
    const [low1, setLow1] = useState(false);
    const [num1, setNum1] = useState(false);
    const [special1, setSpecial1] = useState(false);

    const [firstError, setFirstError] = useState(false);
    const [lastError, setLastError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [conPasswordError, setConPasswordError] = useState(false);

    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
        setDataError("");
        setEmailError(false);
        setPasswordError(false);
        setLastError(false);
        setFirstError(false);
    };

    useEffect(() => {
        if (signupsuccess) {
            setShowModal(true);
            setData({
                firstname: "",
                lastname: "",
                email: "",
                password: "",
                conPassword: "",
            });
            dispatch(authActions.reset());
        }

        if (signuperror) {
            toast({
                title: "Error",
                description: signuperror,
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
            dispatch(authActions.reset());
        }
        dispatch(authActions.reset());
    }, [dispatch, toast, signupsuccess, signuperror, signuploading]);

    const onSubmit = (e) => {
        setIsLogin(false);
        e.preventDefault();

        if (!data?.firstname) {
            setFirstError(true);
        }

        if (!data?.lastname) {
            setLastError(true);
        }

        if (!data?.email) {
            setEmailError(true);
        }

        if (!(min8 && upp1 && low1 && num1 && special1)) {
            setPasswordError(true);
            setDataError("Password format doesn't match!");
        }

        if (data?.password !== data?.conPassword) {
            setConPasswordError(true);
            setDataError("Password doesn't match!");
        } else {
            setConPasswordError(false);
        }

        if (!data?.firstname || !data?.email || !data?.password) {
            if (
                !data?.email &&
                !data?.password &&
                (!data?.firstname || !data?.lastname)
            ) {
                setDataError("Please enter all the fields!");
            } else if (!data?.firstname) {
                setDataError("Please enter name!");
            } else if (!data?.lastname) {
                setDataError("Please enter last name!");
            } else if (!data?.email && !data?.password) {
                setDataError("Please enter an email and password!");
            } else if (!data?.email) {
                setDataError("Please enter an email!");
            } else if (!data?.password) {
                setDataError("Please enter an password!");
            }
        }

        const isValidated =
            min8 &&
            upp1 &&
            low1 &&
            num1 &&
            special1 &&
            data?.password === data?.conPassword &&
            data?.firstname &&
            data?.lastname &&
            data?.email;

        if (isValidated) {
            dispatch(signUpUser(data));
        }
    };

    const checkValidation = (e) => {
        // For length
        if (e.target.value?.length >= 8) {
            setMin8(true);
        } else {
            setMin8(false);
        }
        // For Upper
        if (e.target.value?.toLowerCase() !== e.target.value) {
            setUpp1(true);
        } else {
            setUpp1(false);
        }
        // For Lower
        if (e.target.value?.toUpperCase() !== e.target.value) {
            setLow1(true);
        } else {
            setLow1(false);
        }
        // For Number
        const regexNum = /\d/;
        if (regexNum.test(e.target.value)) {
            setNum1(true);
        } else {
            setNum1(false);
        }
        // For Number
        const regexSpecial = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
        if (regexSpecial.test(e.target.value)) {
            setSpecial1(true);
        } else {
            setSpecial1(false);
        }
    };

    return (
        <>
            {/* Success Modal */}
            <div className="modal-wrapper">
                <Modal
                    size="md"
                    active={showModal}
                    toggler={() => setShowModal(false)}
                >
                    <div className="p-1">
                        <div className="position-relative">
                            <BsInfoCircleFill
                                className="position-absolute top-0 left-0"
                                color="#007893"
                                fontSize={24}
                            />
                        </div>
                        <div className="d-block p-5">
                            <p className="email-sent">
                                Email verfication has been sent to your
                                registered email ID. <br /> kindly verify your
                                email.
                            </p>
                        </div>
                    </div>
                </Modal>
            </div>
            <div className="signup-container">
                <div className="signup-left">
                    <img src={Signup_Left_Frame_1} alt="Signup Frame" />
                </div>
                <div className="signup-right">
                    <div className="logo-main">
                        {WORDPRESS_URL ? (
                            <a href={WORDPRESS_URL}>
                                <img src={logo} alt="logo" />
                            </a>
                        ) : (
                            <img
                                src={logo}
                                alt="logo"
                                onClick={() => navigate("/")}
                            />
                        )}
                    </div>

                    <div className="rest-body">
                        <div className="signup-right-heading">
                            <h1>Create new account</h1>
                            {/* <DetailSteps activeStep={0} /> */}
                        </div>
                        <form onSubmit={onSubmit}>
                            <div className="signup-right-form">
                                {dataError && (
                                    <div className="error-box">{dataError}</div>
                                )}
                                <div className="form-group signup-right-form-name">
                                    <div className="firstname">
                                        <label htmlFor="firstname">
                                            First name*
                                        </label>
                                        <input
                                            value={data?.firstname}
                                            type="text"
                                            name="firstname"
                                            id="firstname"
                                            className={
                                                firstError ? "error-input" : ""
                                            }
                                            placeholder="First Name"
                                            onChange={(e) => onChange(e)}
                                        />
                                    </div>
                                    <div className="lastname">
                                        <label htmlFor="lastname">
                                            Last name*
                                        </label>
                                        <input
                                            value={data?.lastname}
                                            type="text"
                                            name="lastname"
                                            id="lastname"
                                            className={
                                                lastError ? "error-input" : ""
                                            }
                                            placeholder="Last Name"
                                            onChange={(e) => onChange(e)}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email ID*</label>
                                    <span className="shared-email">
                                        A shared email ID may be used if
                                        multiple users requires access.
                                    </span>
                                    <input
                                        value={data?.email}
                                        type="email"
                                        name="email"
                                        className={
                                            emailError ? "error-input" : ""
                                        }
                                        placeholder="example@gmail.com"
                                        onChange={(e) => onChange(e)}
                                    />
                                </div>
                                <div className="form-group">
                                    <div className="password-input relative">
                                        {visible?.password1 ? (
                                            <AiOutlineEye
                                                className="absolute right-2"
                                                fontSize={24}
                                                color="#8d8d8d"
                                                onClick={() =>
                                                    setVisible({
                                                        ...visible,
                                                        password1:
                                                            !visible?.password1,
                                                    })
                                                }
                                            />
                                        ) : (
                                            <AiOutlineEyeInvisible
                                                className="absolute right-2"
                                                fontSize={24}
                                                color="#8d8d8d"
                                                onClick={() =>
                                                    setVisible({
                                                        ...visible,
                                                        password1:
                                                            !visible?.password1,
                                                    })
                                                }
                                            />
                                        )}
                                        <label htmlFor="password">
                                            Password*
                                        </label>
                                        <input
                                            type={
                                                visible?.password1
                                                    ? "text"
                                                    : "password"
                                            }
                                            value={data?.password}
                                            name="password"
                                            className={
                                                passwordError
                                                    ? "error-input"
                                                    : ""
                                            }
                                            placeholder="********"
                                            onChange={(e) => {
                                                onChange(e);
                                                checkValidation(e);
                                            }}
                                        />
                                    </div>

                                    <div className="password-validation">
                                        <p>
                                            <GoPrimitiveDot
                                                color={
                                                    min8 ? "#5AB659" : "#A6A6A6"
                                                }
                                                fontSize={16}
                                            />
                                            Minimum 8 characters
                                        </p>
                                        <p>
                                            <GoPrimitiveDot
                                                color={
                                                    upp1 ? "#5AB659" : "#A6A6A6"
                                                }
                                                fontSize={16}
                                            />
                                            Atleast 1 uppercase
                                        </p>
                                        <p>
                                            <GoPrimitiveDot
                                                color={
                                                    low1 ? "#5AB659" : "#A6A6A6"
                                                }
                                                fontSize={16}
                                            />
                                            Atleast 1 lower case
                                        </p>
                                        <p>
                                            <GoPrimitiveDot
                                                color={
                                                    num1 ? "#5AB659" : "#A6A6A6"
                                                }
                                                fontSize={16}
                                            />
                                            Atleast 1 number
                                        </p>
                                        <p>
                                            <GoPrimitiveDot
                                                color={
                                                    special1
                                                        ? "#5AB659"
                                                        : "#A6A6A6"
                                                }
                                                fontSize={16}
                                            />
                                            Atleast 1 special character
                                        </p>
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <div className="password-input relative">
                                        {visible?.password2 ? (
                                            <AiOutlineEye
                                                className="absolute right-2"
                                                fontSize={24}
                                                color="#8d8d8d"
                                                onClick={() =>
                                                    setVisible({
                                                        ...visible,
                                                        password2:
                                                            !visible?.password2,
                                                    })
                                                }
                                            />
                                        ) : (
                                            <AiOutlineEyeInvisible
                                                className="absolute right-2"
                                                fontSize={24}
                                                color="#8d8d8d"
                                                onClick={() =>
                                                    setVisible({
                                                        ...visible,
                                                        password2:
                                                            !visible?.password2,
                                                    })
                                                }
                                            />
                                        )}
                                    </div>
                                    <label htmlFor="password">
                                        Verify New Password*
                                    </label>
                                    <input
                                        value={data?.conPassword}
                                        type={
                                            visible?.password2
                                                ? "text"
                                                : "password"
                                        }
                                        name="conPassword"
                                        className={
                                            conPasswordError
                                                ? "error-input"
                                                : ""
                                        }
                                        placeholder="********"
                                        onChange={(e) => onChange(e)}
                                    />
                                </div>
                                <ReCAPTCHA
                                    className="recaptcha"
                                    sitekey={GOOGLE_CAPTCHA_KEY}
                                    onChange={() => setPassed(true)}
                                    theme="light"
                                    type="image"
                                />

                                <div className="submit-button">
                                    <Button
                                        color={"blue"}
                                        buttonType="filled"
                                        size="regular"
                                        rounded={false}
                                        type="submit"
                                        disabled={!passed}
                                    >
                                        Continue
                                    </Button>
                                </div>
                                <p className="already-have-account">
                                    Already have an account?{" "}
                                    <span onClick={() => setIsLogin(true)}>
                                        Login
                                    </span>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Signup;
