import React, { useEffect, useState } from "react";
import imagelogo from "../../assets/image-logo.svg";
import giflogo from "../../assets/gif-logo.svg";
import "./LaunchForm.css";
import closeimage from "../../assets/unsplash_Z5cyBi5CLPg.svg";
import { MdOutlineCancel } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import {
    detailsActions,
    getIndustries,
} from "../../data/reducers/details/details";
import { useNavigate } from "react-router-dom";
import {
    addAdvertisementAction,
    getAllAdvertisementAction,
} from "../../data/reducers/advertisement/advertisement";
import "../../pages/CompanyDetails/Components/BasicDetails1.css";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
// import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import CancelIcon from "@material-ui/icons/Cancel";
import _without from "lodash/without";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { MdOutlineCheck } from "react-icons/md";
import Select, { StylesConfig } from "react-select";

const LaunchForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToast();
    const { launchForm } = useSelector((state) => state.details);

    const [data, setData] = useState({
        budget: launchForm?.budget,
        campaignType: launchForm?.campaignType,
        targetMarket: launchForm?.targetMarket,
    });
    const { advertisement } = useSelector((state) => state.advertisement);
    const [isError, setIsError] = useState(false);
    const [isError1, setIsError1] = useState(false);
    const [error, setError] = useState(false);
    const [campaign, setCampaign] = useState(launchForm?.campaign);
    const [campaignPreview, setCampaignPreview] = useState(
        launchForm?.campaignPreview
    );
    const [isOld, setIsOld] = useState(false);
    const [oldId, setOldId] = useState(null);
    const [market, setMarket] = useState([]);
    const [tempMarkets, setMarkets] = useState(launchForm?.targetMarket || []);
    const { industries } = useSelector((state) => state.details);

    const selectClosedCampaign = (item, e) => {
        console.log(item.id);
        e.preventDefault();
        setCampaignPreview(e.target.src);
        setIsOld(true);
        setOldId(item.id);
    };

    const onSubmit = async () => {
        // setData({ ...data, markets: tempMarkets });

        if (
            !data.budget ||
            !data.campaignType
            // (isOld === false ? campaign !== null || undefined : !isOld
        ) {
            console.log(tempMarkets);
            console.log(campaign);
            toast({
                title: "Please Add the fields",
                position: "top-right",
                duration: 3000,
                status: "error",
                isClosable: true,
            });
            return;
        }

        if (!campaign || (campaign === undefined && isOld === false)) {
            toast({
                title: "Please Add the fields",
                position: "top-right",
                duration: 3000,
                status: "error",
                isClosable: true,
            });
            return;
        }

        if (parseInt(data.budget) < 500) {
            toast({
                title: "Budget Should be more than $500",
                position: "top-right",
                duration: 3000,
                status: "error",
                isClosable: true,
            });
            setError(true);
            return;
        }

        const payload = {
            budget: parseInt(data?.budget),
            campaignType: data?.campaignType,
            targetMarket: market || [],
            campaign: campaign,
            campaignPreview: campaignPreview,
            isOld: isOld,
            oldId: oldId,
        };

        dispatch(detailsActions.setLaunchForm(payload));

        if (!isError || !isError1) {
            navigate("/adpayment");
        }
    };

    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const [suggestionSearch, setSuggestionSearch] = useState("");
    const [showSuggestions, setShowSuggestions] = useState(false);
    const onSuggestionClick = (val) => {
        setSuggestionSearch(val);
        setShowSuggestions(false);
    };

    const onSearchSuggestion = (e) => {
        // if (e.target.value.length !== 0 && e.target.value !== "") {
        setMarket(e.target.value);
        setSuggestionSearch(e.target.value);
        setShowSuggestions(true);
        // }
    };

    const onFileSelect = (e) => {
        if (e.target.name === "ad-panel-image") {
            const reader = new FileReader();
            if (
                e.target.files[0].size > 512000 &&
                e.target.files[0].name.match(/.(jpeg|png|jpg)$/i)
            ) {
                setIsError(true);
                return;
            }
            setIsError(false);
            setCampaign(e.target.files[0]);
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setCampaignPreview(reader.result);
                }
            };
            reader.readAsDataURL(e.target.files[0]);
            setIsOld(false);
            setOldId(null);
        }
        if (e.target.name === "ad-panel-gif") {
            const reader = new FileReader();
            if (
                e.target.files[0].size > 10240000 &&
                e.target.files[0].name.match(/.(gif)$/i)
            ) {
                setIsError1(true);
                return;
            }
            setIsError1(false);
            setCampaign(e.target.files[0]);
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setCampaignPreview(reader.result);
                }
            };
            reader.readAsDataURL(e.target.files[0]);
            setIsOld(false);
            setOldId(null);
        }
    };

    useEffect(() => {
        dispatch(getIndustries());
        dispatch(getAllAdvertisementAction());
    }, [dispatch]);

    const colourStyles = {
        control: (styles) => ({ ...styles, backgroundColor: "white" }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = "#29b6f6";
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                    ? "#fff"
                    : isFocused
                    ? undefined
                    : undefined,
                color: isDisabled ? "#ccc" : isSelected ? "#fff" : "#3a3a3a",
                cursor: isDisabled ? "not-allowed" : "default",

                ":active": {
                    ...styles[":active"],
                    backgroundColor: !isDisabled
                        ? isSelected
                            ? "#29b6f6"
                            : undefined
                        : undefined,
                },
            };
        },
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: "#29b6f6",
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "#fff",
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: "#fff",
            ":hover": {
                backgroundColor: undefined,
                color: "white",
            },
        }),
    };

    return (
        <div className="launch-form">
            {/* heading */}
            <div className="head-panel">
                <b className="heading-ad">Advertise with us{" >>"}</b>
            </div>
            {/* main-panel */}
            <div className="main-panel-2">
                {/* image preview */}

                <div className="preview-panel">
                    {campaignPreview ? (
                        <img
                            className="ad-image"
                            src={campaignPreview}
                            style={{
                                height: "42rem",
                                width: "40rem",
                                borderRadius: "1rem",
                                objectFit: "contain",
                            }}
                            alt="Logo"
                        />
                    ) : (
                        <div className="ad-blank-image">
                            See your advertisment preview here.
                        </div>
                    )}
                </div>
                {/* upload form */}
                <div className="form-panel">
                    <div className="add-content">
                        <div className="add-content-heading">Add content</div>
                        <div className="add-content-media">
                            <div className="add-content-image">
                                <label>
                                    <input
                                        name="ad-panel-image"
                                        accept="image/png, image/svg, image/jpeg, image/jpg "
                                        style={{ display: "none" }}
                                        type="file"
                                        onChange={onFileSelect}
                                    />
                                    <img src={imagelogo} alt="" />
                                    <span
                                        style={{
                                            justifyContent: "center",
                                            display: !isError ? "flex" : "none",

                                            fontSize: "12px",
                                        }}
                                    >
                                        Max 500KB
                                    </span>
                                    <span
                                        style={{
                                            justifyContent: "center",
                                            display: isError ? "flex" : "none",

                                            color: "red",
                                            fontWeight: "bold",
                                            fontSize: "15px",
                                        }}
                                    >
                                        Max 500KB
                                    </span>
                                </label>
                            </div>
                            <div className="add-content-gif">
                                <label>
                                    <input
                                        name="ad-panel-image"
                                        accept="image/gif"
                                        style={{ display: "none" }}
                                        type="file"
                                        onChange={onFileSelect}
                                    />
                                    <img src={giflogo} alt="" className="" />
                                    <span
                                        style={{
                                            justifyContent: "center",
                                            display: !isError1
                                                ? "flex"
                                                : "none",

                                            fontSize: "12px",
                                        }}
                                    >
                                        Max 10MB
                                    </span>
                                    <span
                                        style={{
                                            justifyContent: "center",
                                            display: isError1 ? "flex" : "none",

                                            color: "red",
                                            fontWeight: "bold",
                                            fontSize: "15px",
                                        }}
                                    >
                                        Max 10MB
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="additonal-details">
                        <div className="additonal-details-heading">
                            Additional details
                        </div>
                        <div className="additonal-details-budget">
                            <div className="additonal-details-budget-head">
                                <span>Budget*</span>
                            </div>
                            <div className="budget">
                                <input
                                    type="text"
                                    name="budget"
                                    className={error && "error"}
                                    value={data.budget}
                                    placeholder="Minimum $500"
                                    onChange={(e) => onChange(e)}
                                />
                                {error && (
                                    <p className="error-text">Minimum $500 </p>
                                )}
                            </div>
                            <hr />
                        </div>
                        <div className="additonal-details-campaign-type">
                            <div className="additonal-details-campaign-head">
                                <span>Campaign type*</span>
                            </div>

                            <div className="panel-radio-group">
                                <div className="panel-radio-group-1">
                                    <input
                                        type="radio"
                                        value="Build Awareness"
                                        name="campaignType"
                                        className="panel-radio-input"
                                        onChange={(e) => onChange(e)}
                                        checked={
                                            data?.campaignType ===
                                            "Build Awareness"
                                        }
                                    />

                                    <label>Build Awareness</label>

                                    <div className="panel-radio-group-1-text">
                                        <span>
                                            (cost per impression @ $0.10)
                                        </span>
                                    </div>
                                </div>

                                <div className="panel-radio-group-2">
                                    <input
                                        type="radio"
                                        name="campaignType"
                                        value="Call-To-Action"
                                        className="panel-radio-input"
                                        onChange={(e) => onChange(e)}
                                        checked={
                                            data?.campaignType ===
                                            "Call-To-Action"
                                        }
                                    />
                                    <label>Call-To-Action</label>
                                    <div className="panel-radio-group-1-text">
                                        <span> (cost per click @ $0.99)</span>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>
                        <div className="form-group my-3 w-100 position-relative">
                            {/* <div className="additonal-details-campaign-type"> */}
                            <div className="additonal-details-campaign-head">
                                <span>Target-Market</span>
                            </div>

                            <div className="target">
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={industries?.map((item) => ({
                                        label: item?.industry_name,
                                        value: item?.industry_name,
                                    }))}
                                    styles={colourStyles}
                                    onChange={(item) => {
                                        setMarket(item.map((e) => e.value));
                                    }}
                                    value={market?.map((item) => ({
                                        value: item,
                                        label: item,
                                    }))}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-panel-2-button">
                <button className="launch-campaign" onClick={onSubmit}>
                    Launch Campaign
                </button>
            </div>
            {/* submit button  */}
            <div className="closed-campaign container mx-auto mt-10">
                <b>Closed Campaign</b>
                <div className="closed-campaign-text">
                    <span>Select from a previous campaign</span>
                </div>
                <div className="panel-closed-images">
                    {advertisement.map(
                        (item, i) =>
                            item.currbudget <= 0 && (
                                <img
                                    onClick={(e) =>
                                        selectClosedCampaign(item, e)
                                    }
                                    src={item.advertisement_image}
                                    alt="advertisement"
                                />
                            )
                    )}
                </div>
            </div>
        </div>
    );
};
export default LaunchForm;
