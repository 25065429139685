import React, { useEffect, useState } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@material-tailwind/react/Modal";
import ModalBody from "@material-tailwind/react/ModalBody";
import Button from "@material-tailwind/react/Button";
import { useToast } from "@chakra-ui/react";
import "./ProfileLeaders.css";
import {
    addLeaderAction,
    deleteLeaderAction,
    profileActions,
} from "../../../data/reducers/profile/profile";
import { getMyCompanyAction } from "../../../data/reducers/home/content";

const ProfileLeaders = ({ isMine, leaders }) => {
    const [showModal, setShowModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [name, setName] = useState("");
    const [role, setRole] = useState("");
    const [img, setImage] = useState("");
    const [imgPreview, setImgPreview] = useState("");
    const [isError, setIsError] = useState(false);
    const dispatch = useDispatch();

    const toast = useToast();
    const onFileSelect = (e) => {
        if (e.target.name === "leaderImage") {
            const reader = new FileReader();
            if (e.target.files[0].size > 512000) {
                setIsError(true);
                return;
            }
            setIsError(false);
            setImage(e.target.files[0]);
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setImgPreview(reader.result);
                }
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const { success, message, error } = useSelector((state) => state.profile);

    const addLeader = async () => {
        setShowModal(false);
        setShowAddModal(false);
        const leader = new FormData();
        if (!name || !role) {
            // setIsError(true);
            toast({
                title: "Please name and role!",
                status: "error",
                position: "top-right",
                isClosable: true,
                duration: 2000,
            });
            setShowAddModal(true);
            return;
        }
        leader.append("leader_name", name);
        leader.append("leader_role", role);
        if (img) {
            leader.append("file", img);
        }
        if (!isError) {
            dispatch(addLeaderAction(leader));
            // if (dispatch(addLeaderAction())) {
            //     toast({
            //         title: "Success",
            //         description: "Leader Added Successfully",
            //         status: "success",
            //         position: "top-right",
            //         isClosable: true,
            //     });
            //     setShowAddModal(false);
            // }
        }
        setName("");
        setRole("");
        setImgPreview("");
    };

    const handleDelete = (id) => {
        dispatch(deleteLeaderAction(id));
        toast({
            title: "Success",
            description: "Deleted Leader Successfully",
            status: "success",
            position: "top-right",
            isClosable: true,
        });
        setShowModal(false);
    };

    const handleLeader = () => {
        if (leaders?.length === 0) {
            setShowAddModal(true);
        } else {
            setShowModal(true);
        }
    };

    useEffect(() => {
        if (success && message) {
            dispatch(profileActions.reset());
            // dispatch(addLeaderAction());
        }
        dispatch(profileActions.reset());
    }, [dispatch, success, message, toast]);

    return (
        <>
            <div className="profile-business-leads">
                <div className="profile-business-header">Leadership</div>
                <div className="profile-business-persons">
                    {leaders?.map((leader,index) => (
                        <div key={index} className="profile-business-person">
                            <div className="d-flex">
                                <figure>
                                    <img src={leader?.leader_pic} alt="Logo" />
                                </figure>
                                <div className="profile-business-person-details">
                                    <div>
                                        <h3>{leader?.leader_name}</h3>
                                        <h5>{leader?.leader_role}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {isMine && (
                    <div
                        className="profile-social-add-button"
                        style={{ cursor: "pointer" }}
                    >
                        <button
                            onClick={() => {
                                handleLeader();
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            Add other Leaders <AiFillPlusCircle />
                        </button>
                    </div>
                )}
            </div>

            {/* Show Leader Modal */}
            <div className="profile-modal modal-wrapper">
                <Modal
                    size="regular"
                    active={showModal}
                    toggler={() => setShowModal(false)}
                    className="profile-tailwind-modal"
                >
                    <ModalBody>
                        <h1 className="modal-head">Add Leader</h1>
                        <div className="pt-3 leader-modal-cont">
                            {leaders?.map((leader,index) => (
                                <div
                                   key={index}
                                    className="profile-business-person"
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div className="d-flex">
                                        <figure>
                                            <img
                                                src={leader?.leader_pic}
                                                alt="Logo"
                                            />
                                        </figure>
                                        <div className="profile-business-person-details">
                                            <div>
                                                <h3>{leader?.leader_name}</h3>
                                                <h5>{leader?.leader_role}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="delete-leader"
                                        onClick={() => {
                                            handleDelete(leader.id);
                                        }}
                                    >
                                        <svg
                                            width="25"
                                            height="30"
                                            viewBox="0 0 25 30"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M7.88169 22.3531C8.13396 22.3531 8.37599 22.2476 8.55564 22.0594C8.73528 21.8711 8.83804 21.6154 8.84152 21.3473V13.1547C8.84152 12.7904 8.65859 12.4535 8.36151 12.2715C8.06462 12.0892 7.69878 12.0892 7.40169 12.2715C7.1048 12.4535 6.92188 12.7904 6.92188 13.1547V21.3473C6.92535 21.6154 7.02811 21.8711 7.20775 22.0594C7.3872 22.2476 7.62943 22.3531 7.8817 22.3531H7.88169Z"
                                                fill="#4A4A4A"
                                            />
                                            <path
                                                d="M12.5008 22.3531C12.7531 22.3531 12.9951 22.2476 13.1748 22.0594C13.3544 21.8711 13.4572 21.6154 13.4607 21.3473V13.1547C13.4607 12.7904 13.2777 12.4535 12.9806 12.2715C12.6838 12.0892 12.3179 12.0892 12.021 12.2715C11.7239 12.4535 11.541 12.7904 11.541 13.1547V21.3473C11.5445 21.6154 11.6473 21.8711 11.8269 22.0594C12.0065 22.2476 12.2486 22.3531 12.5008 22.3531H12.5008Z"
                                                fill="#4A4A4A"
                                            />
                                            <path
                                                d="M17.1141 22.3531C17.3664 22.3531 17.6086 22.2476 17.7881 22.0594C17.9677 21.8711 18.0705 21.6154 18.0739 21.3473V13.1547C18.0739 12.7904 17.891 12.4535 17.5941 12.2715C17.297 12.0892 16.9312 12.0892 16.6343 12.2715C16.3372 12.4535 16.1543 12.7904 16.1543 13.1547V21.3473C16.1578 21.6154 16.2605 21.8711 16.4402 22.0594C16.6198 22.2476 16.8618 22.3531 17.1141 22.3531H17.1141Z"
                                                fill="#4A4A4A"
                                            />
                                            <path
                                                d="M24.0398 4.93835H17.8972L17.107 2.12526C16.932 1.51398 16.5777 0.978054 16.0958 0.595671C15.614 0.213477 15.0297 0.00472921 14.4284 0H10.5757C9.97443 0.00472102 9.39028 0.213471 8.90834 0.595671C8.42659 0.978069 8.07215 1.51403 7.89715 2.12526L7.10694 4.93835H0.959849C0.616984 4.93835 0.299992 5.13273 0.128648 5.44822C-0.0428828 5.76391 -0.0428828 6.15266 0.128648 6.46815C0.299982 6.78384 0.616964 6.97822 0.959849 6.97822H2.10278V26.6129C2.10742 27.3561 2.38827 28.0671 2.88413 28.5914C3.37997 29.1156 4.05065 29.4106 4.75007 29.4118H20.2812C20.983 29.4105 21.6556 29.1137 22.1518 28.5864C22.648 28.0591 22.9274 27.3444 22.9285 26.5987V6.94459H24.0402C24.2947 6.94459 24.5389 6.83724 24.7189 6.64594C24.8988 6.45463 25 6.19518 25 5.92463C24.9988 5.65758 24.8965 5.40224 24.7157 5.21645C24.5351 5.03069 24.2915 4.93032 24.0402 4.93791L24.0398 4.93835ZM9.75428 2.70879H9.75409C9.80875 2.52036 9.91712 2.35471 10.0643 2.23484C10.2117 2.11497 10.3904 2.04682 10.5756 2.03985H14.4551C14.6448 2.0382 14.8296 2.10327 14.9807 2.22519C15.1317 2.34712 15.2405 2.51892 15.2899 2.71351L15.9059 4.93834H9.08881L9.75428 2.70879ZM21.0085 26.822H20.9415H20.9417C20.8917 26.9745 20.799 27.1069 20.6761 27.2019C20.5533 27.297 20.4061 27.3497 20.254 27.3532H4.74958C4.55487 27.3495 4.36924 27.2652 4.23248 27.118C4.09573 26.9708 4.01846 26.7727 4.0173 26.5658V6.94536H21.0082L21.0085 26.822Z"
                                                fill="#4A4A4A"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="profile-social-add-button">
                            <button
                                onClick={() => {
                                    setShowAddModal(true);
                                    setShowModal(false);
                                }}
                                style={{ justifyContent: "flex-end" }}
                            >
                                <AiFillPlusCircle />
                            </button>
                        </div>
                    </ModalBody>
                    <div className="d-flex justify-content-between">
                        <div className="close-button">
                            <Button
                                color="red"
                                buttonType="link"
                                onClick={(e) => {
                                    setShowModal(false);
                                }}
                                ripple="dark"
                            >
                                Close
                            </Button>
                        </div>
                        <div className="save-button">
                            <Button
                                color="green"
                                onClick={(e) => {
                                    setShowModal(false);
                                }}
                                ripple="light"
                            >
                                Save Changes
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>

            {/* Add Modal */}
            <div className="profile-modal modal-wrapper">
                <Modal
                    size="regular"
                    active={showAddModal}
                    toggler={() => setShowAddModal(false)}
                    className="profile-tailwind-modal"
                >
                    <ModalBody>
                        <h1 className="modal-head">Add Business Leaders</h1>
                        <div className="pt-3">
                            <div className="select-leader my-2">
                                <input
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => {
                                        e.target.value = e.target.value
                                            .trimStart()
                                            .replace(/[^a-zA-Z\s]/gi, "");

                                        setName(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="select-leader my-2">
                                <input
                                    type="text"
                                    placeholder="Position"
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)}
                                />
                            </div>
                            <div className="leader-image">
                                <div>
                                    <label className="file-box">
                                        <center>
                                            {imgPreview ? (
                                                <img
                                                    src={imgPreview}
                                                    alt="Logo"
                                                />
                                            ) : (
                                                <div className="blank-image"></div>
                                            )}
                                        </center>
                                    </label>
                                </div>

                                <div className="leader-image-upload">
                                    <label
                                        htmlFor="leader-image"
                                        className="browse-files"
                                    >
                                        <div>Upload Image</div>
                                    </label>
                                    <div className="leader-image-label">
                                        <input
                                            type="file"
                                            name="leaderImage"
                                            id="leader-image"
                                            accept=".jpg, .png, .jpeg"
                                            onChange={onFileSelect}
                                        />
                                    </div>

                                    <span
                                        style={{
                                            justifyContent: "center",
                                            alignItems: "end",
                                            display: !isError ? "flex" : "none",
                                            fontSize: "12px",
                                        }}
                                    >
                                        Max 500KB
                                    </span>
                                    <span
                                        style={{
                                            justifyContent: "center",
                                            alignItems: "end",
                                            display: isError ? "flex" : "none",
                                            color: "red",
                                            fontWeight: "bold",
                                            fontSize: "15px",
                                        }}
                                    >
                                        Max 500KB
                                    </span>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <div className="d-flex justify-content-between">
                        <div className="close-button">
                            <Button
                                color="red"
                                buttonType="link"
                                onClick={(e) => {
                                    setShowAddModal(false);
                                    setName("");
                                    setRole("");
                                    setImgPreview("");
                                    setIsError(false);
                                }}
                                ripple="dark"
                            >
                                Close
                            </Button>
                        </div>
                        <div className="save-button">
                            <Button
                                color="green"
                                onClick={(e) => {
                                    addLeader();
                                }}
                                ripple="light"
                            >
                                Save Changes
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default ProfileLeaders;
