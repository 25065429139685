import React, { useEffect, useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import { Add, Minimize } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "@material-tailwind/react/Modal";
import ModalBody from "@material-tailwind/react/ModalBody";
import Button from "@material-tailwind/react/Button";
import { useToast } from "@chakra-ui/react";
import ContactBg from "../../../assets/contact-bg.svg";
import Footer from "../../../shared/Footer/Footer";
import {
    contactUsActions,
    sendContact,
} from "../../../data/reducers/contact/contact";
import Logo from "../../../assets/backbone-logo.svg";

import "./FaqFooter.css";
import { NavLink } from "react-router-dom";
import { getFAQAction } from "../../../data/reducers/home/content";

export default function FAQs({ isExpanded }) {
    const toast = useToast();
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const [expanded, setExpanded] = React.useState(false);
    const dispatch = useDispatch();
    const { faqs } = useSelector((state) => state.content);
    const [data, setData] = useState({
        name: null,
        email: null,
        message: null,
    });

    const handleSubmit = () => {
        dispatch(sendContact(data));
    };
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };
    const { error, message } = useSelector((state) => state.contact);
    useEffect(() => {
        if (message) {
            toast({
                title: "Success",
                description: message,
                status: "success",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
            dispatch(contactUsActions.reset());
            setData({ name: "", email: "", message: "" });
        }
        if (error) {
            toast({
                title: "Error",
                description: error,
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
            dispatch(contactUsActions.reset());
        }
    }, [dispatch, error, message, toast]);
    useEffect(() => {
        dispatch(getFAQAction());
    }, [dispatch]);

    return (
        <>
            <div onClick={() => navigate("/")}>
                <img
                    style={{
                        width: "4rem",
                        marginLeft: "3.5rem",
                        marginTop: "1rem",
                    }}
                    src={Logo}
                    alt="logo"
                    className="logo"
                />
            </div>
            <div className="faqs">
                <h1 className="faqs-title">Support Centre</h1>
                {faqs?.map((val, ind) => (
                    <Accordion
                        key={ind}
                        expanded={expanded === `panel${ind}`}
                        onChange={handleChange(`panel${ind}`)}
                    >
                        <AccordionSummary
                            expandIcon={
                                expanded === `panel${ind}` ? (
                                    <Minimize />
                                ) : (
                                    <Add />
                                )
                            }
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography className="faqs-heading">
                                {val?.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="faqs-answer">
                                {val?.answer?.line1}
                                {val?.answer?.line2 !== "" && (
                                    <>
                                        <br /> <br />
                                        {val?.answer?.line2}
                                    </>
                                )}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}

                <h1 className="faqs-sub-title">Still have questions?</h1>
                <p className="faq-contact-message">
                    Send us a mail on{" "}
                    <button onClick={() => setShowModal(true)}>
                        contact us
                    </button>{" "}
                    and we’ll be in touch shortly!
                </p>
            </div>
            <div className="faq-modal-container modal-wrapper">
                <Modal
                    size="regular"
                    active={showModal}
                    toggler={() => setShowModal(false)}
                    className="profile-tailwind-modal"
                >
                    <div className="faq-modalbody">
                        <ModalBody>
                            <div id="faq-contact">
                                <div className="faq-modal-main-body">
                                    <div className="contact-part-hide-mobile">
                                        <img
                                            src={ContactBg}
                                            alt="Contact Background"
                                        />
                                    </div>
                                    <div className="faq-contact-part">
                                        <h1 className="faq-contact-heading">
                                            Contact Us
                                        </h1>
                                        <div className="form-group my-4">
                                            <label htmlFor="name">Name:</label>
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                value={data?.name}
                                                onChange={onChange}
                                            />
                                        </div>
                                        <div className="form-group my-4">
                                            <label htmlFor="email">
                                                Email:
                                            </label>
                                            <input
                                                type="email"
                                                name="email"
                                                id="email"
                                                value={data?.email}
                                                onChange={onChange}
                                            />
                                        </div>
                                        <div className="form-group my-4">
                                            <label htmlFor="message">
                                                Your Message:
                                            </label>
                                            <textarea
                                                name="message"
                                                id="message"
                                                onChange={onChange}
                                                value={data?.message}
                                                rows={4}
                                                maxLength={300}
                                            ></textarea>
                                            <span
                                                style={{
                                                    fontSize: "12px",
                                                    color: "#474747",
                                                }}
                                            >
                                                Max 300 Characters
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="save-button">
                                    <Button
                                        color="blue"
                                        onClick={handleSubmit}
                                        ripple="light"
                                    >
                                        Send
                                    </Button>
                                </div>
                            </div>
                        </ModalBody>
                    </div>
                </Modal>
            </div>
            <Footer />
        </>
    );
}
