import React, { memo, useEffect, useState } from "react";
import BackboneLogo from "../../../assets/backbone-logo.svg";
import { useNavigate } from "react-router";
import Modal from "@material-tailwind/react/Modal";
import ModalBody from "@material-tailwind/react/ModalBody";
import { useToast } from "@chakra-ui/react";
import { getInTouchAction } from "../../../data/reducers/home/content";
import { useDispatch, useSelector } from "react-redux";
import { BsPeople } from "react-icons/bs";
import { Radio } from "@material-ui/core";
import BlueCheck from "../../../assets/blue-check.svg";

const HomeCardTwo = ({ topFiveMatched, getYouHaveBennMatched }) => {
    const navigate = useNavigate();
    const toast = useToast();
    const [openModel, setOpenModel] = useState(false);
    const dispatch = useDispatch();
    const [company, setCompany] = useState(undefined);
    const [companyUser,setCompanyUser]=useState(undefined)
    const [selectedValue, setSelectedValue] = React.useState("email");
    const [sentEmailModel, setSentEmailModel] = useState(false);
    const [status, setStatus] = useState("");
    const {
        myCompany,
        loading: myLoading,
        myFavourites,
    } = useSelector((state) => state.content);
    const [content, setContent] = useState();


    useEffect(()=>{
        const text = `Hi ${company?.company_name?.toUpperCase()}

        I'd like to have a quick chat about using your service. Please let me know good time for us to speak. My contact information is below:
        
        ${myCompany?.company_name} 
        
        ${myCompany?.User?.email ? myCompany?.User?.email : ""}
            
        ${myCompany?.User?.mobileNo ? myCompany?.User?.mobileNo : ""}
        Regards ,`;
        setContent(text)
    },[company,myCompany,companyUser])
   
   
   

    const getInTouchCompany = async () => {
        let response = await dispatch(
            getInTouchAction({
                serviceId: company?.id,
                myCompanyId: myCompany?.id,
                selectedValue,
                status,
            })
        );

        if (response?.payload?.success) {
            getYouHaveBennMatched();
            setOpenModel(false);
            setSentEmailModel(true);
        } else {
            toast({
                title: `${response?.payload?.message}`,
                position: "top-right",
                duration: 3000,
                status: "error",
                isClosable: true,
            });
            setOpenModel(false);
            return;
        }
    };
    const onChange = (e) => {
        setContent(e.target.value);
    };

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        console.log("event", selectedValue);
    };
    return (
        <div className="home_card">
            <div className="d-flex justify-content-between align-items-center">
                <div className="text-left">
                    <h4 className="fs-5 fw-bolder">You’ve been matched!</h4>
                    <p style={{ fontSize: "14px", color: "#0078BE" }}>
                        5 businesses you should connect with
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                        <img
                            style={{ height: "30px" }}
                            src="https://cdn-icons-png.flaticon.com/512/2989/2989995.png"
                        />
                        <img
                            style={{ height: "30px" }}
                            src="https://cdn-icons-png.flaticon.com/512/2989/2989995.png"
                        />
                        <img
                            style={{ height: "30px" }}
                            src="https://cdn-icons-png.flaticon.com/512/2989/2989995.png"
                        />
                        <img
                            style={{ height: "30px" }}
                            src="https://cdn-icons-png.flaticon.com/512/2989/2989995.png"
                        />
                        <img
                            style={{ height: "30px" }}
                            src="https://cdn-icons-png.flaticon.com/512/2989/2989995.png"
                        />
                    </div>
                </div>
                <div
                    className="text-center"
                    style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <img
                        style={{ width: "90%" }}
                        src=" https://cdn-icons-png.flaticon.com/512/808/808497.png"
                    />
                </div>
            </div>

            <div className="my-3">
                {topFiveMatched?.length > 0 ?
                    topFiveMatched?.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className="my-2"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <div className="w-50 d-flex justify-content-start align-items-center">
                                    <div>
                                        <div style={{width:"35px"}}>
                                            <img src={item?.Company?.company_logo?item?.Company?.company_logo:"https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"} style={{width:"35px",height:"35px",borderRadius:"100%"}}/>
                                        </div>
                                    </div>
                                    <div className='company_name_column ms-1' onClick={()=>{
                                        navigate(`/company/${item?.Company?.id}`)
                                    }}>
                                        <h4>{item?.Company?.company_name}</h4>
                                    </div>
                                  </div>
                                  <div className='w-50 d-flex justify-content-end align-items-center'>
                                  <div className='me-1' onClick={()=>{
                                    setStatus("connected")
                                    console.log(item?.Company)
                                    setCompany(item?.Company)
                                    setCompanyUser(item?.Company?.User)
                                    setOpenModel(true)
                                  }}>
                                        <button className='connect_button'>Connect</button>
                                    </div>
                                    <div className='ms-1'  onClick={async ()=>{
                                    let response=await  dispatch(
                                        getInTouchAction({
                                            serviceId: item?.Company?.id,
                                            myCompanyId: myCompany?.id,
                                            selectedValue,
                                            status:"remind_later"
                                        })
                                    );
                            
                                    if(response?.payload?.success){
                                        getYouHaveBennMatched()
                                        // setSentEmailModel(true);
                                    }else{
                                        toast({
                                            title: `${response?.payload?.message}`,
                                            position: "top-right",
                                            duration: 3000,
                                            status: "error",
                                            isClosable: true,
                                        })
                                        // setOpenModel(false);
                                        return
                                    }
                                    
                                    // setOpenModel(true)
                                  }}>
                                    <button className='maybe_later_button'>Maybe Later</button>
                                    </div>
                                  </div>
                                
                           </div> )
                }) : <h2  className="my-3 text-center fw-bold">No records found</h2>
                }
                {/* <div className='my-1' style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    <div>
                        <div>
                            <img src={BackboneLogo} style={{width:"35px",height:"35px",borderRadius:"100%"}}/>
                        </div>
                    </div>
                    <div className='company_name_column'>
                        <h4>Company Name</h4>
                    </div>
                    <div>
                        <button className='connect_button'>Connect</button>
                    </div>
                    <div>
                    <button className='maybe_later_button'>Maybe Later</button>
                    </div>
                </div>
                <div className='my-1' style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    <div>
                            <img src={BackboneLogo} style={{width:"35px",height:"35px",borderRadius:"100%"}}/>
                    </div>
                    <div className='company_name_column'>
                        <h4>Company Name</h4>
                    </div>
                    <div>
                        <button className='connect_button'>Connect</button>
                    </div>
                    <div>
                    <button className='maybe_later_button'>Maybe Later</button>
                    </div>
                </div>
                <div className='my-1' style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    <div>
                            <img src={BackboneLogo} style={{width:"35px",height:"35px",borderRadius:"100%"}}/>
                    </div>
                    <div className='company_name_column'>
                        <h4>Company Name</h4>
                    </div>
                    <div>
                        <button className='connect_button'>Connect</button>
                    </div>
                    <div>
                    <button className='maybe_later_button'>Maybe Later</button>
                    </div>
                </div>
                <div className='my-1' style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    <div>
                            <img src={BackboneLogo} style={{width:"35px",height:"35px",borderRadius:"100%"}}/>
                    </div>
                    <div className='company_name_column'>
                        <h4>Company Name</h4>
                    </div>
                    <div>
                        <button className='connect_button'>Connect</button>
                    </div>
                    <div>
                    <button className='maybe_later_button'>Maybe Later</button>
                    </div>
                </div>
                <div className='my-1' style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    <div>
                            <img src={BackboneLogo} style={{width:"35px",height:"35px",borderRadius:"100%"}}/>
                    </div>
                    <div className='company_name_column'>
                        <h4>Company Name</h4>
                    </div>
                    <div>
                        <button className='connect_button'>Connect</button>
                    </div>
                    <div>
                    <button className='maybe_later_button'>Maybe Later</button>
                    </div>
                </div> */}

                <div className="modal-wrapper">
                    <Modal
                        size="lg"
                        active={openModel}
                        toggler={() => {
                            setOpenModel(false);
                        }}
                    >
                        <ModalBody>
                            <div className="Touch-body">
                                <div className="top-heading">
                                    <div className="icon-wrapper">
                                        <BsPeople />
                                    </div>
                                    <h1>Connect</h1>
                                </div>
                                <p className="get-free-ad-credit">Send to:</p>
                                <div>
                                    <div className="companyDetail">
                                        {companyUser?.email}
                                    </div>
                                    {companyUser?.mobileNo && (
                                        <div className="companyDetail">
                                            {companyUser?.mobileNo
                                                ? companyUser?.mobileNo
                                                : ""}
                                        </div>
                                    )}
                                </div>

                                <div className="input-section">
                                    <div className="mail-content">
                                        <textarea
                                            name="emailcontent"
                                            id="emailcontent"
                                            cols="30"
                                            rows="8"
                                            value={content}
                                            disabled
                                            onChange={(e) => onChange(e)}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex justify-space-between">
                                    {companyUser?.email && (
                                        <div className="d-flex">
                                            <span>
                                                <Radio
                                                    checked={
                                                        selectedValue ===
                                                        "email"
                                                    }
                                                    onChange={handleChange}
                                                    value="email"
                                                    name="radio-buttons"
                                                    inputProps={{
                                                        "aria-label": "EMAIL",
                                                    }}
                                                    color="primary"
                                                    size="small"
                                                />
                                                <span className="Radio-label">
                                                    Send via Email
                                                </span>
                                            </span>
                                            {companyUser?.mobileNo && (
                                                <>
                                                    <span>
                                                        <Radio
                                                            checked={
                                                                selectedValue ===
                                                                "phone"
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value="phone"
                                                            name="radio-buttons"
                                                            inputProps={{
                                                                "aria-label":
                                                                    "PHONE",
                                                            }}
                                                            color="primary"
                                                            size="small"
                                                        />
                                                        <span className="Radio-label">
                                                            Send via Phone
                                                        </span>
                                                    </span>

                                                    <span>
                                                        <Radio
                                                            checked={
                                                                selectedValue ===
                                                                "both"
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value="both"
                                                            name="radio-buttons"
                                                            inputProps={{
                                                                "aria-label":
                                                                    "BOTH",
                                                            }}
                                                            color="primary"
                                                            size="small"
                                                        />
                                                        <span className="Radio-label">
                                                            Send via both
                                                        </span>
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    )}

                                    <div
                                        className={
                                            companyUser?.email
                                                ? ""
                                                : "button-not-radio-user"
                                        }
                                    >
                                        <button
                                            className="send-button"
                                            onClick={() => {
                                                getInTouchCompany();
                                            }}
                                        >
                                            Send
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                    <Modal
                        size="lg"
                        active={sentEmailModel}
                        toggler={() => {
                            setSentEmailModel(false);
                        }}
                    >
                        <ModalBody>
                            <div className="sent-popup">
                                <img src={BlueCheck} />
                            </div>
                            <div className="sent">
                                Your message has been sent!
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            </div>

            <Modal
                size="lg"
                active={sentEmailModel}
                toggler={() => {
                    setSentEmailModel(false);
                }}
            >
                <ModalBody>
                    <div className="sent-popup">
                        <img src={BlueCheck} />
                    </div>
                    <div className="sent">Your message has been sent!</div>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default memo(HomeCardTwo);
