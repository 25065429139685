import React, { useLayoutEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Advertisement.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import advertisement, {
    getHomeAdvertisementAction,
    reduceBudgetAction,
} from "../../../data/reducers/advertisement/advertisement";
import { useState } from "react";

const Advertisement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { homeAdvertisements } = useSelector((state) => state.advertisement);

    const [scrollPosition, setPosition] = useState(0);
    const [x, setX] = useState(0);
    const [y, setY] = useState(1);
    const [firstAdvertisemnt,setFirstAdvertisement]=useState(0)
    const [secondAdvertisemnt,setSecondAdvertisemnt]=useState(1)
    // const [advertisementShown, setAdvertisementShown] = useState(false);

    useEffect(() => {
        dispatch(getHomeAdvertisementAction());
        
    }, [dispatch]);


    const findRandomTwoAdvertisemnt=(randomNumber1, randomNumber2)=>{
           if(homeAdvertisements){
               setFirstAdvertisement(randomNumber1)
               setSecondAdvertisemnt(randomNumber2)
           }
    }

    useEffect(() => {
        // Function to generate two random numbers and call the advertisement function
        const generateRandomNumbers = () => {
            if(homeAdvertisements?.length>0){
                 let count=Number(homeAdvertisements?.length)
                const randomNumber1 = Math.floor(Math.random() * count);
                const randomNumber2 = Math.floor(Math.random() * count);
                findRandomTwoAdvertisemnt(randomNumber1, randomNumber2);
            }
        };

        // Call the function immediately when the component mounts
        generateRandomNumbers();

        // Set an interval to call the function every 30 seconds
        const intervalId = setInterval(generateRandomNumbers, 30000);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, [homeAdvertisements]);

    
    // useEffect(() => {
    // if (!advertisementShown) {
    //     window.addEventListener("scroll", (e) => {
    //         if (e.currentTarget.scrollY > 0) {
    //             if (!advertisementShown) {
    //                 // dispatch(reduceBudgetAction(0));
    //                 console.log("aa");
    //                 setAdvertisementShown(true);
    //             }
    //         }
    //     });
    //     window.removeEventListener("scroll", () => {
    //         console.log("Advertisement Shown");
    //     });
    //     setAdvertisementShown(true);
    // } else {
    //     window.removeEventListener("scroll", () => {
    //         console.log("Advertisement Shown");
    //     });
    //     setAdvertisementShown(true);
    // }
    // }, [advertisementShown]);

    // useEffect(() => {
    //     function showAdvertisement() {
    //         if (homeAdvertisements?.length > 0) {
    //             const max = homeAdvertisements?.length;
    //             let x1 = Math.floor(Math.random() * max);
    //             let y1 = Math.floor(Math.random() * (max - x1));

    //             while (
    //                 homeAdvertisements[x1]?.currbudget <= 0 ||
    //                 homeAdvertisements[y1]?.currbudget <= 0
    //             ) {
    //                 x1 = Math.floor(Math.random() * max);
    //                 y1 = Math.floor(Math.random() * (max - x1));
    //             }

    //             setX(x1);
    //             setY(y1);
    //         }
    //     }
    //     showAdvertisement();
    //     setInterval(showAdvertisement, 30000); // 30 seconds
    // }, [homeAdvertisements]);

    // console.log(homeAdvertisements);

    // const visitAdvertisement = (id, company) => {
    //     dispatch(reduceBudgetAction(id));
    //     navigate(`/company/${company}`);
    // };

    // useLayoutEffect(() => {
    //     function updatePosition() {
    //         setPosition(window.pageYOffset);
    //         console.log(window.pageYOffset);
    //     }
    //     window.addEventListener("scroll", updatePosition);
    //     updatePosition();
    //     return () => window.removeEventListener("scroll", updatePosition);
    // }, []);

    // useEffect(() => {
    //     let scrollPos = false;
    //     if (scrollPosition >= 450 && scrollPosition <= 900) {
    //         if (
    //             homeAdvertisements[x]?.type === "Build Awareness" &&
    //             !scrollPos
    //         ) {
    //             dispatch(reduceBudgetAction(homeAdvertisements[x]?.id));
    //         }
    //         if (
    //             homeAdvertisements[y]?.type === "Build Awareness" &&
    //             !scrollPos
    //         ) {
    //             dispatch(reduceBudgetAction(homeAdvertisements[y]?.id));
    //         }
    //         scrollPos = true;
    //     } else {
    //         scrollPos = false;
    //     }
    // }, [scrollPosition]);

    return (
        <>
            {homeAdvertisements?.length > 0 && (
                <div className="tab-hide row px-0 mt-2">
                    <div
                        className="col-12 mb-3"
                        // onClick={() => {
                        //     if (
                        //         homeAdvertisements[x]?.type === "Call-To-Action"
                        //     )
                        //         visitAdvertisement(
                        //             homeAdvertisements[x]?.id,
                        //             homeAdvertisements[x]?.company_id
                        //         );
                        // }}
                    >
                        <div className="adss-card">
                            <div className="text-right advertisement-css ml-auto mr-auto">
                                Advertisement
                            </div>

                           {homeAdvertisements[firstAdvertisemnt]
                                            ?.advertisement_image && homeAdvertisements[firstAdvertisemnt]
                                            ?.advertisement_image!=null && homeAdvertisements[firstAdvertisemnt]
                                            ?.advertisement_image!=undefined && homeAdvertisements[firstAdvertisemnt]
                                            ?.advertisement_image!=""  && <img
                                src={homeAdvertisements[firstAdvertisemnt]?.advertisement_image}
                                alt="Adds"
                                className="adss-card"
                            />}
                        </div>
                    </div>
                    {homeAdvertisements?.length > 1 && (
                        <div
                            className="col-12 mb-3"
                            // onClick={() => {
                            //     if (
                            //         homeAdvertisements[x]?.type ===
                            //         "Call-To-Action"
                            //     ) {
                            //         visitAdvertisement(
                            //             homeAdvertisements[y]?.id,
                            //             homeAdvertisements[y]?.company_id
                            //         );
                            //     }
                            // }}
                        >
                            <div className="adss-card">
                                <p className="text-right  advertisement-css  ml-auto mr-auto">
                                    Advertisement
                                </p>

                                {homeAdvertisements[secondAdvertisemnt]
                                            ?.advertisement_image && homeAdvertisements[secondAdvertisemnt]
                                            ?.advertisement_image!=null && homeAdvertisements[secondAdvertisemnt]
                                            ?.advertisement_image!=undefined && homeAdvertisements[secondAdvertisemnt]
                                            ?.advertisement_image!="" && <img
                                    src={
                                        homeAdvertisements[secondAdvertisemnt]
                                            ?.advertisement_image
                                    }
                                    alt="Adds"
                                    className="adss-card"
                                />}
                            </div>
                        </div>
                    )}
                </div>
            )}

        </>
    );
};

export default Advertisement;
