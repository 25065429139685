import React from "react";
import "./CookiesPolicy.css";
import Footer from "../../../shared/Footer/Footer";
import PolicyNavbar from "../Navbar/PolicyNavbar";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/backbone-logo.svg";

const CookiesPolicy = () => {
    const navigate = useNavigate();
    return (
        <>
            <div onClick={() => navigate("/")}>
                <img
                    style={{
                        width: "4rem",
                        marginLeft: "3.5rem",
                        marginTop: "1rem",
                    }}
                    src={Logo}
                    alt="logo"
                    className="logo"
                />
            </div>
            <div className="cookies-policy ">
                <div className="container">
                    <div className="row my-5"
                    style={{
                            marginTop: "0rem",
                        }}
                    >
                        <div className="col-md-8 col-sm-4 ml-3 ">&nbsp;</div>
                        <div className="col-md-4 col-sm-8 col-12 ml-3 update-date">
                            Last updated on 8 April 2022
                            <br />
                            BackBone Global Services Pty Ltd trading as BackBone
                            <br />
                            ABN 33 656 180 504
                            <br />
                        </div>
                    </div>

                    <h2 className="cookies-header pt-10">COOKIES POLICY</h2>
                    <br />
                    <br />
                    <div
                        className="row"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <div className="col-md-9 col-sm-8 col-12 ml-3 ">
                            <div className="d-flex ">
                                <p className="">
                                    <span className="cookies-subheader">
                                        1 &emsp;INTRODUCTION
                                    </span>{" "}
                                    <br />
                                    <br />
                                    (a)&emsp; This Cookies Policy applies
                                    between you and us, BackBone Global Services
                                    Pty Ltd ACN 656 180 504 trading under the
                                    registered business name BackBone (“we”,
                                    “us”, or “our”).
                                    <br />
                                    (b)&emsp; This Cookies Policy applies when
                                    you use our website accessible at
                                    https://kfactor.com.au/ (“Website”), and
                                    describes the types of cookies we use on our
                                    Website, how we use them, and how you can
                                    control them.
                                    <br />
                                    (c)&emsp; A cookie is a small file that’s
                                    stored on your computer or device when you
                                    visit a website
                                    <br />
                                    that uses cookies. We may use several
                                    different cookies on our Website, for the
                                    <br />
                                    purposes of website functionality,
                                    performance, advertising, and social media
                                    or content
                                    <br />
                                    cookies. Cookies enhance your experience on
                                    our Website, as it allows us to recognise
                                    <br />
                                    you, remember your details and preferences
                                    (for example, your log-in details), and
                                    <br />
                                    provide us with information on when you’ve
                                    visited and how you’ve interacted with our
                                    website.
                                    <br />
                                    <br />
                                    <span className="cookies-subheader">
                                        2 &emsp;TYPES OF COOKIES WE USE
                                    </span>
                                    <br />
                                    <br />
                                    The below table sets out the type of cookies
                                    we may collect on our Website
                                    <br />
                                    <table>
                                        <tr>
                                            <td
                                                style={{
                                                    border: "1px solid black",
                                                    "border-collapse":
                                                        "collapse",
                                                }}
                                                bgcolor="#B4B4B4"
                                            >
                                                <span className="cookies-subheader">
                                                    Strictly Necessary Cookies
                                                </span>
                                            </td>
                                            <td
                                                style={{
                                                    border: "1px solid black",
                                                    "border-collapse":
                                                        "collapse",
                                                }}
                                            >
                                                Certain cookies we use are
                                                essential for the proper
                                                functioning of our Website,
                                                without which our Website won’t
                                                work or certain features won’t
                                                be accessible to you. For
                                                example, we may need to remember
                                                data you’ve inputted from one
                                                page to the next in a single
                                                session. <br />
                                                <br />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{
                                                    border: "1px solid black",
                                                    "border-collapse":
                                                        "collapse",
                                                }}
                                                bgcolor="#B4B4B4"
                                            >
                                                <span className="cookies-subheader">
                                                    Performance Cookies
                                                </span>
                                            </td>
                                            <td
                                                style={{
                                                    border: "1px solid black",
                                                    "border-collapse":
                                                        "collapse",
                                                }}
                                            >
                                                Performance cookies collect
                                                information about your use of
                                                the Website to help enhance the
                                                services we provide to you. We
                                                collect information about how
                                                you interact with the Website,
                                                including the pages you visit
                                                and the frequency of your
                                                visits. This information helps
                                                us identify patterns of usage on
                                                the site, collect analytics
                                                data, identify issues you may
                                                have had on the Website, make
                                                changes to enhance your browsing
                                                experience, and analyse if our
                                                marketing is effective and
                                                relevant to you.
                                                <br />
                                                <br />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{
                                                    border: "1px solid black",
                                                    "border-collapse":
                                                        "collapse",
                                                }}
                                                bgcolor="#B4B4B4"
                                            >
                                                <span className="cookies-subheader">
                                                    Functional Cookies
                                                </span>
                                            </td>
                                            <td
                                                style={{
                                                    border: "1px solid black",
                                                    "border-collapse":
                                                        "collapse",
                                                }}
                                            >
                                                We use functional cookies to
                                                improve your experience on our
                                                Website and make things more
                                                convenient for you. These
                                                cookies personalise your
                                                experience on our Website based
                                                on your preferences, by
                                                remembering your details such as
                                                your login details or region.
                                                <br />
                                                Security cookies are a type of
                                                functional cookie, which assist
                                                with website and user account
                                                security. Load balancing session
                                                cookies are used for the
                                                duration of the session to
                                                distribute user requests across
                                                multiple servers to optimize
                                                website speed and capacity. We
                                                may also use user interface
                                                customization persistent cookies
                                                to store a user’s preferred
                                                version of our Website, such as
                                                font and language preferences.
                                                <br />
                                                <br />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{
                                                    border: "1px solid black",
                                                    "border-collapse":
                                                        "collapse",
                                                }}
                                                bgcolor="#B4B4B4"
                                            >
                                                <span className="cookies-subheader">
                                                    Advertising cookies
                                                </span>
                                            </td>
                                            <td
                                                style={{
                                                    border: "1px solid black",
                                                    "border-collapse":
                                                        "collapse",
                                                }}
                                            >
                                                Advertising cookies are used on
                                                our Website to provide you with
                                                targeted marketing materials in
                                                accordance with your interests
                                                and preferences. These cookies
                                                remember that you visited our
                                                Website, and we may provide this
                                                information to third-parties.
                                                These cookies usually cannot
                                                personally identify you, so your
                                                anonymity is typically secured.
                                                These cookies ensure that
                                                advertisements displayed to you
                                                are things that may be of
                                                interest to you. <br />
                                                <br />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{
                                                    border: "1px solid black",
                                                    "border-collapse":
                                                        "collapse",
                                                }}
                                                bgcolor="#B4B4B4"
                                            >
                                                <span className="cookies-subheader table-header">
                                                    Content cookies
                                                </span>
                                            </td>
                                            <td
                                                style={{
                                                    border: "1px solid black",
                                                    "border-collapse":
                                                        "collapse",
                                                }}
                                            >
                                                Content cookies are placed by
                                                many social media plugins (like
                                                plugins that allow you to share
                                                content on Facebook), and other
                                                tools to enhance the content
                                                displayed on a website (for
                                                example, services that allow the
                                                playing of video files). We
                                                integrate these plugins into our
                                                Website to improve usability and
                                                customer experience. Some of
                                                these third party services may
                                                place cookies that are also used
                                                for the purposes of behavioural
                                                advertising or market analysis.
                                                <br />
                                                <br />
                                            </td>
                                        </tr>
                                    </table>
                                    <br />
                                    <br />
                                    <span className="cookies-subheader">
                                        3&emsp; HOW LONG WILL COOKIES REMAIN ON
                                        MY DEVICE?
                                    </span>
                                    <br />
                                    <br />
                                    The amount of time that a cookie remains on
                                    your computer or device depends on the type
                                    of
                                    <br />
                                    cookie – cookies are either “persistent” or
                                    “session” cookies. Persistent cookies last
                                    until they
                                    <br />
                                    expire or are deleted, so they may remain on
                                    your device for as little as 10 minutes to
                                    several
                                    <br />
                                    years. Session cookies last until you stop
                                    browsing, so just for the relevant session.
                                    <br />
                                    <br />
                                    <span className="cookies-subheader">
                                        4&emsp; HOW DO THIRD PARTIES USE COOKIES
                                        ON THE WEBSITE?
                                    </span>
                                    <br />
                                    <br />
                                    We may use third party analytics cookies to
                                    collect information about your interaction
                                    with our
                                    <br />
                                    Website. We also may use Google Analytics
                                    and other third-party analytics providers to
                                    help
                                    <br />
                                    process data. To find out more, see How
                                    Google uses data when you use our partners’
                                    sites or
                                    <br />
                                    apps.
                                    <br />
                                    <br />
                                    <span className="cookies-subheader">
                                        5&emsp; HOW DO I CONTROL COOKIES?
                                    </span>
                                    <br />
                                    <br />
                                    (a)&emsp; Usually, you can control and
                                    manage cookies through your browser. You can
                                    control whether or not your browser accepts
                                    cookies, how to filter and manage cookies,
                                    and how to delete cookies at the end of a
                                    session.
                                    <br />
                                    (b)&emsp; If you remove or block cookies,
                                    this may negatively impact your experience
                                    of our Website and you may not be able to
                                    access all parts of our Website.
                                    <br />
                                    (c)&emsp; Many third party advertising
                                    services allow you to opt out of their
                                    tracking systems, by giving you the
                                    opportunity to opt out by way of a pop-up
                                    before downloading cookies to your device.
                                    <br />
                                    <br />
                                    <span className="cookies-subheader">
                                        6&emsp; UPDATES TO THIS POLICY
                                    </span>
                                    <br />
                                    <br />
                                    We may update this Cookies Policy from time
                                    to time. When we make changes, we’ll update
                                    the “Last updated” date at the top of the
                                    Cookies Policy and post it on our sites. We
                                    encourage you to check back periodically to
                                    review this Cookies Policy to ensure that
                                    you are aware of our current Cookies Policy.
                                    <br />
                                    <br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default CookiesPolicy;
