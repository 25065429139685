import React from "react";
import { Routes as Switch, Route} from "react-router-dom";
import ChangedPassword from "../pages/Auth/Components/ChangedPassword";
import CompanyDetails from "../pages/CompanyDetails/CompanyDetails";
import Home from "../pages/Home/Home";
import PaymentReject from "../pages/PaymentReject/PaymentReject";
import "./Routes.css";

const Routes = () => {
    return (
        <>
            <Switch>
                <Route exact path="/*" element={<Home />} />
                <Route
                    exact
                    path="/auth/change-password"
                    element={<ChangedPassword />}
                />
                <Route exact path="/details" element={<CompanyDetails />} />
                <Route
                    exact
                    path="/paymentreject"
                    element={<PaymentReject />}
                />
            </Switch>
        </>
    );
};

export default Routes;
