let API_END_POINT = "https://dev-api.bizbackbone.com";
// "6Ld-oRMqAAAAAL8OJlLG4C0zgbtKnnIzkqABkGsz"
// let GOOGLE_CAPTCHA_KEY = "6Ld-oRMqAAAAAL8OJlLG4C0zgbtKnnIzkqABkGsz"
let GOOGLE_CAPTCHA_KEY = process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY;
let ENDORSE_BASE_URL = "https://dev-app.bizbackbone.com";
let WORDPRESS_URL = "";

switch (process.env.REACT_APP_DEVELOPMENT_TYPE) {
    case "prod":
        // Endpoint for Production website
        API_END_POINT = "https://api.bizbackbone.com";
        ENDORSE_BASE_URL = "https://bizbackbone.com";
        WORDPRESS_URL = "https://bizbackbone.com/";
        break;
    case "staging":
        // Endpoint for Staging website
        API_END_POINT = "https://stage-api.bizbackbone.com";
        ENDORSE_BASE_URL = "https://stage-app.bizbackbone.com";
        break;
    case "dev":
        // Endpoint for Development website
        API_END_POINT = "https://dev-api.bizbackbone.com";
        ENDORSE_BASE_URL = "https://dev-app.bizbackbone.com";
        break;
    case "local":
        API_END_POINT = "http://localhost:5000";
        ENDORSE_BASE_URL = "http://localhost:3000";
        break;

    default:
        // Endpoint for Local
        API_END_POINT = "https://dev-api.bizbackbone.com";
    //    API_END_POINT = "http://localhost:3002";

}

export { API_END_POINT, GOOGLE_CAPTCHA_KEY, ENDORSE_BASE_URL, WORDPRESS_URL };
