import React from "react";
import { useEffect, useState } from "react";
import mastercard from "../../assets/logos_mastercard.svg";
import addcard from "../../assets/Group 159.svg";
import redtick from "../../assets/red-tick.svg";
import { AiOutlineCreditCard, AiOutlineIdcard } from "react-icons/ai";
import "./PaymentFailed.css";
import { useLocation, useNavigate } from "react-router-dom";
import failed from "../../assets/paymentfailed.svg";
import axios from "axios";
import Loader from "../../shared/Loader/Loader";
import { useSelector } from "react-redux";

const PaymentFailed = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [showDiv, setShowDiv] = useState(false);

    return (
        <>
            <div className="payment-failed">
                <div className="payf-main-card-panel">
                    <div className="payment-failed-left-panel">
                        <div className="payment-failed-image">
                            <img src={failed} alt="confirm" />
                        </div>
                        <div className="payment-failed-card">
                            <div className="payment-tick">
                                <img src={redtick} alt="" />
                            </div>
                            <div className="payment-failed-card-head">
                                Payment Unsuccessful
                            </div>
                            <div className="payment-failed-card-body">
                                <span>
                                    Your payment of{" "}
                                    <b>
                                        $
                                        {state?.creditUsed
                                            ? state?.budget - state.creditUsed
                                            : state?.budget}
                                    </b>
                                    <br />
                                    was unsuccessful. Please retry
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="payment-failed-right-panel">
                        <div className="payment-failed-detail-head">
                            <b>Payment details</b>
                        </div>
                        <div className="payment-amount">
                            <div className="payment-amount-text">
                                Payment amount
                            </div>
                            <div className="payment-amount-value">
                                $
                                {state?.creditUsed
                                    ? state?.budget - state.creditUsed
                                    : state?.budget}
                            </div>
                        </div>
                        <div className="payment-date">
                            <div className="payment-date-text">
                                Payment date{" "}
                            </div>
                            <div className="payment-date-value">
                                {new Date().toDateString()}
                            </div>
                        </div>
                        {state?.creditUsed && (
                            <div className="pay-free-credit">
                                <div className="pay-free-credit-text">
                                    Free credit
                                </div>
                                <div className="pay-free-credit-value">
                                    ${state?.usedAmount}
                                </div>
                            </div>
                        )}
                        <div className="retry-button">
                            <button onClick={() => navigate("/adpayment")}>
                                Retry payment
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentFailed;
