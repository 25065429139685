import React from "react";
import Modal from "@material-tailwind/react/Modal";
import ModalBody from "@material-tailwind/react/ModalBody";
import styled from "styled-components";
const HeadingDetail = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e2e2e2;
    color: #3f3f3f;
`;

const CancelDetail = styled.div`
    width: 600px;
    margin-top: 25px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    color: #000000;
`;

const ButtonCancel = styled.button`
    background: #fff;
    border-radius: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #039be5;
    padding: 5px 30px;
    margin-top: 50px;
    border: 1px solid #039be5;
`;

const ButtonBack = styled.button`
    background: #039be5;
    border-radius: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    padding: 5px 30px;
    margin-top: 50px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: "center";
    gap: 50px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

const ReactivateModal = ({ modal, setModal, onReactivate, onCancel }) => {
    return (
        <div className="modal-wrapper">
            <Modal size="lg" active={modal} toggler={() => {}}>
                <ModalBody>
                    <HeadingDetail>We’re glad to have you Back!</HeadingDetail>
                    <CancelDetail>
                        Hi XyZ Ltd Welcome back to BackBone!
                        <br />
                        <br /> Please choose a plan to reactivate your account.
                        As promised, all your account information, ratings &
                        endorsements will immediately be restored.
                        <br />
                        <br />
                        Warm Regards, The BackBone Team
                    </CancelDetail>
                    <ButtonWrapper>
                        <ButtonCancel
                            onClick={() => {
                                onCancel();
                                setModal(false);
                            }}
                        >
                            Cancel
                        </ButtonCancel>

                        <ButtonBack
                            onClick={() => {
                                onReactivate();
                                setModal(false);
                            }}
                        >
                            Reactivate account
                        </ButtonBack>
                    </ButtonWrapper>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default ReactivateModal;
