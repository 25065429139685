import React from "react";
import ReactPlayer from "react-player";
import "./LandVideo.css";

export default function LandVideo() {
    return (
        <>
            <div className="landing-video" style={{ marginTop: "0.5rem" }}>
                <ReactPlayer
                    url="https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/BB_AV.mp4"
                    playing={true}
                    muted={true}
                    loop={true}
                    controls={true}
                    width="100%"
                    height="50%"
                    className="react-player"
                    onReady={() => {
                        console.log("play");
                    }}
                />
            </div>
        </>
    );
}
