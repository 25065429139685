import React, { useEffect, useMemo, useState } from "react";
import Rating from "@material-ui/lab/Rating";
import favi from "../../../assets/backbone-logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "./UserProfile.css";
import Modal from "@material-tailwind/react/Modal";
import ModalBody from "@material-tailwind/react/ModalBody";
import ReactStars from "react-rating-stars-component";
import Helmet from "react-helmet";
import triangle from "../../../assets/triangle.png";

import {
    getMyCompanyAction,
    getMyCompanyByIdAction,
    getMyCompany,
    markFavouriteAction,
    UnmarkFavouriteAction,
    contentActions,
    getInTouchAction,
    getTotalNumberOfBackedCompany,
    backedBizBucks,
    getBizBucksByConfigDetailsData,
    getBadgeTierDetails,
} from "../../../data/reducers/home/content";
import ProfileReviews from "../../Profile/Components/ProfileReviews";
import Tags from "../../Profile/Components/Tags";
import IndustryType from "../../Profile/Components/IndustryType";
import OtherInfo from "../../Profile/Components/OtherInfo";
import RequestEndorse from "../../Profile/Components/RequestEndorse";
import MemberSince from "../../Profile/Components/MemberSince";
import ProfileBasicDetails from "../../Profile/Components/ProfileBasicDetails";
import ProfileSocialLinks from "../../Profile/Components/ProfileSocialLinks";
import ProfileLeaders from "../../Profile/Components/ProfileLeaders";
import Loader from "../../../shared/Loader/Loader";
import {
    getAllUserProfile,
    getUserProfile,
} from "../../../data/reducers/auth/auth";
import { getAllUserService } from "../../../data/services/auth/auth";
import {
    addCompanyActions,
    addReview,
} from "../../../data/reducers/company/add";
import { getSocialAction } from "../../../data/reducers/profile/profile";
import { useToast } from "@chakra-ui/react";
import Navbar from "../Navbar/Navbar";
import review from "../../../assets/star1.svg";
import newMember from "../../../assets/newMember.svg";
import getintouch from "../../../assets/get.svg";
import { BsHeart, BsHeartFill } from "react-icons/bs";
import GetInTouch from "../../../shared/GetInTouch/GetInTouch";
import { Radio } from "@material-ui/core";
import { BsPeople } from "react-icons/bs";
import BlueCheck from "../../../assets/blue-check.svg";
import "../Main/Components/Service.css";
import moment from "moment";
import MyPreference from "../../Profile/Components/MyPreference";
import Activity from "../../Profile/Components/Activity";
import Badge from "../../Profile/Components/Badge";
import BackboneLogo from "../../../assets/backbone-logo.svg";

const UserProfile = () => {
    const dispatch = useDispatch();

    const ratingChanged = (newRating) => {
        setReviewStar(newRating);
    };
    const { social } = useSelector((state) => state.content);


    const { company,totalBackedBizBuck,backedCompany,backedUsCompany , bizBucksConfig,badgeTier} = useSelector((state) => state.content);
    const {
        myCompany,
        loading: myLoading,
        myFavourites,
    } = useSelector((state) => state.content);

    const memoizedBadge=useMemo(()=>{
            return <Badge totalBackedBizBuck={totalBackedBizBuck} badgeTier={badgeTier}/>
    },[totalBackedBizBuck,badgeTier])
    const memoizedActivity=useMemo(()=>{
        if(company && (backedCompany?.backed_detail?.length>0 || backedUsCompany?.backed_detail?.length>0))
            return <Activity backed_detail={backedCompany?.backed_detail} company_detail={backedCompany?.backed_company_detail}  backedUsCompanyDetail={backedUsCompany?.backed_company_detail} backedUsDetail={backedUsCompany?.backed_detail}/>
    },[company,backedCompany,backedUsCompany])
    const memoizedMyPreference=useMemo(()=>{
        if(company &&  company?.Industrypreferences?.length>0)
        return <MyPreference preference={company?.Industrypreferences}/>
        else{
            return <MyPreference preference={[]}/>
        }
    },[company])
    const { id } = useParams();
    const { feedbackGiven } = useSelector((state) => state.add);
    const { user } = useSelector((state) => state.auth);
    // const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [showModal4, setShowModal4] = useState(false);
    const [checknewMem, setChecknewMem] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [value, setValue] = useState(2);
    const [allUser, setAllUser] = useState([]);
    const [allUserEmail, setAllUserEmail] = useState("");
    const [reviewStar, setReviewStar] = useState(0);
    const [reviewText, setReviewText] = useState("");
    const [showDiv, setShowDiv] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    // for input field validation
    const [emailError, setEmailError] = useState(false);
    const [companyNameError, setCompanyNameError] = useState(false);
    const [reviewStarError, setReviewStarError] = useState(false);
    const [reviewTextError, setReviewTextError] = useState(false);
    const [openModel, setOpenModel] = useState(false);
    const [content, setContent] = useState(`Hi ${company?.company_name?.toUpperCase()}

    I'd like to have a quick chat about using your service. Please let me know good time for us to speak. My contact information is below:
    
    ${myCompany?.company_name} 
    
    ${myCompany?.User?.email ? myCompany?.User?.email : ""}
        
    ${myCompany?.User?.mobileNo ? myCompany?.User?.mobileNo : ""}`);
    const [modifiedTxt, setModifiedTxt] = useState(false);
    const [selectedValue, setSelectedValue] = React.useState("email");
    const [sentEmailModel, setSentEmailModel] = useState(false);
    const navigate = useNavigate();
    const handleClose = () => {
        setVisible(false);
    };
    useEffect(() => {
        setContent(`Hi ${company?.company_name?.toUpperCase()}

        I'd like to have a quick chat about using your service. Please let me know good time for us to speak. My contact information is below:

        ${myCompany?.company_name}

        ${myCompany?.User?.email ? myCompany?.User?.email : ""}

        ${myCompany?.User?.mobileNo ? myCompany?.User?.mobileNo : ""}`);
    }, [openModel, company]);

    const onChange = (e) => {
        setContent(e.target.value);
        setModifiedTxt(true);
    };

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const getInTouchCompany = async () => {
        if (!company?.User?.email) {
            toast({
                title: "The company has no contact details",
                description: "",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
            setOpenModel(false);
            return;
        }
        let response=await dispatch(
            getInTouchAction({
                serviceId: company.id,
                myCompanyId: myCompany.id,
                selectedValue,
            })
        );
        if(response?.payload?.success){
            setOpenModel(false);
            setSentEmailModel(true);
        }else{
            setOpenModel(false);
            toast({
                title: `${response?.payload?.message}`,
                description: "",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
        }
        // setOpenModel(false);
        // setSentEmailModel(true);
    };

    const { loading, error, message, success } = useSelector(
        (state) => state.add
    );

    useEffect(() => {
        if (!localStorage.getItem("token")) {
            localStorage.setItem("redirectUrl", window.location.href);
            if (localStorage.getItem("redirectUrl")) {
                navigate("/");
            }
        }
    }, []);
    useEffect(()=>{
        if(id){
            dispatch(getTotalNumberOfBackedCompany(`?id=${id}`))
        }
    },[id])

    useEffect(() => {
        if (!searchParams.get("review")) {
            // setShowModal1(false);
            setShowModal3(false);
        }
    }, [searchParams]);

    useEffect(() => {
        if (user && myCompany?.company_name && searchParams.get("review")) {
            setShowModal3(true);
        }
    }, [user, myCompany]);

    useEffect(() => {
        if (!searchParams.get("review")) {
            // setShowModal1(false);
            setShowModal3(false);
        }

        if (myCompany?.company_name && searchParams.get("review")) {
            setCompanyName(myCompany?.company_name);
            // setShowModal1(false);
            setShowModal3(true);
        }
    }, [myCompany, searchParams]);

    useEffect(()=>{
        dispatch(getBadgeTierDetails())
    },[dispatch])

    useEffect(() => {
        if (showModal3 && myCompany?.company_name) {
            setCompanyName(myCompany?.company_name);
        }
    }, [showModal3]);

    const toast = useToast();

   

    const [isExpanded, setIsExpanded] = useState(false);
    const [visible,setVisible]=useState(false)

    const [isMarked, setisMarked] = useState(false);
    const [isReviewed, setisReviewed] = useState(false);

    const markFavouriteCompany = async (id) => {
        await dispatch(markFavouriteAction(id));
        setisMarked(!isMarked);
    };

    const unmarkFavouriteCompany = (id) => {
        dispatch(UnmarkFavouriteAction(id));
        setisMarked(!isMarked);
    };

    const onDone = async () => {
        // if (reviewStar === 0) {
        //     setReviewStarError(true);
        // }
        // if (companyName === "") {
        //     setCompanyNameError(true);
        // }
        if (reviewText?.trim() === "") {
            setReviewTextError(true);
        }
        if(company && company?.User && company?.User?.id){
            let backedBizData={
                backed_id:company?.User?.id,
                backed_company_id:company?.id,
                interaction_type:"Other: Enter Free Text (200 max)",
                free_text:reviewText,
                backType:"BACK"
            }
            let response = await dispatch(backedBizBucks(backedBizData));
            if (response?.payload?.success) {
                setVisible(true);
                setTimeout(()=>{
                    dispatch(getUserProfile());                
                },2000)
            } else {
                toast({
                    title: `${response?.payload?.message}`,
                    position: "top-right",
                    duration: 3000,
                    status: "error",
                    isClosable: true,
                });
            }
        }
        
        // const res = await dispatch(
        //     addReview({
        //         company_id: id,
        //         review_given: reviewStar,
        //         review_text: reviewText,
        //         company_name: companyName,
        //         bussiness_email: user ? user?.email : allUserEmail,
        //         user_id: user?.id,
        //         bussiness_image: myCompany?.company_logo,
        //         reviewer_company_id: user?.company_id,
        //     })
        // );
        // if (res) {
        //     let backedBizData={
        //         backed_id:company?.User?.id,
        //         backed_company_id:company?.id,
        //         interaction_type:"Other: Enter Free Text (200 max)",
        //         free_text:reviewText,
        //         backType:"BACK"
        //     }
        //     let response = await dispatch(backedBizBucks(backedBizData));
        //     if (response?.payload?.success) {
        //         setVisible(true);
        //         dispatch(getUserProfile());                
        //     } else {
        //         toast({
        //             title: `${response?.payload?.message}`,
        //             position: "top-right",
        //             duration: 3000,
        //             status: "error",
        //             isClosable: true,
        //         });
        //     }
        // }
        setShowModal3(false);
    };

    useEffect(() => {
        if (success && message) {
            toast({
                title: "Review added successfully",
                status: "success",
                position: "top-right",
                isClosable: true,
            });
            // dispatch(contentActions.reviewGiven());
            dispatch(addCompanyActions.reset());
            // dispatch(getMyCompanyByIdAction(id));
            setShowModal4(true);
        }

        if (error) {
            toast({
                title: error,
                status: "warning",
                position: "top-right",
                isClosable: true,
            });
            dispatch(addCompanyActions.reset());
        }

        dispatch(getMyCompanyByIdAction(id));
    }, [loading, error, message, toast, dispatch]);

    // const getAllUser = async () => {
    //     const allUser = await getAllUserService();
    //     const temp = allUser.users.map((user) => user.email);
    //     setAllUser(temp);
    // };

    // useEffect(() => {
    //     dispatch(getMyCompanyByIdAction(id));
    //     dispatch(getMyCompanyAction());
    // }, [dispatch]);

    useEffect(() => {
        if (user) {
            dispatch(getMyCompanyAction());
        }
        dispatch(getBizBucksByConfigDetailsData())
    }, [user]);

    // useEffect(() => {
    //     getAllUser();
    // }, []);

    useEffect(() => {
        setStartDate(company?.createdAt);
    }, [company]);

    useEffect(() => {
        // if(company?.createdAt){
        //     setStartDate(company?.createdAt);
        //     console.log("Ajay",startDate);
        // }
        // console.log("st", startDate, company?.createdAt);
        setStartDate(startDate.slice(0, 10));
        const today = new Date();
        let endDate =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();
        endDate = new Date(endDate);
        let sd = new Date(startDate);
        // setStartDate(sd)

        var diff = Math.floor(today.getTime() - sd.getTime());
        var day = 1000 * 60 * 60 * 24;

        var days = Math.floor(diff / day);
        var months = Math.floor(days / 29);

        let x = moment(startDate, "YYYY-MM-DD").fromNow();
        // const diff =
        //     endDate.getMonth() -
        //     sd.getMonth() +
        //     12 * (endDate.getFullYear() - sd.getFullYear());
        if (months < 6) {
            setChecknewMem(true);
        }
    }, [startDate]);

    useEffect(() => {
        setisMarked(myFavourites?.find((e) => e?.company_id === company?.id));
    }, [myFavourites]);

    useEffect(() => {
        setisReviewed(
            company?.Reviews?.find((e) => {
                return (
                    e?.company_id === company?.id &&
                    e?.user_id === myCompany?.User?.id
                );
            })
        );
    }, [company?.Reviews]);

    // if (myLoading) {
    //     return <Loader />;
    // }

    return (
        <>
            <Helmet>
                <link rel="icon" type="image/png" href={favi} sizes="16x16" />
            </Helmet>
            {/* {!user && (
                <nav className={isExpanded ? "nav-expanded" : ""}>
                    <Navbar
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                    />
                </nav>
            )} */}

            {!company?.id || company?.id!=id ? (
                <Loader />
            ) : (
                <div className="profile">
                    <div className="profile-top">
                        {/* <header className="profile-header">
                            <h1>
                                {company?.company_name} Business Profile {">>"}
                            </h1>
                        </header> */}
                        <div className="profile-background">
                            <figure>
                                {/* {setUpdateBanner(myCompany?.Banners[0].id)} */}
                                <img
                                    src={
                                        !(company?.Banners.length === 0)
                                            ? company.Banners[0].banner_pic
                                            : "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/default_banner.svg"
                                    }
                                    alt="Background"
                                    className="object-cover"
                                />
                            </figure>
                        </div>
                        <div
                            className={
                                user
                                    ? "profile-picture-login"
                                    : "profile-picture"
                            }
                        >
                            <figure>
                                <img
                                    src={
                                        company?.company_logo
                                            ? company.company_logo
                                            : "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"
                                    }
                                    alt=""
                                />
                            </figure>
                        </div>
                        <div className="profile-picture-blank" />

                        <div className="profile-content">
                            <div className="profile-name company_profile_name">
                                <div className="company_name">
                                    <h3>{company?.company_name}</h3>
                                    {checknewMem && (
                                        <div className="newMember">
                                            <img src={newMember} alt="" />
                                        </div>
                                    )}
                                </div>

                                <div className="profile-rating">
                                    {/* <Rating
                                        name="read-only"
                                        value={
                                            company?.Reviews?.length === 0 &&
                                            checknewMem
                                                ? 5
                                                : company?.Reviews?.length ===
                                                      0 && !checknewMem
                                                ? 3
                                                : company.avg_ratings
                                        }
                                        readOnly
                                        precision={0.25}
                                    /> */}
                                    <Rating
                                        name="read-only"
                                        value={company.avg_ratings || 0}
                                        readOnly
                                        precision={0.25}
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            className={
                                user
                                    ? "horizontal-line"
                                    : "horizontal-line-login"
                            }
                        />
                        <div className="profile-about">
                            <div>
                                <h4>About</h4>
                                <p style={{ wordBreak: "break-all" }}>
                                    {company?.about_company}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="hide-mobile">
                        <div className="favourites-cont">
                            <div className="favourites">
                                <div className="action-icon-user-profile ">
                                    {isMarked ? (
                                        <BsHeartFill
                                            color="red"
                                            onClick={() =>
                                                unmarkFavouriteCompany(
                                                    company?.id
                                                )
                                            }
                                        />
                                    ) : (
                                        <BsHeart
                                            color="#C11717"
                                            onClick={() =>
                                                markFavouriteCompany(
                                                    company?.id
                                                )
                                            }
                                        />
                                    )}
                                </div>
                                <div className="favourite-text">
                                    <p className="favourite-text">
                                        {isMarked ? "Remove from favourite":"Add to favourite"}
                                    </p>
                                </div>
                            </div>
                            <div
                                className="favourites"
                                onClick={() => setOpenModel(true)}
                            >
                                <div
                                    className="action-icon-2"
                                    onClick={() => setOpenModel(true)}
                                >
                                    <img src={getintouch} alt="getintouch" />
                                </div>
                                <div
                                    className="favourite-text"
                                    onClick={() => setOpenModel(true)}
                                >
                                    <p className="favourite-text">
                                        Get in touch
                                    </p>
                                </div>
                            </div>
                            { (
                                <div
                                    className="favourites"
                                    onClick={() => setShowModal3(true)}
                                >
                                    <div
                                        className="action-icon-2"
                                        onClick={() => setShowModal3(true)}
                                    >
                                        <img src={review} alt="getintouch" />
                                    </div>
                                    <div
                                        className="favourite-text"
                                        onClick={() => setShowModal3(true)}
                                    >
                                        <p className="favourite-text">
                                            Back this Biz
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="profile-down">
                            <div className="profile-down-left">
                                <div className="left-info">
                                    <Tags
                                        industry={company?.Industry}
                                        serv={company?.Services}
                                    />

                                   {
                                       memoizedMyPreference
                                   }

                                    {
                                        memoizedActivity
                                    }

                                    <IndustryType
                                        companyIndustry={company?.Industry}
                                    />
                                    {/* {company?.Reviews?.length > 0 && (
                                        <ProfileReviews
                                            id={company?.id}
                                            reviews={company?.Reviews}
                                        />
                                    )} */}
                                    {(company?.OtherInfos?.length >= 1 ||
                                        company?.Images?.length >= 1 ||
                                        company?.Pdfs?.length >= 1 ||
                                        company?.Links?.length >= 1) && (
                                        <OtherInfo
                                            myProfile={false}
                                            title={company?.OtherInfos}
                                            pdfLinks={company?.Pdfs}
                                            images={company?.Images}
                                            links={company?.Links}
                                        />
                                    )}
                                </div>
                                {user && user?.company_id === company?.id && (
                                    <RequestEndorse />
                                )}
                            </div>
                            <div className="profile-down-right">

                               {
                                 memoizedBadge
                               }


                                <MemberSince date={company?.createdAt} />
                                <ProfileBasicDetails
                                    isMine={
                                        user
                                            ? user?.company_id === company?.id
                                            : false
                                    }
                                    company={company}
                                />
                                <ProfileSocialLinks
                                    socials={company?.Socials}
                                />
                                {company?.Leaders?.length > 0 && (
                                    <ProfileLeaders
                                        leaders={company?.Leaders}
                                        isMine={
                                            user
                                                ? user?.company_id ===
                                                  company?.id
                                                : false
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    </div>{" "}
                    <div className="favourites-cont-mobile">
                        <div className="favourites-mobile">
                            <div className="action-icon">
                                {isMarked ? (
                                    <BsHeartFill
                                        color="red"
                                        onClick={() =>
                                            unmarkFavouriteCompany(company?.id)
                                        }
                                    />
                                ) : (
                                    <BsHeart
                                        color="#C11717"
                                        onClick={() =>
                                            markFavouriteCompany(company?.id)
                                        }
                                    />
                                )}
                            </div>
                        </div>
                        <div
                            className="favourites-mobile"
                            onClick={() => setOpenModel(true)}
                        >
                            <div
                                className=".action-icon-2-m"
                                onClick={() => setOpenModel(true)}
                            >
                                <img src={getintouch} alt="getintouch" />
                            </div>
                        </div>
                        { (
                            <div
                                className="favourites-mobile"
                                onClick={() => setShowModal3(true)}
                            >
                                <div
                                    className=".action-icon-2-m"
                                    onClick={() => setShowModal3(true)}
                                >
                                    <img src={review} alt="getintouch" />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="hide-laptop">
                        <div className="favourites-cont-mobile">
                            <div className="favourites-mobile">
                                <div className="action-icon">
                                    {isMarked ? (
                                        <BsHeartFill
                                            color="red"
                                            onClick={() =>
                                                unmarkFavouriteCompany(
                                                    company?.id
                                                )
                                            }
                                        />
                                    ) : (
                                        <BsHeart
                                            color="#C11717"
                                            onClick={() =>
                                                markFavouriteCompany(
                                                    company?.id
                                                )
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                            <div
                                className="favourites-mobile"
                                onClick={() => setOpenModel(true)}
                            >
                                <div
                                    className=".action-icon-2-m"
                                    onClick={() => setOpenModel(true)}
                                >
                                    <img src={getintouch} alt="getintouch" />
                                </div>
                            </div>
                            {!isReviewed && (
                                <div
                                    className="favourites-mobile"
                                    onClick={() => setShowModal3(true)}
                                >
                                    <div
                                        className=".action-icon-2-m"
                                        onClick={() => setShowModal3(true)}
                                    >
                                        <img src={review} alt="getintouch" />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="profile-down my-3">
                            <div className="profile-mobile-margin">
                                <MemberSince date={company?.createdAt} />
                                <Tags
                                    industry={company?.Industry}
                                    serv={company?.Services}
                                />
                                   {
                                       memoizedMyPreference
                                   }

                                    {
                                        memoizedActivity
                                    }
                                <IndustryType
                                    companyIndustry={company?.Industry}
                                />
                                {/* <ProfileBasicDetails
                                    isMine={
                                        user
                                            ? user?.company_id === company?.id
                                            : false
                                    }
                                    company={company}
                                /> */}

                                {(company?.OtherInfos?.length > 0 ||
                                    company?.Images?.length > 0 ||
                                    company?.Pdfs?.length > 0 ||
                                    company?.Links?.length > 0) && (
                                    <OtherInfo
                                        title={company?.OtherInfos}
                                        pdfLinks={company?.Pdfs}
                                        images={company?.Images}
                                        links={company?.Links}
                                    />
                                )}
                                 {
                                 memoizedBadge
                               }


                                <MemberSince date={company?.createdAt} />
                                <ProfileBasicDetails
                                    isMine={
                                        user
                                            ? user?.company_id === company?.id
                                            : false
                                    }
                                    company={company}
                                />
                                <ProfileSocialLinks
                                    socials={company?.Socials}
                                />

                                {company?.Leaders?.length > 0 && (
                                    <ProfileLeaders
                                        leaders={company?.Leaders}
                                        isMine={
                                            user
                                                ? user?.company_id ===
                                                  company?.id
                                                : false
                                        }
                                    />
                                )}

                                {user && user?.company_id === company?.id && (
                                    <RequestEndorse />
                                )}

                                {/* {company?.Reviews?.length > 0 && (
                                    <ProfileReviews
                                        id={company?.id}
                                        reviews={company?.Reviews}
                                    />
                                )} */}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="user-profile-modal modal-wrapper">
                {/* <Modal
                    size="lg"
                    active={showModal1}
                    toggler={() => {
                        setShowModal1(true);
                    }}
                    className="profile-tailwind-modal"
                    style={{
                        width: "100%",
                    }}
                >
                    <ModalBody>
                        <div className="user-email-modal">
                            <div className="user-email-modal-label">
                                <label>
                                    Please enter your business email ID
                                </label>
                            </div>
                            <div className="user-email-modal-input">
                                <input
                                    value={allUserEmail}
                                    type="email"
                                    onChange={(e) =>
                                        setAllUserEmail(e.target.value)
                                    }
                                    placeholder="Your email ID"
                                />
                            </div>
                            <div className="user-email-modal-button">
                                <button
                                    onClick={() => {
                                        if (
                                            allUserEmail.length > 0 &&
                                            allUserEmail.includes("@")
                                        ) {
                                            setShowModal1(false);

                                            if (
                                                allUser.includes(allUserEmail)
                                            ) {
                                                setShowModal3(true);
                                            } else {
                                                setShowModal2(true);
                                            }
                                        }
                                    }}
                                >
                                    Done
                                </button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal> */}
                <Modal
                    size="lg"
                    active={showModal2}
                    toggler={() => {
                        setShowModal2(false);
                    }}
                    className="profile-tailwind-modal"
                >
                    <ModalBody>
                        <div className="proceed-model-body">
                            <div className="proceed-model-text">
                                <p>
                                    Looks like you are new here! Please register
                                    your details on the waiting list (takes ~ 30
                                    sec time) to proceed with your endorsement
                                </p>
                            </div>
                            <div className="proceed-model-button">
                                <button
                                    onClick={() => {
                                        window.open(
                                            "https://hli2pvo6rp1.typeform.com/to/a03nixvp",
                                            "_blank"
                                        );

                                        setTimeout(() => {
                                            setShowModal2(false);
                                            setShowModal3(true);
                                        }, 3000);
                                    }}
                                >
                                    Proceed
                                </button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                <div className="rate-modal-container">
                    <Modal
                        size="lg"
                        active={showModal3}
                        toggler={() => {
                            setShowModal3(false);
                        }}
                        className="profile-tailwind-modal"
                    >
                        <ModalBody>
                            <div className="rate-model-body">
                                <div className="rate-model-body-head">
                                    <p>Back - {company?.company_name}</p>
                                </div>
                                {showDiv && (
                                    <div className="rate-model-body-error">
                                        <p>Please complete all fields.</p>
                                    </div>
                                )}
                                <div className="rate-model-body-container">
                                    {/* <div className="rate-model-body-star">
                                        <ReactStars
                                            count={5}
                                            onChange={ratingChanged}
                                            size={50}
                                            emptyIcon={
                                                <i className="far fa-star"></i>
                                            }
                                            fullIcon={
                                                <i className="fa fa-star"></i>
                                            }
                                            activeColor="#ffd700"
                                            value={reviewStar}
                                        />
                                    </div> */}
                                    <div className="rate-model-body-input">
                                        <input
                                            className="text-gray-700"
                                            type="text"
                                            placeholder="Your Company Name"
                                            value={companyName}
                                            onChange={(e) =>
                                                setCompanyName(e.target.value)
                                            }
                                            disabled
                                        />
                                    </div>
                                    <div className="rate-model-body-textarea">
                                        <textarea
                                            name="reviewText"
                                            placeholder="Write intraction type..."
                                            id="rate-textarea"
                                            cols="20"
                                            rows="7"
                                            maxLength="300"
                                            value={reviewText}
                                            onChange={(e) =>
                                                setReviewText(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="rate-model-body-count">
                                        {reviewText ? reviewText.length : 0}
                                        /300 characters left
                                    </div>
                                    <div className="rate-model-body-button">
                                        <button
                                            onClick={() => {
                                                if (reviewText) {
                                                    setShowModal3(false);
                                                    onDone();
                                                } else {
                                                    setShowDiv(true);
                                                }
                                            }}
                                        >
                                            Done
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
                <Modal
                    size="lg"
                    active={showModal4}
                    toggler={() => {
                        setShowModal4(false);
                    }}
                    className="profile-tailwind-modal"
                >
                    <ModalBody>
                        <div className="success-model-body">
                            <div className="success-model-text">
                                <p>
                                    <b>
                                        Congratulations! You have earned $100
                                        bizbucks* that can be used on
                                        BackBone to promote your business!
                                    </b>
                                    <br />
                                    *requires business to be a member or on a
                                    trial to access the credit
                                </p>
                            </div>
                            <div className="success-model-button">
                                <button
                                    onClick={() => {
                                        setShowModal4(false);
                                    }}
                                >
                                    Done
                                </button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
            <div className="modal-wrapper">
                <Modal
                    size="lg"
                    active={openModel}
                    toggler={() => {
                        setOpenModel(false);
                    }}
                >
                    <ModalBody>
                        <div className="Touch-body">
                            <div className="top-heading">
                                <div className="icon-wrapper">
                                    <BsPeople />
                                </div>
                                <h1>Get in Touch</h1>
                            </div>
                            <p className="get-free-ad-credit">Send to:</p>
                            <div>
                                <div className="companyDetail">
                                    {company?.User?.email}
                                </div>
                                {company?.User?.mobileNo && (
                                    <div className="companyDetail">
                                        {company?.User?.mobileNo
                                            ? company?.User?.mobileNo
                                            : ""}
                                    </div>
                                )}
                            </div>

                            <div className="input-section">
                                <div className="mail-content">
                                    <textarea
                                        name="emailcontent"
                                        id="emailcontent"
                                        cols="30"
                                        rows="8"
                                        value={content}
                                        disabled
                                        onChange={(e) => onChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-space-between">
                                {company?.User?.email && (
                                    <div className="d-flex">
                                        <span>
                                            <Radio
                                                checked={
                                                    selectedValue === "email"
                                                }
                                                onChange={handleChange}
                                                value="email"
                                                name="radio-buttons"
                                                inputProps={{
                                                    "aria-label": "EMAIL",
                                                }}
                                                color="primary"
                                                size="small"
                                            />
                                            <span className="Radio-label">
                                                Send via Email
                                            </span>
                                        </span>
                                        {company?.User?.mobileNo && (
                                            <>
                                                <span>
                                                    <Radio
                                                        checked={
                                                            selectedValue ===
                                                            "phone"
                                                        }
                                                        onChange={handleChange}
                                                        value="phone"
                                                        name="radio-buttons"
                                                        inputProps={{
                                                            "aria-label":
                                                                "PHONE",
                                                        }}
                                                        color="primary"
                                                        size="small"
                                                    />
                                                    <span className="Radio-label">
                                                        Send via Phone
                                                    </span>
                                                </span>

                                                <span>
                                                    <Radio
                                                        checked={
                                                            selectedValue ===
                                                            "both"
                                                        }
                                                        onChange={handleChange}
                                                        value="both"
                                                        name="radio-buttons"
                                                        inputProps={{
                                                            "aria-label":
                                                                "BOTH",
                                                        }}
                                                        color="primary"
                                                        size="small"
                                                    />
                                                    <span className="Radio-label">
                                                        Send via both
                                                    </span>
                                                </span>
                                            </>
                                        )}
                                    </div>
                                )}

                                <div
                                    className={
                                        company?.User?.email
                                            ? ""
                                            : "button-not-radio-user"
                                    }
                                >
                                    <button
                                        className="send-button"
                                        onClick={() => {
                                            getInTouchCompany();
                                        }}
                                    >
                                        Send
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal
                    size="lg"
                    active={sentEmailModel}
                    toggler={() => {
                        setSentEmailModel(false);
                    }}
                >
                    <ModalBody>
                        <div className="sent-popup">
                            <img src={BlueCheck} />
                        </div>
                        <div className="sent">Your message has been sent!</div>
                    </ModalBody>
                </Modal>
            </div>
            <Modal
                size="xl"
                backdrop
                className="mb-0"
                active={visible}
                toggler={handleClose}
            >
                <ModalBody className="mb-0">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div
                                    className="row mb-0"
                                    style={{
                                        border: "2px solid #03b0fb",
                                        borderRadius: "10px",
                                        padding: "10px 15px",
                                    }}
                                >
                                    <div className="col-10">
                                        <div className="">
                                            <h3
                                                className="fs-4 fw-bolder"
                                                style={{ color: "#162452" }}
                                            >
                                                You have backed {company?.company_name}!
                                            </h3>
                                            <p
                                                className="fw-bold my-2 w-100"
                                                style={{
                                                    fontSize: "12px",
                                                    color: "#162452",
                                                }}
                                            >
                                                Thank you for backing a
                                                businesses
                                            </p>
                                        </div>
                                        <div
                                            className="d-flex justify-content-between align-items-center"
                                            style={{
                                                background: "#ebffe4",
                                                padding: "10px",
                                                borderRadius: "15px",
                                            }}
                                        >
                                            <div className="d-flex justify-content-start align-items-center">
                                                <span
                                                    style={{
                                                        fontSize: "45px",
                                                        color: "#162452",
                                                        fontWeight: "900",
                                                    }}
                                                >
                                                    +
                                                </span>
                                                <span
                                                    className="ms-2"
                                                    style={{
                                                        color: "#ffd79c",
                                                        fontSize: "50px",
                                                        fontWeight: "900",
                                                    }}
                                                >
                                                    {
                                                        bizBucksConfig?.find((item)=>item?.configKey=="back_a_business")?.configValue
                                                    }
                                                </span>
                                            </div>
                                            <div>
                                                <h3 className="fs-6 fw-bolder text-center pe-5">
                                                    BizBucks credited to your{" "}
                                                    <br /> account
                                                </h3>
                                            </div>
                                        </div>

                                        <div className="my-2 w-100">
                                            <p style={{ fontSize: "10px" }}>
                                                Your backing will be posted on
                                                the Dashboard under “Success
                                                Stories”
                                            </p>
                                            <p style={{ fontSize: "10px" }}>
                                                To edit or delete, go to “My
                                                Backings” on your profile
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div
                                            style={{
                                                display: "flex",
                                                height: "100%",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <img src={triangle} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default UserProfile;
