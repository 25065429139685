import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    unreadCount: 0,
    notifications: [],
};

const notificationSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        updateCount: (state, action) => {
            state.unreadCount = action.payload;
        },
        resetCount: (state, action) => {
            state.unreadCount = 0;
        },
        addNotifications: (state, action) => {
            state.notifications = action.payload;
        },
    },
});

export const { resetCount, updateCount, addNotifications } =
    notificationSlice.actions;
export default notificationSlice.reducer;
