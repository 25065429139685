import React, { Fragment, memo, useEffect, useState } from "react";
import { IoEllipsisHorizontal } from "react-icons/io5";
import Modal from "@material-tailwind/react/Modal";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import ModalBody from "@material-tailwind/react/ModalBody";
import Button from "@material-tailwind/react/Button";
import { SlCalender } from "react-icons/sl";
import { CiClock2 } from "react-icons/ci";
import { IoLocationOutline } from "react-icons/io5";
import { MdFavoriteBorder } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { MdOutlineEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
    getAdminEventsData,
    registeredNewEvent,
} from "../../../data/reducers/home/content";
import { useToast } from "@chakra-ui/react";
import validator from "validator";
import { getUserRegisteredEventById } from '../../../data/reducers/events/events';
import { editUserRegistredEventsById } from '../../../data/services/events/events';

const CommonModalBackboneEvent = ({
    open,
    setOpen,
    setVisible2,
    visible2,setEventType,
    setEventDate,
    selecetedEventId
}) => {
    const toast = useToast();
    const {
        bizBucksConfig,
        events,
        totalEvents,
        loading,
        success,
        message,
        error,myCompany,
    } = useSelector((state) => state.content);
    const {registeredUserEvent}=useSelector((state)=>state.events)
    const [numberOfEventAttendes, setNumberOfEventAttendes] = useState();
    const dispatch = useDispatch();
    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        if (success && message && !error) {
            setOpen(false);
            setVisible2(true);
        }
    }, [loading, success, message, error]);
    const [registerEvent, setRegisterEvent] = useState({
        event_id: selecetedEventId?selecetedEventId:undefined,
        attendees: [],
    });
    useEffect(()=>{
      if(registeredUserEvent){
          let temp=[]
          registeredUserEvent?.eventRegistrations?.map((item)=>{
              let temp2={
                  company_id:item?.company_id,
                  company_name:item?.company_name,
                  email:item?.email,
                  event_id:selecetedEventId?selecetedEventId:item?.event_id,
                  first_name:item?.first_name,
                  job_title:item?.job_title,
                  last_name:item?.last_name,
                  phone_number:item?.phone_number,
                  registration_id:item?.registration_id,
                  user_id:item?.user_id
              }
              temp.push(temp2) 
          })
          if(Number(bizBucksConfig.find((item)=>item.configKey=="number_of_event_attendees" && item?.configType=="event")?.configValue)==temp?.length){
            setRegisterEvent({
              ...registerEvent,attendees:temp
            })
          }else{
            setNumberOfEventAttendes(Number(bizBucksConfig.find((item)=>item.configKey=="number_of_event_attendees" && item?.configType=="event")?.configValue))
            let temp3=[]
            for(let i=0;i<Number(bizBucksConfig.find((item)=>item.configKey=="number_of_event_attendees" && item?.configType=="event")?.configValue);i++){
               if(temp[i]?.event_id){
                 temp3.push(temp[i])
               }else{
                temp3.push(attendeedObject)
               }
            }
            setRegisterEvent({
               ...registerEvent,attendees:temp3
           })
          }
      }
      if(!registeredUserEvent){
        setNumberOfEventAttendes(Number(bizBucksConfig.find((item)=>item.configKey=="number_of_event_attendees" && item?.configType=="event")?.configValue))
        let temp=[]
        for(let i=0;i<Number(bizBucksConfig.find((item)=>item.configKey=="number_of_event_attendees" && item?.configType=="event")?.configValue);i++){
           temp.push(attendeedObject)
        }
        setRegisterEvent({
           ...registerEvent,attendees:temp
       })
      }
    },[useSelector,registeredUserEvent])

    let attendeedObject = {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        job_title: "",
    };
    const optionsDate = {
        weekday: "long", // Tuesday
        month: "long", // June
        day: "numeric", // 25
    };

    const optionsTime = {
        hour: "numeric",
        minute: "numeric",
        hour12: true, // 1pm
    };
    useEffect(() => {
        if (bizBucksConfig?.length > 0) {
            setNumberOfEventAttendes(
                Number(
                    bizBucksConfig.find(
                        (item) =>
                            item.configKey == "number_of_event_attendees" &&
                            item?.configType == "event"
                    )?.configValue
                )
            );
            let temp = [];
            for (
                let i = 0;
                i <
                Number(
                    bizBucksConfig.find(
                        (item) =>
                            item.configKey == "number_of_event_attendees" &&
                            item?.configType == "event"
                    )?.configValue
                );
                i++
            ) {
                temp.push(attendeedObject);
            }
            setRegisterEvent({
                ...registerEvent,
                attendees: temp,
            });
        }
    }, [bizBucksConfig]);
    useEffect(() => {
        dispatch(getAdminEventsData());
    }, [dispatch]);

    const handleInputChange = (index, field, value) => {
        const updatedAttendees = registerEvent.attendees?.map((attendee, i) =>
            i === index ? { ...attendee, [field]: value } : attendee
        );
        setRegisterEvent({ ...registerEvent, attendees: updatedAttendees });
    };
    const validateAndSubmitRequest=async ()=>{
      try{
        if(!selecetedEventId && !registerEvent?.event_id){
          toast({
              title: `Select Event`,
              position: "top-right",
              duration: 3000,
              status: "error",
              isClosable: true,
          })
        }else{
          let findType=registerEvent?.attendees?.some((item)=>item?.user_id && item?.company_id && item?.registration_id && item?.event_id)
          let flag=true
          registerEvent?.attendees?.map((item,i)=>{
              if(i==0){
                if(!item?.first_name?.trim() || !item?.last_name?.trim()  || !item?.job_title?.trim()){
                  toast({
                    title: `Attendees ${i+1} is required`,
                    position: "top-right",
                    duration: 3000,
                    status: "error",
                    isClosable: true,
                  })
                  flag=false
                  return
                }else if(item?.email?.trim()==""){
                  toast({
                    title: `Email is Required for Attendees ${i+1}`,
                    position: "top-right",
                    duration: 3000,
                    status: "error",
                    isClosable: true,
                  })
                  flag=false
                  return
                }
                else if(!validator.isEmail(item?.email?.trim())){
                  toast({
                    title: `Invalid Email for Attendees ${i+1}`,
                    position: "top-right",
                    duration: 3000,
                    status: "error",
                    isClosable: true,
                  })
                  flag=false
                  return
                }
              }else{
                if(item?.first_name?.trim() || item?.last_name?.trim()  || item?.job_title?.trim() || validator.isEmail(item?.email?.trim())){
                  if(!item?.first_name?.trim() || !item?.last_name?.trim()  || !item?.job_title?.trim()){
                    toast({
                      title: `Filled all details for Attendees ${i+1}`,
                      position: "top-right",
                      duration: 3000,
                      status: "error",
                      isClosable: true,
                    })
                    flag=false
                    return
                  }
                  else if(item?.email?.trim()==""){
                    toast({
                      title: `Email is Required for Attendees ${i+1}`,
                      position: "top-right",
                      duration: 3000,
                      status: "error",
                      isClosable: true,
                    })
                    flag=false
                    return
                  }
                  else if(!validator.isEmail(item?.email?.trim())){
                    toast({
                      title: `Invalid Email for Attendees ${i+1}`,
                      position: "top-right",
                      duration: 3000,
                      status: "error",
                      isClosable: true,
                    })
                    flag=false
                    return
                  }
                }
              }
          })

          if(flag){
            let filterdata=registerEvent?.attendees?.filter((item)=>{
              if(item?.first_name?.trim() && item?.last_name?.trim()  && item?.job_title?.trim() && validator.isEmail(item?.email?.trim())){
                return item
              }
            })
            if(findType){
              setEventType("Edit")
                 let finalAttendeed=[]
                 filterdata?.map((item)=>{
                     if(item?.registration_id && item?.company_id && item?.event_id && item?.user_id){
                        finalAttendeed.push({
                          ...item,company_name:myCompany?.company_name
                        })
                     }else{
                      let temp={
                        company_id:myCompany?.User?.company_id,
                        company_name:myCompany?.company_name,
                        email:item?.email,
                        event_id:Number(selecetedEventId?selecetedEventId:registerEvent?.event_id),
                        first_name:item?.first_name,
                        job_title:item?.job_title,
                        last_name:item?.last_name,
                        phone_number:item?.phone_number,
                        user_id:myCompany?.User?.id
                       }
                       finalAttendeed.push(temp)
                     }
                 })
                 let finalPayload={
                  event_id:Number(selecetedEventId?selecetedEventId:registerEvent?.event_id),
                  attendees:finalAttendeed
                }
                const response=await editUserRegistredEventsById(finalPayload?.event_id,finalPayload)
                if(response.success){
                  setVisible2(true)
                  setRegisterEvent({
                    event_id:undefined,
                    attendees:[]
                  })
                }else{
                  toast({
                    title: `${response.message}`,
                    position: "top-right",
                    duration: 3000,
                    status: "error",
                    isClosable: true,
                 })
                }
                // let response=await dispatch(registeredNewEvent(finalPayload))
                // if(!response?.payload?.success){
                  //   toast({
                  //     title: `${response?.payload?.message}`,
                  //     position: "top-right",
                  //     duration: 3000,
                  //     status: "error",
                  //     isClosable: true,
                  // })
                // }else{
                    // setVisible2(true)
                    // setRegisterEvent({
                    //   event_id:undefined,
                    //   attendees:[]
                    // })
                // }
            }else{
              setEventType("Register")
                let finalPayload={
                  event_id:Number(selecetedEventId?selecetedEventId:registerEvent?.event_id),
                  attendees:filterdata
                }
                let response=await dispatch(registeredNewEvent(finalPayload))
                if(!response?.payload?.success){
                    toast({
                      title: `${response?.payload?.message}`,
                      position: "top-right",
                      duration: 3000,
                      status: "error",
                      isClosable: true,
                  })
                }else{
                    setVisible2(true)
                    setRegisterEvent({
                      event_id:undefined,
                      attendees:[]
                    })
                }
            }
          }




          // let filterdata=registerEvent?.attendees?.filter((item)=>{
          //   if(item?.first_name?.trim() && item?.last_name?.trim()  && item?.job_title?.trim() && validator.isEmail(item?.email?.trim())){
          //     return item
          //   }
          // })

        //  console.log(filterdata)
        }

        // let data=registerEvent?.attendees?.some((item)=>{
        //      if(!item?.first_name?.trim() || !item?.last_name?.trim() || !item?.job_title?.trim() || !validator.isEmail(item?.email?.trim())){
                
        //      }
        //      return !item?.first_name?.trim() || !item?.last_name?.trim() || !item?.job_title?.trim() || !validator.isEmail(item?.email?.trim())
        // })
        // console.log(data)
        // else if(!registerEvent?.attendees[0]?.first_name?.trim() || !registerEvent?.attendees[0]?.last_name?.trim()  || !registerEvent?.attendees[0]?.job_title?.trim()){
            // toast({
            //     title: `First Attendees are required`,
            //     position: "top-right",
            //     duration: 3000,
            //     status: "error",
            //     isClosable: true,
            // })
        // }
        // else if(!validator.isEmail(registerEvent?.attendees[0]?.email?.trim())){
        //     toast({
        //         title: `Invalid Email`,
        //         position: "top-right",
        //         duration: 3000,
        //         status: "error",
        //         isClosable: true,
        //     })
        // }
        // else{
          // let filterdata=registerEvent?.attendees?.filter((item)=>{
          //    if(!item?.first_name?.trim() || !item?.last_name?.trim()  || !item?.job_title?.trim() || !validator.isEmail(item?.email?.trim())){
                 
          //    }else{
          //     return item
          //    }
          // })
          // let finalPayload={
          //   event_id:registerEvent?.event_id,
          //   attendees:filterdata
          // }
          //   let response=await dispatch(registeredNewEvent(finalPayload))
          //   if(!response?.payload?.success){
          //       toast({
          //         title: `${response?.payload?.message}`,
          //         position: "top-right",
          //         duration: 3000,
          //         status: "error",
          //         isClosable: true,
          //     })
          //   }else{
          //       setVisible2(true)
          //       setRegisterEvent({
          //         event_id:undefined,
          //         attendees:[]
          //       })
          //   }
        // }
      }catch(err){

      }
    }

    const dateFormat = (eventDate) => {
        const formattedDate = eventDate.toLocaleDateString(
            "en-AU",
            optionsDate
        );
        const formattedTime = eventDate
            .toLocaleTimeString("en-AU", optionsTime)
            .replace(" ", ""); // Removing space before AM/PM

        return `${formattedDate}, ${formattedTime} AEST`;
    };
    return (
        <Fragment>
            <Modal size="md" backdrop active={open} toggler={handleClose} >
                <ModalBody>
                    <div className="container-fluid" style={{position:"relative",zIndex:"99999"}}>
                        <div className="row">
                            <div className="col-12">
                                <div
                                    className="row position-relative"
                                    style={{ overflowX: "hidden" }}
                                >
                                    {/* <div>
                  <h2 className='fw-bolder fs-4'>Power Hour Registration from Events</h2>
              </div> */}
                                    <div
                                        className="my-4 gap-3 d-flex justify-content-between align-items-center flex-wrap flex-md-nowrap"
                                        style={{
                                            background: "#142252",
                                            padding: "15px 25px",
                                            borderRadius: "5px",
                                            margin: "0 auto",
                                            width: "100%",
                                            maxWidth: "100%",
                                            overflowX: "hidden",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "100%",
                                                maxWidth: "100%",
                                                textAlign: "left",
                                                marginBottom: "1rem",
                                            }}
                                        >
                                            <h2
                                                className="fw-bold text-white"
                                                style={{ fontSize: "24px" }}
                                            >
                                                BackBone Events
                                            </h2>
                                            <div className="event_popup_banner_section text-left">
                                                Description: A Power hour
                                                session with 3 rounds Speed
                                                Dating & 2 Spotlight
                                                presentations from industry
                                                professionals!
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                background: "white",
                                                minWidth: "150px",
                                                height: "150px",
                                                borderRadius: "50%",
                                                padding: "20px",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                margin: "0 auto",
                                            }}
                                        >
                                            <h4
                                                style={{ color: "#142252" }}
                                                className="fs-4 fw-bolder"
                                            >
                                                Earn
                                            </h4>
                                            <h3
                                                style={{
                                                    fontSize: "20px",
                                                    color: "#f4c58f",
                                                }}
                                            >
                                                {
                                                    bizBucksConfig?.find(
                                                        (item) =>
                                                            item?.configKey ===
                                                            "attend_an_event"
                                                    )?.configValue
                                                }
                                            </h3>
                                            <h2
                                                style={{ color: "#142252" }}
                                                className="fs-5 fw-bolder"
                                            >
                                                BizBucks
                                            </h2>
                                            <p
                                                style={{
                                                    color: "#142252",
                                                    fontSize: "10px",
                                                }}
                                            >
                                                *when you attend
                                            </p>
                                        </div>
                                    </div>

                                       <div className='my-2 w-100'>
                                           <h3 className='fs-4 fw-bolder'>Register</h3>
                                           <select disabled={selecetedEventId?true:false}  className='w-100 form-control p-2 mt-3' onChange={async (e)=>{
                                             if(e?.target?.value && e?.target?.value!="undefined"){
                                               dispatch(getUserRegisteredEventById(e?.target?.value))
                                               setRegisterEvent({
                                                ...registerEvent,event_id:e.target?.value
                                               })
                                               setEventDate(events?.find((item)=>item.id==e?.target?.value))
                                             }else{
                                               setRegisterEvent({
                                                event_id:undefined,
                                                attendees:[]
                                                })
                                               setEventDate(undefined)
                                             }
                                             
                                            
                            
                                           }}>
                                            <option value="undefined">BackBone Schedule of Events</option>
                                             
                                              {
                                                events?.length>0 && events?.map((item,index)=>{
                                                    return <option  selected={selecetedEventId==item?.id?true:false}  key={index} value={item?.id}>
                                                       
                                                        {
                                                            // new Date(item?.eventDate)?.toLocaleString('en-AU', options)
                                                            dateFormat(new Date(item?.eventDate))
                                                        }
                                                    </option>
                                                })
                                              }
                                           </select>
                                       </div>
                                       <div className='col-12 my-2'>
                                           
                                           {
                                             numberOfEventAttendes>0 && Array.from({ length: numberOfEventAttendes }).map((_,index)=>{
                                                return  <div key={index} className='row mt-2'>
                                                <div className='col-12 fs-6 fw-bold d-flex justify-content-between align-items-center mb-2'>
                                                   <div>
                                                     Attendee {index+1} {index==0 && <span className='text-danger'>*</span>}
                                                   </div>
                                                </div>
                                                <div className='col-6 my-1'>
                                                  <input type="text" className='form-control p-2 ' placeholder='First Name' value={registerEvent?.attendees[index]?.first_name} 
                                                   onChange={(e) => handleInputChange(index, 'first_name', e.target.value)}/>
                                                </div>
                                                <div className='col-6 my-1'>
                                                  <input type="text" className='form-control p-2' placeholder='Last Name' value={registerEvent?.attendees[index]?.last_name} 
                                                   onChange={(e) => handleInputChange(index, 'last_name', e.target.value)}/>
                                                </div>
                                                <div className='col-12 my-1'>
                                                  <input type="text" className='form-control p-2' placeholder='Email ID' value={registerEvent?.attendees[index]?.email} 
                                                   onChange={(e) => handleInputChange(index, 'email', e.target.value)}/>
                                                </div>
                                                <div className='col-12 my-1'>
                                                  <input type="text" className='form-control p-2' placeholder='Job Title' value={registerEvent?.attendees[index]?.job_title} 
                                                   onChange={(e) => handleInputChange(index, 'job_title', e.target.value)}/>
                                                </div>                                            
                                             </div>
                                             })
                                           }
                                           {/* <div className='row'>
                                              <div className='col-12 fs-6 fw-bold d-flex justify-content-between align-items-center mb-3'>
                                                 <div>
                                                   Attendee 1 <span className='text-danger'>*</span>
                                                 </div>
                                              </div>
                                              <div className='col-4 my-1'>
                                                <input type="text" className='form-control p-2 col-4' placeholder='First Name'/>
                                              </div>
                                              <div className='col-4 my-1'>
                                                <input type="text" className='form-control p-2 col-4' placeholder='Last Name'/>
                                              </div>
                                              <div className='col-4 my-1'>
                                                <input type="text" className='form-control p-2 col-4' placeholder='Email ID'/>
                                              </div>
                                              <div className='col-4 my-1'>
                                                <input type="text" className='form-control p-2 col-4' placeholder='Job Title'/>
                                              </div>                                            
                                           </div>



                                           <div className='row my-2 w-100'>
                                              <div className='col-12 fs-6 fw-bold d-flex justify-content-between align-items-center mb-3'>
                                                 <div>
                                                   Attendee 2
                                                 </div>
                                              </div>
                                              <div className='col-4 my-1'>
                                                <input type="text" className='form-control p-2 col-4' placeholder='First Name'/>
                                              </div>
                                              <div className='col-4 my-1'>
                                                <input type="text" className='form-control p-2 col-4' placeholder='Last Name'/>
                                              </div>
                                              <div className='col-4 my-1'>
                                                <input type="text" className='form-control p-2 col-4' placeholder='Email ID'/>
                                              </div>
                                              <div className='col-4 my-1'>
                                                <input type="text" className='form-control p-2 col-4' placeholder='Job Title'/>
                                              </div>                                            
                                           </div> */}



                                           <div className='row my-3 w-100'>
                                               <div className='col-12 d-flex justify-content-between align-items-center'>
                                                     <button className='bg-primary text-white' style={{borderRadius:"30px",padding:"10px 25px"}} onClick={()=>{
                                                        validateAndSubmitRequest()
                                                     }}>Register</button>
                                               </div>
                                           </div>
                                       </div>
                                       
                                       <span style={{ position: "absolute",
                                            top: "0px",
                                            right: "-10px",
                                            cursor: "pointer",
                                            width: "max-content",}} onClick={()=>{
                                        handleClose()
                                       }}>
                                         <RxCross1 style={{transform:"scale(1.2)"}}/>
                                      </span>
                                       
                                  </div>
                              </div>
                         </div>
                      </div>
                </ModalBody>
            </Modal>
        </Fragment>
    );
};

export default memo(CommonModalBackboneEvent);
