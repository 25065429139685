import React, { memo, useEffect, useState } from "react";
import "./badge.css";
import silverBadge from "../../../assets/CustomIcons/silverBadge.png";
import goldBadge from "../../../assets/CustomIcons/goldMedal.png";
import bronzeBadge from "../../../assets/CustomIcons/bronzeMedal.png";



const Badge = ({ totalBackedBizBuck , badgeTier}) => {
    const [type,setType]=useState("Silver")

    useEffect(()=>{
        let temp=badgeTier?.find((item)=>totalBackedBizBuck>=item?.min_backings && totalBackedBizBuck<=item?.max_backings)
         if(temp){
            setType(temp?.tier_name)
         }else{
            if(totalBackedBizBuck>=100){
                setType("Gold")
            }else{
                setType("Bronze")
            }
         }
    },[badgeTier])
    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div>
                        <div
                            className="badge_container"
                            style={{ backgroundImage: `url(${type=="Gold"?goldBadge:type=="Silver"?silverBadge:bronzeBadge})` }}
                        >
                            <p>Backed by</p>
                            <h3 className="fs-3 fw-bolder my-1">
                                {totalBackedBizBuck > 0
                                    ? totalBackedBizBuck
                                    : 0}
                            </h3>
                            <p>Businesses</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(Badge);
