import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NotFoundImg from "../../assets/NotFound.svg";
import "./Errors.css";

const NotFound = (showBtn, search) => {
    const navigate = useNavigate();
    console.log("showBtn", showBtn);
    useEffect(()=>{

    }, [search])

    return (
        <div className="cont">
            <div>
                <img src={NotFoundImg} alt="NotFoundImg" />
            </div>
            <div className="error-msg">
                <p>Oops no results found!</p>
                <p> We couldn’t find what you were looking for.</p>
            </div>
            {showBtn.showBtn ? (<div className="back-btn" onClick={() => navigate(-1)}>
                    <button>Back to Previous Screen</button>
                </div>) : null
                
            }
        </div>
    );
};

export default NotFound;
