import React, { useEffect, useRef, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import "./ProfileBasicDetails.css";

import Modal from "@material-tailwind/react/Modal";
import ModalBody from "@material-tailwind/react/ModalBody";
import Button from "@material-tailwind/react/Button";
import { updateCompanyInfoAction } from "../../../data/reducers/details/details";
import { getMyCompanyAction } from "../../../data/reducers/home/content";
import { getUserProfile } from "../../../data/reducers/auth/auth";
import { validatePhone } from "../../../shared/utils/checkRegex";
import { useToast } from "@chakra-ui/react";
import countryList from "country-list";

const ProfileBasicDetails = ({ isMine, company }) => {
    const countries = countryList.getCodeList();
    const [showModal, setShowModal] = useState(false);
    const [number, setNumber] = useState("");
    const [location, setLocation] = useState("");
    const [otherLocation, setOtherLocation] = useState("");
    const { user, isAuthenticated } = useSelector((state) => state.auth);
    const elRef = useRef(null);
    const [view, setView] = useState(false);
    const toast = useToast();
    const [locationError, setLocationError] = useState(false);

    const dispatch = useDispatch();
    const editCompanyBasicInfo = async () => {
        if (!validatePhone(number)) {
            dispatch(
                toast({
                    title: "Incorrect mobile number!",
                    status: "error",
                    position: "top-right",
                    isClosable: true,
                    duration: 3000,
                })
            );
            return;
        }
        setShowModal(false);
        await dispatch(
            updateCompanyInfoAction({
                mobileNo: number,
                location: location == "Other" ? otherLocation : location,
            })
        );
        if (user && isAuthenticated) {
            dispatch(getUserProfile());
            dispatch(getMyCompanyAction());
        }
        setNumber("");
    };
    const editPhoneNumber = (number) => {
        setShowModal(true);
        setNumber(number);
    };

    useEffect(() => {
        if (elRef.current.scrollWidth > elRef.current.clientWidth) {
            setView(true);
        }
    }, []);

    return (
        <>
            <div className="profile-basic-details">
                <div className="profile-basic-details-header">
                    {isMine && (
                        <AiOutlineEdit
                            className="profile-down-details-edit"
                            onClick={() => editPhoneNumber(user?.mobileNo)}
                        />
                    )}
                </div>

                <div className="profile-basic-details-body">
                    <div className="row">
                        <div className="col-4 profile-basic-details-body-border py-3">
                            <h3>Phone No.</h3>
                            <h3>Email Id.</h3>
                            <h3>Country</h3>
                            <h3>Industry</h3>
                        </div>
                        <div className="col-8 py-3">
                            {/* <h4>{company?.number || "NA"}</h4> */}
                            <h4>{company?.User?.mobileNo || "NA"}</h4>
                            <div ref={elRef}>
                                <p
                                    className="email-id"
                                    style={{
                                        fontSize: "15px",
                                        wordBreak: "breakall",
                                    }}
                                >
                                    <>{company?.User?.email}</>
                                </p>
                            </div>
                            <h4>{company?.location}</h4>
                            <h4>{company?.Industry?.industry_name}</h4>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal */}
            <div className="profile-modal modal-wrapper">
                <Modal
                    size="lg"
                    active={showModal}
                    toggler={() => setShowModal(false)}
                    className="profile-tailwind-modal"
                >
                    <ModalBody>
                        <div className="form basic-details-form">
                            <div className="form-group my-2">
                                <label htmlFor="phone">Phone Number</label>
                                <input
                                    type="text"
                                    placeholder="Phone Number"
                                    value={number}
                                    onChange={(e) => {
                                        e.target.value = e.target.value
                                            .trimStart()
                                            .replace(/[^0-9\+]/gi, "");
                                        if (e.target.value.length > 13) {
                                        } else {
                                            setNumber(e.target.value);
                                        }
                                    }}
                                />
                            </div>
                            <div className="form-group my-3">
                                <label htmlFor="location">Location*</label>
                                <div className="location d-flex justify-content-between w-100">
                                    <div className="button">
                                        <label
                                            htmlFor="australia"
                                            className={
                                                location === "Australia"
                                                    ? "label-active"
                                                    : ""
                                            }
                                        >
                                            Australia
                                        </label>
                                        <input
                                            type="radio"
                                            id="australia"
                                            name="location"
                                            value="Australia"
                                            onChange={(e) =>
                                                setLocation(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="button">
                                        <label
                                            htmlFor="NewZealand"
                                            className={
                                                location === "New Zealand"
                                                    ? "label-active"
                                                    : ""
                                            }
                                        >
                                            New Zealand
                                        </label>
                                        <input
                                            type="radio"
                                            id="NewZealand"
                                            name="location"
                                            value="New Zealand"
                                            onChange={(e) =>
                                                setLocation(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="button">
                                        <label
                                            htmlFor="other"
                                            className={
                                                location === "Other"
                                                    ? "label-active"
                                                    : ""
                                            }
                                        >
                                            Other
                                        </label>
                                        <input
                                            type="radio"
                                            id="other"
                                            name="location"
                                            value="Other"
                                            onChange={(e) =>
                                                setLocation(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            {location === "Other" && (
                                <div className="form-group my-3">
                                    <label htmlFor="location">Country*</label>
                                    <select
                                        name="otherLocation"
                                        id="otherLocation"
                                        className={
                                            locationError ? "error-input" : ""
                                        }
                                        onChange={(e) =>
                                            setOtherLocation(e.target.value)
                                        }
                                        value={otherLocation}
                                    >
                                        <option value="">Select Country</option>
                                        {Object.entries(countries).map(
                                            (val) => (
                                                <option value={val[1]}>
                                                    {val[1]}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </div>
                            )}
                        </div>
                    </ModalBody>
                    <div className="d-flex justify-content-between">
                        <div className="close-button">
                            <Button
                                color="red"
                                buttonType="link"
                                onClick={(e) => setShowModal(false)}
                                ripple="dark"
                            >
                                Close
                            </Button>
                        </div>
                        <div className="save-button">
                            <Button
                                color="green"
                                onClick={(e) => editCompanyBasicInfo()}
                                ripple="light"
                            >
                                Save Changes
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default ProfileBasicDetails;
