import React, { memo } from "react";
import BackboneLogo from "../../../assets/backbone-logo.svg";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import { useNavigate } from "react-router";
import { getMyCompanyAction } from "../../../data/reducers/home/content";

const ProfileActivityCardCompany = ({ type, item, index, company_detail }) => {
    const { company } = useSelector((state) => state.content);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <div  className="col-12 my-3 px-3">
            <div>
                <div
                    className="p-4"
                    style={{ backgroundColor: "#deebff", borderRadius: "25px" }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                        }}
                    >
                        <div>
                            <div>
                                {type == "We" && (
                                    <img
                                        style={{
                                            minWidth: "35px",
                                            height: "35px",
                                            borderRadius: "100%",
                                        }}
                                        src={
                                            company_detail?.find(
                                                (item2) =>
                                                    item2?.id ==
                                                    item?.backed_company_id
                                            )?.company_logo? company_detail?.find(
                                                (item2) =>
                                                    item2?.id ==
                                                    item?.backed_company_id
                                            )?.company_logo:"https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"
                                        }
                                    />
                                )}
                                {type == "Us" && (
                                    <img
                                        style={{
                                            minWidth: "35px",
                                            height: "35px",
                                            borderRadius: "100%",
                                        }}
                                        src={company?.company_logo?company?.company_logo:"https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"}
                                    />
                                )}
                            </div>
                        </div>
                        <h3
                            className="text-primary fs-5 ms-3 text-center mx-auto cursor-pointer"
                            onClick={() => {
                                if (type == "We") {
                                    navigate(
                                        `/company/${
                                            company_detail?.find(
                                                (item2) =>
                                                    item2?.id ==
                                                    item?.backed_company_id
                                            )?.id
                                        }`
                                    );
                                    window.location.reload();
                                }
                            }}
                        >
                            {type == "We"
                                ? company_detail?.find(
                                      (item2) =>
                                          item2?.id == item?.backed_company_id
                                  )?.company_name
                                : company?.company_name}
                        </h3>
                    </div>
                    <div className="my-3 d-flex justify-content-between align-items-center">
                        <span style={{ fontSize: "12px" }}>backed by</span>
                        <p style={{ fontSize: "18px" }}>
                            {type == "We"
                                ? company?.company_name
                                : company_detail?.find(
                                      (item2) =>
                                          item2?.id == item?.backer_company_id
                                  )?.company_name}
                        </p>
                        <div>
                            {type == "We" ? (
                                <img
                                    style={{
                                        minWidth: "35px",
                                        height: "35px",
                                        borderRadius: "100%",
                                    }}
                                    src={company?.company_logo?company?.company_logo :"https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"}
                                />
                            ) : (
                                <img
                                    style={{
                                        minWidth: "35px",
                                        height: "35px",
                                        borderRadius: "100%",
                                    }}
                                    src={
                                        company_detail?.find(
                                            (item2) =>
                                                item2?.id ==
                                                item?.backer_company_id
                                        )?.company_logo?company_detail?.find(
                                            (item2) =>
                                                item2?.id ==
                                                item?.backer_company_id
                                        )?.company_logo:"https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"
                                    }
                                />
                            )}
                        </div>
                    </div>
                    <div
                        style={{
                            margin: "auto",
                            position: "relative",
                            background: "white",
                            width: "100%",
                            padding: "15px 10px",
                            borderRadius: "40px",
                            minHeight: "90px",
                        }}
                    >
                        <div
                            style={{
                                width: "70%",
                                margin: "auto",
                                textAlign: "center",
                            }}
                        >
                            {item?.interaction_type ==
                            "Other: Enter Free Text (200 max)"
                                ? item?.free_text
                                : item?.interaction_type}
                        </div>
                        <span
                            style={{
                                position: "absolute",
                                top: "-10px",
                                left: "-8px",
                            }}
                        >
                            <img
                                style={{ width: "40px" }}
                                src="https://cdn-icons-png.flaticon.com/512/7350/7350737.png"
                            />
                        </span>
                    </div>
                    <div style={{ marginLeft: "auto", textAlign: "end" }}>
                        <span style={{ textAlign: "end", fontSize: "10px" }}>
                            <Moment fromNow>
                                {new Date(item?.created_at)}
                            </Moment>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileActivityCardCompany;
