import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    contentActions,
    getBlogByIdAction,
    getBlogsAction,
} from "../../data/reducers/home/content";
import ArrowIcon from "../../assets/Arrow-icon.svg";
import SearchIcon from "../../assets/search-icon.svg";

import { EditorState, convertToRaw, convertFromRaw } from "draft-js";

import draftToHtmlPuri from "draftjs-to-html";
import styled from "styled-components";
import "./Blogs.css";
import { Link } from "react-router-dom";

const ArrowImg = styled.img``;
const SearchImg = styled.img`
    height: 20px;
`;
const Container = styled.div`
    position: relative;
    bottom: 60px;
    margin-left: 40px;
    margin-right: 60px;
    @media (max-width: 770px) {
        margin-left: 40px;
        margin-right: 40px;
    }
`;
const DataContainer = styled.div`
    /* display: flex; */
`;

const SimilarBlogs = styled.div`
    display: flex;
    margin-bottom: 30px;
    @media (max-width: 770px) {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
`;
const SimilarBlogContain = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 15px;
`;

const AllBlogs = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    color: #000000;
    margin-bottom: 20px;
    margin-top: 80px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const SearchInput = styled.div`
    margin-top: 20px;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 7px;
    font-size: 16px;
    display: flex;
    @media (max-width: 770px) {
        margin-top: 0;
        margin-left: 35px;
    }
`;

const BlogTitle = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #555555;
    margin-bottom: 25px;
`;

const Description = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #4e4e4e;
    margin-top: 25px;
    span {
        margin: 1em 0;
        display: block;
    }
`;

const MiniBlogTitle = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #555555;
`;

const MiniDescription = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #4e4e4e;
`;
const OtherBlogs = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
    margin-bottom: 20px;
`;

const Blogs = () => {
    const dispatch = useDispatch();
    const { blogs, currentBlog, currentBlogId } = useSelector(
        (state) => state.content
    );
    const [blogIds, setBlogIds] = useState([]);
    const [htmlBlog, setHTMLBlog] = useState(<></>);
    const [searchItem, setSearchItem] = useState("");
    const count = 1;
    const blogsCount = blogIds.length;
    const pageCount = new Array(Math.ceil(blogsCount / count) || 1).fill(0);
    const pageLength = pageCount?.length;
    const brand = ["software", "Branding"];
    let desc, html;
    const handlePagination = (val) => {
        dispatch(contentActions.setCurrentBlogId(blogIds[val]));
    };
    useEffect(() => {
        desc =
            currentBlog?.blog_description &&
            JSON.parse(currentBlog?.blog_description);

        if (desc != null) {
            html = draftToHtmlPuri(
                convertToRaw(
                    EditorState.createWithContent(
                        convertFromRaw(desc)
                    )?.getCurrentContent()
                )
            );

            setHTMLBlog(html);
        }
    }, [currentBlog]);
    useEffect(() => {
        dispatch(getBlogsAction({ page: 1, searchItem }));
    }, [searchItem]);

    useEffect(() => {
        let ids = [];
        let allCurrentIds = [];
        if (searchItem) {
            allCurrentIds.push(...blogs?.map((blog) => blog.id));
            if (allCurrentIds.includes(currentBlogId)) {
                ids.push(...allCurrentIds);
            } else {
                ids.push(currentBlogId);
                ids.push(...allCurrentIds);
            }
        } else {
            ids.push(...blogs?.map((blog) => blog.id));
        }
        setBlogIds(ids);
    }, [blogs]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        dispatch(getBlogByIdAction(currentBlogId));
    }, [currentBlogId]);

    const SmallBlogDetail = ({ blog, ind }) => {
        let desc = () => {
            try {
                blog?.blog_description && JSON.parse(blog?.blog_description);
            } catch (e) {
                return {};
            }
        };
        const getValue = () => {
            try {
                return desc?.blocks?.length > 0 &&
                    desc?.blocks[0]?.text?.length > 200
                    ? `${desc?.blocks[0]?.text?.substring(0, 200)}...`
                    : desc?.blocks[0]?.text;
            } catch (e) {
                return "";
            }
        };
        return (
            <div className="Blog-card">
                <SimilarBlogs
                    onClick={() => {
                        dispatch(contentActions.setCurrentBlogId(blog?.id));
                        window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                >
                    <img
                        src={blog?.blog_image}
                        alt=""
                        className="otherImg object-cover"
                    />
                    <SimilarBlogContain className="">
                        <MiniBlogTitle>{blog?.blog_title}</MiniBlogTitle>
                        <MiniDescription>{getValue()}</MiniDescription>
                    </SimilarBlogContain>
                </SimilarBlogs>

                <div className="service-tags">
                    <div>
                        {brand.map((service) => {
                            return (
                                <div>
                                    <span>{service}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };
    return (
        <Container>
            <AllBlogs>
                <Header>
                    <Link to={"/?page=1"}>
                        <ArrowImg src={ArrowIcon} />
                    </Link>
                    <SearchInput>
                        <input
                            style={{
                                backgroundColor: "#f5f5f5",
                            }}
                            type="text"
                            placeholder="Search By Keywords"
                            value={searchItem}
                            onChange={(e) => setSearchItem(e.target.value)}
                        />
                        <SearchImg src={SearchIcon} />
                    </SearchInput>
                </Header>
                <p style={{ fontSize: "36px" }}>All blogs</p>
            </AllBlogs>
            <DataContainer className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                    <div className="Blog-card">
                        <BlogTitle>{currentBlog?.blog_title}</BlogTitle>
                        <img
                            src={currentBlog?.blog_image}
                            alt=""
                            className="Img object-cover"
                        />
                        <Description>
                            {currentBlog?.blog_description &&
                                JSON.parse(currentBlog?.blog_description)
                                    ?.blocks[0] ? (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: htmlBlog,
                                    }}
                                />
                            ) : (
                                ""
                            )}
                        </Description>
                        <div className="service-tags-blog">
                            <div>
                                {brand.map((service) => {
                                    return (
                                        <div>
                                            <span>{service}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <button
                            className="pagination-pre"
                            disabled={blogIds.indexOf(currentBlogId) + 1 == 1}
                            onClick={() => {
                                if (blogIds.indexOf(currentBlogId) + 1 == 1) {
                                    return;
                                } else {
                                    handlePagination(
                                        blogIds.indexOf(currentBlogId) - 1
                                    );
                                }
                            }}
                        >
                            {"<"} &nbsp; Previous
                        </button>
                        <div className="pagination-buttons d-flex">
                            {pageCount.map((val, ind) => (
                                <button
                                    className={
                                        blogIds.indexOf(currentBlogId) + 1 ==
                                            parseInt(ind) + 1
                                            ? "active-button"
                                            : ""
                                    }
                                    key={ind}
                                    onClick={() => handlePagination(ind)}
                                >
                                    {ind + 1}
                                </button>
                            ))}
                        </div>
                        <button
                            key={currentBlogId}
                            className="pagination-pre"
                            disabled={
                                blogIds.indexOf(currentBlogId) + 1 == pageLength
                            }
                            onClick={() => {
                                if (
                                    blogIds.indexOf(currentBlogId) + 1 ==
                                    pageLength
                                ) {
                                    return;
                                } else {
                                    handlePagination(
                                        blogIds.indexOf(currentBlogId) + 1
                                    );
                                }
                            }}
                        >
                            Next &nbsp; {">"}
                        </button>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <OtherBlogs>Other Blogs</OtherBlogs>
                    {blogs?.map((blog, i) =>
                        currentBlogId == blog.id ? null : (
                            <SmallBlogDetail blog={blog} ind={i} />
                        )
                    )}
                </div>
            </DataContainer>
        </Container>
    );
};

export default Blogs;
