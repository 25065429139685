import React, { useEffect, useState } from "react";
import Rating from "@material-ui/lab/Rating";
import { BsHeart, BsLink, BsChatLeftText, BsHeartFill } from "react-icons/bs";

import Radio from "@material-ui/core/Radio";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@material-tailwind/react/Modal";
import ModalBody from "@material-tailwind/react/ModalBody";
import BlueCheck from "../../../assets/blue-check.svg";
import { BsPeople } from "react-icons/bs";
import "./Fav.css";
import { useToast } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {
    contentActions,
    getAllFavouriteAction,
    getInTouchAction,
    markFavouriteAction,
    UnmarkFavouriteAction,
} from "../../../data/reducers/home/content";
// import { getUserProfile } from "../../../../data/reducers/auth/auth";
const Fav = ({ fav, i }) => {
    // const [value, setValue] = useState(fav?.avg_ratings);
    const { companies, companyCount, myCompany } = useSelector(
        (state) => state.content
    );

    const text = `Hi ${fav?.company_name}

I'd like to have a quick chat about using your service. Please let me know good time for us to speak. My contact information is below:

${myCompany?.company_name} 

${myCompany?.User?.email ? myCompany?.User?.email : ""}
    
${myCompany?.User?.mobileNo ? myCompany?.User?.mobileNo : ""}

Regards,
`;
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const [isMarked, setisMarked] = useState(false);
    const [selectedValue, setSelectedValue] = useState("both");

    const { allfavourites, allReviews } = useSelector((state) => state.content);
    const [openModel, setOpenModel] = useState(false);
    const [sentEmailModel, setSentEmailModel] = useState(false);
    const [content, setContent] = useState(text);
    const [modifiedTxt, setModifiedTxt] = useState(false);

    const toast = useToast();
    const { success, message, error } = useSelector((state) => state.content);

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const onChange = (e) => {
        setContent(e.target.value);
        setModifiedTxt(true);
    };

    const unmarkFavouriteCompany = (id) => {
        dispatch(UnmarkFavouriteAction(id));
        setisMarked(true);
    };
    const markFavouriteCompany = (id) => {
        dispatch(markFavouriteAction(id));
        setisMarked(true);
    };
    

    const getInTouchCompany = async (id) => {
        if (!fav?.User?.email) {
            toast({
                title: "The company has no contact details",
                description: "",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
            setOpenModel(false);
            return;
        }
        let response=await dispatch(getInTouchAction(id));
        if(response?.payload?.success){
            setOpenModel(false);
            setSentEmailModel(true);
        }else{
            setOpenModel(false);
            toast({
                title: `${response?.payload?.message}`,
                description: "",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
        }
        // toast({
        //     title: "Success",
        //     description: "Message Sent Successfully",
        //     isClosable: true,
        // });
    };

    return (
        <>
            {/* <div className="favourite-comp-cont"> */}
            <div className="col-xl-11 col-lg-12 col-md-12 col-12 mx-auto">
                <div className="fav-card mx-auto">
                    <div className="row">
                        <div className="col-xl-11 col-lg-10 col-md-10 col-12 favs-left">
                            <div className="top-layer">
                                <div className="d-flex ">
                                    <figure>
                                        <img
                                            src={
                                                fav?.company_logo
                                                    ? fav?.company_logo
                                                    : "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"
                                            }
                                            alt="Lorem"
                                        />
                                    </figure>
                                    <div className="fav-card-details ps-3">
                                        <Link
                                            to={`/company/${fav?.id}`}
                                            className="company_visit"
                                        >
                                            <h3>{fav?.company_name}</h3>
                                        </Link>

                                        <div className="d-flex">
                                            <Rating
                                                name="read-only"
                                                value={fav?.avg_ratings}
                                                readOnly
                                            />
                                            <span className="reviews-count">
                                                ({allReviews[i]?.length}
                                                {allReviews[i]?.length
                                                    ? " Reviews"
                                                    : " Review"}
                                                )
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* {fav?.Reviews?.length > 0 ? ( */}
                            <div
                                style={{ wordBreak: "break-all" }}
                                className="reviews-section"
                            >
                                {allReviews[i]?.slice(0, 1).map((rev, j) => {
                                    return (
                                        <div key={j}>
                                            <p className="reviews-text">
                                                {rev.review_text}
                                            </p>
                                            <p className="review-by">
                                                - {rev.company_name}
                                            </p>
                                        </div>
                                    );
                                })}

                                <Link to={`/reviews/${fav?.id}`}>
                                    <h6>See all reviews</h6>
                                </Link>
                            </div>
                            {/* ) : ( */}
                            {/* <p> */}
                            {/* {(fav?.about_company !== undefined ||
                                fav?.about_company !== null) &&
                                fav?.about_company} */}
                            {/* About */}
                            {/* </p> */}
                            {/* )} */}
                            <div className="fav-tags">
                                <div>
                                    <span>{fav?.Industry?.industry_name}</span>
                                </div>
                                {fav?.Services?.length > 0 &&
                                    fav?.Services?.map((s, i) => (
                                        <div key={i}>
                                            <span>
                                                {/* {s?.fav_name} */}
                                                {s.service_name}
                                            </span>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <div className="col-xl-1 col-lg-2 col-md-2 col-12 action-items">
                            <div className="d-flex">
                                <div className="action-icon">
                                    {
                                        allfavourites?.find((item)=>item?.id==fav?.id)?.id ?   <BsHeartFill
                                        color="red"
                                        onClick={() =>
                                            unmarkFavouriteCompany(fav?.id)
                                        }
                                    />:  <BsHeart
                                            color=""
                                            onClick={() =>
                                                markFavouriteCompany(fav?.id)
                                            }
                                        />
                                    }
                                    {/* <BsHeartFill
                                        color="red"
                                        onClick={() =>
                                            unmarkFavouriteCompany(fav?.id)
                                        }
                                    /> */}
                                </div>
                                <div className="action-icon">
                                    <Link
                                        to={`/company/${fav?.id}`}
                                        target="_blank"
                                    >
                                        <BsLink />
                                    </Link>
                                </div>
                                <div className="action-icon">
                                    <BsChatLeftText
                                        onClick={() =>
                                            // getInTouchCompany(
                                            //     fav?.id
                                            // )
                                            setOpenModel(true)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
            <div className="modal-wrapper">
                <Modal
                    size="lg"
                    active={openModel}
                    toggler={() => {
                        setOpenModel(false);
                    }}
                >
                    <ModalBody>
                        <div className="Touch-body">
                            <div className="top-heading">
                                <div className="icon-wrapper">
                                    <BsPeople />
                                </div>
                                <h1>Get in Touch</h1>
                            </div>
                            <p className="get-free-ad-credit">Send to:</p>
                            <div>
                                <div className="companyDetail">
                                    {fav?.User?.email}
                                </div>
                                {fav?.User?.mobileNo && (
                                    <div className="companyDetail">
                                        {fav?.User?.mobileNo
                                            ? fav?.User?.mobileNo
                                            : ""}
                                    </div>
                                )}
                            </div>

                            <div className="input-section">
                                <div className="mail-content">
                                    <textarea
                                        name="emailcontent"
                                        id="emailcontent"
                                        cols="30"
                                        rows="8"
                                        value={content}
                                        disabled
                                        onChange={(e) => onChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-space-between">
                                {fav?.User?.mobileNo && (
                                    <div className="d-flex">
                                        <span>
                                            <Radio
                                                checked={
                                                    selectedValue === "email"
                                                }
                                                onChange={handleChange}
                                                value="email"
                                                name="radio-buttons"
                                                inputProps={{
                                                    "aria-label": "EMAIL",
                                                }}
                                                color="primary"
                                                size="small"
                                            />
                                            <span className="Radio-label">
                                                Send via Email
                                            </span>
                                        </span>

                                        <span>
                                            <Radio
                                                checked={
                                                    selectedValue === "phone"
                                                }
                                                onChange={handleChange}
                                                value="phone"
                                                name="radio-buttons"
                                                inputProps={{
                                                    "aria-label": "PHONE",
                                                }}
                                                color="primary"
                                                size="small"
                                            />
                                            <span className="Radio-label">
                                                Send via Phone
                                            </span>
                                        </span>

                                        <span>
                                            <Radio
                                                checked={
                                                    selectedValue === "both"
                                                }
                                                onChange={handleChange}
                                                value="both"
                                                name="radio-buttons"
                                                inputProps={{
                                                    "aria-label": "BOTH",
                                                }}
                                                color="primary"
                                                size="small"
                                            />
                                            <span className="Radio-label">
                                                Send via both
                                            </span>
                                        </span>
                                    </div>
                                )}

                                <div
                                    className={
                                        fav?.User?.mobileNo
                                            ? ""
                                            : "button-not-radio"
                                    }
                                >
                                    <button
                                        className="send-button"
                                        onClick={() => {
                                            getInTouchCompany();
                                        }}
                                    >
                                        Send
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
            <div className="modal-wrapper">
                <Modal
                    size="lg"
                    active={sentEmailModel}
                    toggler={() => {
                        setSentEmailModel(false);
                    }}
                >
                    <ModalBody>
                        <div className="sent-popup">
                            <img src={BlueCheck} />
                        </div>
                        <div className="sent">Your message has been sent!</div>
                    </ModalBody>
                </Modal>
            </div>
        </>
    );
};

export default Fav;
