import React, { useEffect, useState } from "react";
import Rating from "@material-ui/lab/Rating";
import Radio from "@material-ui/core/Radio";
import { BsHeart, BsLink, BsChatLeftText, BsHeartFill } from "react-icons/bs";
import Modal from "@material-tailwind/react/Modal";
import ModalBody from "@material-tailwind/react/ModalBody";
import { BsPeople } from "react-icons/bs";
import BlueCheck from "../../../../assets/blue-check.svg";

import { useDispatch, useSelector } from "react-redux";
import "./Service.css";
import {
    getInTouchAction,
    getMyCompanyByIdAction,
    markFavouriteAction,
    UnmarkFavouriteAction,
} from "../../../../data/reducers/home/content";
import { useToast } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getAllUserService } from "../../../../data/services/auth/auth";
import ReactStars from "react-rating-stars-component";
import { addReview } from "../../../../data/reducers/company/add";
import { Redirect } from "react-router-dom";
import { getSocialAction } from "../../../../data/reducers/profile/profile";
import { getUserProfile } from "../../../../data/reducers/auth/auth";
import { postCompanySearchInfo } from "../../../../data/services/home/content";

const Service = ({ service, myCompany }) => {
    //     const text = `Hi ${service?.company_name}

    // I'd like to have a quick chat about using your service. please let me know good time for us to speak. my contact information is below:

    // ${myCompany?.company_name}

    // ${myCompany?.User?.email}

    // ${myCompany?.User?.mobileNo == null ? "" : myCompany?.User?.mobileNo}

    // Regards,
    // `;
    const [value, setValue] = useState(service?.avg_ratings);
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const [isMarked, setisMarked] = useState(false);
    const [openModel, setOpenModel] = useState(false);
    const [sentEmailModel, setSentEmailModel] = useState(false);
    const [modifiedTxt, setModifiedTxt] = useState(false);
    const [content, setContent] = useState("");
    const [selectedValue, setSelectedValue] = React.useState("email");
    const toast = useToast();
    // const { company } = useSelector((state) => state.content);
    // console.log("company", company);
    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [showModal4, setShowModal4] = useState(false);
    const [allUserEmail, setAllUserEmail] = useState([]);
    const [reviewStar, setReviewStar] = useState(0);
    const [reviewText, setReviewText] = useState("");
    const [showDiv, setShowDiv] = useState(false);
    const [allUser, setAllUser] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        const text = `Hi ${service?.company_name?.toUpperCase()}

        I'd like to have a quick chat about using your service. Please let me know good time for us to speak. My contact information is below:
        
        ${myCompany?.company_name} 
        
        ${myCompany?.User?.email ? myCompany?.User?.email : ""}
            
        ${myCompany?.User?.mobileNo ? myCompany?.User?.mobileNo : ""}
        
        Regards,
        `;
        setContent(text);
    }, [service]);
    // const getAllUser = async () => {
    //     const allUser = await getAllUserService();
    //     const temp = allUser.users.map((user) => user.email);
    //     setAllUser(temp);
    // };
    const ratingChanged = (newRating) => {
        setReviewStar(newRating);
    };
    // useEffect(() => {
    //     dispatch(getMyCompanyByIdAction(id));
    //     dispatch(getSocialAction());
    // }, [dispatch]);
    // useEffect(() => {
    //     getAllUser();
    // }, []);
    const onDone = async () => {
        const res = await dispatch(
            addReview({
                user_id: user.id,
                company_id: service?.id,
                review_given: reviewStar,
                review_text: reviewText,
                bussiness_image: myCompany?.company_logo,
                reviewer_company_id: user?.company_id,
            })
        );
        if (res) {
            dispatch(getUserProfile());
        }
    };
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };
    const markFavouriteCompany = () => {
        dispatch(markFavouriteAction(service?.id));
        setisMarked(!isMarked);
    };
    const onChange = (e) => {
        setContent(e.target.value);
        setModifiedTxt(true);
    };

    const unmarkFavouriteCompany = () => {
        dispatch(UnmarkFavouriteAction(service?.id));
        setisMarked(!isMarked);
    };

    const getInTouchCompany = async () => {
        if (!service?.User?.email) {
            toast({
                title: "The company has no contact details",
                description: "",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
            setOpenModel(false);
            return;
        }
        let response=await dispatch(
            getInTouchAction({
                serviceId: service.id,
                myCompanyId: myCompany.id,
                selectedValue,
            })
        );
        if(response?.payload?.success){
            setOpenModel(false);
            setSentEmailModel(true);
        }else{
            setOpenModel(false);
            toast({
                title: `${response?.payload?.message}`,
                description: "",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
        }
    };

    useEffect(() => {
        setisMarked(service?.Favourites?.find((e) => e?.user_id === user?.id));
    }, [service, service?.Favourites, user?.id]);

    return (
        <>
            <div className="service-card mx-auto">
                <div className="row">
                    <div className="col-xl-11 col-lg-10 col-md-10 col-12 services-left">
                        <div className="top-layer">
                            <div className="d-flex">
                                <figure>
                                    <img
                                        src={
                                            service?.company_logo
                                                ? service.company_logo
                                                : "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"
                                        }
                                        alt="Lorem"
                                    />
                                </figure>
                                <div className="service-card-details ps-3 curser-pointer">
                                    {/* <h3 onClick={() => setShowModal1(true)}>
                                        {service?.company_name}
                                    </h3> */}
                                    <Link
                                        to={`/company/${service?.id}`}
                                        className="company_visit"
                                    >
                                        <h3 onClick={async ()=>{
                                               try{
                                                   const response=await postCompanySearchInfo({
                                                    company_id:service?.id,
                                                    search_query:service?.company_name 
                                                   })
                                               }catch(err){

                                               }
                                        }}>{service?.company_name}</h3>
                                    </Link>

                                    <div className="d-flex">
                                        <Rating
                                            name="read-only"
                                            value={value}
                                            readOnly
                                            precision={0.25}
                                        />
                                        <span className="reviews-count">
                                            ({service?.backingCount} &nbsp;
                                            {service?.backingCount > 1
                                                ? "Backings"
                                                : "Backings"}
                                            )
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {service?.backingCount> 0 ? (
                            <div className="reviews-section">
                                {/* <p className="reviews-text">
                                    "
                                    {
                                        service?.Reviews[
                                            service?.Reviews?.length - 1
                                        ]?.review_text
                                    }
                                    "
                                </p> */}
                                <p className="about_text">
                                    {(service?.about_company !== undefined ||
                                        service?.about_company !== null) &&
                                        service?.about_company}
                                </p>
                                {/* <p
                                    onClick={() => {
                                        if (
                                            service?.Reviews[
                                                service?.Reviews?.length - 1
                                            ]?.reviewer_company_id
                                        )
                                            navigate(
                                                `/company/${
                                                    service?.Reviews[
                                                        service?.Reviews
                                                            ?.length - 1
                                                    ]?.reviewer_company_id
                                                }`
                                            );
                                    }}
                                    className="review-by cursor-pointer"
                                >
                                    -
                                    {
                                        service?.Reviews[
                                            service?.Reviews?.length - 1
                                        ]?.company_name
                                    }
                                </p> */}
                                {/* <Link to={`/company/${service?.id}`}>
                                    <h6>See all backings</h6>
                                </Link> */}
                            </div>
                        ) : (
                            <p className="about_text">
                                {(service?.about_company !== undefined ||
                                    service?.about_company !== null) &&
                                    service?.about_company}
                            </p>
                        )}

                        <div className="service-tags">
                            <div>
                                <span>{service?.Industry?.industry_name}</span>
                            </div>
                            {service?.Services?.length > 0 &&
                                service?.Services?.map((s,index) => (
                                    <div key={index}>
                                        <span>{s?.service_name}</span>
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className="col-xl-1 col-lg-2 col-md-2 col-12 action-items">
                        <div className="d-flex justify-center items-center p">
                            <div className="action-icon">
                                {isMarked ? (
                                    <BsHeartFill
                                        color="red"
                                        onClick={() => unmarkFavouriteCompany()}
                                    />
                                ) : (
                                    <BsHeart
                                        color="#C11717"
                                        onClick={() => markFavouriteCompany()}
                                    />
                                )}
                            </div>
                            <div className="action-icon">
                                <Link
                                    to={`/company/${service?.id}`}
                                    className="company_visit"
                                >
                                    <BsLink />
                                </Link>
                            </div>
                            <div className="action-icon">
                                <BsChatLeftText
                                    onClick={() => setOpenModel(true)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="user-profile-modal modal-wrapper">
                <Modal
                    size="lg"
                    active={showModal1}
                    toggler={() => {
                        console.log("1");
                        // window.open(`/company/${service?.id}`, "_self");
                        navigate(`/company/${service?.id}`);
                        setShowModal1(false);
                    }}
                    className="profile-tailwind-modal"
                >
                    <ModalBody>
                        <div className="user-email-modal">
                            <div className="user-email-modal-label">
                                <label>
                                    Please enter your business email ID
                                </label>
                            </div>
                            <div className="user-email-modal-input">
                                <input
                                    value={allUserEmail}
                                    type="email"
                                    onChange={(e) =>
                                        setAllUserEmail(e.target.value)
                                    }
                                    placeholder="Your email ID"
                                />
                            </div>
                            <div className="user-email-modal-button">
                                <button
                                    onClick={() => {
                                        if (
                                            allUserEmail.length > 0 &&
                                            allUserEmail.includes("@")
                                        ) {
                                            setShowModal1(false);
                                            // <Link
                                            //     to={`/company/${service?.id}`}
                                            // />

                                            if (
                                                allUser.includes(allUserEmail)
                                            ) {
                                                setShowModal3(true);
                                            } else {
                                                setShowModal2(true);
                                            }
                                        }
                                    }}
                                >
                                    Done
                                </button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal
                    size="lg"
                    active={showModal2}
                    toggler={() => {
                        // window.open(`/company/${service?.id}`, "_self");
                        navigate(`/company/${service?.id}`);
                        setShowModal2(false);
                    }}
                    className="profile-tailwind-modal"
                >
                    <ModalBody>
                        <div className="proceed-model-body">
                            <div className="proceed-model-text">
                                <p>
                                    Looks like you are new here! Please register
                                    your details on the waiting list (takes ~ 30
                                    sec time) to proceed with your endorsement
                                </p>
                            </div>
                            <div className="proceed-model-button">
                                <button
                                    onClick={() => {
                                        window.open(
                                            "https://hli2pvo6rp1.typeform.com/to/a03nixvp",
                                            "_blank"
                                        );

                                        setTimeout(() => {
                                            setShowModal2(false);
                                            setShowModal3(true);
                                        }, 3000);
                                    }}
                                >
                                    Proceed
                                </button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                <div className="rate-modal-container modal-wrapper">
                    <Modal
                        size="lg"
                        active={showModal3}
                        toggler={() => {
                            // window.open(`/company/${service?.id}`, "_self");
                            navigate(`/company/${service?.id}`);
                            setShowModal3(false);
                        }}
                        className="profile-tailwind-modal"
                    >
                        <ModalBody>
                            <div className="rate-model-body">
                                <div className="rate-model-body-head">
                                    <p>Rate - {service?.company_name}</p>
                                </div>
                                {showDiv && (
                                    <div className="rate-model-body-error">
                                        <p>Please complete all fields.</p>
                                    </div>
                                )}
                                <div className="rate-model-body-container">
                                    <div className="rate-model-body-star">
                                        <ReactStars
                                            count={5}
                                            onChange={ratingChanged}
                                            size={50}
                                            emptyIcon={
                                                <i className="far fa-star"></i>
                                            }
                                            fullIcon={
                                                <i className="fa fa-star"></i>
                                            }
                                            activeColor="#ffd700"
                                            value={reviewStar}
                                        />
                                    </div>
                                    <div className="rate-model-body-input">
                                        <input
                                            type="text"
                                            placeholder="Your Company Name"
                                        />
                                    </div>
                                    <div className="rate-model-body-textarea">
                                        <textarea
                                            name="reviewText"
                                            placeholder="Write your review here..."
                                            id="rate-textarea"
                                            cols="20"
                                            rows="7"
                                            maxLength="300"
                                            value={reviewText}
                                            onChange={(e) =>
                                                setReviewText(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="rate-model-body-count">
                                        {reviewText ? reviewText.length : 0}
                                        /300 characters left
                                    </div>
                                    <div className="rate-model-body-button">
                                        <button
                                            onClick={() => {
                                                if (reviewStar && reviewText) {
                                                    setShowModal3(false);
                                                    setShowModal4(true);
                                                    onDone();
                                                } else {
                                                    setShowDiv(true);
                                                }
                                            }}
                                        >
                                            Done
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
                <Modal
                    size="lg"
                    active={showModal4}
                    toggler={() => {
                        // window.open(`/company/${service?.id}`, "_self");
                        navigate(`/company/${service?.id}`);
                        setShowModal4(false);
                    }}
                    className="profile-tailwind-modal"
                >
                    <ModalBody>
                        <div className="success-model-body">
                            <div className="success-model-text">
                                <p>
                                    <b>
                                        Congratulations! You have earned $100
                                        advertising credit* that can be used on
                                        BackBone to promote your business!
                                    </b>
                                    <br />
                                    *requires business to be a member or on a
                                    trial to access the credit
                                </p>
                            </div>
                            <div className="success-model-button">
                                <button
                                    onClick={() => {
                                        navigate(`/company/${service?.id}`);
                                        // window.open(
                                        //     `/company/${service?.id}`,
                                        //     "_self"
                                        // );
                                        setShowModal4(false);
                                    }}
                                >
                                    Done
                                </button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div> */}
            <div className="modal-wrapper">
                <Modal
                    size="lg"
                    active={openModel}
                    toggler={() => {
                        setOpenModel(false);
                    }}
                >
                    <ModalBody>
                        <div className="Touch-body">
                            <div className="top-heading">
                                <div className="icon-wrapper">
                                    <BsPeople />
                                </div>
                                <h1>Get in Touch</h1>
                            </div>
                            <p className="get-free-ad-credit">Send to:</p>
                            <div>
                                <div className="companyDetail">
                                    {service?.User?.email}
                                </div>
                                {service?.User?.mobileNo && (
                                    <div className="companyDetail">
                                        {service?.User?.mobileNo
                                            ? service?.User?.mobileNo
                                            : ""}
                                    </div>
                                )}
                            </div>

                            <div className="input-section">
                                <div className="mail-content">
                                    <textarea
                                        name="emailcontent"
                                        id="emailcontent"
                                        cols="30"
                                        rows="8"
                                        value={content}
                                        disabled
                                        onChange={(e) => onChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-space-between">
                                {service?.User?.email && (
                                    <div className="d-flex">
                                        <span>
                                            <Radio
                                                checked={
                                                    selectedValue === "email"
                                                }
                                                onChange={handleChange}
                                                value="email"
                                                name="radio-buttons"
                                                inputProps={{
                                                    "aria-label": "EMAIL",
                                                }}
                                                color="primary"
                                                size="small"
                                            />
                                            <span className="Radio-label">
                                                Send via Email
                                            </span>
                                        </span>
                                        {service?.User?.mobileNo && (
                                            <>
                                                <span>
                                                    <Radio
                                                        checked={
                                                            selectedValue ===
                                                            "phone"
                                                        }
                                                        onChange={handleChange}
                                                        value="phone"
                                                        name="radio-buttons"
                                                        inputProps={{
                                                            "aria-label":
                                                                "PHONE",
                                                        }}
                                                        color="primary"
                                                        size="small"
                                                    />
                                                    <span className="Radio-label">
                                                        Send via Phone
                                                    </span>
                                                </span>

                                                <span>
                                                    <Radio
                                                        checked={
                                                            selectedValue ===
                                                            "both"
                                                        }
                                                        onChange={handleChange}
                                                        value="both"
                                                        name="radio-buttons"
                                                        inputProps={{
                                                            "aria-label":
                                                                "BOTH",
                                                        }}
                                                        color="primary"
                                                        size="small"
                                                    />
                                                    <span className="Radio-label">
                                                        Send via both
                                                    </span>
                                                </span>
                                            </>
                                        )}
                                    </div>
                                )}
                                <div
                                    className={
                                        service?.User?.email
                                            ? ""
                                            : "button-not-radio"
                                    }
                                >
                                    <button
                                        className="send-button"
                                        onClick={() => {
                                            getInTouchCompany();
                                        }}
                                    >
                                        Send
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal
                    size="lg"
                    active={sentEmailModel}
                    toggler={() => {
                        setSentEmailModel(false);
                    }}
                >
                    <ModalBody>
                        <div className="sent-popup">
                            <img src={BlueCheck} />
                        </div>
                        <div className="sent">Your message has been sent!</div>
                    </ModalBody>
                </Modal>
            </div>
        </>
    );
};

export default Service;
