import { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useToast } from "@chakra-ui/react";

import Login_Left_Frame_2 from "../../../assets/login.jpg";
import ForgotPassword from "./ForgotPassword";
import Button from "@material-tailwind/react/Button";
import logo from "../../../assets/backbone-logo.svg";

import "./Login.css";

import { useDispatch } from "react-redux";
import {
    authActions,
    getUserProfile,
    login,
} from "../../../data/reducers/auth/auth";
import { useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { GOOGLE_CAPTCHA_KEY, WORDPRESS_URL } from "../../../config/api";
import { useNavigate } from "react-router-dom";
import DetailSteps from "../../../shared/Details/DetailSteps";
import { useSearchParams } from "react-router-dom";
import { getInvitedEvent } from "../../../data/reducers/events/events";

const Login = ({ setIsLogin }) => {
    const dispatch = useDispatch();
    const toast = useToast();
    const navigate = useNavigate();
    const { error } = useSelector((state) => state.auth);
    const [readOnly, setReadOnly] = useState(true);
    const [show, setShow] = useState(false);
    const [visible, setVisible] = useState(false);
    const [passed, setPassed] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const Reset = searchParams.get("reset");

    const [data, setData] = useState({
        email: "",
        password: "",
        reset: Reset,
    });
    const [dataError, setDataError] = useState("");

    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
        setDataError("");
        setEmailError(false);
        setPasswordError(false);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const Reset = searchParams.get("reset");

        if (!data?.email) {
            setEmailError(true);
        }

        if (!data?.password) {
            setPasswordError(true);
        }
        if (!data?.email || !data?.password) {
            if (!data?.email && !data?.password) {
                setDataError("Please enter email and password!");
            } else if (!data?.email) {
                setDataError("Please enter an email!");
            } else if (!data?.password) {
                setDataError("Please enter an password!");
            }
        }

        if (data?.email && data?.password) {
            try {
                const { payload } = await dispatch(login(data));
                if (payload?.success) {
                    dispatch(getUserProfile());
                    // navigate("/?page=1");

                    if (Reset === "true") {
                        dispatch(authActions.setIsReset(true));
                        navigate("/auth/change-password");
                    } else if (localStorage.getItem("event")) {
                        dispatch(
                            getInvitedEvent({
                                id: localStorage.getItem("event"),
                            })
                        );
                        navigate("/upcoming-events");
                        localStorage.removeItem("event");
                    } else if (localStorage.getItem("redirectUrl")) {
                        const url = localStorage.getItem("redirectUrl");
                        localStorage.removeItem("redirectUrl");
                        const companyId = url.split("/company/")[1];
                        navigate(`/company/${companyId}`);
                    } else {
                        navigate("/dashboard");
                    }
                } else {
                    if (payload?.error) {
                        setDataError(payload?.error);
                    } else setDataError("Something went wrong!");
                }
            } catch (e) {
                setDataError("Something went wrong!");
            }
        }
    };

    useEffect(() => {
        if (error) {
            toast({
                title: "Error",
                description: error,
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
            dispatch(authActions.reset());
        }
    }, [dispatch, toast, error]);

    return (
        <div>
            {/* Modal */}
            <ForgotPassword visible={visible} setVisible={setVisible} />

            <div className="login-container">
                <div className="login-left">
                    <img src={Login_Left_Frame_2} alt="Login Frame" />
                </div>

                <div className="login-right">
                    <div className="logo-main">
                        {WORDPRESS_URL ? (
                            <a href={WORDPRESS_URL}>
                                <img src={logo} alt="logo" />
                            </a>
                        ) : (
                            <img
                                src={logo}
                                alt="logo"
                                onClick={() => navigate("/")}
                            />
                        )}
                    </div>

                    <div className="rest-body">
                        <div className="login-right-heading">
                            <h1>Log in</h1>
                            {/* <DetailSteps activeStep={0} /> */}
                        </div>

                        <form onSubmit={onSubmit}>
                            <div className="login-right-form">
                                {dataError && (
                                    <div className="error-box">{dataError}</div>
                                )}
                                <div className="form-group">
                                    <label htmlFor="email">Email ID*</label>
                                    <span className="shared-email">
                                        A shared email ID may be used if
                                        multiple users require access.
                                    </span>
                                    <input
                                        type="email"
                                        name="email"
                                        autoComplete="off"
                                        className={
                                            emailError ? "error-input" : ""
                                        }
                                        placeholder="example@gmail.com"
                                        onChange={(e) => onChange(e)}
                                        readOnly={readOnly}
                                        onFocus={() => setReadOnly(false)}
                                        onBlur={() => setReadOnly(true)}
                                        onTouchStart={() => setReadOnly(false)}
                                    />
                                </div>
                                <div className="form-group">
                                    {show ? (
                                        <AiOutlineEye
                                            fontSize={24}
                                            color="#8d8d8d"
                                            onClick={() => setShow(!show)}
                                        />
                                    ) : (
                                        <AiOutlineEyeInvisible
                                            fontSize={24}
                                            color="#8d8d8d"
                                            onClick={() => setShow(!show)}
                                        />
                                    )}

                                    <label htmlFor="password">Password*</label>
                                    <input
                                        type={show ? "text" : "password"}
                                        name="password"
                                        className={
                                            passwordError ? "error-input" : ""
                                        }
                                        placeholder="********"
                                        onChange={(e) => onChange(e)}
                                    />
                                </div>
                                <div className="mb-3">
                                    <span
                                        className="forgot-password"
                                        onClick={() => setVisible(true)}
                                    >
                                        Forgot Password?
                                    </span>
                                </div>
                                <ReCAPTCHA
                                    sitekey={GOOGLE_CAPTCHA_KEY}
                                    onChange={() => setPassed(true)}
                                    theme="light"
                                    type="image"
                                />
                                <div
                                    className="submit-button"
                                    style={{
                                        paddingBottom: "1rem",
                                    }}
                                >
                                    <Button
                                        color="blue"
                                        buttonType="filled"
                                        size="regular"
                                        rounded={false}
                                        type="submit"
                                        disabled={!passed}
                                    >
                                        Continue
                                    </Button>
                                </div>
                                <p className="dont-have-account">
                                    Don't have an account?
                                </p>
                                <p className="create-new-account">
                                    <span onClick={() => setIsLogin(false)}>
                                        Click here
                                    </span>{" "}
                                    to create new account
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
