import React, { useEffect, useState } from "react";
import ContactBg from "../../../assets/contact-bg.svg";

import Button from "@material-tailwind/react/Button";
import "@material-tailwind/react/tailwind.css";

import "./Contact.css";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import {
    contactUsActions,
    sendContact,
} from "../../../data/reducers/contact/contact";

const Contact = () => {
    const [data, setData] = useState({
        name: null,
        email: null,
        message: null,
    });

    const toast = useToast();

    const dispatch = useDispatch();
    const { error, message } = useSelector((state) => state.contact);

    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = () => {
        dispatch(sendContact(data));
    };

    useEffect(() => {
        if (message) {
            toast({
                title: "Success",
                description: message,
                status: "success",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
            dispatch(contactUsActions.reset());
            setData({ name: "", email: "", message: "" });
        }
        if (error) {
            toast({
                title: "Error",
                description: error,
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
            dispatch(contactUsActions.reset());
        }
    }, [dispatch, error, message, toast]);

    return (
        <div id="contact">
            <div className="container">
                <div className="row py-5">
                    <div className="col-md-12 col-lg-6 hide-mobile">
                        <figure className="p-5  d-flex h-100 justify-content-center align-items-center">
                            <img src={ContactBg} alt="Contact Background" />
                        </figure>
                    </div>
                    <div className="col-md-12 col-lg-6 col-12 contact-part">
                        <h1 className="contact-heading poppins">Contact Us</h1>
                        <div className="form-group my-4 text-base">
                            <label htmlFor="name">Name:</label>
                            <input
                                type="text"
                                name="name"
                                id="name"
                                value={data?.name}
                                onChange={onChange}
                            />
                        </div>
                        <div className="form-group my-4 text-base">
                            <label htmlFor="email">Email:</label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                value={data?.email}
                                onChange={onChange}
                            />
                        </div>
                        <div className="form-group my-4 text-base">
                            <label htmlFor="message">Your Message:</label>
                            <textarea
                                name="message"
                                id="message"
                                onChange={onChange}
                                value={data?.message}
                                rows={4}
                                maxLength={300}
                            ></textarea>
                            <span
                                style={{
                                    fontSize: "12px",
                                    color: "#474747",
                                }}
                            >
                                Max 300 Characters
                            </span>
                        </div>
                        <div className="d-flex justify-content-end">
                            <div className="submit-button text-xl font-bold">
                                <Button
                                    color="blue"
                                    buttonType="filled"
                                    size="regular"
                                    rounded={false}
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Send
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
