import React, { useState } from "react";
import BasicDetails1 from "./BasicDetails1";
import BasicDetails2 from "./BasicDetails2";

const Details = ({ setActiveStep, activeStep }) => {
    const [page, setPage] = useState(1);

    return (
        <div>
            {page === 1 ? (
                <BasicDetails1
                    setPage={setPage}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                />
            ) : (
                <BasicDetails2
                    setPage={setPage}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                />
            )}
        </div>
    );
};

export default Details;
