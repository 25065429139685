import React, { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import Modal from "@material-tailwind/react/Modal";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import ModalBody from "@material-tailwind/react/ModalBody";
import Button from "@material-tailwind/react/Button";
import successIcon from "../../../assets/Vector-check.svg";
import "./addEvent.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    addEvent,
    getEvents,
    updateEvent,
} from "../../../data/reducers/events/events";
import { useDispatch, useSelector } from "react-redux";
import { RiCreativeCommonsSaLine } from "react-icons/ri";

const AddEvent = ({ visible, setVisible, data }) => {
    const dispatch = useDispatch();
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [locationFormSubmitted, setLocationFormSubmitted] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [event, setEvent] = useState({
        name: "",
        type: "",
        mode: false,
        registrationLink: "",
        date: new Date(),
        hour: "-1",
        minute: "-1",
        time: "-1",
        about: "",
        streetName: "",
        suburb: "",
        pincode: "",
        city: "",
    });
    const [eventDate, setEventDate] = React.useState({
        date: new Date(),
    });

    const isToday = (date) => {
        const today = new Date();
        return (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        );
    };

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        // If the selected date is today, filter out times before the current time
        if (isToday(eventDate?.date)) {
            return selectedDate.getTime() > currentDate.getTime();
        }
        // If the selected date is not today, show all times
        return true;
    };
    const toast = useToast();
    const addEventData = useSelector((state) => state.events.success);

    useEffect(() => {
        if (data) {
            setEvent({
                id: data.id,
                name: data.eventName,
                type: data.eventType,
                mode: data.eventMode,
                registrationLink: data.registrationLink,
                date: new Date(data.eventDate),
                about: data.eventDescription,
                streetName: data.streetName,
                suburb: data.subUrb,
                pincode: data.pincode,
                city: data.city,
                company_id: data.company_id,
            });
            setIsEdit(true);
        }
    }, [data]);

    const handleClose = () => {
        setVisible(false);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        console.log(event.mode);
        if (
            event.name === "" ||
            event.type === "" ||
            event.date === "" ||
            event.about === "" ||
            (event.mode &&
                (event.registrationLink == "" ||
                    !event.registrationLink?.includes("http")))
        ) {
            toast({
                title: "Please Add the fields",
                position: "top-right",
                duration: 3000,
                status: "error",
                isClosable: true,
            });

            setVisible(true);
            return;
        } else {
            setVisible(false);
            event.mode === true
                ? setShowConfirmModal(true)
                : setShowLocationModal(true);
        }
    };

    const onSubmitLocation = (e) => {
        e.preventDefault();

        if (
            event.streetName == "" ||
            event.suburn == "" ||
            event.pincode == "" ||
            event.city == ""
        ) {
            toast({
                title: "Please Add the fields",
                position: "top-right",
                duration: 3000,
                status: "error",
                isClosable: true,
            });
            return;
        } else {
            setLocationFormSubmitted(true);
            setShowLocationModal(false);
            setShowConfirmModal(true);
        }
    };

    const handleAddEvent = (e) => {
        e.preventDefault();
        let payload = {
            ...(event.id && { id: event.id }),
            eventName: event.name,
            eventType: event.type,
            eventMode: event.mode,
            registrationLink: event.registrationLink,
            eventDate: new Date(event.date).toISOString(),
            eventDescription: event.about,
            city: event.mode ? "" : event.city,
            pincode: event.mode ? "" : event.pincode,
            subUrb: event.mode ? "" : event.suburb,
            streetName: event.mode ? "" : event.streetName,
        };
        isEdit ? dispatch(updateEvent(payload)) : dispatch(addEvent(payload));
        setShowConfirmModal(false);
        setShowSuccessModal(true);
        if (!isEdit) {
            setEvent({
                name: "",
                type: "",
                mode: false,
                registrationLink: "",
                date: new Date(),
                hour: "-1",
                minute: "-1",
                time: "-1",
                about: "",
                streetName: "",
                suburb: "",
                pincode: "",
                city: "",
            });
        }
    };

    return (
        <div className="modal-wrapper-addEvents">
            <Modal size="md" backdrop active={visible} toggler={handleClose}>
                <ModalBody>
                    <h1 className="text-gray-900 text-2xl font-bold mt-0 mb-3 text-center">
                        {isEdit ? "Update Event" : "Add Event"}
                    </h1>
                    <form
                        onSubmit={onSubmit}
                        className="row"
                        autoComplete="off"
                    >
                        <div className="col-12">
                            <div className="form-group mb-3">
                                <label htmlFor="eventname">Event Name</label>
                                <input
                                    type="text"
                                    name="eventname"
                                    value={event.name}
                                    className={
                                        formSubmitted && event.name == ""
                                            ? "event-error-input"
                                            : "event-input"
                                    }
                                    onChange={(e) =>
                                        setEvent({
                                            ...event,
                                            name: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group mb-3">
                                <label htmlFor="eventType">Event Type</label>
                                <input
                                    type="text"
                                    name="eventType"
                                    className={
                                        formSubmitted && event.type == ""
                                            ? "event-error-input"
                                            : "event-input"
                                    }
                                    value={event.type}
                                    onChange={(e) =>
                                        setEvent({
                                            ...event,
                                            type: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group mb-3">
                                <label htmlFor="eventMode">Event Mode</label>
                                <div className="d-flex mb-1">
                                    <div className="radinput">
                                        <input
                                            className="radio-input"
                                            type="radio"
                                            value="online"
                                            onChange={(e) =>
                                                e.target.checked &&
                                                setEvent({
                                                    ...event,
                                                    mode: true,
                                                })
                                            }
                                            name="eventMode"
                                            checked={event.mode}
                                        />
                                    </div>
                                    <div className="label-input">
                                        <label className="label-text">
                                            Online
                                        </label>
                                    </div>
                                </div>
                                <div className="d-flex mb-1">
                                    <div className="radinput">
                                        <input
                                            className="radio-input"
                                            type="radio"
                                            value="offline"
                                            onChange={(e) =>
                                                e.target.checked &&
                                                setEvent({
                                                    ...event,
                                                    mode: false,
                                                })
                                            }
                                            name="eventMode"
                                            checked={!event.mode}
                                        />
                                    </div>
                                    <div className="label-input">
                                        <label className="label-text">
                                            Offline
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group mb-3">
                                <label htmlFor="registrationLink">
                                    Registration Link
                                </label>
                                <input
                                    type="text"
                                    name="registrationLink"
                                    placeholder="https://example.com"
                                    className={
                                        formSubmitted &&
                                        event.mode &&
                                        (event.registrationLink == "" ||
                                            !event.registrationLink?.includes(
                                                "http"
                                            ))
                                            ? "event-error-input"
                                            : "event-input"
                                    }
                                    value={event.registrationLink}
                                    onChange={(e) =>
                                        setEvent({
                                            ...event,
                                            registrationLink: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row g-0">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="date">Date</label>
                                        <DatePicker
                                            selected={event.date}
                                            onChange={(date) =>
                                                setEvent({
                                                    ...event,
                                                    date: date,
                                                })
                                            }
                                            showTimeSelect
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            minDate={new Date()}
                                            filterTime={filterPassedTime} 
                                        />
                                        {/* <input
                                            type="date"
                                            name="date"
                                            className={
                                                formSubmitted &&
                                                event.type == ""
                                                    ? "event-error-input"
                                                    : "event-input"
                                            }
                                            value={event.date}
                                            onChange={(e) =>
                                                setEvent({
                                                    ...event,
                                                    date: e.target.value,
                                                })
                                            }
                                        /> */}
                                    </div>
                                </div>
                                {/* <div className="col-lg-5">
                                    <div className="form-group mb-3 me-2 ms-2">
                                        <label>Time</label>
                                        <div className="d-flex justify-between">
                                            <select
                                                name="hour"
                                                id="hour"
                                                className={
                                                    formSubmitted &&
                                                    event.hour == "-1"
                                                        ? "event-error-input form-select event-input form-select-lg me-1"
                                                        : "form-select event-input form-select-lg me-1"
                                                }
                                                value={event.hour}
                                                onChange={(e) =>
                                                    setEvent({
                                                        ...event,
                                                        hour: e.target.value,
                                                    })
                                                }
                                            >
                                                <option
                                                    value="-1"
                                                    className="option"
                                                >
                                                    Hour
                                                </option>
                                                {[...Array(13).keys()].map(
                                                    (id, idx) => {
                                                        return (
                                                            <option
                                                                value={id}
                                                                key={idx}
                                                                className="option"
                                                            >
                                                                {id}
                                                            </option>
                                                        );
                                                    }
                                                )}
                                            </select>
                                            <select
                                                name="minute"
                                                id="minute"
                                                className={
                                                    formSubmitted &&
                                                    event.minute == "-1"
                                                        ? "event-error-input form-select event-input form-select-lg me-1"
                                                        : "form-select event-input form-select-lg me-1"
                                                }
                                                value={event.minute}
                                                onChange={(e) =>
                                                    setEvent({
                                                        ...event,
                                                        minute: e.target.value,
                                                    })
                                                }
                                            >
                                                <option
                                                    value="-1"
                                                    className="option"
                                                >
                                                    Minute
                                                </option>
                                                {[...Array(60).keys()].map(
                                                    (id, idx) => {
                                                        return (
                                                            <option
                                                                value={id + 1}
                                                                key={idx}
                                                                className="option"
                                                            >
                                                                {id + 1}
                                                            </option>
                                                        );
                                                    }
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="button-group">
                                        <Button
                                            color={
                                                event.time === "AM"
                                                    ? "blue"
                                                    : "black"
                                            }
                                            buttonType={
                                                event.time === "AM"
                                                    ? "filled"
                                                    : "outline"
                                            }
                                            size="small"
                                            type="button"
                                            onClick={() =>
                                                setEvent({
                                                    ...event,
                                                    time: "AM",
                                                })
                                            }
                                        >
                                            AM
                                        </Button>
                                        <Button
                                            color={
                                                event.time === "PM"
                                                    ? "blue"
                                                    : "black"
                                            }
                                            buttonType={
                                                event.time === "PM"
                                                    ? "filled"
                                                    : "outline"
                                            }
                                            size="small"
                                            type="button"
                                            onClick={() =>
                                                setEvent({
                                                    ...event,
                                                    time: "PM",
                                                })
                                            }
                                        >
                                            PM
                                        </Button>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group mb-3">
                                <label htmlFor="about">About the event</label>
                                <div>
                                    <textarea
                                        className={
                                            formSubmitted && event.about == ""
                                                ? "event-error-input"
                                                : "event-input"
                                        }
                                        name="about"
                                        id="about"
                                        cols="30"
                                        rows="5"
                                        value={event.about}
                                        onChange={(e) =>
                                            setEvent({
                                                ...event,
                                                about: e.target.value,
                                            })
                                        }
                                        maxLength="550"
                                    />
                                </div>
                                <span>
                                    {550 -
                                        (event.about
                                            ? event.about.length
                                            : 0)}{" "}
                                    characters left
                                </span>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="d-flex justify-between btn-group">
                                <Button
                                    color="gray"
                                    buttonType="filled"
                                    onClick={handleClose}
                                    type="reset"
                                >
                                    Cancel
                                </Button>

                                <Button
                                    color="blue"
                                    buttonType="filled"
                                    size="regular"
                                    type="submit"
                                >
                                    {isEdit ? "Update" : "Add"}
                                </Button>
                            </div>
                        </div>
                    </form>
                </ModalBody>
            </Modal>

            <Modal
                size="md"
                backdrop
                active={showLocationModal}
                toggler={handleClose}
            >
                <ModalBody>
                    <h1 className="text-gray-900 text-2xl font-bold mt-0 mb-3 text-start">
                        Add event location
                    </h1>
                    <form
                        onSubmit={onSubmitLocation}
                        className="row"
                        autoComplete="off"
                    >
                        <div className="col-12">
                            <div className="form-group mb-3">
                                <label htmlFor="streetName">Street Name</label>
                                <input
                                    type="text"
                                    className={
                                        locationFormSubmitted &&
                                        event.streetName == ""
                                            ? "event-error-input"
                                            : "event-input"
                                    }
                                    name="streetName"
                                    value={event.streetName || ""}
                                    onChange={(e) =>
                                        setEvent({
                                            ...event,
                                            streetName: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group mb-3">
                                <label htmlFor="suburb">Suburb</label>
                                <input
                                    type="text"
                                    className={
                                        locationFormSubmitted &&
                                        event.suburb == ""
                                            ? "event-error-input"
                                            : "event-input"
                                    }
                                    name="suburb"
                                    value={event.suburb || ""}
                                    onChange={(e) =>
                                        setEvent({
                                            ...event,
                                            suburb: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group mb-3">
                                        <label htmlFor="pincode">Pincode</label>
                                        <input
                                            type="text"
                                            className={
                                                locationFormSubmitted &&
                                                event.pincode == ""
                                                    ? "event-error-input"
                                                    : "event-input"
                                            }
                                            name="pincode"
                                            value={event.pincode || ""}
                                            onChange={(e) => {
                                                e.target.value = e.target.value
                                                    .trimStart()
                                                    .replace(/[^0-9]/gi, "");
                                                if (e.target.value.length > 6)
                                                    return;
                                                setEvent({
                                                    ...event,
                                                    pincode: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-3">
                                        <label htmlFor="city">City</label>
                                        <input
                                            type="text"
                                            name="city"
                                            className={
                                                locationFormSubmitted &&
                                                event.city == ""
                                                    ? "event-error-input"
                                                    : "event-input"
                                            }
                                            value={event.city || ""}
                                            onChange={(e) => {
                                                e.target.value = e.target.value
                                                    .trimStart()
                                                    .replace(
                                                        /[^a-zA-Z\s]/gi,
                                                        ""
                                                    );
                                                setEvent({
                                                    ...event,
                                                    city: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="d-grid d-lg-flex justify-content-lg-between btn-group">
                                <Button
                                    color="gray"
                                    buttonType="filled"
                                    onClick={() => {
                                        setShowLocationModal(false);
                                        setVisible(true);
                                    }}
                                    type="button"
                                >
                                    Back
                                </Button>

                                <Button
                                    color="blue"
                                    buttonType="filled"
                                    size="regular"
                                    type="submit"
                                >
                                    {isEdit ? "Update" : "Add"}
                                </Button>
                            </div>
                        </div>
                    </form>
                </ModalBody>
            </Modal>

            <Modal
                size="md"
                backdrop
                active={showConfirmModal}
                toggler={handleClose}
            >
                <ModalBody>
                    <h1 className="text-gray-900 text-2xl font-bold mt-0 mb-3 text-center">
                        {isEdit
                            ? "Are you sure you want to update the event?"
                            : "Do you want to add event?"}
                    </h1>
                    <form onSubmit={handleAddEvent} className="row">
                        <div className="col-12">
                            <div className="d-flex justify-between btn-group">
                                <Button
                                    color="gray"
                                    buttonType="filled"
                                    onClick={() => {
                                        setShowConfirmModal(false);
                                        event.mode === "online"
                                            ? setVisible(true)
                                            : setShowLocationModal(true);
                                    }}
                                    type="button"
                                >
                                    Cancel
                                </Button>

                                <Button
                                    color="blue"
                                    buttonType="filled"
                                    size="regular"
                                    type="submit"
                                >
                                    {isEdit ? "Update" : "Add"}
                                </Button>
                            </div>
                        </div>
                    </form>
                </ModalBody>
            </Modal>

            <Modal
                size="md"
                backdrop
                active={showSuccessModal}
                toggler={handleClose}
            >
                <ModalBody>
                    <form className="row">
                        <div className="d-flex flex-column align-items-center success-modal">
                            <img
                                src={successIcon}
                                alt=""
                                width={50}
                                height={50}
                            />
                            <h3 className="mt-3 mb-2">Confirmed!</h3>
                            <p className="mb-5">
                                Your event has been scheduled!
                            </p>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="d-grid">
                                <Button
                                    color="blue"
                                    buttonType="filled"
                                    size="regular"
                                    type="submit"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowSuccessModal(false);
                                    }}
                                >
                                    Go back to events page
                                </Button>
                            </div>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default AddEvent;
