import React from "react";
import { useEffect, useState } from "react";
import mastercard from "../../assets/logos_mastercard.svg";
import addcard from "../../assets/Group 159.svg";
import redtick from "../../assets/red-tick.svg";
import { AiOutlineCreditCard, AiOutlineIdcard } from "react-icons/ai";
import "./PaymentReject.css";
import { useNavigate } from "react-router-dom";
import failed from "../../assets/paymentfailed.svg";
import DetailSteps from "../../shared/Details/DetailSteps";
import { detailsActions } from "../../data/reducers/details/details";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

const PaymentReject = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { activeStep } = useSelector((state) => state.details);
    dispatch(detailsActions.setActiveStep(3));
    const [showDiv, setShowDiv] = useState(false);

    return (
        <>
            <div className="payment-reject">
                <p
                    style={{
                        fontSize: "2rem",
                        textAlign: "center",
                        fontWeight: "700",
                        fontFamily: "Roboto",
                        marginTop: "2rem",
                    }}
                >
                    BackBone registration
                </p>
                <DetailSteps activeStep={activeStep} />
                <div className="payr-main-card-panel">
                    <div className="payment-reject-panel">
                        <div className="payment-failed-image">
                            <img src={failed} alt="confirm" />
                        </div>
                        <div className="payment-reject-card">
                            <div className="payment-reject-tick">
                                <img src={redtick} alt="" />
                            </div>
                            <div className="payment-reject-card-head">
                                Payment Unsuccessful
                            </div>
                            <div className="payment-reject-card-body">
                                <span>
                                    Your payment was
                                    {/* of <b>$150</b> <br /> */}
                                    <br /> not completed.
                                </span>
                            </div>
                        </div>
                        <div className="reject-retry-button">
                            <button onClick={() => navigate("/details")}>
                                Back to payment screen
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default PaymentReject;
