import React from "react";
import Modal from "@material-tailwind/react/Modal";
import ModalBody from "@material-tailwind/react/ModalBody";
import ReferFriendIcon from "../../../assets/ReferFriend.svg";
import EndorsementIcon from "../../../assets/EndorsementIcon.svg";
import styled from "styled-components";
import { useState } from "react";
import { useSelector } from "react-redux";
import ReferFriend from "../../Home/ReferFriend/ReferFriend";

const EarnCredit = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #5b5b5b;
`;

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-left: 50px;
    margin-bottom: 20px;
`;
const BodyContainer = styled.div`
    display: flex;
    max-width: 700px;
    margin-left: 50px;
    margin-right: 50px;
    gap: 50px;
`;
const LeftBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const RightBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const Button = styled.button`
    background: #067eed;
    border-radius: 5px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;
    padding: 7px 50px 7px 50px;
    margin-top: 30px;
    @media only screen and (max-width: 800px) and (min-width: 466px) {
        font-size: 10px;
        padding: 2px 25px 2px 25px;
    }
    @media only screen and (max-width: 466px) and (min-width: 400px) {
        font-size: 10px;
        padding: 2px 10px 2px 10px;
    }
`;
const Textdetail = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    color: #8d8d8d;
    margin-top: 20px;
    @media only screen and (max-width: 800px) and (min-width: 400px) {
        font-size: 12px;
        line-height: normal;
    }
    @media only screen and (max-width: 466px) and (min-width: 400px) {
        font-size: 9.5px;
    }
`;
const EndoImg = styled.img`
    width: 212px;
    height: 212px;
    @media only screen and (max-width: 800px) and (min-width: 400px) {
        width: 140px;
        height: 140px;
    }
`;
const ReferImg = styled.img`
    width: 212px;
    height: 212px;
    @media only screen and (max-width: 800px) and (min-width: 400px) {
        width: 140px;
        height: 140px;
    }
`;

const EarnCreditModal = (props) => {
    const { earnCreditModal, setEarnCreditModal, setReviewModal } = props;
    const [endorseModal, setEndorseModal] = useState(false);
    const [referFriend, setReferFriend] = useState(false);
    const { myCompany } = useSelector((state) => state.content);
    return (
        <>
            <div
                className="modal-wrapper"
                style={{ display: referFriend ? "none" : "content" }}
            >
                <Modal
                    size="lg"
                    active={earnCreditModal}
                    toggler={() => {
                        setEarnCreditModal(false);
                    }}
                >
                    <ModalBody>
                        <TopContainer>
                            <EarnCredit>Earn credits</EarnCredit>
                        </TopContainer>
                        <BodyContainer>
                            <LeftBodyContainer>
                                <ReferImg src={ReferFriendIcon} />
                                <Textdetail>
                                    Refer a friend and earn 1 month subscription
                                    credit when they join
                                </Textdetail>
                                <Button
                                    onClick={() => {
                                        // setEarnCreditModal(false);
                                        setReferFriend(true);
                                    }}
                                >
                                    Refer a friend
                                </Button>
                                {referFriend && (
                                    <ReferFriend
                                        id={myCompany?.id}
                                        openModel={referFriend}
                                        setOpenModel={setReferFriend}
                                    />
                                )}
                            </LeftBodyContainer>
                            <RightBodyContainer>
                                <EndoImg src={EndorsementIcon} />
                                <Textdetail>
                                    Request a customer review and earn $100
                                    bizbucks when they complete it
                                </Textdetail>
                                <Button
                                    onClick={() => {
                                        setEarnCreditModal(false);
                                        setReviewModal(true);
                                    }}
                                >
                                    Request a review
                                </Button>
                            </RightBodyContainer>
                        </BodyContainer>
                    </ModalBody>
                </Modal>
            </div>

            {referFriend && (
                <ReferFriend
                    id={myCompany?.id}
                    openModel={referFriend}
                    setOpenModel={(val) => {
                        setReferFriend(val);
                        setEarnCreditModal(val);
                    }}
                />
            )}
        </>
    );
};
export default EarnCreditModal;
