import { configureStore } from "@reduxjs/toolkit";

import details from "./reducers/details/details";
import auth from "./reducers/auth/auth";
import contact from "./reducers/contact/contact";
import advertisement from "./reducers/advertisement/advertisement";
import subscription from "./reducers/company/subscription";
import add from "./reducers/company/add";
import content from "./reducers/home/content";
import profile from "./reducers/profile/profile";
import events from "./reducers/events/events";
import payment from "./reducers/payment/payment";
import notification from "./reducers/notification/notification";

const store = configureStore({
    reducer: {
        details: details,
        auth: auth,
        contact: contact,
        subscription: subscription,
        content: content,
        profile: profile,
        advertisement: advertisement,
        add: add,
        events: events,
        payment: payment,
        notification: notification,
    },
});

export default store;
