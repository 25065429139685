import React, { memo, useState } from "react";
import HomeCardFive from "../../dashboard/component/HomeCardFive";
import ProfileActivityCardCompany from "./ProfileActivityCardCompany";

const Activity = ({
    backed_detail,
    company_detail,
    backedUsCompanyDetail,
    backedUsDetail,
}) => {
    const [totalShow, setTotalShow] = useState(2);

    return (
        <>
            <div className="container">
                <div className="row my-4">
                    <div className="col-12 profile-industry-type-section px-3">
                        <h3 className="fs-4 fw-bolder text-secondary">
                            Activity
                        </h3>

                        <div
                            className="row my-3 mx-0"
                            style={{
                                borderRadius: "10px",
                                padding: "10px",
                                border: "1px solid rgba(159, 159, 159, 1)",
                            }}
                        >
                            {backed_detail?.length > 0 && (
                                <div className="col-12 col-md-12 col-lg-6 borderEnd">
                                    <div className="">
                                        <div className="my-3">
                                            <h3 className="fs-4 text-center mx-auto fw-bold">
                                                Companies we’ve <br /> backed
                                            </h3>
                                        </div>
                                        <div>
                                            {backed_detail?.length > 0 &&
                                                backed_detail
                                                    ?.slice(0, totalShow)
                                                    ?.map((item, index) => {
                                                        return (
                                                            <ProfileActivityCardCompany key={index}
                                                                type="We"
                                                                item={item}
                                                                index={index}
                                                                company_detail={
                                                                    company_detail
                                                                }
                                                            />
                                                        );
                                                    })}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {backedUsDetail?.length > 0 && (
                                <div className="col-12 col-md-12 col-lg-6">
                                    <div className="">
                                        <div className="my-3">
                                            <h3 className="fs-4 text-center mx-auto fw-bold">
                                                Companies that <br />
                                                have backed us
                                            </h3>
                                        </div>
                                        <div>
                                            {backedUsDetail?.length > 0 &&
                                                backedUsDetail
                                                    ?.slice(0, totalShow)
                                                    ?.map((item, index) => {
                                                        return (
                                                            <ProfileActivityCardCompany key={index}
                                                                type="Us"
                                                                item={item}
                                                                index={index}
                                                                company_detail={
                                                                    backedUsCompanyDetail
                                                                }
                                                            />
                                                        );
                                                    })}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="col-12">
                                {(backed_detail?.length > totalShow ||
                                    backedUsDetail?.length > totalShow) && (
                                    <p
                                        className="my-2 fs-5 fw-bold text-dark text-center mx-auto cursor-pointer"
                                        onClick={() => {
                                            setTotalShow((pre) => {
                                                return pre + 2;
                                            });
                                        }}
                                    >
                                        See more +
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default memo(Activity);
