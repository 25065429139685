import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    getFAQ,
    getCompanies,
    markFavourite,
    getSearchCompanies,
    getInTouch,
    unmarkFavourite,
    getBlogs,
    getNewCompanies,
    getMyCompany,
    getCompanyById,
    getBanners,
    getAllFavourite,
    getBlogsPaginated,
    getIndustries,
    getBlogById,
    getAllCompanies,
    getBizBucksById,
    getBizBucksConfigDetails,
    backedBizBuck,
    getTotalBackedBizBuck,
    getAdminEvents,
    registerEventData,
    userRegistredEvents,
    getRecentStoriesData,
    mostTopFiveBackedData,
    mostTopFiveViewedData,
    topFiveHaveBeenMatched,
    getBadgeTierData
} from "../../services/home/content";
import { getTopFiveBacked, getTopFiveViewed } from "../../api";

const initialState = {
    success: false,
    error: null,
    loading: true,
    faqs: [],
    banner: [],
    companies: [],
    message: "",
    markFav: false,
    blogs: [],
    blogsPaginated: [],
    totalBlogsCount: 0,
    newCompanies: [],
    myCompany: {},
    myBizBucks:undefined,
    bizBucksConfig:[],
    myFavourites: [],
    myBackedCompany:[],
    companyBackedUs:undefined,
    company: {},
    backedCompany:undefined,
    backedUsCompany:undefined,
    companyCount: 0,
    allfavourites: [],
    allReviews: [],
    allUniqueLocation: [],
    favCount: 0,
    industries: [],
    currentBlogId: 1,
    currentBlog: [],
    allCompanies: [],
    totalBackedBizBuck:undefined,
    events:[],
    totalEvents:0,
    recentStories:undefined,
    topFiveBacked:[],
    topFiveViewed:[],
    topFiveMatched:[],
    myCompanyPreference:[],
    badgeTier:[]
};

export const getFAQAction = createAsyncThunk("get/faq", async () => {
    const response = await getFAQ();
    if (response?.error) {
        return { error: response };
    }
    return response;
});

export const getBannersAction = createAsyncThunk("get/banner", async () => {
    const response = await getBanners();
    if (response?.error) {
        return { error: response };
    }
    return response;
});

export const getBlogsAction = createAsyncThunk(
    "get/blogs",
    async ({ page = 1, searchItem }) => {
        const response = await getBlogs(page, searchItem);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const getBlogsActionPaginated = createAsyncThunk(
    "get/blogsPaginated",
    async (page) => {
        const response = await getBlogsPaginated(page);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const getBlogByIdAction = createAsyncThunk(
    "get/blogById",
    async (id) => {
        const response = await getBlogById(id);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const getCompaniesAction = createAsyncThunk(
    "get/companies",
    async (page) => {
        const response = await getCompanies(page);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const getAllCompaniesAction = createAsyncThunk(
    "get/allcompanies",
    async (search) => {
        const response = await getAllCompanies(search);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);



// backaBiz/back-a-biz
export const getNewCompaniesAction = createAsyncThunk(
    "get/newCompanies",
    async () => {
        const response = await getNewCompanies();
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const markFavouriteAction = createAsyncThunk(
    "mark/favourite",
    async (id) => {
        const response = await markFavourite(id);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const UnmarkFavouriteAction = createAsyncThunk(
    "unmark/favourite",
    async (id) => {
        const response = await unmarkFavourite(id);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const getAllFavouriteAction = createAsyncThunk(
    "get/favourite",
    async (options) => {
        console.log(options);
        const response = await getAllFavourite(options);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const getInTouchAction = createAsyncThunk(
    "company/getInTouch",
    async (data) => {
        const response = await getInTouch(data);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const getMyCompanyAction = createAsyncThunk(
    "company/getMyCompany",
    async () => {
        const response = await getMyCompany();
        console.log("....");
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);


export const getBizBucksByForCompanyById = createAsyncThunk(
    "company/getBizBucksById",
    async (id) => {
        const response = await getBizBucksById(id);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const getTotalNumberOfBackedCompany = createAsyncThunk(
    "company/getTotalNumberOfBackedCompany",
    async (search) => {
        const response = await getTotalBackedBizBuck(search);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const getBizBucksByConfigDetailsData = createAsyncThunk(
    "company/getBizBucksConfig",
    async () => {
        const response = await getBizBucksConfigDetails();
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);


export const backedBizBucks = createAsyncThunk(
    "post/backedBizBuck",
    async (data) => {
        const response = await backedBizBuck(data);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
)


export const getMyCompanyByIdAction = createAsyncThunk(
    "company/getCompanyById",
    async (id) => {
        const response = await getCompanyById(id);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const getSearchedCompaniesAction = createAsyncThunk(
    "get/search",
    async (keyword) => {
        const response = await getSearchCompanies(keyword);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const getIndustriesAction = createAsyncThunk(
    "get/industries",
    async () => {
        const response = await getIndustries();
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const getAdminEventsData = createAsyncThunk(
    "get/adminEvents",
    async () => {
        const response = await getAdminEvents();
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const registeredNewEvent = createAsyncThunk(
    "post/registeredEvent",
    async (data) => {
        const response = await registerEventData(data);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);
export const getRecentStoriesDetails = createAsyncThunk(
    "get/recentStories",
    async () => {
        const response = await getRecentStoriesData();
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);


export const mostTopFiveBacked = createAsyncThunk(
    "get/topFiveBacked",
    async () => {
        const response = await mostTopFiveBackedData();
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const mostTopFiveViewed = createAsyncThunk(
    "get/topFiveViewed",
    async () => {
        const response = await mostTopFiveViewedData();
        console.log(response,">>>>>>>>>>>>>>>>>>>>>>>>>>>")
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const youHaveBeenMatched = createAsyncThunk(
    "get/YouHaveBeenmatched",
    async () => {
        const response = await topFiveHaveBeenMatched();
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const getBadgeTierDetails = createAsyncThunk(
    "get/badgeTier",
    async () => {
        const response = await getBadgeTierData();
        console.log(response,">>>>>>>>badge tire>>>>>>>>>>>>>>>>>>>>>>>>>>>")
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);
const getContentSlice = createSlice({
    name: "getContents",
    initialState,
    reducers: {
        reset: (state, action) => {
            state.error = null;
            state.message = null;
            state.markFav = false;
        },
        reviewGiven: (state, action) => {
            state.feedbackGiven = true;
        },
        setCurrentBlogId: (state, action) => {
            state.currentBlog = state.blogs[action.payload];
            state.currentBlogId = action.payload;
        }
    },
    extraReducers: {
        [getFAQAction.pending]: (state, action) => {
            state.loading = true;
        },
        [getFAQAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.faqs = action.payload?.faq;
            }
        },
        [getBannersAction.pending]: (state, action) => {
            state.loading = true;
        },
        [getBannersAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.banner = action.payload?.result;
            }
        },
        [UnmarkFavouriteAction.pending]: (state, action) => {
            // state.loading = true;
        },
        [UnmarkFavouriteAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
            }
        },
        [getCompaniesAction.pending]: (state, action) => {
            state.loading = true;
        },
        [getCompaniesAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.companies = action.payload?.companies;
                state.companyCount = action.payload?.length;
                state.allUniqueLocation = action.payload?.allLocation;
            }
        },
        [getAllCompaniesAction.pending]: (state, action) => {
            state.loading = true;
        },
        [getAllCompaniesAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.allCompanies = action.payload?.company;
            }
        },
        [getIndustriesAction.pending]: (state, action) => {
            state.loading = true;
        },
        [getIndustriesAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.industries = action.payload?.industries;
            }
        },

        [getAdminEventsData.pending]: (state, action) => {
            state.loading = true;
        },
        [getAdminEventsData.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.events = action.payload?.events;
                state.totalEvents = action.payload?.totalEvents
            }
        },
        [registeredNewEvent.pending]: (state, action) => {
            state.loading = true;
        },
        [registeredNewEvent.fulfilled]: (state, action) => {
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
            }
        },
        [getRecentStoriesDetails.pending]: (state, action) => {
            state.loading = true;
        },
        [getRecentStoriesDetails.fulfilled]: (state, action) => {
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.loading=false
                state.success = action.payload?.success;
                state.recentStories=action?.payload?.data
            }
        },




        [mostTopFiveBacked.pending]: (state, action) => {
            state.loading = true;
        },
        [mostTopFiveBacked.fulfilled]: (state, action) => {
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.loading=false
                state.success = action.payload?.success;
                state.topFiveBacked=action?.payload?.topBackedBusinesses
            }
        },
  
        [youHaveBeenMatched.pending]: (state, action) => {
            state.loading = true;
        },
        [youHaveBeenMatched.fulfilled]: (state, action) => {
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.loading=false
                state.success = action.payload?.success;
                state.topFiveMatched=action?.payload?.topMatches
            }
        },

        [getBadgeTierDetails.pending]: (state, action) => {
            state.loading = true;
        },
        [getBadgeTierDetails.fulfilled]: (state, action) => {
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.loading=false
                state.success = action.payload?.success;
                state.badgeTier=action?.payload?.badgeTiers
            }
        },
           
        [mostTopFiveViewed.pending]: (state, action) => {
            state.loading = true;
        },
        [mostTopFiveViewed.fulfilled]: (state, action) => {
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.loading=false
                state.success = action.payload?.success;
                state.topFiveViewed=action?.payload?.topViewedBusinesses
            }
        },








        [getSearchedCompaniesAction.pending]: (state, action) => {
            state.loading = true;
        },
        [getSearchedCompaniesAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.companies = action.payload?.companies;
                state.allUniqueLocation = action.payload?.allLocation;
                state.companyCount = action.payload?.length;
            }
        },
        // [markFavouriteAction.pending]: (state, action) => {
        //     state.loading = true;
        // },
        [markFavouriteAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.markFav = action.payload?.success;
            }
        },
        [getInTouchAction.pending]: (state, action) => {
            // state.loading = true;
        },
        [getInTouchAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
            }
        },
        [getBlogsAction.pending]: (state, action) => {
            state.loading = true;
        },
        [getBlogsAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.blogs = action.payload?.blogs;
                state.totalBlogsCount = action.payload?.totalBlogsCount;
            }
        },

        [getBlogsActionPaginated.pending]: (state, action) => {
            state.loading = true;
        },
        [getBlogsActionPaginated.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.blogsPaginated = action.payload?.blogs;
                state.totalBlogsCount = action.payload?.totalBlogsCount;
            }
        },

        [getBlogByIdAction.pending]: (state, action) => {
            state.loading = true;
        },
        [getBlogByIdAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.currentBlog = action.payload?.blog;
            }
        },

        [getNewCompaniesAction.pending]: (state, action) => {
            state.loading = true;
        },
        [getNewCompaniesAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.newCompanies = action.payload?.companies;
            }
        },
        [getAllFavouriteAction.pending]: (state, action) => {
            state.loading = true;
        },
        [getAllFavouriteAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.allfavourites = action.payload?.favourites;
                state.allReviews = action.payload?.allReviews;
                state.favCount = action.payload?.length;
            }
        },
        [getMyCompanyAction.pending]: (state, action) => {
            state.loading = true;
        },
        [getMyCompanyAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            state.loading = false;
            if (action.payload?.error) {
                state.loading = false;
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.loading = false;
                state.success = action.payload?.success;
                state.myCompany = action.payload?.company;
                state.myFavourites = action.payload?.Favourites;
                state.myBackedCompany=action.payload?.findAllBackedCompany
                state.companyBackedUs=action.payload?.findAllBackedUsCompany

            }
        },
        [getBizBucksByForCompanyById.pending]: (state, action) => {
            state.loading = true;
        },
        [getBizBucksByForCompanyById.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            state.loading = false;
            if (action.payload?.error) {
                state.loading = false;
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.myBizBucks = action.payload?.bizBucks;
            }
        },

        [getBizBucksByConfigDetailsData.pending]: (state, action) => {
            state.loading = true;
        },
        [getBizBucksByConfigDetailsData.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            state.loading = false;
            if (action.payload?.error) {
                state.loading = false;
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.bizBucksConfig = action.payload?.config;
            }
        },

        [getTotalNumberOfBackedCompany.pending]: (state, action) => {
            state.loading = true;
        },
        [getTotalNumberOfBackedCompany.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            state.loading = false;
            if (action.payload?.error) {
                state.loading = false;
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.totalBackedBizBuck = action.payload?.totalBacked;
            }
        },

        [getMyCompanyByIdAction.pending]: (state, action) => {
            state.loading = true;
        },
        [getMyCompanyByIdAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            state.loading = false;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.company = action.payload?.company;
                state.backedCompany = action.payload?.findAllBackedCompany
                state.backedUsCompany=action.payload?.findAllBackedUsCompany
            }
        },

        [backedBizBucks.pending]: (state, action) => {
            state.loading = true;
        },
        [backedBizBucks.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            state.loading = false;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
            }
            if (!action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
            }
        }
    },
});

export const contentActions = getContentSlice.actions;

export default getContentSlice.reducer;
