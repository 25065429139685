import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { Provider } from "react-redux";
import webfont from "webfontloader";

import Layout from "./containers/Layout";
import store from "./data/store";

import aos from "aos";
import "aos/dist/aos.css";

import "@material-tailwind/react/tailwind.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

import "./css/base/common.css";
import "./css/base/variables.css";
import "./css/customStyle.css";

aos.init();
console.log("hi heelo 123");
const App = () => {
    useEffect(() => {
        webfont.load({
            google: {
                families: ["Poppins", "Roboto"],
            },
        });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [window.location.pathname]);

    return (
        <>
            <ChakraProvider>
                <Provider store={store}>
                    <BrowserRouter>
                        <Layout />
                    </BrowserRouter>
                </Provider>
            </ChakraProvider>
        </>
    );
};

export default App;
