import React from "react";
import { Typography, Stepper, StepLabel, Step } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import { FaRegDotCircle } from "react-icons/fa";

import "./DetailSteps.css";

const DetailSteps = ({ activeStep }) => {
    const steps = [
        {
            label: <Typography>Admin Details</Typography>,
            icon1: <CheckCircle />,
            icon2: <FaRegDotCircle fontSize={20} />,
        },
        {
            label: <Typography>Company Info</Typography>,
            icon1: <CheckCircle />,
            icon2: <FaRegDotCircle fontSize={20} />,
        },
        {
            label: <Typography>Payment</Typography>,
            icon1: <CheckCircle />,
            icon2: <FaRegDotCircle fontSize={20} />,
        },
    ];

    return (
        <>
            <Stepper alternativeLabel activeStep={activeStep} className="line">
                {steps?.map((item, index) => (
                    <Step
                        key={index}
                        active={activeStep === index ? true : false}
                        completed={activeStep > index ? true : false}
                    >
                        <StepLabel
                            style={{
                                color:
                                    activeStep >= index ? "#067EED" : "#A7A7A7",
                            }}
                            icon={
                                activeStep > index ? item?.icon1 : item?.icon2
                            }
                        >
                            {item?.label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </>
    );
};

export default DetailSteps;
