import {
    getEventsAPI,
    addEventAPI,
    updateEventAPI,
    deleteEventAPI,
    likeEventAPI,
    unlikeEventAPI,
    getLikedEventsAPI,
    getLikedEventsByCompanyAPI,
    getMyEventsAPI,
    getSearchEventsAPI,
    getInvitedEventAPI,
    getLikedSearchEventsAPI,
    userRegistartionEvent,
    getUserRegistartionEventById,
    editUserRegistartionEventById,
    deleteAttendees,
    cancelEvent,
    registerPodCastUserdata,
    registerSpotliteUser,
    userRegistartionSpotlightEvent,
    userRegistartionPodcastEvent,
} from "../../api";

export const getEventsService = async (page) => {
    try {
        const response = await getEventsAPI(page);
        return response?.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const getSearchEventsService = async (value) => {
    try {
        const response = await getSearchEventsAPI(value);
        return response?.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const getLikedSearchEventsService = async (value) => {
    try {
        const response = await getLikedSearchEventsAPI(value);
        return response?.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const getMyEventsService = async (data) => {
    try {
        const response = await getMyEventsAPI(data);
        return response?.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const getInvitedEventsService = async (data) => {
    try {
        const response = await getInvitedEventAPI(data);
        return response?.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const addEventService = async (payload) => {
    console.log(payload);
    try {
        const response = await addEventAPI(payload);
        return response?.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const updateEventService = async (data) => {
    try {
        const response = await updateEventAPI(data);
        return response?.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const deleteEventService = async (id) => {
    try {
        const response = await deleteEventAPI(id);
        return response?.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const likeEventService = async (id) => {
    try {
        const response = await likeEventAPI(id);
        return response?.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const unlikeEventService = async (id) => {
    try {
        const response = await unlikeEventAPI(id);
        return response?.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const getLikedEventsService = async (page) => {
    try {
        const response = await getLikedEventsAPI(page);
        return response?.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const getLikedEventsByCompanyService = async (id) => {
    try {
        const response = await getLikedEventsByCompanyAPI(id);
        return response?.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const userRegistredEvents=async (search)=>{
    const response =await userRegistartionEvent(search);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
}

export const userRegistredPodcastEvents=async (search)=>{
    const response =await userRegistartionPodcastEvent(search);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
}
export const userRegistredSpotlightEvents=async (search)=>{
    const response =await userRegistartionSpotlightEvent(search);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
}


export const getUserRegistredEventsById=async (id)=>{
    const response =await getUserRegistartionEventById(id);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
}

export const editUserRegistredEventsById=async (id,userEvent)=>{
    const response =await editUserRegistartionEventById(id,userEvent);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
}

export const deleteAttendeesData=async (id)=>{
    const response =await deleteAttendees(id);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
}
export const cancelEventRegistration=async (id)=>{
    const response =await cancelEvent(id);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
}


export const registerPodcastUser=async (data)=>{
    const response =await registerPodCastUserdata(data);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
}

export const registerSpotLiteUser=async (data)=>{
    const response =await registerSpotliteUser(data);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
}