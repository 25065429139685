import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import frame from "../../assets/advertisements.png";
import "./AdvertisementPanel.css";
import activeimage from "../../assets/unsplash_ZR48YvUpk04.svg";
import closeimage from "../../assets/unsplash_Z5cyBi5CLPg.svg";
import { useDispatch, useSelector } from "react-redux";
import { getAllAdvertisementAction } from "../../data/reducers/advertisement/advertisement";
import NotFound from "../Errors/NotFound";
import EmptyState from "../Errors/EmptyState";

function AdvertisementPanel() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const elRef = useRef(null);
    const [viewAll, setViewAll] = useState(false);
    const [activeAd, setActiveAd] = useState([]);
    const [closedAd, setClosedAd] = useState([]);
    const { advertisement } = useSelector((state) => state.advertisement);

    useEffect(() => {
        dispatch(getAllAdvertisementAction());
    }, [dispatch]);
    console.log("advertisement", advertisement);

    useEffect(() => {
        let activeAd1 = advertisement?.filter((item) => item?.currbudget > 0);

        let closedAd2 = advertisement?.filter((item) => {
            return item?.currbudget <= 0;
        });

        setActiveAd(activeAd1);

        setClosedAd(closedAd2);
        console.log("activeAd1", activeAd, activeAd1);
        console.log("closedAd1", closedAd, closedAd2);
    }, [advertisement]);

    useEffect(() => {
        let activeAd1 = advertisement?.filter((item) => item?.currbudget > 0);
        setTimeout(() => {
            if (
                elRef?.current?.scrollHeight > elRef?.current?.clientHeight &&
                activeAd1?.length != 0
            ) {
                setViewAll(true);
            }
        }, 500);
    }, [viewAll, advertisement]);

    console.log("activeAd", activeAd);
    console.log("closedAd", closedAd);
    return (
        <div className="panel-container">
            {/* banner panel   */}
            <div className="advertisement-panel-banner">
                <img src={frame} alt="" />
                <button
                    className="advertisement-panel-create-button"
                    onClick={() => navigate("/launchform")}
                >
                    Create new campaign
                </button>
            </div>
            {advertisement?.length === 0 ? (
                <EmptyState showBtn={false} />
            ) : (
                <>
                    <div className="active-campaign">
                        <div className="active-image-head">
                            <p>Active campaign</p>
                        </div>
                        <div
                            className=// ? // activeAd.length != 0 // {
                            "active-images active-images-h"
                            // : "active-images"
                            // }
                            ref={elRef}
                        >
                            {/* {console.log(advertisement?.length)} */}
                            {/* {} */}
                            {activeAd?.length === 0 ? (
                                // <div className="ads-empty">
                                <EmptyState active={true} />
                            ) : (
                                // </div>
                                <>
                                    {advertisement?.map((item) => {
                                        return (
                                            item.currbudget > 0 && (
                                                <div className="ads-imgs">
                                                    {/* {console.log(item?.advertisement_image)} */}
                                                    <img
                                                        src={
                                                            item?.advertisement_image
                                                        }
                                                        alt="advertisement"
                                                        style={{
                                                            width: "156px",
                                                            height: "156px",
                                                        }}
                                                    />
                                                </div>
                                            )
                                            // <h1>aa</h1>
                                        );
                                    })}
                                </>
                            )}
                        </div>
                        {viewAll && (
                            <div className="see-all-btn">
                                <button
                                    onClick={() => {
                                        navigate("/all-ads");
                                    }}
                                >
                                    See all {">>"}
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="panel-closed-campaign">
                        <div className="panel-closed-image-head">
                            <p> Closed campaign</p>
                        </div>
                        <div className="panel-closed-images">
                            {closedAd?.length === 0 ? (
                                // <div className="ads-empty">
                                <EmptyState showBtn={false} />
                            ) : (
                                // </div>
                                <>
                                    {advertisement.map(
                                        (item, i) =>
                                            item.currbudget <= 0 && (
                                                <div className="ads-imgs">
                                                    <img
                                                        src={
                                                            item.advertisement_image
                                                        }
                                                        alt="advertisement"
                                                    />
                                                </div>
                                            )
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
export default AdvertisementPanel;
