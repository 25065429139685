import React, { Fragment, useEffect, useMemo, useState } from "react";
import "./css/index.css";
import HomeCardOne from "./component/HomeCardOne";
import HomeCardTwo from "./component/HomeCardTwo";
import HomeCardThree from "./component/HomeCardThree";
import HomeCardFour from "./component/HoemCardFour";
import HomeCardFive from "./component/HomeCardFive";
import { IoEllipsisHorizontal } from "react-icons/io5";
import Modal from "@material-tailwind/react/Modal";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import ModalBody from "@material-tailwind/react/ModalBody";
import Button from "@material-tailwind/react/Button";
import { CgNotes } from "react-icons/cg";
import BackboneLogo from "../.././assets/backbone-logo.svg";
import longArrow from "../../assets/CustomIcons/Arrow.svg";
import { RxCross1 } from "react-icons/rx";
import { MdOutlineEdit } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import {
    getAdminEventsData,
    getBadgeTierDetails,
    getBizBucksByConfigDetailsData,
    getBizBucksByForCompanyById,
    getRecentStoriesDetails,
    mostTopFiveBacked,
    mostTopFiveViewed,
    youHaveBeenMatched,
} from "../../data/reducers/home/content";
import { useToast } from "@chakra-ui/react";
import { SettingsInputComponent } from "@material-ui/icons";
import CommonModalBackboneEvent from "../UpcomingEvents/Components/CommonModalBackboneEvent";
import validator from "validator";
import {
    registerPodCastData,
    registerSpotLiteData,
} from "../../data/reducers/events/events";
import { contentActions } from "../../data/reducers/home/content";
import { eventActions } from "../../data/reducers/events/events";
import RequestEndorse from "../Profile/Components/RequestEndorse";
import { getHomeAdvertisementAction } from "../../data/reducers/advertisement/advertisement";
import blackBiz from "../../assets/CustomIcons/blackBiz.png";
import { useNavigate } from "react-router";
import moment from "moment";
const Dashboard = () => {
    const toast = useToast();

    const [visible, setVisible] = useState(false);
    const [open, setOpen] = useState(false);
    const navigate=useNavigate()
    const [visible2, setVisible2] = useState(false);
    const [eventType,setEventType]=useState("Register")
    const [podCastVisible, setPodCastVisible] = useState(false);
    const [podCastVisible2, setPodCastVisible2] = useState(false);
    const [podCastVisible3, setPodCastVisible3] = useState(false);
    const [showModal1, setShowModal1] = useState(false);
    const [spotliteVisible, setSpotliteVisible] = useState(false);
    const [randomAd,setRandomAd]=useState(0)
    const [spotLiteData, setSpotLiteData] = useState({
        event_id: undefined,
        first_name: "",
        last_name: "",
        email: "",
        company_name: "",
        job_title: "",
        description: "",
    });

    const {
        myCompany,
        myBizBucks,
        events,
        bizBucksConfig,
        recentStories,
        topFiveBacked,
        topFiveViewed,
        topFiveMatched,
        badgeTier
    } = useSelector((state) => state?.content);
    const { homeAdvertisements } = useSelector((state) => state.advertisement);

    const { success, message, loading } = useSelector((state) => state?.events);
    const [evenetDate, setEventDate] = useState(null);
    const [podCastuser, setPodCastUser] = useState({
        first_name: "",
        last_name: "",
        email: "",
        company_name: "",
        job_title: "",
        podcast_title: "",
        description: "",
    });
    const dispatch = useDispatch();
    const getLatestCredentials = () => {
        dispatch(getRecentStoriesDetails());
        dispatch(mostTopFiveViewed());
        dispatch(mostTopFiveBacked());
        dispatch(youHaveBeenMatched());
    };
    useEffect(() => {
        dispatch(getRecentStoriesDetails());
        dispatch(mostTopFiveViewed());
        dispatch(mostTopFiveBacked());
        dispatch(youHaveBeenMatched());
    }, [dispatch]);


    useEffect(()=>{
        let intervalTimer=undefined
        if(homeAdvertisements && homeAdvertisements?.length>0){
            console.log(homeAdvertisements?.length)
            let start=0;
            let end=homeAdvertisements?.length
            
            intervalTimer=setInterval(()=>{
                let randomNumber = Math.floor(Math.random() * (end - start)) + start;
                setRandomAd(randomNumber)
            },10000)
        }

        return()=>{
            clearInterval(intervalTimer)
        }
       
    },[homeAdvertisements])

    const getYouHaveBennMatched = () => {
        dispatch(youHaveBeenMatched());
    };

    useEffect(() => {
        dispatch(getHomeAdvertisementAction());
    }, [dispatch]);

    const memoizedHomeCardOne = useMemo(() => {
        return <HomeCardOne getLatestCredentials={getLatestCredentials} />;
    }, [getLatestCredentials]);
    const memoizedHomeCardTwo = useMemo(() => {
        if (topFiveMatched && topFiveMatched?.length > 0){
            return (
                <HomeCardTwo
                    topFiveMatched={topFiveMatched}
                    getYouHaveBennMatched={getYouHaveBennMatched}
                />
            );
        }else{
            return  <HomeCardTwo
            topFiveMatched={[]}
            getYouHaveBennMatched={()=>{

            }}
        />
        }
    }, [topFiveMatched, getYouHaveBennMatched]);
    const memoizedHomeCardThree = useMemo(() => {
        if (topFiveBacked && topFiveBacked?.length > 0  && badgeTier?.length>0) {
            return <HomeCardThree topFiveBacked={topFiveBacked} badgeTier={badgeTier}/>;
        }else{
            return <HomeCardThree topFiveBacked={[]} badgeTier={[]}/>;
        }
    }, [topFiveBacked,badgeTier]);
    const memoizedHomeCardFour = useMemo(() => {
        if (topFiveViewed && topFiveViewed?.length > 0 && badgeTier?.length>0) {
            return <HomeCardFour topFiveViewed={topFiveViewed} badgeTier={badgeTier}/>;
        }else{
            return <HomeCardFour topFiveViewed={[]} badgeTier={[]}/>;
        }
    }, [topFiveViewed,badgeTier]);
    // const memoizedHomeCardFive = useMemo(() => {
    //     if (recentStories?.backing_details?.length > 0) {
    //         return recentStories?.backing_details?.slice(0,1)?.map((item, index) => {
    //             return (
    //                 <HomeCardFive
    //                     key={index}
    //                     item={item}
    //                     index={index}
    //                     backed_company_detail={
    //                         recentStories?.backed_company_detail
    //                     }
    //                     backer_company_detail={
    //                         recentStories?.backer_company_detail
    //                     }
    //                 />
    //             );
    //         });
    //     }
    // }, [recentStories]);
    useEffect(() => {
        if (!bizBucksConfig?.length) dispatch(getBizBucksByConfigDetailsData());
    }, [dispatch]);

    useEffect(() => {
        if (myCompany && myCompany?.id) {
            dispatch(getBizBucksByForCompanyById(myCompany?.id));
        }
    }, [myCompany, dispatch]);
    useEffect(() => {
        dispatch(getAdminEventsData());
    }, [dispatch]);

    useEffect(()=>{
        dispatch(getBadgeTierDetails())
    },[dispatch])


    const handleClose2 = () => {
        setVisible2(false);
        setOpen(false);
    };
    const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    function formatDateTime(dateStr) {
        // Create a Date object from the given date string
        const date = new Date(dateStr);

        // Define the options for the date part
        const dateOptions = { month: "long", day: "numeric" };

        // Define the options for the time part
        const timeOptions = {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            timeZone: "Australia/Sydney", // Use the appropriate time zone
        };

        // Get the date and time strings
        const dateString = date.toLocaleString("en-US", dateOptions);
        const timeString = date.toLocaleString("en-US", timeOptions);

        // Combine date and time parts
        return `${dateString} @ ${timeString}`;
    }

    const validateAndSubmitRequest = async () => {
        try {
            if (
                !podCastuser?.first_name?.trim() ||
                !podCastuser?.last_name?.trim() ||
                !podCastuser?.job_title?.trim() ||
                !myCompany?.company_name?.trim() ||
                !podCastuser?.description?.trim()
            ) {
                toast({
                    title: `All fields are required`,
                    position: "top-right",
                    duration: 3000,
                    status: "error",
                    isClosable: true,
                });
            } else if (!validator.isEmail(podCastuser?.email?.trim())) {
                toast({
                    title: `Invalid Email`,
                    position: "top-right",
                    duration: 3000,
                    status: "error",
                    isClosable: true,
                });
            } else {
                // dispatch(registeredNewEvent(podCastuser))
                const response = await dispatch(
                    registerPodCastData({
                        ...podCastuser,
                        company_name: myCompany?.company_name,
                    })
                );
                if (response?.payload?.success) {
                    setPodCastVisible(false);
                    setPodCastVisible2(true);
                    setPodCastUser({
                        first_name: "",
                        last_name: "",
                        email: "",
                        company_name: "",
                        job_title: "",
                        podcast_title: "",
                        description: "",
                    });
                } else {
                    if (
                        response?.payload?.message ==
                        "You’ve already submitted a request. A BackBone Team member will be in touch shortly"
                    ) {
                        setPodCastVisible(false);
                        setPodCastVisible2(true);
                        setPodCastUser({
                            first_name: "",
                            last_name: "",
                            email: "",
                            company_name: "",
                            job_title: "",
                            podcast_title: "",
                            description: "",
                        });
                    } else {
                        setPodCastVisible(false);
                        setPodCastVisible3(true);
                        setPodCastUser({
                            first_name: "",
                            last_name: "",
                            email: "",
                            company_name: "",
                            job_title: "",
                            podcast_title: "",
                            description: "",
                        });
                    }
                }
            }
        } catch (err) {
            setPodCastVisible3(true);
            setPodCastUser({
                first_name: "",
                last_name: "",
                email: "",
                company_name: "",
                job_title: "",
                podcast_title: "",
                description: "",
            });
        }
    };

    const validateAndSubmitRequestSpotlite = async () => {
        try {
            if (
                !spotLiteData?.first_name?.trim() ||
                !spotLiteData?.last_name?.trim() ||
                !spotLiteData?.job_title?.trim() ||
                !myCompany?.company_name?.trim() ||
                !spotLiteData?.description?.trim()
            ) {
                toast({
                    title: `All fields are required`,
                    position: "top-right",
                    duration: 3000,
                    status: "error",
                    isClosable: true,
                });
            } else if (!validator.isEmail(spotLiteData?.email?.trim())) {
                toast({
                    title: `Invalid Email`,
                    position: "top-right",
                    duration: 3000,
                    status: "error",
                    isClosable: true,
                });
            } else if(!spotLiteData?.event_id?.trim() || spotLiteData?.event_id?.trim()=="undefined"){
                toast({
                    title: `Select Event`,
                    position: "top-right",
                    duration: 3000,
                    status: "error",
                    isClosable: true,
                });
            }
            else {
                // dispatch(registeredNewEvent(spotLiteData))
                const response = await dispatch(
                    registerSpotLiteData({
                        ...spotLiteData,
                        company_name: myCompany?.company_name,
                    })
                );
                if (response?.payload?.success) {
                    setSpotliteVisible(false);
                    setPodCastVisible2(true);
                    setSpotLiteData({
                        event_id: undefined,
                        first_name: "",
                        last_name: "",
                        email: "",
                        company_name: "",
                        job_title: "",
                        description: "",
                    });
                } else {
                    if (
                        response?.payload?.message ==
                        "You’ve already submitted a request. A BackBone Team member will be in touch shortly"
                    ) {
                        setSpotliteVisible(false);
                        setPodCastVisible2(true);
                        setSpotLiteData({
                            event_id: undefined,
                            first_name: "",
                            last_name: "",
                            email: "",
                            company_name: "",
                            job_title: "",
                            description: "",
                        });
                    } else {
                        setSpotliteVisible(false);
                        setPodCastVisible3(true);
                        setSpotLiteData({
                            event_id: undefined,
                            first_name: "",
                            last_name: "",
                            email: "",
                            company_name: "",
                            job_title: "",
                            description: "",
                        });
                    }
                }
            }
        } catch (err) {
            setPodCastVisible3(true);
            setSpotLiteData({
                event_id: undefined,
                first_name: "",
                last_name: "",
                email: "",
                company_name: "",
                job_title: "",
                description: "",
            });
        }
    };

    const dateFormat = (eventDate) => {
        const optionsDate = {
            weekday: "long", // Tuesday
            month: "long", // June
            day: "numeric", // 25
        };

        const optionsTime = {
            hour: "numeric",
            minute: "numeric",
            hour12: true, // 1pm
        };
        const formattedDate = eventDate.toLocaleDateString(
            "en-AU",
            optionsDate
        );
        const formattedTime = eventDate
            .toLocaleTimeString("en-AU", optionsTime)
            .replace(" ", ""); // Removing space before AM/PM

        return `${formattedDate}, ${formattedTime} AEST`;
    };
    return (
        <Fragment>
            <div className="container home-wrapper">
                <div className="row">
                    {/* Dashboard Heading Start */}
                    <div className="col-12">
                        <div className="">
                            <h1 className="fs-2 fw-bolder text-secondary">
                                Dashboard
                            </h1>
                        </div>
                    </div>
                    {/* Dashboard Heading End  */}

                    {/* Dashboard Content Section Start  */}
                    <div className="col-12 my-3">
                        <div className="row">
                            <div className="col-12 col-xl-9">
                                <div className="row">
                                    <div className="col-12 col-lg-6 mb-5 mb-lg-0">
                                        <div style={{ height: "100%" }}>
                                            {memoizedHomeCardOne}
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div style={{ height: "100%" }}>
                                            {memoizedHomeCardTwo}
                                        </div>
                                    </div>
                                </div>

                                {/* Business LeaderBoard Section Started  */}

                                <div className="row my-5">
                                    <div className="col-12">
                                        <h2 className="fs-2 fw-bolder text-secondary">
                                            Business Leaderboard
                                        </h2>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <div style={{ height: "100%" }}>
                                            <div className="home_card2">
                                                <div
                                                    className="businessLeadeshipBox"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    {memoizedHomeCardThree}
                                                    <div
                                                        className="bg-secondary leadeshipLine"
                                                        style={{
                                                            width: "3.4px",
                                                            height: "240px",
                                                        }}
                                                    ></div>
                                                    {memoizedHomeCardFour}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Business LeaderBoard Section End  */}
                            </div>

                            {/* Add Section Started  */}
                            <div className="col-12 col-lg-12 col-xl-3">
                                <div className="adBox">
                                    <div
                                        className="p-4"
                                        style={{
                                            background: "rgb(222, 235, 255)",
                                            borderRadius: "10px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                gap: "1rem",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    width: "60px",
                                                    height: "60px",
                                                    borderRadius: "50%",
                                                    border: "4px solid #000",
                                                    padding: "10px",
                                                }}
                                            >
                                                <img src={BackboneLogo} />
                                            </span>
                                            <h4
                                                className="ms-3"
                                                style={{
                                                    fontSize: "56px",
                                                    fontFamily: "Rye, serif",
                                                    color: "#8C58F5",
                                                }}
                                            >
                                                {myBizBucks?.balance>0 ? myBizBucks?.balance : 0}
                                            </h4>
                                        </div>
                                        <div className="mx-auto text-center my-2 fs-3">
                                            <img
                                                className="mx-auto"
                                                src={blackBiz}
                                                alt=""
                                            />
                                        </div>

                                        <div>
                                            <h4 className="fw-bold fs-4 d-flex justify-content-between">
                                                Earn{" "}
                                                <img
                                                    style={{
                                                        transform:
                                                            "rotate(180deg)",
                                                    }}
                                                    src={longArrow}
                                                    alt=""
                                                />
                                            </h4>

                                            {bizBucksConfig?.length > 0 &&
                                                bizBucksConfig?.map(
                                                    (item, index) => {
                                                        if (
                                                            item?.configType ==
                                                            "earn"
                                                        )
                                                            return (
                                                                <p
                                                                    key={index}
                                                                    className="d-flex justify-content-between align-items-center my-2 cursor-pointer earnLinks"
                                                                    onClick={() => {
                                                                        if (
                                                                            item?.configKey ==
                                                                            "attend_an_event"
                                                                        ) {
                                                                            dispatch(
                                                                                contentActions.reset()
                                                                            );
                                                                            setOpen(
                                                                                true
                                                                            );
                                                                        }
                                                                        if (
                                                                            item?.configKey ==
                                                                            "back_a_business"
                                                                        ) {
                                                                            dispatch(
                                                                                eventActions.backABusinessStatusFunc(
                                                                                    true
                                                                                )
                                                                            );
                                                                        }
                                                                        if (
                                                                            item?.configKey ==
                                                                            "get_backed"
                                                                        ) {
                                                                            setShowModal1(
                                                                                true
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    <span>
                                                                        {
                                                                            item?.configLabel
                                                                        }
                                                                    </span>
                                                                    <span>
                                                                        +
                                                                        {
                                                                            item?.configValue
                                                                        }
                                                                    </span>
                                                                </p>
                                                            );
                                                    }
                                                )}
                                        </div>

                                        <div>
                                            <h4 className="fw-bold fs-4 d-flex justify-content-between">
                                                Reedem{" "}
                                                <img src={longArrow} alt="" />
                                            </h4>
                                           
                                            {bizBucksConfig?.length > 0 &&
                                                bizBucksConfig?.map(
                                                    (item, index) => {
                                                        if (
                                                            item?.configType ==
                                                            "redeem"
                                                        )
                                                            return (
                                                                <p
                                                                    key={index}
                                                                    className="d-flex justify-content-between align-items-center my-2 cursor-pointer redeemLinks"
                                                                    onClick={() => {
                                                                        if (
                                                                            item?.configKey ==
                                                                            "ceo_podcast"
                                                                        ) {
                                                                            setPodCastVisible(
                                                                                true
                                                                            );

                                                                        }
                                                                        if (
                                                                            item?.configKey ==
                                                                            "business_spotlight"
                                                                        ) {
                                                                            setSpotliteVisible(
                                                                                true
                                                                            );

                                                                            setEventDate("undefined")
                                                                        }
                                                                        if (
                                                                            item?.configKey ==
                                                                            "advertise"
                                                                        ) {
                                                                            navigate("/ad")
                                                                        }
                                                                    }}
                                                                >
                                                                    <span>
                                                                        {
                                                                            item?.configLabel
                                                                        }
                                                                    </span>
                                                                    <span>
                                                                        -
                                                                        {
                                                                            item?.configValue
                                                                        }
                                                                    </span>
                                                                </p>
                                                            );
                                                    }
                                                )}
                                        </div>
                                    </div>

                                    <div
                                        className="mt-0 mt-xl-4 d-flex justify-content-center align-items-center adContainer"
                                        style={{
                                            borderRadius: "10px",
                                            width: "100%",
                                        }}
                                    >
                                        <div className="w-75">
                                            <img
                                                src={
                                                    homeAdvertisements[randomAd]
                                                        ?.advertisement_image
                                                }
                                                style={{
                                                    width: "100%",
                                                    height: "auto",
                                                    objectFit: "cover",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Add Section End  */}
                        </div>
                    </div>

                    <RequestEndorse
                        id={myCompany?.id}
                        showModal={showModal1}
                        setShowModal={setShowModal1}
                    />
                    {/* Dashboard Content Section End  */}

                    {/* Success Stories Slider Section Start  */}
                    {
                        <div
                            className="col-12"
                            style={{ marginBottom: "100px" }}
                        >
                            <div className="row gy-4">
                                <div className="col-12">
                                    <h3 className="fs-2 fw-bolder text-secondary">
                                        Recent Success Stories
                                    </h3>
                                </div>
                                <div className="d-flex w-100 ">
                            
                                {
                                   recentStories?.backing_details?.length>0 && recentStories?.backing_details?.length<=2 && recentStories?.backing_details?.map((item, index) => {
                                        return (
                                            <HomeCardFive
                                                key={index}
                                                item={item}
                                                index={index}
                                                backed_company_detail={
                                                    recentStories?.backed_company_detail
                                                }
                                                backer_company_detail={
                                                    recentStories?.backer_company_detail
                                                }
                                            />
                                        );
                                    })
                                }
                                </div>
                                {
                               recentStories?.backing_details?.length>2 && <div className="slider-container">
                                    <Slider {...settings}>
                                        {/* {memoizedHomeCardFive} */}
                                        {
                                           recentStories?.backing_details?.length>0 && recentStories?.backing_details?.map((item, index) => {
                                                return (
                                                    <HomeCardFive
                                                        key={index}
                                                        item={item}
                                                        index={index}
                                                        backed_company_detail={
                                                            recentStories?.backed_company_detail
                                                        }
                                                        backer_company_detail={
                                                            recentStories?.backer_company_detail
                                                        }
                                                    />
                                                );
                                            })
                                        }
                                        
                                    </Slider>
                                </div>}
                                {recentStories?.backing_details?.length==0 &&
                                <div className="slider-container">
                                        {<h2 className="my-3 text-center fw-bold">No records found</h2>}
                                </div>}
                                
                                
                            
                                

                                {/* {memoizedHomeCardFive ? <div className="slider-container">
                                    <Slider {...settings}>
                                        {
                                           recentStories?.backing_details?.length>0 && recentStories?.backing_details?.slice(0,3)?.map((item, index) => {
                                                return (
                                                    <HomeCardFive
                                                        key={index}
                                                        item={item}
                                                        index={index}
                                                        backed_company_detail={
                                                            recentStories?.backed_company_detail
                                                        }
                                                        backer_company_detail={
                                                            recentStories?.backer_company_detail
                                                        }
                                                    />
                                                );
                                            })
                                        }
                                        
                                    </Slider>
                                </div>:<div className="slider-container">
                                        {<h2 className="my-3 text-center fw-bold">No records found</h2>}
                                </div>} */}
                            </div>
                        </div>
                    }
                    {/* Success Stories Slider Section ENd  */}
                </div>
            </div>

            <Modal
                size="md"
                backdrop
                active={podCastVisible}
                toggler={() => {
                    setPodCastVisible(false);
                }}
            >
                <ModalBody>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="row position-relative">
                                    <div
                                        className="my-4 d-flex justify-content-between align-items-center flex-wrap flex-md-nowrap"
                                        style={{
                                            background: "#142252",
                                            padding: "15px 25px",
                                            borderRadius: "5px",
                                        }}
                                    >
                                        <div className="text-left px-3">
                                            <h2
                                                className="fw-bold text-white pb-3"
                                                style={{ fontSize: "30px" }}
                                            >
                                                Request to be on a <br />{" "}
                                                Podcast
                                            </h2>
                                            <p
                                                style={{
                                                    fontSize: "14px",
                                                    color: "white",
                                                }}
                                            >
                                                Description: Be Showcased on our
                                                CEO Podcast with our Founder{" "}
                                            </p>
                                        </div>
                                        <div
                                            style={{
                                                background: "white",
                                                width: "145px",
                                                height: "145px",
                                                borderRadius: "100%",
                                                padding: "10px",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <h3
                                                style={{
                                                    fontSize: "30px",
                                                    color: "#f4c58f",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {
                                                    bizBucksConfig?.find(
                                                        (item) =>
                                                            item?.configKey ==
                                                            "ceo_podcast"
                                                    )?.configValue
                                                }
                                            </h3>
                                            <h2
                                                style={{ color: "#142252" }}
                                                className="fs-5 fw-bolder"
                                            >
                                                BizBucks
                                            </h2>
                                            <h2
                                                style={{ color: "#142252" }}
                                                className="fs-5 fw-bolder"
                                            >
                                                Required
                                            </h2>
                                        </div>
                                    </div>

                                    <div className="w-100 my-2 text-start mx-auto">
                                        <h2 className="fs-4 fw-bold">
                                            Make a Request Here
                                        </h2>
                                    </div>

                                    <div className="my-2">
                                        <div className="col-12 fs-6 fw-bold d-flex justify-content-between align-items-center mb-3">
                                            <div>
                                                Name of Person being featured{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 my-2">
                                        <div className="row">
                                            <div className="col-12 fs-6 fw-bold d-flex justify-content-between align-items-center mb-3">
                                                <div>
                                                    Attendee{" "}
                                                    <span className="text-danger">
                                                        *
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-4 my-1">
                                                <input
                                                    type="text"
                                                    className="form-control p-2 col-4"
                                                    placeholder="First Name"
                                                    value={
                                                        podCastuser?.first_name
                                                    }
                                                    onChange={(e) => {
                                                        setPodCastUser({
                                                            ...podCastuser,
                                                            first_name:
                                                                e?.target
                                                                    ?.value,
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-4 my-1">
                                                <input
                                                    type="text"
                                                    className="form-control p-2 col-4"
                                                    placeholder="Last Name"
                                                    value={
                                                        podCastuser?.last_name
                                                    }
                                                    onChange={(e) => {
                                                        setPodCastUser({
                                                            ...podCastuser,
                                                            last_name:
                                                                e?.target
                                                                    ?.value,
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-4 my-1">
                                                <input
                                                    type="text"
                                                    className="form-control p-2 col-4"
                                                    placeholder="Email ID"
                                                    value={podCastuser?.email}
                                                    onChange={(e) => {
                                                        setPodCastUser({
                                                            ...podCastuser,
                                                            email: e?.target
                                                                ?.value,
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-4 my-1">
                                                <input
                                                    type="text"
                                                    disabled
                                                    className="form-control p-2 col-4"
                                                    placeholder="Company Name"
                                                    value={
                                                        myCompany?.company_name
                                                    }
                                                    onChange={(e) => {}}
                                                />
                                            </div>
                                            <div className="col-4 my-1">
                                                <input
                                                    type="text"
                                                    className="form-control p-2 col-4"
                                                    placeholder="Job Title"
                                                    value={
                                                        podCastuser?.job_title
                                                    }
                                                    onChange={(e) => {
                                                        setPodCastUser({
                                                            ...podCastuser,
                                                            job_title:
                                                                e?.target
                                                                    ?.value,
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="row w-100 my-2">
                                            <div className="col-12 fs-6 fw-bold mb-3">
                                                <div>
                                                    Description{" "}
                                                    <span className="text-danger">
                                                        *
                                                    </span>
                                                </div>
                                                <div>
                                                    <p
                                                        style={{
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        Enter a short
                                                        description of your
                                                        presentation topic (max
                                                        200 characters)
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-12 my-1">
                                                <textarea
                                                    type="textarea"
                                                    rows="2"
                                                    className="form-control p-2 col-4"
                                                    placeholder="Enter a description"
                                                    value={
                                                        podCastuser?.description
                                                    }
                                                    onChange={(e) => {
                                                        if(e?.target?.value?.length<200){
                                                            setPodCastUser({
                                                                ...podCastuser,
                                                                description:
                                                                    e?.target
                                                                        ?.value,
                                                            });
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="row my-3 w-100">
                                            <div className="my-3 w-100">
                                                <p style={{ fontSize: "12px" }}>
                                                    *A member of the Backbone
                                                    Team will contact you to
                                                    confirm your spot after
                                                    submitting the form
                                                </p>
                                            </div>
                                            <div className="col-12 d-flex justify-content-between align-items-center">
                                                <button
                                                    className="bg-primary text-white"
                                                    style={{
                                                        borderRadius: "30px",
                                                        padding: "10px 25px",
                                                    }}
                                                    onClick={() => {
                                                        validateAndSubmitRequest();
                                                    }}
                                                >
                                                    Submit Request
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <span
                                        style={{
                                            position: "absolute",
                                            top: "0",
                                            right: "-10px",
                                            cursor: "pointer",
                                            width: "max-content",
                                        }}
                                        onClick={() => {
                                            setPodCastVisible(false);
                                        }}
                                    >
                                        <RxCross1
                                            style={{ transform: "scale(1.2)" }}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <Modal
                size="md"
                backdrop
                active={podCastVisible2}
                toggler={() => {
                    setPodCastVisible2(false);
                }}
                className=""
                style={{ background: "#deebff" }}
            >
                <ModalBody>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div
                                        className="col-12 pb-0"
                                        style={{ width: "300px" }}
                                    >
                                        <div>
                                            <div className="d-flex justify-content-center align-items-center">
                                                {success ? (
                                                    <span>
                                                        <CgNotes
                                                            className="text-primary"
                                                            style={{
                                                                fontSize:
                                                                    "30px",
                                                            }}
                                                        />
                                                    </span>
                                                ) : (
                                                    <span>
                                                        <img
                                                            style={{
                                                                width: "40px",
                                                                height: "40px",
                                                                borderRadius:
                                                                    "100%",
                                                            }}
                                                            src="https://cdn-icons-png.flaticon.com/512/6711/6711656.png"
                                                        />
                                                    </span>
                                                )}
                                            </div>

                                            <div className="mt-3">
                                                <h3 className="ms-4 text-center fs-5 fw-bold">
                                                    {success
                                                        ? "Request Sent!"
                                                        : "Request Already Sent!"}
                                                </h3>
                                                <p
                                                    style={{
                                                        textAlign: "center",
                                                        fontSize: "11px",
                                                        marginTop: "15px",
                                                    }}
                                                >
                                                    {message}
                                                </p>
                                                <p
                                                    style={{
                                                        textAlign: "center",
                                                        fontSize: "11px",
                                                        marginTop: "8px",
                                                    }}
                                                >
                                                    *We will not deduct BizBucks
                                                    until confirmed
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <Modal
                size="md"
                backdrop
                active={podCastVisible3}
                toggler={() => {
                    setPodCastVisible3(false);
                }}
                className="bg-success"
            >
                <ModalBody>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div
                                        className="col-12 pb-0"
                                        style={{ width: "300px" }}
                                    >
                                        <div>
                                            <div className="d-flex justify-content-start align-items-center">
                                                <span>
                                                    <img
                                                        style={{
                                                            width: "40px",
                                                            height: "40px",
                                                            borderRadius:
                                                                "100%",
                                                        }}
                                                        src="https://cdn-icons-png.flaticon.com/512/6711/6711656.png"
                                                    />
                                                </span>
                                                <h3 className="ms-3w-75 px-2 text-center fs-6 fw-bold">
                                                    Insufficient BizBucks
                                                </h3>
                                            </div>
                                            <div className="text-center my-3 w-100">
                                                <h4
                                                    className="fw-bold"
                                                    style={{ fontSize: "13px" }}
                                                >
                                                    {message
                                                        ? message
                                                        : "Server Error!"}
                                                </h4>
                                                <h4
                                                    className="fw-bold w-100 my-2"
                                                    style={{ fontSize: "13px" }}
                                                >
                                                    Consider these ways to earn!
                                                </h4>
                                            </div>
                                            <div className="mt-3">
                                                {bizBucksConfig?.length > 0 &&
                                                    bizBucksConfig?.map(
                                                        (item, index) => {
                                                            if (
                                                                item?.configType ==
                                                                "earn"
                                                            )
                                                                return (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                        className="d-flex justify-content-between align-items-center"
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                fontSize:
                                                                                    "11px",
                                                                            }}
                                                                        >
                                                                            {
                                                                                item?.configLabel
                                                                            }
                                                                        </span>
                                                                        <span
                                                                            style={{
                                                                                fontSize:
                                                                                    "11px",
                                                                            }}
                                                                        >
                                                                            +{" "}
                                                                            {
                                                                                item?.configValue
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                );
                                                        }
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <Modal
                size="md"
                backdrop
                active={spotliteVisible}
                toggler={() => {
                    setSpotliteVisible(false);
                }}
            >
                <ModalBody>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div
                                    className="row position-relative"
                                    style={{
                                        overflowX: "hidden",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    <div
                                        className="my-4 d-flex justify-content-between align-items-center flex-wrap flex-md-nowrap"
                                        style={{
                                            background: "#142252",
                                            padding: "15px 25px",
                                            borderRadius: "5px",
                                            width: "100%",
                                            margin: "0 auto",
                                            overflowX: "hidden",
                                        }}
                                    >
                                        <div
                                            className="text-left w-100 w-md-75 px-2"
                                            style={{ maxWidth: "100%" }}
                                        >
                                            <h2
                                                className="fw-bold text-white pb-3"
                                                style={{ fontSize: "24px" }}
                                            >
                                                Request to present at
                                                <br /> a Business Spotlight
                                            </h2>
                                            <p
                                                style={{
                                                    fontSize: "14px",
                                                    color: "white",
                                                }}
                                            >
                                                Description: Give a 10 minute
                                                presentation/industry talk and
                                                showcase your business at a
                                                future Backbone event!
                                            </p>
                                        </div>
                                        <div
                                            style={{
                                                background: "white",
                                                minWidth: "150px",
                                                height: "150px",
                                                borderRadius: "100%",
                                                padding: "10px",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <h3
                                                style={{
                                                    fontSize: "28px",
                                                    fontWeight: "bold",
                                                    color: "#f4c58f",
                                                }}
                                            >
                                                {
                                                    bizBucksConfig?.find(
                                                        (item) =>
                                                            item?.configKey ===
                                                            "business_spotlight"
                                                    )?.configValue
                                                }
                                            </h3>
                                            <h2
                                                style={{ color: "#142252" }}
                                                className="fs-5 fw-bolder"
                                            >
                                                BizBucks
                                            </h2>
                                            <h2
                                                style={{ color: "#142252" }}
                                                className="fs-5 fw-bolder"
                                            >
                                                Required
                                            </h2>
                                        </div>
                                    </div>

                                    <div className="w-100 my-2 text-start mx-auto">
                                        <h2 className="fs-4 fw-bold">
                                            Make a Request Here
                                        </h2>
                                    </div>

                                    <div className="my-2">
                                        <div className="col-12 fs-6 fw-bold d-flex justify-content-between align-items-center mb-3">
                                            <div>
                                                Available Dates{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </div>
                                        </div>
                                        <select
                                            className="w-100 form-control p-2 mt-3"
                                            value={evenetDate}
                                            onChange={(e) => {
                                                setSpotLiteData({
                                                    ...spotLiteData,
                                                    event_id: e?.target?.value,
                                                });
                                    
                                                setEventDate(
                                                    events?.find(
                                                        (item) =>
                                                            item.id ===
                                                            e?.target?.value
                                                    )
                                                );
                                            }}
                                        >
                                            <option value="undefined" disabled>Date</option>
                                            {events?.length > 0 &&
                                                events?.map((item, index) => {
                                                    return (
                                                        <option
                                                            key={index}
                                                            value={item?.id}
                                                        >
                                                            {dateFormat(
                                                                new Date(
                                                                    item?.eventDate
                                                                )
                                                            )}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </div>

                                    <div className="col-12 my-2">
                                        <div className="row">
                                            <div className="col-12 fs-6 fw-bold d-flex justify-content-between align-items-center mb-3">
                                                <div>
                                                    Attendee{" "}
                                                    <span className="text-danger">
                                                        *
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-6 my-1">
                                                <input
                                                    type="text"
                                                    className="form-control p-2"
                                                    placeholder="First Name"
                                                    value={
                                                        spotLiteData?.first_name
                                                    }
                                                    onChange={(e) => {
                                                        setSpotLiteData({
                                                            ...spotLiteData,
                                                            first_name:
                                                                e?.target
                                                                    ?.value,
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-6 my-1">
                                                <input
                                                    type="text"
                                                    className="form-control p-2"
                                                    placeholder="Last Name"
                                                    value={
                                                        spotLiteData?.last_name
                                                    }
                                                    onChange={(e) => {
                                                        setSpotLiteData({
                                                            ...spotLiteData,
                                                            last_name:
                                                                e?.target
                                                                    ?.value,
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-6 my-1">
                                                <input
                                                    type="text"
                                                    className="form-control p-2"
                                                    placeholder="Email ID"
                                                    value={spotLiteData?.email}
                                                    onChange={(e) => {
                                                        setSpotLiteData({
                                                            ...spotLiteData,
                                                            email: e?.target
                                                                ?.value,
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-6 my-1">
                                                <input
                                                    type="text"
                                                    disabled
                                                    className="form-control p-2"
                                                    placeholder="Company Name"
                                                    value={
                                                        myCompany?.company_name
                                                    }
                                                    onChange={(e) => {}}
                                                />
                                            </div>
                                            <div className="col-12 my-1">
                                                <input
                                                    type="text"
                                                    className="form-control p-2"
                                                    placeholder="Job Title"
                                                    value={
                                                        spotLiteData?.job_title
                                                    }
                                                    onChange={(e) => {
                                                        setSpotLiteData({
                                                            ...spotLiteData,
                                                            job_title:
                                                                e?.target
                                                                    ?.value,
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="row w-100 my-2">
                                            <div className="col-12 fs-6 fw-bold mb-3">
                                                <div>
                                                    Description{" "}
                                                    <span className="text-danger">
                                                        *
                                                    </span>
                                                </div>
                                                <div>
                                                    <p
                                                        style={{
                                                            fontSize: "11px",
                                                        }}
                                                    >
                                                        Enter a short
                                                        description of your
                                                        presentation topic (max
                                                        200 characters)
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-12 my-1">
                                                <textarea
                                                    type="textarea"
                                                    rows="2"
                                                    className="form-control p-2 col-4"
                                                    placeholder="Enter a description"
                                                    value={
                                                        spotLiteData?.description
                                                    }
                                                    onChange={(e) => {
                                                        if(e?.target?.value?.length<200){
                                                            setSpotLiteData({
                                                                ...spotLiteData,
                                                                description:
                                                                    e?.target
                                                                        ?.value,
                                                            });
                                                        }                                                        
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="row my-3 w-100">
                                            <div className="my-3 w-100">
                                                <p style={{ fontSize: "10px" }}>
                                                    *A member of the Backbone
                                                    Team will contact you to
                                                    confirm your spot after
                                                    submitting the form
                                                </p>
                                            </div>
                                            <div className="col-12 d-flex justify-content-between align-items-center">
                                                <button
                                                    className="bg-primary text-white"
                                                    style={{
                                                        borderRadius: "30px",
                                                        padding: "10px 25px",
                                                    }}
                                                    onClick={() => {
                                                        validateAndSubmitRequestSpotlite();
                                                    }}
                                                >
                                                    Submit Request
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <span
                                        style={{
                                            position: "absolute",
                                            top: "0",
                                            right: "-10px",
                                            cursor: "pointer",
                                            width: "max-content",
                                        }}
                                        onClick={() => {
                                            setSpotliteVisible(false);
                                        }}
                                    >
                                        <RxCross1
                                            style={{ transform: "scale(1.2)" }}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* <Modal size="md" backdrop active={visible2} toggler={handleClose2} className="" style={{background:"#deebff"}}>
                <ModalBody>
                      <div className='container-fluid'>
                         <div className='row'>
                              <div className='col-12'>
                                  <div className='row'>
                                       <div className='col-12 pb-0' style={{width:"300px"}}>
                                           <div>
                                               <div className='d-flex justify-content-center align-items-center'>
                                                   <span>
                                                       <CgNotes className='text-primary' style={{fontSize:"30px"}}/>
                                                   </span>
                                               </div>

                                               <div className='mt-3'>
                                                  <h3 className='ms-4 text-center fs-5 fw-bold'>Request Sent!</h3>
                                                  <p style={{textAlign:"center",fontSize:"11px",marginTop:"15px"}}>A BackBone team member will be in touch with you shortly</p>
                                                  <p style={{textAlign:"center",fontSize:"11px",marginTop:"8px"}}>*We will not deduct BizBucks until confirmed</p>
                                               </div>
                                           </div>
                                       </div>
                                  </div>
                              </div>
                         </div>
                      </div>
                </ModalBody>
        </Modal>


        
        <Modal size="md" backdrop active={visible3} toggler={handleClose3} className="bg-success">
                <ModalBody>
                      <div className='container-fluid'>
                         <div className='row'>
                              <div className='col-12'>
                                  <div className='row'>
                                       <div className='col-12 pb-0' style={{width:"300px"}}>
                                           <div>
                                               <div className='d-flex justify-content-start align-items-center'>
                                                   <span>
                                                       <img style={{width:"40px",height:"40px",borderRadius:"100%"}} src="https://cdn-icons-png.flaticon.com/512/6711/6711656.png"/>
                                                   </span>
                                                   <h3 className='ms-4 text-center fs-6 fw-bold'>Insufficient BizBucks</h3>
                                               </div>
                                               <div className='text-center my-3 w-100'>
                                                  <h4 className='fw-bold' style={{fontSize:"13px"}}>Minimum 200 BizBucks needed to <br/> redeem this offer</h4>
                                                  <h4 className='fw-bold w-100 my-2' style={{fontSize:"13px"}}>Consider these ways to earn!</h4>
                                               </div>
                                               <div className='mt-3'>
                                                  <div className='d-flex justify-content-between align-items-center'>
                                                      <span style={{fontSize:"11px"}}>Attend a BackBone event</span>
                                                      <span style={{fontSize:"11px"}}>+100</span>
                                                  </div>
                                                  <div className='my-2 d-flex justify-content-between align-items-center'>
                                                      <span style={{fontSize:"11px"}}>Post a perk</span>
                                                      <span style={{fontSize:"11px"}}>+100</span>
                                                  </div>
                                                  <div className='d-flex justify-content-between align-items-center'>
                                                      <span style={{fontSize:"11px"}}>Back a Business</span>
                                                      <span style={{fontSize:"11px"}}>+100</span>
                                                  </div>
                                                  <div className='my-2 d-flex justify-content-between align-items-center'>
                                                      <span style={{fontSize:"11px"}}>Get Backed</span>
                                                      <span style={{fontSize:"11px"}}>+100</span>
                                                  </div>
                                               </div>
                                           </div>
                                       </div>
                                  </div>
                              </div>
                         </div>
                      </div>
                </ModalBody>
        </Modal> */}

            {/* <Modal size="md" backdrop active={visible} toggler={handleClose}>
                <ModalBody>
                      <div className='container-fluid'>
                         <div className='row'>
                              <div className='col-12'>
                                  <div className='row position-relative' >
                                       <div>
                                           <h2 className='fw-bolder fs-4'>Present at Spotlight popup</h2>
                                       </div>
                                       <div className='my-4 d-flex justify-content-between align-items-center' style={{background:"#142252",padding:"15px 25px",borderRadius:"5px"}}>
                                           <div className='text-center w-75 px-3'>
                                              <h2 className='fw-bold text-white pb-3' style={{fontSize:"30px"}}>Present at a <br/> Business Spotlight</h2>
                                              <p style={{fontSize:"12px",color:"white"}}>Description: Give a 10 minute presentation during one of our Power Hour events. Give an industry talk and showcase your business!</p> 
                                           </div>
                                           <div style={{background:"white",width:"145px",height:"145px",borderRadius:"100%",padding:"10px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"
                                           }}>
                                                <h3 style={{fontSize:"30px",color:"#f4c58f"}}>200</h3>
                                                <h2 style={{color:"#142252"}} className='fs-5 fw-bolder'>BizBucks</h2>
                                                <h2 style={{color:"#142252"}} className='fs-5 fw-bolder'>Required</h2>
                                           </div>
                                       </div>

                                       <div className='w-100 my-2 text-start mx-auto'>
                                          <h2 className='fs-4 fw-bold'>Make a Request Here</h2>
                                       </div>

                                       <div className='my-2'>
                                              <div className='col-12 fs-6 fw-bold d-flex justify-content-between align-items-center mb-3'>
                                                 <div>
                                                    Available Dates <span className='text-danger'>*</span>
                                                 </div>
                                              </div>
                                           <select className='w-50 form-control p-2 mt-3'>
                                               <option>Date</option>
                                               <option>Tuesday, June 25, 1pm AEST</option>
                                               <option>Wednesday, July 8, 1pm AEST</option>
                                               <option>Wednesday, July 24, 1pm AEST</option>
                                           </select>
                                       </div>
                                       <div className='col-12 my-2'>
                                           <div className='row'>
                                              <div className='col-12 fs-6 fw-bold d-flex justify-content-between align-items-center mb-3'>
                                                 <div>
                                                   Attendee <span className='text-danger'>*</span>
                                                 </div>
                                              </div>
                                              <div className='col-4 my-1'>
                                                <input type="text" className='form-control p-2 col-4' placeholder='First Name'/>
                                              </div>
                                              <div className='col-4 my-1'>
                                                <input type="text" className='form-control p-2 col-4' placeholder='Last Name'/>
                                              </div>
                                              <div className='col-4 my-1'>
                                                <input type="text" className='form-control p-2 col-4' placeholder='Email ID'/>
                                              </div>
                                              <div className='col-4 my-1'>
                                                <input type="text" className='form-control p-2 col-4' placeholder='Company Name'/>
                                              </div>    
                                              <div className='col-4 my-1'>
                                                <input type="text" className='form-control p-2 col-4' placeholder='Job Title'/>
                                              </div>                                              
                                           </div>



                                           <div className='row w-100 my-2'>
                                              <div className='col-12 fs-6 fw-bold mb-3'>
                                                 <div>
                                                   Description <span className='text-danger'>*</span>
                                                 </div>
                                                 <div>
                                                    <p style={{fontSize:"11px"}}>Enter a short description of your presentation topic (max 200 characters)</p>
                                                 </div>
                                              </div>
                                              <div className='col-12 my-1'>
                                                <textarea type="textarea" rows="2" className='form-control p-2 col-4' placeholder='Enter a description'/>
                                              </div>                                             
                                           </div>

                                           <div className='row my-3 w-100'>
                                               <div className='my-3 w-100'>
                                                 <p style={{fontSize:"10px"}}>*A member of the Backbone Team will contact you to confirm your spot after submitting the form</p>
                                               </div>
                                               <div className='col-12 d-flex justify-content-between align-items-center'>
                                                     <button className='bg-primary text-white' style={{borderRadius:"30px",padding:"10px 25px"}}>Submit Request</button>
                                               </div>
                                           </div>
                                       </div>
                                       
                                       <span style={{position:"absolute",top:"0px",left:"95%",cursor:"pointer"}} onClick={()=>{
                                        handleClose()
                                       }}>
                                         <RxCross1 style={{transform:"scale(1.2)"}}/>
                                      </span>
                                       
                                  </div>
                              </div>
                         </div>
                      </div>
                </ModalBody>
        </Modal>

        <Modal size="md" backdrop active={visible2} toggler={handleClose2} className="" style={{background:"#deebff"}}>
                <ModalBody>
                      <div className='container-fluid'>
                         <div className='row'>
                              <div className='col-12'>
                                  <div className='row'>
                                       <div className='col-12 pb-0' style={{width:"300px"}}>
                                           <div>
                                               <div className='d-flex justify-content-center align-items-center'>
                                                   <span>
                                                       <CgNotes className='text-primary' style={{fontSize:"30px"}}/>
                                                   </span>
                                               </div>

                                               <div className='mt-3'>
                                                  <h3 className='ms-4 text-center fs-5 fw-bold'>Request Sent!</h3>
                                                  <p style={{textAlign:"center",fontSize:"11px",marginTop:"15px"}}>A BackBone team member will be in touch with you shortly</p>
                                                  <p style={{textAlign:"center",fontSize:"11px",marginTop:"8px"}}>*We will not deduct BizBucks until confirmed</p>
                                               </div>
                                           </div>
                                       </div>
                                  </div>
                              </div>
                         </div>
                      </div>
                </ModalBody>
        </Modal>


        
        <Modal size="md" backdrop active={visible3} toggler={handleClose3} className="bg-success">
                <ModalBody>
                      <div className='container-fluid'>
                         <div className='row'>
                              <div className='col-12'>
                                  <div className='row'>
                                       <div className='col-12 pb-0' style={{width:"300px"}}>
                                           <div>
                                               <div className='d-flex justify-content-start align-items-center'>
                                                   <span>
                                                       <img style={{width:"40px",height:"40px",borderRadius:"100%"}} src="https://cdn-icons-png.flaticon.com/512/6711/6711656.png"/>
                                                   </span>
                                                   <h3 className='ms-4 text-center fs-6 fw-bold'>Insufficient BizBucks</h3>
                                               </div>
                                               <div className='text-center my-3 w-100'>
                                                  <h4 className='fw-bold' style={{fontSize:"13px"}}>Minimum 200 BizBucks needed to <br/> redeem this offer</h4>
                                                  <h4 className='fw-bold w-100 my-2' style={{fontSize:"13px"}}>Consider these ways to earn!</h4>
                                               </div>
                                               <div className='mt-3'>
                                                  <div className='d-flex justify-content-between align-items-center'>
                                                      <span style={{fontSize:"11px"}}>Attend a BackBone event</span>
                                                      <span style={{fontSize:"11px"}}>+100</span>
                                                  </div>
                                                  <div className='my-2 d-flex justify-content-between align-items-center'>
                                                      <span style={{fontSize:"11px"}}>Post a perk</span>
                                                      <span style={{fontSize:"11px"}}>+100</span>
                                                  </div>
                                                  <div className='d-flex justify-content-between align-items-center'>
                                                      <span style={{fontSize:"11px"}}>Back a Business</span>
                                                      <span style={{fontSize:"11px"}}>+100</span>
                                                  </div>
                                                  <div className='my-2 d-flex justify-content-between align-items-center'>
                                                      <span style={{fontSize:"11px"}}>Get Backed</span>
                                                      <span style={{fontSize:"11px"}}>+100</span>
                                                  </div>
                                               </div>
                                           </div>
                                       </div>
                                  </div>
                              </div>
                         </div>
                      </div>
                </ModalBody>
        </Modal> */}

            {open && (
                <CommonModalBackboneEvent
                    setOpen={setOpen}
                    open={open}
                    visible2={visible2}
                    setEventType={setEventType}
                    setVisible2={setVisible2}
                    setEventDate={setEventDate}
                />
            )}
            <Modal
                size="md"
                backdrop
                active={visible2}
                toggler={handleClose2}
                className="bg-success"
            >
                <ModalBody>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div
                                        className="col-12 pb-0"
                                        style={{ width: "300px" }}
                                    >
                                        <div>
                                            <div className="d-flex justify-content-start align-items-center">
                                                <span>
                                                    <img
                                                        style={{
                                                            width: "45px",
                                                            height: "45px",
                                                            borderRadius:
                                                                "100%",
                                                        }}
                                                        src="https://cdn-icons-png.flaticon.com/512/4315/4315445.png"
                                                    />
                                                </span>
                                               {eventType=="Register" &&  <h3 className="ms-4 text-center fs-5 fw-bold">
                                                    You have been <br />{" "}
                                                    registered!{" "}
                                                </h3>}
                                                {eventType=="Edit" &&  <h4 className="ms-4 text-center fs-5 fw-bold">
                                                    You event detail  <br />{" "}
                                                    has been updated!{" "}
                                                </h4>}

                                            </div>
                                            <div className="mt-3">
                                                <h5
                                                    className="fw-bold"
                                                    style={{ fontSize: "15px" }}
                                                >
                                                    Power Hour on{" "}
                                                    {/* {formatDateTime(
                                                        evenetDate?.eventDate
                                                    )} */}
                                                    {moment(evenetDate?.eventDate).format('MMMM D')} at {moment(evenetDate?.eventDate).format('h:mm a')}
                                                </h5>
                                                <p
                                                    style={{
                                                        textAlign: "center",
                                                        fontSize: "11px",
                                                        marginTop: "15px",
                                                    }}
                                                >
                                                    You will receive a Calendar
                                                    Invite shortly with the zoom
                                                    link for the event. 100
                                                    BizBucks will be deposited
                                                    in your account AFTER you
                                                    have attended the event. You
                                                    may edit or cancel
                                                    registration by clicking the
                                                    event on the calendar
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    );
};

export default Dashboard;
