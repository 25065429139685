import React, { useEffect, useState } from 'react'
import { MdOutlineEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import BackboneLogo from "../../../assets/backbone-logo.svg";
import { deleteBackingDetailsData,editBackingDetailsData } from '../../../data/services/home/content';
import Modal from "@material-tailwind/react/Modal";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import ModalBody from "@material-tailwind/react/ModalBody";
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router';

const ProfileActivityCard = ({type,activity,item,index,deleteBackingDetails}) => {
  const toast = useToast();
  const navigate=useNavigate()
  const [id,setid]=useState(null)
  const [visible,setVisible]=useState(false)
  const [visible2,setVisible2]=useState(false)
  const [backingDetails,setbackingDetails]=useState({
    interaction_type:item?.interaction_type,
    free_text:item?.interaction_type=="Other: Enter Free Text (200 max)"?item?.free_text:""
  })

  useEffect(()=>{
     if(item){
      setbackingDetails({
           interaction_type:item?.interaction_type,
           free_text:item?.interaction_type=="Other: Enter Free Text (200 max)"?item?.free_text:""
      })
     }
  },[item])

  const deleteBackingDetail=async (backing_id,action_type)=>{
     if(backing_id && action_type=="delete"){
       setid(backing_id)
       setVisible(true)
     }else if(backing_id && action_type=="edit"){
       setid(backing_id)
       setVisible2(true)
     }
  }
  return (
    <div className='p-4 mt-2' style={{backgroundColor:"#f2f2f2",borderRadius:"25px"}}>
    <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
        <div>
        <div >
          
          {
            type=="We" &&  <img style={{minWidth:"35px",height:"35px",borderRadius:"100%"}} src={activity?.filter((item2)=>item2?.id==item?.backed_company_id)[0]?.company_logo?activity?.filter((item2)=>item2?.id==item?.backed_company_id)[0]?.company_logo:"https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"}/>
          }
          {
            type=="Us" &&  <img style={{minWidth:"35px",height:"35px",borderRadius:"100%"}} src={activity?.filter((item2)=>item2?.id==item?.backer_company_id)[0]?.company_logo?activity?.filter((item2)=>item2?.id==item?.backer_company_id)[0]?.company_logo:"https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"}/>

          }
            
        </div>
        </div>
        <h3 className='text-primary fs-5 ms-3 text-center mx-auto cursor-pointer' onClick={()=>{
            if(type=="Us"){
              navigate(`/company/${item?.backer_company_id}`)
            }else{
              navigate(`/company/${item?.backed_company_id}`)
            }
        }}>
          {
            type=="We" ? activity?.find((item2)=>item2?.id==item?.backed_company_id)?.company_name : activity?.find((item2)=>item2?.id==item?.backer_company_id)?.company_name
          }
        </h3>
    </div>
   
   <div className='w-100' style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
      <div className='my-2' style={{width:"90%",margin:"auto",position:"relative",background:"white",padding:"15px 5px",borderRadius:"40px"}}>
            <div style={{fontSize:"17px",margin:"auto",textAlign:"center"}}>
                {item?.interaction_type=="Other: Enter Free Text (200 max)"?item?.free_text:item?.interaction_type}
            </div>
        </div>
        <div className='ms-3 d-flex flex-column justify-content-end' style={{width:"40px"}}>
             <span className='my-2 cursor-pointer'>
                 <MdOutlineEdit style={{transform:"scale(1.6)"}} onClick={()=>{
                  deleteBackingDetail(item?.backing_id,"edit")
             }}/>
             </span>
             <span className='my-2 cursor-pointer' onClick={()=>{
                  deleteBackingDetail(item?.backing_id,"delete")
             }}>
                  <MdDeleteOutline style={{transform:"scale(1.6)"}} />
             </span>
        </div>
   </div>


   <Modal size="md" backdrop active={visible} toggler={()=>{
    setVisible(false)
   }} className="bg-success">
                <ModalBody>
                      <div className='container-fluid'>
                         <div className='row'>
                              <div className='col-12'>
                                  <div className='row'>
                                       <div className='col-12 pb-0' style={{width:"300px"}}>
                                           <div>
                                               <div className='d-flex flex-column justify-content-start align-items-center'>
                                                   <span>
                                                       <img style={{width:"40px",height:"40px",borderRadius:"100%"}} src="https://cdn-icons-png.flaticon.com/512/6897/6897039.png"/>
                                                   </span>
                                                   <h3 className='ms-4 text-center fs-5 fw-bold'>Are you sure?? </h3>
                                               </div>
                                               <div className='mt-2 text-center'>
                                                  <p className='' style={{fontSize:"15px"}}>You want able to revert it!</p>
                                               </div>
                                               <div className='mt-3 d-flex justify-content-center align-items-center'>
                                                   <button className='text-white btn btn-primary' onClick={async ()=>{
                                                       try{
                                                            if(id){
                                                              const response=await deleteBackingDetailsData(id)
                                                              if(response?.success){
                                                                toast({
                                                                  title: `${response?.message}`,
                                                                  position: "top-right",
                                                                  duration: 3000,
                                                                  status: "success",
                                                                  isClosable: true,
                                                                })
                                                                deleteBackingDetails("delete")
                                                                setVisible(false)
                                                              }else{
                                                                toast({
                                                                  title: `${response?.message}`,
                                                                  position: "top-right",
                                                                  duration: 3000,
                                                                  status: "error",
                                                                  isClosable: true,
                                                                })
                                                              }
                                                            }
                                                       }catch(err){
                                                        toast({
                                                          title: `${err?.message}`,
                                                          position: "top-right",
                                                          duration: 3000,
                                                          status: "error",
                                                          isClosable: true,
                                                        })
                                                       }
                                                   }}>Delete</button>
                                                   <button className='btn ms-3 btn-danger' onClick={()=>{
                                                    setVisible(false)
                                                   }}> Cancel</button>
                                               </div>
                                           </div>
                                       </div>
                                  </div>
                              </div>
                         </div>
                      </div>
                </ModalBody>
   </Modal>


   <Modal size="lg" backdrop active={visible2} toggler={()=>{
    setVisible2(false)
   }} className="bg-success">
                <ModalBody>
                      <div className='container-fluid w-100'>
                         <div className='row'>
                              <div className='col-12'>
                                  <div className='row'>
                                       <div className='col-12 pb-0' style={{width:"300px"}}>
                                           <div>
                                               <div className='d-flex flex-column justify-content-start align-items-center'>
                                                  
                                                   <h3 className='ms-4 text-center fs-5 fw-bold'>Update Backing details</h3>
                                               </div>
                                               <div className='my-3'>
                                                  <select className='form-control p-2' value={backingDetails?.interaction_type} onChange={(e)=>{
                                                      setbackingDetails({
                                                          ...backingDetails,interaction_type:e?.target?.value
                                                      })
                                                  }}>
                                                      <option value="">Interaction</option>
                                                      <option value="Met at an event & highly rate">Met at an event & highly rate</option>
                                                      <option value="Partnered together & highly rate">Partnered together & highly rate</option>
                                                      <option value="Outstanding Partnership Outcome">Outstanding Partnership Outcome</option>
                                                      <option value="Other: Enter Free Text (200 max)">Other: Enter Free Text (200 max)</option>
                                                  </select>
                                                </div>
                                                {backingDetails?.interaction_type?.trim()=="Other: Enter Free Text (200 max)" && <div>
                                                    <textarea rows="2" className='form-control p-2' placeholder='Enter Text' value={backingDetails?.free_text} onChange={(e)=>{
                                                       if(e?.target?.value?.length<200){
                                                        setbackingDetails({
                                                          ...backingDetails,free_text:e?.target?.value
                                                        })
                                                       }
              
                                                    }}/>
                                                </div>}
                                               <div className='mt-3 d-flex justify-content-center align-items-center'>
                                                   <button className='text-white btn btn-primary' onClick={async ()=>{
                                                       try{
                                                            if(id){
                                                              if(backingDetails?.interaction_type?.trim()==""){
                                                                toast({
                                                                  title: `Select interaction type`,
                                                                  position: "top-right",
                                                                  duration: 3000,
                                                                  status: "error",
                                                                  isClosable: true,
                                                                })
                                                              }else if(backingDetails?.interaction_type?.trim()=="Other: Enter Free Text (200 max)" && !backingDetails?.free_text?.trim()){
                                                                  toast({
                                                                    title: `Enter free text`,
                                                                    position: "top-right",
                                                                    duration: 3000,
                                                                    status: "error",
                                                                    isClosable: true,
                                                                  })
                                                              }else{

                                                                const response=await editBackingDetailsData(id,backingDetails)
                                                                if(response?.success){
                                                                  toast({
                                                                    title: `${response?.message}`,
                                                                    position: "top-right",
                                                                    duration: 3000,
                                                                    status: "success",
                                                                    isClosable: true,
                                                                  })
                                                                  deleteBackingDetails("edit")
                                                                  setVisible2(false)
                                                                }else{
                                                                  toast({
                                                                    title: `${response?.message}`,
                                                                    position: "top-right",
                                                                    duration: 3000,
                                                                    status: "error",
                                                                    isClosable: true,
                                                                  })
                                                                }

                                                              }
                                                              // const response=await deleteBackingDetailsData(id)
                                                              // if(response?.success){
                                                              //   toast({
                                                              //     title: `${response?.message}`,
                                                              //     position: "top-right",
                                                              //     duration: 3000,
                                                              //     status: "success",
                                                              //     isClosable: true,
                                                              //   })
                                                              //   deleteBackingDetails("delete")
                                                              //   setVisible(false)
                                                              // }else{
                                                              //   toast({
                                                              //     title: `${response?.message}`,
                                                              //     position: "top-right",
                                                              //     duration: 3000,
                                                              //     status: "error",
                                                              //     isClosable: true,
                                                              //   })
                                                              // }
                                                            }
                                                       }catch(err){
                                                        toast({
                                                          title: `${err?.message}`,
                                                          position: "top-right",
                                                          duration: 3000,
                                                          status: "error",
                                                          isClosable: true,
                                                        })
                                                       }
                                                   }}>Confirm</button>
                                                   <button className='btn ms-3 btn-danger' onClick={()=>{
                                                    setVisible2(false)
                                                   }}> Cancel</button>
                                               </div>
                                           </div>
                                       </div>
                                  </div>
                              </div>
                         </div>
                      </div>
                </ModalBody>
   </Modal>

    
</div>
  )
}

export default ProfileActivityCard

