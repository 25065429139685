import React, { useEffect, useState } from "react";
import Button from "@material-tailwind/react/Button";
import styled from "styled-components";
import { BsCircle } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "@material-ui/core";
import BulletDarkIcon from "../../../assets/BulletDark.svg";
import "./UpgradeMembership.css";
//import Main from "../../../../components/Main";

import DetailSteps from "../../../shared/Details/DetailSteps";
import {
    addCompany,
    detailsActions,
} from "../../../data/reducers/details/details";
import ArrowIcon from "../../../assets/Arrow-icon.svg";
import ComingSoon from "../../../assets/coming-soon.svg";
import Modal from "@material-tailwind/react/Modal";
import ModalBody from "@material-tailwind/react/ModalBody";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { getSubscriptions } from "../../../data/reducers/company/subscription";
import { getUserProfile } from "../../../data/reducers/auth/auth";
import { color, useToast } from "@chakra-ui/react";
import { addSocialAction } from "../../../data/reducers/profile/profile";
import { toast } from "@chakra-ui/react";
import ConfirmModal from "../ConfirmModal";

const UpgradeMembership = () => {
    const toast = useToast();
    const [check, setCheck] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const { basicDetails2, basicDetails1 } = useSelector(
        (state) => state.details
    );
    const { activeStep } = useSelector((state) => state.details);
    const { subscriptions } = useSelector((state) => state.subscription);

    const onFreeTrial = (type) => {
        dispatch(detailsActions.setIsFree(true));
        dispatch(detailsActions.setActiveStep(3));
        dispatch(detailsActions.setPlan(type));

        navigate(`/details?type=${type}`);
    };

    const onNext = (type) => {
        dispatch(detailsActions.setActiveStep(3));
        dispatch(detailsActions.setPlan(type));
        setShowModal({ isOpen: true, type: type });
    };

    const onBack = () => {
        dispatch(detailsActions.setActiveStep(1));
    };

    useEffect(() => {
        dispatch(getSubscriptions());
    }, [dispatch]);

    const SettingDiv = styled.div`
        display: flex;
        gap: 45px;
    `;
    const LogoutDiv = styled.button`
        background: #ffffff;
        border: 1px solid #00aaf5;
        border-radius: 10px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #00aaf5;
        padding: 5px 30px 5px 30px;
    `;
    const Setting = styled.div`
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 54px;
        color: #333d43;
    `;
    const ImgContainer = styled.div`
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    `;

    return (
        <>
            <div>
                <div className="containerMembership">
                    <ImgContainer>
                        <SettingDiv>
                            <img
                                src={ArrowIcon}
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate("/Setting")}
                            />
                            <Setting>Settings</Setting>
                        </SettingDiv>
                        <LogoutDiv
                            onClick={(e) => {
                                localStorage.clear();
                                navigate("/auth");
                                window.location.reload();
                            }}
                        >
                            <div>Logout</div>
                        </LogoutDiv>
                    </ImgContainer>
                    <div className="payment">
                        <div className="row ">
                            <div className="subscription ">
                                <h1 className="subs-heading">
                                    Subscription Plans
                                </h1>
                                <p
                                    className="details py-3"
                                    style={{
                                        fontSize: "1rem",
                                    }}
                                >
                                    Simple pricing, no hidden fees, and great
                                    value
                                </p>
                            </div>
                        </div>

                        <div className="row  plans">
                            {!isMobile ? (
                                <>
                                    {subscriptions
                                        ?.filter(
                                            (subscription) =>
                                                subscription?.subscription_name !==
                                                "Free"
                                        )
                                        ?.map((subscription) => (
                                            <div className="col-lg-4 col-md-12 col-sm-12 mx-auto">
                                                <PlanCard
                                                    check={check}
                                                    subscription={subscription}
                                                    onNext={() => {
                                                        onNext(
                                                            subscription?.subscription_name
                                                        );
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    <div
                                        className="col-lg-4 col-md-12 col-sm-12 mx-auto plan-card"
                                        style={{
                                            marginTop: "1rem",
                                        }}
                                    >
                                        <h1>Platinum</h1>
                                        <div className="coming-soon">
                                            <img
                                                src={ComingSoon}
                                                alt="Platinum"
                                            />
                                        </div>
                                        <div className="coming-soon-text">
                                            <h3 className="text-center">
                                                Coming Soon
                                            </h3>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <Carousel
                                    showIndicators={true}
                                    showArrows={false}
                                    showStatus={false}
                                    infiniteLoop={true}
                                    interval={5000}
                                >
                                    {subscriptions
                                        ?.filter(
                                            (subscription) =>
                                                subscription?.subscription_name !==
                                                "Free"
                                        )
                                        ?.map((subscription) => (
                                            <div>
                                                <PlanCard
                                                    check={check}
                                                    subscription={subscription}
                                                    onNext={() =>
                                                        onNext(
                                                            subscription.subscription_name
                                                        )
                                                    }
                                                />
                                            </div>
                                        ))}

                                    <div>
                                        <h1>Platinum</h1>
                                        <div className="coming-soon">
                                            <img
                                                src={ComingSoon}
                                                alt="Platinum"
                                            />
                                        </div>
                                        <div className="coming-soon-text">
                                            <h3 className="text-center">
                                                Coming Soon
                                            </h3>
                                        </div>
                                    </div>
                                </Carousel>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showModal && (
                <ConfirmModal
                    setModal={(val) => {
                        setShowModal({ ...showModal, isOpen: val });
                        navigate("/Setting");
                    }}
                    type={showModal?.type}
                    modal={showModal?.isOpen}
                />
            )}
        </>
    );
};

const PlanCard = ({ subscription, onNext, check }) => {
    const toast = useToast();
    var desc = subscription?.subscription_description.split(",");
    console.log(subscription);
    return (
        <>
            <div className="plan-card">
                {subscription && (
                    <div>
                        <h1>{subscription?.subscription_name}</h1>
                        <h2 className="d-flex justify-content-center align-items-center">${subscription?.subscription_price} {subscription?.subscription_name!="Free" && <span className="text-secondary fs-6 fw-bold ms-2">+ GST</span>}</h2>
                        <div className="features py-4">
                            <div>
                                {desc.map((val, ind) => (
                                    <div key={ind} style={{ display: "flex" }}>
                                        <div
                                            style={{
                                                marginTop: "8px",
                                                marginRight: "10px",
                                            }}
                                        >
                                            <img src={BulletDarkIcon} />
                                        </div>
                                        <div
                                            style={{
                                                width: "100%",
                                                color: "#646464",
                                            }}
                                        >
                                            {val}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="select-plan-button">
                            <Button
                                color="blue"
                                buttonType="filled"
                                size="regular"
                                rounded={false}
                                block={false}
                                iconOnly={false}
                                ripple="light"
                                onClick={() => {
                                    // if (check) {
                                    onNext();
                                    // } else {
                                    //     toast({
                                    //         title: "Accept Terms and Condtions",
                                    //         description:
                                    //             "You need to accept terms and conditions",
                                    //         status: "error",
                                    //         duration: 3000,
                                    //         isClosable: true,
                                    //         position: "top-right",
                                    //     });
                                    // }
                                }}
                            >
                                Select Plan
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default UpgradeMembership;
