import Rating from "@material-ui/lab/Rating";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getAllReviewsAction } from "../../../data/reducers/company/add";
import "./AllReviews.css";
import Helmet from "react-helmet";
import favi from "../../../assets/backbone-logo.svg";

const AllReviews = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch = useDispatch();
    const { reviews } = useSelector((state) => state.add);
    const getCompanyAllReviews = async () => {
        dispatch(getAllReviewsAction(id));
    };

    useEffect(() => {
        getCompanyAllReviews();
    }, [id]);

    return (
        <>
            <Helmet>
                <link rel="icon" type="image/png" href={favi} sizes="16x16" />
            </Helmet>

            <div
                className="root_all_reviews"
                style={{ border: "2px solid red" }}
            >
                <div className="allReviews">
                    <h1>Reviews {">>"}</h1>
                </div>
                <div className="all_reviews_count">
                    <h1>({reviews?.length} Reviews) </h1>
                </div>
            </div>
            <div className="review_all">
                {reviews?.map((review) => (
                    <div className="profile-Allreview-card">
                        <div className="top-section">
                            <img
                                src={
                                    review?.bussiness_image ||
                                    "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"
                                }
                                alt="Lorem"
                            />
                            <div className="top-section-detail">
                                <div>
                                    <h3
                                        className="cursor-pointer"
                                        onClick={() => {
                                            if (review?.reviewer_company_id)
                                                navigate(
                                                    `/company/${review?.reviewer_company_id}`
                                                );
                                        }}
                                    >
                                        {review?.company_name}
                                    </h3>
                                    <Rating
                                        name="read-only"
                                        value={review?.review_given}
                                        readOnly
                                        precision={0.25}
                                    />
                                </div>
                            </div>
                        </div>
                        <p className="mt-3">{review?.review_text}</p>
                    </div>
                ))}
            </div>
        </>
    );
};

export default AllReviews;
