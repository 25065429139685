import React, { useEffect, useState } from "react";
import "./Navbar.css";

import { AiOutlineHome } from "react-icons/ai";
import {
    BsCalendarCheck,
    BsBell,
    BsArrowLeftCircle,
    BsArrowRightCircle,
    BsHeart,
    BsQuestionCircle,
} from "react-icons/bs";
import { MdHome } from "react-icons/md";
import { BiSearchAlt } from "react-icons/bi";

import { useNavigate, useLocation } from "react-router-dom";
import { MdPeople } from "react-icons/md";
import { FcAdvertising } from "react-icons/fc";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { FiSettings } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import BackboneLogo from "../../../assets/backbone-logo.svg";
// import { getMyCompany } from "../../data/services/home/content";
import { useDispatch, useSelector } from "react-redux";
import why3 from "../../../assets/why-3.svg";
import { getMyCompanyAction } from "../../../data/reducers/home/content";
import SettingIcon from "../../../assets/Setting-Icon.svg";
import { allNotificationApi } from "../../../data/api";
import {
    resetCount,
    updateCount,
    addNotifications,
} from "../../../data/reducers/notification/notification";

const Navbar = ({ setIsExpanded, isExpanded }) => {
    const [Notification, setnotification] = useState([]);
    const { unreadCount } = useSelector((state) => state.notification);
    const navigate = useNavigate();
    const [isTab, setIsTab] = useState(false);
    // const dispatch = useDispatch();
    const { myCompany } = useSelector((state) => state.content);
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    let isAd = false;
    let isPayment = false;
    let isProfile = false;
    let isServices = false;
    if (
        pathname === "/adpayment" ||
        pathname === "/adPayment" ||
        pathname === "/all-ads" ||
        pathname === "/launchform"
    ) {
        isAd = true;
    } else if (
        pathname === "/upgradePayment" ||
        pathname === "UpgradeMembership" ||
        pathname === "/paymentsuccess" ||
        pathname === "/paymentreject" ||
        pathname === "/paymentconfirm" ||
        pathname === "/paymentfailed"
    ) {
        isPayment = true;
    } else if (pathname.includes("company")) {
        isProfile = true;
    } else if (pathname.includes("reviews")) {
        isServices = true;
    }

    useEffect(() => {
        if (window.screen.width <= 1000) {
            setIsTab(true);
        }
    }, [setIsTab]);

    useEffect(() => {
        dispatch(getMyCompanyAction());
        // dispatch(getBannersAction());
    }, [dispatch]);

    // useEffect(() => {
    //     getAllNotification(`?page=1`);
    //     let count = 0;
    //     // Notification.map((n) => {
    //     //     if (!n.seen) {
    //     //         count += 1;
    //     //     }
    //     // });

    //     if (count === 0) {
    //         dispatch(resetCount());
    //     } else {
    //         dispatch(updateCount(count));
    //     }
    // }, [dispatch, pathname, unreadCount]);

    // const getAllNotification = async (search) => {
    //     try {
    //         const response = await allNotificationApi(search);
    //         if (response.data) {
    //             let data = response.data;
    //             setnotification(data);
    //             dispatch(addNotifications(data));
    //         }
    //         console.log(response);
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };

    return (
        <div>
            <figure className="backbone-nav-logo mobile-hide cursor-pointer">
                <img
                    src={BackboneLogo}
                    alt="BackboneLogo"
                    onClick={() => navigate("/dashboard")}
                />
            </figure>

            <div className="backbone-items">
                <div className="backbone-items-each browse-icon">
                    <NavItem
                        Icon={MdHome}
                        title="Home"
                        link="/dashboard"
                        isExpanded={isExpanded}
                        heading="Home"
                        customActive={isServices}
                    />
                </div>

                <div className="backbone-items-each browse-icon">
                    <NavItem
                        Icon={BiSearchAlt}
                        title="Browse Services"
                        link="/?page=1"
                        isExpanded={isExpanded}
                        heading="Browse Services"
                        customActive={isServices}
                    />
                </div>

                <div className="backbone-items-each event-icon">
                    <NavItem
                        Icon={BsCalendarCheck}
                        title="Upcoming events"
                        link="/upcoming-events"
                        isExpanded={isExpanded}
                        heading="Events"
                    />
                </div>
                <div className="backbone-items-each favourites-icon">
                    <NavItem
                        Icon={BsHeart}
                        title="Liked Businesses"
                        link="/liked-businesses/?page=1"
                        isExpanded={isExpanded}
                        heading="Favourites"
                    />
                </div>
                <div className="backbone-items-each notification-icon">
                    <NavItem
                        Icon={BsBell}
                        title="Notification"
                        link="/notification"
                        isExpanded={isExpanded}
                        heading="Notifications"
                    />
                    {unreadCount > 0 ? (
                        <div className="notification-unread">
                            <p>{unreadCount}</p>
                        </div>
                    ) : null}
                </div>
                <div className="backbone-items-each faq-icon">
                    <NavItem
                        Icon={BsQuestionCircle}
                        title="FAQ"
                        link="/faq"
                        isExpanded={isExpanded}
                        heading="Support Centre"
                    />
                </div>
                <div className="backbone-items-each advertisment-icon">
                    <NavItem
                        Icon={HiOutlineSpeakerphone}
                        title="Advertisement"
                        link="/ad"
                        isExpanded={isExpanded}
                        heading="Advertisements"
                        customActive={isAd}
                    />
                </div>
                <div className="backbone-items-each profile-pic-icon">
                    <NavItem
                        Icon={FiSettings}
                        title="Settings"
                        link="/Setting"
                        isExpanded={isExpanded}
                        heading="Settings"
                        customActive={isPayment}
                    />
                </div>

                <div className="backbone-items-each profile-pic-icon">
                    <NavLink
                        to="/profile"
                        className={({ isActive }) =>
                            "backbone-nav-item" +
                            (!isActive ? "" : " backbone-active-link") +
                            (!isProfile ? "" : " backbone-active-link")
                        }
                    >
                        <div className="logo-wrapper">
                            <img
                                src={
                                    myCompany?.company_logo
                                        ? myCompany.company_logo
                                        : "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"
                                }
                                alt="Profile"
                                className="nav-logo"
                                style={{ maxWidth: "40px" }}
                            />
                        </div>
                        <p
                            className={`${
                                isExpanded ? "" : "backbone-items-nav-title"
                            } `}
                        >
                            {isExpanded ? "My Business Profile" : "My Profile"}
                        </p>
                    </NavLink>
                </div>
            </div>
            <div className={isTab ? "hide-expand-icons" : "hide-expand-icons"}>
                {isExpanded ? (
                    <BsArrowLeftCircle
                        fontSize={28}
                        className="wrap-button"
                        onClick={() => setIsExpanded(!isExpanded)}
                    />
                ) : (
                    <BsArrowRightCircle
                        fontSize={28}
                        className="wrap-button"
                        onClick={() => setIsExpanded(!isExpanded)}
                    />
                )}
            </div>
        </div>
    );
};

const NavItem = ({ Icon, title, link, isExpanded, heading, customActive }) => {
    return (
        <>
            <NavLink
                to={link}
                className={({ isActive }) =>
                    "backbone-nav-item" +
                    (!isActive ? "" : " backbone-active-link") +
                    (!customActive ? "" : " backbone-active-link")
                }
            >
                <div className="icon-wrapper">
                    <Icon />
                </div>
                <p
                    className={`${
                        isExpanded ? "" : "backbone-items-nav-title"
                    } `}
                >
                    {isExpanded ? title : heading}
                </p>
            </NavLink>
        </>
    );
};

export const MobileNavItem = ({ Icon, title, link, setShow }) => {
    return (
        <>
            <NavLink
                to={link}
                onClick={() => setShow(false)}
                className={({ isActive }) =>
                    "mobile-backbone-nav-item" +
                    (!isActive ? "" : " mobile-backbone-active-link")
                }
            >
                <div className="mobile-icon-wrapper">
                    <Icon />
                </div>
                <div className="mobile-nav-title">{title}</div>
            </NavLink>
        </>
    );
};

export default Navbar;
