import React from "react";
import Modal from "@material-tailwind/react/Modal";
import ModalBody from "@material-tailwind/react/ModalBody";
import styled from "styled-components";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { makePayment } from "../../../data/reducers/details/details";
import { updateUser } from "../../../data/api";
import { useNavigate } from "react-router";
import { useToast } from "@chakra-ui/react";
const HeadingDetail = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e2e2e2;
    color: #3f3f3f;
`;

const CancelDetail = styled.div`
    width: 600px;
    margin-top: 25px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    @media only screen and (max-width: 450px) and (min-width: 400px) {
        font-size: 6px;
    }
    @media only screen and (max-width: 500px) and (min-width: 450px) {
        font-size: 6.5px;
    }
    @media only screen and (max-width: 550px) and (min-width: 500px) {
        font-size: 7px;
    }
    @media only screen and (max-width: 600px) and (min-width: 550px) {
        font-size: 8px;
    }
    @media only screen and (max-width: 650px) and (min-width: 600px) {
        font-size: 9px;
    }
`;

const ButtonCancel = styled.button`
    background: #fff;
    border-radius: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #039be5;
    padding: 5px 30px;
    margin-top: 50px;
    border: 1px solid #039be5;
`;

const ButtonBack = styled.button`
    background: #039be5;
    border-radius: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    padding: 5px 30px;
    margin-top: 50px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: "center";
    gap: 50px;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

const CancelMembershipModal = ({
    cancelMembershipModal,
    setCancelMembershipModal,
}) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state?.auth);

    const toast = useToast();
    const navigate = useNavigate();

    const submitHandler = async () => {
        console.log("inside submit handler");
        const payload = await dispatch(
            makePayment({
                email: user?.email,
                cancel: true,
            })
        );
        console.log(payload);
        if (
            payload.payload.message === "Subscription cancelled successfully!"
        ) {
            console.log("inseide 200 status");
            try {
                const res = await updateUser(user?.id, {
                    status: false,
                });
                console.log(res);
                if (res.status === 201) {
                    localStorage.clear();
                    navigate("/auth");
                    window.location.reload();
                    console.log("log out");
                } else {
                    dispatch(
                        toast({
                            title: "Profile Update unsuccessful",
                            status: "error",
                            position: "top-right",
                            isClosable: true,
                            duration: 3000,
                        })
                    );
                }
            } catch (e) {
                dispatch(
                    toast({
                        title: e,
                        status: "error",
                        position: "top-right",
                        isClosable: true,
                        duration: 3000,
                    })
                );
            }
        } else {
            dispatch(
                toast({
                    title: payload?.payload?.error,
                    status: "error",
                    position: "top-right",
                    isClosable: true,
                    duration: 3000,
                })
            );
        }

        setCancelMembershipModal(false);
    };

    return (
        <div className="modal-wrapper">
            <Modal
                size="lg"
                active={cancelMembershipModal}
                toggler={() => {
                    setCancelMembershipModal(false);
                }}
            >
                <ModalBody>
                    <HeadingDetail>Cancel Membership</HeadingDetail>
                    <CancelDetail>
                        If you choose to deactivate please note the following
                        points below and then confirm deactivation
                        <ol className="px-3" style={{ listStyleType: "disc" }}>
                            <li>
                                In case of deactivation, you will not be able to
                                access the platform or its feature till you
                                activate your account back
                            </li>
                            <li>
                                Your existing credits will be immediately wiped
                                off upon deactivation.
                            </li>

                            <li>
                                The data and endorsements related to your
                                account would be saved on the system for 3
                                months.
                            </li>

                            <li>
                                If you return within 3 months of deactivation
                                you will be able to access your account.
                            </li>

                            <li>
                                If you fail to activate within 3 months of
                                deactivation, your account information be
                                deleted forever from the platform.
                            </li>
                        </ol>
                    </CancelDetail>
                    <ButtonWrapper>
                        <ButtonBack
                            onClick={() => {
                                setCancelMembershipModal(false);
                                navigate("/profile");
                            }}
                        >
                            Back to Profile
                        </ButtonBack>

                        <ButtonCancel
                            onClick={() => {
                                submitHandler();
                            }}
                        >
                            Proceed to Cancel
                        </ButtonCancel>
                    </ButtonWrapper>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default CancelMembershipModal;
