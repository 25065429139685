import {
    addCompanyDetailsAPI,
    addReviewAPI,
    getAllReviewsAPI,
} from "../../api";

export const addCompanyDetails = async (payload) => {
    const response = await addCompanyDetailsAPI(payload);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getAllReviews = async (id) => {
    const response = await getAllReviewsAPI(id);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const addReviewService = async (dataReview) => {
    const response = await addReviewAPI(dataReview);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};
