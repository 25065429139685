import React from "react";
import "./MemberSince.css";
import Moment from "react-moment";
const MemberSince = ({ date }) => {
    return (
        <div className="member-since">
            <p>
                Joined{" "}
                <span>
                    <Moment fromNow>{date}</Moment>
                </span>
            </p>
        </div>
    );
};

export default MemberSince;
