import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    forgotPasswordService,
    loginService,
    resetPasswordService,
    signUpUserService,
    verifyUserService,
    getUserService,
    getAllUserService,
    changePasswordService,
    feedbackService,
    referAFriendService,
} from "../../services/auth/auth";

const initialState = {
    success: false,
    loading: false,
    user: undefined,
    isAuthenticated: null,
    error: null,
    message: null,
    signupsuccess: false,
    signuperror: null,
    signuploading: false,
    allUser: [],
    isReset: null,
    isInWaitlist: false,
    upgradePaymentStep: 1,
    upgradePaymentType: null,
};

export const verifyUser = createAsyncThunk("auth/verify", async (token) => {
    const response = await verifyUserService(token);
    if (response?.error) {
        return { error: response?.error };
    }
    return response;
});

export const feedbackAction = createAsyncThunk(
    "user/feedback",
    async (text) => {
        const response = await feedbackService(text);
        if (response?.error) {
            return { error: response?.error };
        }
        return response;
    }
);

export const referAFriendAction = createAsyncThunk(
    "user/referAfriend",
    async (token) => {
        const response = await referAFriendService(token);
        if (response?.error) {
            return { error: response?.error };
        }
        return response;
    }
);

export const login = createAsyncThunk("auth/login", async (payload) => {
    const response = await loginService(payload);
    if (response?.error) {
        return { error: response?.error };
    }
    return response;
});

export const signUpUser = createAsyncThunk("auth/signup", async (payload) => {
    const response = await signUpUserService(payload);
    if (response?.error) {
        return { error: response?.error };
    }
    return response;
});

export const resetPasswordAction = createAsyncThunk(
    "auth/reset",
    async (token, password, conPassword) => {
        const response = await resetPasswordService(
            token,
            password,
            conPassword
        );
        if (response?.error) {
            return { error: response?.error };
        }
        return response;
    }
);

export const changePasswordAction = createAsyncThunk(
    "auth/change",
    async (payload) => {
        const response = await changePasswordService(payload);
        if (response?.error) {
            return { error: response?.error };
        }
        return response;
    }
);

export const forgotPasswordAction = createAsyncThunk(
    "auth/forgot",
    async (payload) => {
        const response = await forgotPasswordService(payload);
        if (response?.error) {
            return { error: response?.error };
        }
        return response;
    }
);

export const getUserProfile = createAsyncThunk("get/user", async (token) => {
    const response = await getUserService();
    if (response?.error) {
        return { error: response?.error };
    }
    return response;
});

export const getAllUserProfile = createAsyncThunk(
    "get/allUser",
    async (token) => {
        const response = await getAllUserService();
        if (response?.error) {
            return { error: response?.error };
        }
        return response;
    }
);

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        reset: (state, action) => {
            state.error = null;
            state.message = null;
            state.success = false;
            state.loading = false;
            state.signuperror = null;
            // state.isReset = false;
        },
        logout: (state, action) => {
            state.user = null;
            state.isAuthenticated = false;
            state.loading = false;
        },
        setIsAuthenticated: (state, action) => {
            state.isAuthenticated = true;
        },
        setIsReset: (state, action) => {
            state.isReset = true;
        },
        setUpgradePaymentStep: (state, action) => {
            if (
                action.payload?.step !== null ||
                action.payload?.step !== undefined
            ) {
                state.upgradePaymentStep = action.payload?.step;
            }
            if (
                action.payload?.type !== null ||
                action.payload?.type !== undefined
            ) {
                state.upgradePaymentType = action.payload?.type;
            }
        },
        resetActiveStep: (state, action) => {
            state.upgradePaymentStep=1
        }
    },
    extraReducers: {
        [verifyUser.pending]: (state, action) => {
            state.loading = true;
        },
        [verifyUser.fulfilled]: (state, action) => {
            state.loading = false;
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error;
            }
            if (action.payload?.message) {
                state.message = action.payload?.message;
            }
            if (action.payload?.user?.hasCompany) {
                state.isAuthenticated = true;
            } else {
                state.isAuthenticated = false;
            }
        },

        // Login
        [login.pending]: (state, action) => {
            state.loading = true;
        },
        [login.fulfilled]: (state, action) => {
            state.loaded = true;
            state.success = action.payload?.success;
            state.loading = false;
            if (action.payload?.user?.token) {
                localStorage.setItem("token", action.payload?.user?.token);
                state.user = action.payload?.user;
            }
            if (action.payload?.isIn) {
                state.isInWaitlist = true;
            }
            if (action.payload?.user?.hasCompany) {
                state.isAuthenticated = true;
            } else {
                state.isAuthenticated = false;
            }
            if (action.payload?.error) {
                state.error = action.payload?.error;
            }
            if (action.payload?.message) {
                state.message = action.payload?.message;
            }
        },

        // Signup
        [signUpUser.pending]: (state, action) => {
            state.signuploading = true;
            state.signuperror = null;
            state.signupsuccess = null;
        },
        [signUpUser.fulfilled]: (state, action) => {
            state.signuploading = false;
            state.signupsuccess = action.payload?.success;
            if (action.payload?.error) {
                state.signuperror = action.payload?.error;
            }
            if (action.payload?.message) {
            }
        },

        // Forgot Password
        [forgotPasswordAction.pending]: (state, action) => {
            state.loading = true;
        },
        [forgotPasswordAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            state.loading = false;
            if (action.payload?.error) {
                state.error = action.payload?.error;
            }
            if (action.payload?.message) {
                state.message = action.payload?.message;
            }
        },

        // Reset Password
        [resetPasswordAction.pending]: (state, action) => {
            state.loading = true;
        },
        [resetPasswordAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            state.loading = false;
            if (action.payload?.error) {
                state.error = action.payload?.error;
            }
            if (action.payload?.message) {
                state.message = action.payload?.message;
            }
        },
        // Changed Password
        [changePasswordAction.pending]: (state, action) => {
            state.loading = true;
        },
        [changePasswordAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            state.loading = false;
            if (action.payload?.error) {
                state.error = action.payload?.error;
            }
            if (action.payload?.message) {
                state.message = action.payload?.message;
            }
        },

        [feedbackAction.pending]: (state, action) => {
            state.loading = true;
        },
        [feedbackAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            state.loading = false;
            if (action.payload?.error) {
                state.error = action.payload?.error;
            }
            if (action.payload?.message) {
                state.message = action.payload?.message;
            }
        },

        // //Load AllUser
        // [getAllUserProfile.pending]: (state, action) => {
        //     state.loading = true;
        // },
        // [getAllUserProfile.fulfilled]: (state, action) => {
        //     state.loading = false;
        //     state.success = action.payload?.success;
        //     console.log("action.payload", action.payload);
        // },
        // Load User
        [getUserProfile.pending]: (state, action) => {
            state.loading = true;
        },
        [getUserProfile.fulfilled]: (state, action) => {
            state.loading = false;
            state.success = action.payload?.success;
            if (action.payload?.user?.hasCompany) {
                state.isAuthenticated = true;
            } else {
                state.isAuthenticated = false;
            }
            if (action.payload?.user) {
                state.user = action.payload?.user;
                localStorage.setItem(
                    "userInfo",
                    JSON.stringify(action.payload?.user)
                );
            }
            if (action.payload?.error) {
                state.error = action.payload?.error;
                localStorage.clear();
            }
        },
    },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
