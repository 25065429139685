import React, { useEffect, useMemo, useState } from "react";
import Rating from "@material-ui/lab/Rating";
import { useNavigate } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai";
import "./Profile.css";
import Tags from "./Components/Tags";
import IndustryType from "./Components/IndustryType";
import OtherInfo from "./Components/OtherInfo";
import ProfileReviews from "./Components/ProfileReviews";
import MemberSince from "./Components/MemberSince";
import ProfileBasicDetails from "./Components/ProfileBasicDetails";
import ProfileSocialLinks from "./Components/ProfileSocialLinks";
import ProfileLeaders from "./Components/ProfileLeaders";
import RequestEndorse from "./Components/RequestEndorse";
import Modal from "@material-tailwind/react/Modal";
import ModalBody from "@material-tailwind/react/ModalBody";
import Button from "@material-tailwind/react/Button";
import { useDispatch, useSelector } from "react-redux";
import "./Profile.css";
import {
    contentActions,
    getBannersAction,
    getBizBucksByForCompanyById,
    getMyCompanyAction,
    getServicesAction,
    getTotalNumberOfBackedCompany,
} from "../../data/reducers/home/content";
import {
    addBannerAction,
    addDemoBannerAction,
    editProfileAction,
    getSocialAction,
    myCompanyPreferences,
    profileActions,
    updateBannerAction,
    updateDemoBannerAction,
} from "../../data/reducers/profile/profile";
import { getMyCompany } from "../../data/services/home/content";
import Loader from "../../shared/Loader/Loader";
import { toast, useToast } from "@chakra-ui/react";
import { feedbackAction } from "../../data/reducers/auth/auth";
import moment from "moment";
import favi from "../../assets/backbone-logo.svg";
import Helmet from "react-helmet";
import newMember from "../../assets/newMember.svg";
import Activity from "./Components/Activity";
import ProfileActivity from "./Components/ProfileActivity";
import bgvector from "../../assets/CustomIcons/6.png";
import whiteBiz from "../../assets/CustomIcons/whiteBiz.png";
import CompanyPrefernce from "./Components/CompantPrefernce";

const Profile = () => {
    const navigate = useNavigate();
    const { myCompany, myBizBucks, myBackedCompany, companyBackedUs } =
        useSelector((state) => state.content);
    const [showModal, setShowModal] = useState(false);
    const [showSignoutModal, setShowSignoutModal] = useState(false);
    const [logo, setLogo] = useState("");
    const [logoPreview, setLogoPreview] = useState("");
    const [bannerPreview, setBannerPreview] = useState("");
    const [dontHave, setDontHave] = useState(false);
    const [banner, setBanner] = useState("");
    const [updateBanner, setUpdateBanner] = useState("");
    const [tagSaved, setTagSaved] = useState(false);
    const [name, setName] = useState("");
    const [about, setAbout] = useState("");
    const [isError, setIsError] = useState(false);
    const [isError1, setIsError1] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [showModal1, setShowModal1] = useState(false);

    const [feedbackText, setFeedbackText] = useState("");
    const [checknewMem, setChecknewMem] = useState(false);

    const [demoBanner, setDemoBanner] = useState([""]);
    const [chars, setChars] = useState(550);
    const { user } = useSelector((state) => state.auth);
    const toast = useToast();
    // const { banner } = useSelector((state) => state.content);
    const { services, totalBackedBizBuck } = useSelector(
        (state) => state.content
    );
    const deleteBackingDetails = (type) => {
        if (type == "delete") {
            dispatch(getMyCompanyAction());
            dispatch(getTotalNumberOfBackedCompany("?id="));
        }
        if (type == "edit") {
            dispatch(getMyCompanyAction());
        }
    };
    const memoizedProfileActivity = useMemo(() => {
        if (
            myCompany &&
            (myBackedCompany?.length > 0 ||
                companyBackedUs?.backed_detail?.length > 0)
        )
            return (
                <ProfileActivity
                    myCompany={myCompany}
                    activity={myBackedCompany}
                    companyBackedUs={companyBackedUs?.backed_detail}
                    companyBackedUsDetail={
                        companyBackedUs?.findAllBackedUsCompany
                    }
                    deleteBackingDetails={deleteBackingDetails}
                />
            );
    }, [myCompany, myBackedCompany, companyBackedUs, deleteBackingDetails]);

    const demoBanners = [
        "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/banner1.png",
        "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/banner2.png",
        "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/banner3.png",
        "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/banner4.png",
        "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/banner5.png",
        "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/banner6.png",
    ];

    // const sendFeedback = async () => {
    //     setShowSignoutModal(false);
    //     await dispatch(feedbackAction(feedbackText));
    //     setFeedbackText("");
    //     localStorage.clear();
    //     window.location.href = "/auth";
    // };

    // const checkandLogout = () => {
    //     if (user?.feedbackGiven) {
    //         setShowSignoutModal(false);
    //         localStorage.clear();
    //         window.location.href = "/auth";
    //     } else {
    //         setShowSignoutModal(true);
    //     }
    // };

    const editProfile = async () => {
        let profile = new FormData();
        if (!name) {
            // setIsError(true);
            toast({
                title: "Please add the company name",
                status: "error",
                position: "top-right",
                isClosable: true,
                duration: 2000,
            });

            return;
        }
        if (name) {
            profile.append("company_name", name);
        }
        if (about) {
            profile.append("about_company", about);
        }
        if (logo) {
            profile.append("file", logo);
        }
        dispatch(editProfileAction(profile));

        let data = new FormData();

        if (dontHave) {
            if (myCompany?.Banners.length === 0) {
                data.append("file", demoBanner);
                dispatch(addDemoBannerAction(data));
            } else {
                data.append("file", demoBanner);
                dispatch(updateDemoBannerAction(data));
            }
        } else if (myCompany?.Banners.length === 0) {
            data.append("file", banner);
            dispatch(addBannerAction(data));
        } else {
            data.append("file", banner);
            dispatch(updateBannerAction(data));
        }

        setShowModal(false);
        dispatch(getMyCompany());
    };

    const onFileSelect = (e) => {
        if (e.target.name === "companyLogo") {
            const reader = new FileReader();
            if (e.target.files[0].size > 512000) {
                setIsError1(true);
                return;
            }
            setIsError1(false);
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setLogoPreview(reader.result);
                }
            };
            setLogo(e.target.files[0]);
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onFileSelectBanner = async (e) => {
        const reader = new FileReader();
        if (e.target.files[0].size > 5242880) {
            setIsError(true);
            return;
        }
        setIsError(false);
        console.log("works");
        //setUpdateBanner(myCompany?.Banners[0].id);
        setBannerPreview(banner.location);
        reader.onload = () => {
            if (reader.readyState === 2) {
                setBannerPreview(reader.result);
            }
        };
        setBanner(e.target.files[0]);
        reader.readAsDataURL(e.target.files[0]);
        console.log("e.target", e.target.files[0]);
    };

    const onFileSelectDemoBanner = (item) => {
        setBanner(item);
        setBannerPreview(item);
        setDemoBanner(item);
        setUpdateBanner(item);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMyCompanyAction());
        dispatch(getTotalNumberOfBackedCompany("?id="));
        // dispatch(getBannersAction());
    }, [dispatch]);

    const { success, message, error } = useSelector((state) => state.profile);
    const { companyPrefernces } = useSelector((state) => state.profile);

    console.log("error,", error);
    useEffect(()=>{
        findCompanyPrefernce()
    },[dispatch])
    const findCompanyPrefernce=()=>{
        dispatch(myCompanyPreferences())
    }
    useEffect(() => {
        if (success && message) {
            dispatch(contentActions.reset());
            dispatch(getMyCompanyAction());
        }
        dispatch(contentActions.reset());
    }, [dispatch, success, message, toast]);

    useEffect(() => {
        if (myCompany && myCompany?.id && !myBizBucks) {
            dispatch(getBizBucksByForCompanyById(myCompany.id));
        }
    }, [myCompany, dispatch]);

    useEffect(() => {
        // // if(company?.createdAt){
        // //     setStartDate(company?.createdAt);
        // //     console.log("Ajay",startDate);
        // // }
        // // console.log("st", startDate, company?.createdAt);
        // setStartDate(startDate.slice(0, 10));
        // const today = new Date();
        // let endDate =
        //     today.getFullYear() +
        //     "-" +
        //     (today.getMonth() + 1) +
        //     "-" +
        //     today.getDate();
        // endDate = new Date(endDate);
        // console.log("startDate", startDate, typeof startDate);
        // let sd = new Date(startDate);
        // console.log("endDate", endDate, typeof endDate, "sd", sd);
        // // setStartDate(sd)
        // var diff = Math.floor(today.getTime() - sd.getTime());
        // var day = 1000 * 60 * 60 * 24;
        // var days = Math.floor(diff / day);
        // var months = Math.floor(days / 29);
        // let x = moment(startDate, "YYYY-MM-DD").fromNow();
        // // const diff =
        // //     endDate.getMonth() -
        // //     sd.getMonth() +
        // //     12 * (endDate.getFullYear() - sd.getFullYear());
        // console.log("diff", x);
        // if (months < 6) {
        //     setChecknewMem(true);
        // }
        let d1 = new Date();
        let d2 = new Date(myCompany?.createdAt);

        const x =
            d2.getMonth() -
            d1.getMonth() +
            12 * (d2.getFullYear() - d1.getFullYear());
        if (x < 6) {
            setChecknewMem(true);
        }
    }, [startDate, myCompany]);

    return (
        <>
            <Helmet>
                <link rel="icon" type="image/png" href={favi} sizes="16x16" />
            </Helmet>
            {!myCompany?.avg_ratings ? (
                <Loader />
            ) : (
                <div className="profile">
                    <div className="profile-top">
                        <div className="profile-top-shadow">
                            {/* <header className="profile-header">
                                <h1>
                                    {myCompany?.company_name} Profile {">>"}
                                </h1>
                                <button onClick={() => checkandLogout()}>
                                    Sign out
                                </button>
                            </header> */}
                            {/* <div className="endorse-modal profile-modal">
                                <Modal
                                    size="lg"
                                    active={showSignoutModal}
                                    toggler={() => {
                                        setShowSignoutModal(false);
                                    }}
                                >
                                    <ModalBody>
                                        <h1 className="feedback-header px-5 text-center">
                                            Kindly, help us with your feedback
                                        </h1>
                                        <div className="feedback-text">
                                            <textarea
                                                name="feedback"
                                                id="feedback"
                                                cols="30"
                                                rows="4"
                                                value={feedbackText}
                                                placeholder="Write your reveiws here..."
                                                onChange={(e) =>
                                                    setFeedbackText(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div className="close-button">
                                                <Button
                                                    color="red"
                                                    buttonType="link"
                                                    onClick={(e) => {
                                                        setShowSignoutModal(
                                                            false
                                                        );
                                                        localStorage.clear();
                                                        navigate("/auth");
                                                        window.location.reload();
                                                    }}
                                                    ripple="dark"
                                                >
                                                    Sign out
                                                </Button>
                                            </div>
                                            <div className="save-button">
                                                <Button
                                                    color="green"
                                                    onClick={(e) =>
                                                        sendFeedback()
                                                    }
                                                    ripple="light"
                                                >
                                                    Done
                                                </Button>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                            </div> */}
                            <div className="profile-background">
                                <figure>
                                    <img
                                        src={
                                            !(myCompany?.Banners.length === 0)
                                                ? myCompany.Banners[0]
                                                      .banner_pic
                                                : "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/default_banner.svg"
                                        }
                                        alt="Background"
                                        className="object-cover"
                                    />
                                </figure>
                            </div>
                            <div className="profile-pictures">
                                <figure>
                                    <img
                                        src={
                                            myCompany?.company_logo
                                                ? myCompany.company_logo
                                                : "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"
                                        }
                                        alt="Profile"
                                    />
                                </figure>
                            </div>
                            <AiOutlineEdit
                                className="profile-edit-icon"
                                onClick={() => {
                                    setShowModal(true);
                                    setName(myCompany?.company_name);
                                    setAbout(myCompany?.about_company);
                                    setBannerPreview(myCompany?.company_banner);
                                }}
                            />
                            <div className="profile-pictures-blank" />
                            <div className="profile-content">
                                <div className="profile-names">
                                    <div className="company_name">
                                        <h3>
                                            {myCompany?.company_name
                                                ? myCompany?.company_name
                                                : "Lorem Ipsum"}
                                        </h3>
                                        {checknewMem && (
                                            <div className="newMember">
                                                <img src={newMember} alt="" />
                                            </div>
                                        )}
                                    </div>
                                    <div className="profile-rating">
                                        <Rating
                                            name="read-only"
                                            value={
                                                myCompany?.Reviews?.length ===
                                                    0 && checknewMem
                                                    ? 5
                                                    : myCompany?.Reviews
                                                          ?.length === 0 &&
                                                      !checknewMem
                                                    ? 3
                                                    : myCompany.avg_ratings
                                            }
                                            readOnly
                                            precision={0.25}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="horizontal-line" />

                            <div className="profile-about row align-items-center">
                                <div
                                    className="col-12 col-md-6 col-lg-6 col-xl-8"
                                    style={{ paddingRight: "20px" }}
                                >
                                    <h4>About</h4>
                                    <p
                                        style={{
                                            wordBreak: "break-all",
                                            whiteSpace: "pre-wrap",
                                        }}
                                    >
                                        {myCompany?.about_company
                                            ? myCompany?.about_company
                                            : "Company About"}
                                    </p>
                                </div>

                                <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                                    <div
                                        className="d-flex justify-content-between align-items-center p-4 mt-4 mt-md-0"
                                        style={{
                                            background: "#0279BD",
                                            borderRadius: "60px",
                                        }}
                                    >
                                        <div
                                            className="d-flex flex-column justify-content-center align-items-center"
                                            style={{
                                                minWidth: "140px",
                                                height: "140px",
                                                backgroundImage: `url(${bgvector})`,
                                                color: "black",
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "cover",
                                            }}
                                        >
                                            <p
                                                className="fw-bold"
                                                style={{ fontSize: "14px" }}
                                            >
                                                Backed by
                                            </p>
                                            <h3 className="fs-3 fw-bolder">
                                                {totalBackedBizBuck > 0
                                                    ? totalBackedBizBuck
                                                    : 0}
                                            </h3>
                                            <p
                                                className="fw-bold"
                                                style={{ fontSize: "14px" }}
                                            >
                                                businesses
                                            </p>
                                        </div>
                                        <div className="text-center">
                                            <h3
                                                className="fw-bolder text-warning"
                                                style={{
                                                    fontSize: "56px",
                                                    fontFamily: "Rye, serif",
                                                }}
                                            >
                                                {myBizBucks?.balance>0 ? myBizBucks?.balance : 0}
                                            </h3>
                                            <h5 className="fs-3 fw-bolder text-white">
                                                <img src={whiteBiz} alt="" />
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hide-mobile">
                        <div className="profile-down">
                            <div className="profile-down-left">
                                <div className="left-info">
                                    <Tags
                                        industry={myCompany?.Industry}
                                        services={services}
                                        serv={myCompany?.Services}
                                        isMine={
                                            user?.company_id ===
                                                myCompany?.id || true
                                        }
                                    />

                                    <CompanyPrefernce
                                        serv={companyPrefernces}
                                        findCompanyPrefernce={findCompanyPrefernce}
                                        isMine={
                                            user?.company_id ===
                                                myCompany?.id || true
                                        }
                                    />
                                    {memoizedProfileActivity}
                                    {/* <IndustryType
                                        companyIndustry={myCompany?.Industry}
                                        isMine={true}
                                    /> */}
                                    <OtherInfo
                                        isMine={
                                            user?.company_id ===
                                                myCompany?.id || true
                                        }
                                        title={myCompany?.OtherInfos}
                                        pdfLinks={myCompany?.Pdfs}
                                        images={myCompany?.Images}
                                        links={myCompany?.Links}
                                        comp={myCompany}
                                    />
                                </div>
                                <div className="request-other-to-endorse">
                                    <button onClick={() => setShowModal1(true)}>
                                        Get a business to vouch for you
                                    </button>
                                </div>
                                <RequestEndorse
                                    id={myCompany?.id}
                                    showModal={showModal1}
                                    setShowModal={setShowModal1}
                                />
                                {/* <ProfileReviews
                                    id={myCompany?.id}
                                    isMine={
                                        user?.company_id === myCompany?.id ||
                                        true
                                    }
                                    reviews={myCompany?.Reviews}
                                /> */}
                            </div>
                            <div className="profile-down-right">
                                <MemberSince date={myCompany?.createdAt} />
                                <ProfileBasicDetails
                                    isMine={
                                        user?.company_id === myCompany?.id ||
                                        true
                                    }
                                    company={myCompany}
                                />
                                <ProfileSocialLinks
                                    isMine={
                                        user?.company_id === myCompany?.id ||
                                        true
                                    }
                                    socials={myCompany?.Socials}
                                />
                                <ProfileLeaders
                                    leaders={myCompany?.Leaders}
                                    isMine={
                                        user?.company_id === myCompany?.id ||
                                        true
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="hide-laptop">
                        <div className="profile-down my-3">
                            <div className="profile-mobile-margin">
                                <MemberSince date={myCompany?.createdAt} />
                                <Tags
                                    industry={myCompany?.Industry}
                                    services={services}
                                    serv={myCompany?.Services}
                                    isMine={
                                        user?.company_id === myCompany?.id ||
                                        true
                                    }
                                />
                                 <CompanyPrefernce
                                        serv={companyPrefernces}
                                        findCompanyPrefernce={findCompanyPrefernce}
                                        isMine={
                                            user?.company_id ===
                                                myCompany?.id || true
                                        }
                                    />
                                {memoizedProfileActivity}
                                <IndustryType
                                    companyIndustry={myCompany?.Industry}
                                    isMine={true}
                                />
                                 <MemberSince date={myCompany?.createdAt} />
                                <ProfileBasicDetails
                                    isMine={
                                        user?.company_id === myCompany?.id ||
                                        true
                                    }
                                    company={myCompany}
                                />
                                <ProfileSocialLinks
                                    isMine={
                                        user?.company_id === myCompany?.id ||
                                        true
                                    }
                                    socials={myCompany?.Socials}
                                />
                                {/* <ProfileBasicDetails
                                    isMine={
                                        user?.company_id === myCompany?.id ||
                                        true
                                    }
                                    company={myCompany}
                                />
                                <ProfileSocialLinks
                                    isMine={
                                        user?.company_id === myCompany?.id ||
                                        true
                                    }
                                    socials={myCompany?.Socials}
                                /> */}

                                <OtherInfo
                                    isMine={
                                        user?.company_id === myCompany?.id ||
                                        true
                                    }
                                    title={myCompany?.OtherInfos}
                                    pdfLinks={myCompany?.Pdfs}
                                    images={myCompany?.Images}
                                    links={myCompany?.Links}
                                    comp={myCompany}
                                />

                                <ProfileLeaders
                                    leaders={myCompany?.Leaders}
                                    isMine={
                                        user?.company_id === myCompany?.id ||
                                        true
                                    }
                                />
                                <RequestEndorse
                                    id={myCompany?.id}
                                    showModal={showModal1}
                                    setShowModal={setShowModal1}
                                />
                                <div className="request-other-to-endorse">
                                    <button onClick={() => setShowModal1(true)}>
                                        Get a business to vouch for you
                                    </button>
                                </div>
                                {/* <ProfileReviews
                                    id={myCompany?.id}
                                    isMine={
                                        user?.company_id === myCompany?.id ||
                                        true
                                    }
                                    reviews={myCompany?.Reviews}
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* Modal */}
            <div className="profile-modal modal-wrapper">
                <Modal
                    size="regular"
                    active={showModal}
                    toggler={() => setShowModal(false)}
                    className="profile-tailwind-modal"
                >
                    <ModalBody>
                        <div className="form-group company-logo">
                            <h1>Company Logo</h1>
                            <div className="change-profile-logo">
                                <img
                                    src={
                                        logoPreview
                                            ? logoPreview
                                            : myCompany?.company_logo
                                            ? myCompany.company_logo
                                            : "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"
                                    }
                                    alt="Lorem"
                                    className="object-cover"
                                />
                                <div className="input-field">
                                    <label htmlFor="logo">Upload Logo</label>
                                    <span
                                        style={{
                                            justifyContent: "center",
                                            display: !isError1
                                                ? "flex"
                                                : "none",
                                            paddingLeft: "0.7rem",
                                            fontSize: "12px",
                                        }}
                                    >
                                        Max 500 KB
                                    </span>
                                    <span
                                        style={{
                                            justifyContent: "center",
                                            display: isError1 ? "flex" : "none",
                                            paddingLeft: "1rem",
                                            color: "red",
                                            fontWeight: "bold",
                                            fontSize: "15px",
                                        }}
                                    >
                                        Max 500 KB
                                    </span>
                                    <input
                                        name="companyLogo"
                                        id="logo"
                                        type="file"
                                        accept=".jpg, .png"
                                        onChange={onFileSelect}
                                    />
                                </div>
                            </div>
                            <div className="change-profile-banner">
                                <img
                                    className="object-cover"
                                    src={
                                        bannerPreview
                                            ? bannerPreview
                                            : "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/default_banner.svg"
                                    }
                                    alt="Lorem"
                                />
                                <div className="input-field">
                                    <div>
                                        <button
                                            onClick={() =>
                                                setDontHave(!dontHave)
                                            }
                                        >
                                            Don't have a banner
                                        </button>
                                    </div>
                                    <div className="upload-banner-btn">
                                        <label htmlFor="banner">
                                            Upload Banner
                                        </label>
                                        <div className="banner-instructions">
                                            <span
                                                style={{
                                                    justifyContent: "end",
                                                    display: !isError
                                                        ? "flex"
                                                        : "none",

                                                    fontSize: "12px",
                                                }}
                                            >
                                                Dimensions : 1315 * 275
                                            </span>
                                            <span
                                                style={{
                                                    justifyContent: "end",
                                                    display: !isError
                                                        ? "flex"
                                                        : "none",

                                                    fontSize: "12px",
                                                }}
                                            >
                                                Max 5 MB
                                            </span>
                                            <span
                                                style={{
                                                    justifyContent: "center",
                                                    display: isError
                                                        ? "flex"
                                                        : "none",
                                                    paddingLeft: "9rem",
                                                    color: "red",
                                                    fontWeight: "bold",
                                                    fontSize: "15px",
                                                }}
                                            >
                                                Max 5 MB
                                            </span>
                                        </div>
                                        <input
                                            name="bannerImg"
                                            id="banner"
                                            type="file"
                                            accept=".jpg, .png"
                                            onChange={(e) =>
                                                onFileSelectBanner(e)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            {dontHave && (
                                <>
                                    <div className="row-banner">
                                        <div className="row-banner-message">
                                            <h2>
                                                Don't have a banner, don't worry
                                                we've got your back.
                                            </h2>
                                            <p>
                                                Select from what we have
                                                designed you.
                                            </p>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                width: "100%",
                                            }}
                                            className="col-md-4 col-sm-6 col-12 my-2"
                                        >
                                            {/* onClick={() =>
                                                onFileSelectDemoBanner({
                                                    demoBanners,
                                                })
                                            } */}

                                            {demoBanners?.map((item, key) => {
                                                console.log("item", item);
                                                return (
                                                    <img
                                                        style={{
                                                            margin: "0.5rem",
                                                        }}
                                                        src={item}
                                                        key={key}
                                                        alt="Lorem"
                                                        onClick={() =>
                                                            onFileSelectDemoBanner(
                                                                item
                                                            )
                                                        }
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="company-name">
                                <h1>Company Name</h1>
                                <div className="modal-form">
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="company-about">
                                <h1>About</h1>
                                <div className="modal-form">
                                    <textarea
                                        rows={6}
                                        type="text"
                                        value={about}
                                        onChange={(e) => {
                                            e.target.value.length < 551 &&
                                                setAbout(e.target.value);
                                        }}
                                    />
                                    <div className="max-length-email-content">
                                        <span>
                                            {chars - about?.length} characters
                                            left
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <div className="d-flex justify-content">
                        <div className="close-button">
                            <Button
                                color="red"
                                buttonType="link"
                                onClick={(e) => setShowModal(false)}
                                ripple="dark"
                            >
                                Close
                            </Button>
                        </div>
                        <div className="save-button">
                            <Button
                                color="green"
                                onClick={(e) => editProfile()}
                                ripple="light"
                            >
                                Save Changes
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default Profile;
