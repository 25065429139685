import {
    forgotPasswordAPI,
    loginServiceAPI,
    resetPasswordAPI,
    signUpUserAPI,
    verifyUserAPI,
    getUserAPI,
    getAllUserAPI,
    getEarlyUserAPI,
    changePasswordAPI,
    sendUserFeedbackAPI,
    referAFriendAPI,
} from "../../api";

export const loginService = async (payload) => {
    try {
        const response = await loginServiceAPI(payload);
        return response?.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const feedbackService = async (text) => {
    try {
        const response = await sendUserFeedbackAPI(text);
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const verifyUserService = async (payload) => {
    try {
        const response = await verifyUserAPI(payload);
        return response?.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const forgotPasswordService = async (payload) => {
    try {
        const response = await forgotPasswordAPI(payload);
        return response?.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const resetPasswordService = async (payload) => {
    try {
        const response = await resetPasswordAPI(payload);
        return response?.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const changePasswordService = async (payload) => {
    try {
        const response = await changePasswordAPI(payload);
        return response?.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const signUpUserService = async (payload) => {
    try {
        const response = await signUpUserAPI(payload);
        return response?.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const getUserService = async () => {
    try {
        const response = await getUserAPI();
        return response?.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const getAllUserService = async () => {
    try {
        const response = await getAllUserAPI();
        return response?.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};
export const getEarlyUserService = async () => {
    try {
        const response = await getEarlyUserAPI();
        return response?.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const referAFriendService = async (token) => {
    console.log("token", token);
    try {
        const response = await referAFriendAPI(token);
        return response?.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};
