import React from "react";

function Pagination({ pages, page, handlePagination, pageLength }) {
    return (
        <div className="backbone-pagination mb-5">
            <div className="d-flex justify-content-between px-4">
                <button
                    className="pagination-previous"
                    disabled={page === 1}
                    onClick={() => {
                        if (page === 1) {
                            return;
                        } else {
                            handlePagination(page - 1);
                        }
                    }}
                >
                    {"<"} &nbsp; Previous
                </button>
                <div className="pagination-buttons">
                    {pages?.map((val, ind) => {
                        let start = Math.floor((page - 1) / 10) * 10;
                        const c = new Array(10)
                            .fill()
                            .map((_, idx) => start + idx + 1);
                        const r = ind + 1;
                        if (c.includes(r)) {
                            return (
                                <button
                                    className={
                                        page === parseInt(ind) + 1
                                            ? "flex flex-[1_0_0] active-button"
                                            : "flex flex-1"
                                    }
                                    key={ind}
                                    onClick={() => handlePagination(ind + 1)}
                                >
                                    {`${ind * 10 + 1} - ${(ind + 1) * 10}`}
                                </button>
                            );
                        }
                    })}
                </div>
                <button
                    key={page}
                    className="pagination-next"
                    disabled={page === pageLength}
                    onClick={() => {
                        if (page === pageLength) {
                            return;
                        } else {
                            handlePagination(parseInt(page) + 1);
                        }
                    }}
                >
                    Next &nbsp; {">"}
                </button>
            </div>
        </div>
    );
}

export default Pagination;
