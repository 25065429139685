import {
    addAdvertisementAPI,
    getAdvertisementAPI,
    getHomeAdvertisementAPI,
    reduceAdvertisementBudgetAPI,
    updateAdvertisementAPI,
} from "../../api";

export const addAdvertisement = async (ad) => {
    const response = await addAdvertisementAPI(ad);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const updateAdvertisement = async (ad) => {
    const response = await updateAdvertisementAPI(ad);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getAllAdvertisement = async () => {
    const response = await getAdvertisementAPI();
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getHomeAdvertisement = async () => {
    const response = await getHomeAdvertisementAPI();
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const reduceAdvertisementBudget = async (id) => {
    const response = await reduceAdvertisementBudgetAPI(id);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};
