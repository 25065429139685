import React, { memo } from 'react'
import "./myPreference.css"
const MyPreference = ({preference}) => {
  return (
    <>
       <div className='container'>
             <div className='row'>
                  <div className='col-12'>
                      <div className='profile-industry-type-section'>
                           <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
                             <h3 className='fs-4 fw-bolder'>My Preferences</h3>
                             <span className='ms-4 fs-6'>Industries they’d liked to be matched to </span>
                           </div>
                      </div>
                  </div>

                  {
                    preference?.length>0 ? 
                              <div className='col-12'>
                                   <div className='profile-industry-type-section profile-tags'>
                                        <div className='d-flex justify-content-start align-items-center'>
                                             {
                                                  preference?.map((item,index)=>{
                                                       return <p key={index} className='preference_tag_item'>{item?.industry}</p>
                                                  })
                                             }
                                           
                                        </div>
                                   </div>
                              </div>:  <div className='col-12'>
                              <div className='profile-industry-type-section profile-tags'>
                              <div className='d-flex justify-content-start align-items-center'>
                                   <h3> No Preferences found</h3>
                                   </div></div>
                              </div>
                    }
             </div>
       </div>
    </>
  )
}

export default memo(MyPreference)