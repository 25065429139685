import { getSubscriptionsAPI } from "../../api";

export const getAllSubscriptions = async () => {
    const response = await getSubscriptionsAPI();
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};
