import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GoPrimitiveDot } from "react-icons/go";
import Button from "@material-tailwind/react/Button";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import favi from "../../../assets/backbone-logo.svg";
import "./ResetPassword.css";
import Login_Left_Frame_2 from "../../../assets/signup-left-frame-2.svg";

import logo from "../../../assets/backbone-logo.svg";
import { useToast } from "@chakra-ui/react";
import {
    authActions,
    resetPasswordAction,
} from "../../../data/reducers/auth/auth";
import Helmet from "react-helmet";

const ResetPassword = () => {
    const { token } = useParams();
    const toast = useToast();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { error, message } = useSelector((state) => state.auth);

    const resetPass = () => {
        console.log("yaha tak aa gya");
        dispatch(resetPasswordAction({ token, password, conPassword }));
    };

    const [password, setPassword] = useState("");
    const [conPassword, setConPassword] = useState("");
    const [desc, setDesc] = useState("");
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);

    const [passwordError, setPasswordError] = useState(false);
    const [conPasswordError, setConPasswordError] = useState(false);

    const [visible, setVisible] = useState({
        password1: false,
        password2: false,
    });

    const [min8, setMin8] = useState(false);
    const [upp1, setUpp1] = useState(false);
    const [low1, setLow1] = useState(false);
    const [num1, setNum1] = useState(false);
    const [special1, setSpecial1] = useState(false);

    const [data, setData] = useState({
        password: "",
    });
    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!(min8 && upp1 && low1 && num1 && special1)) {
            setPasswordError(true);
        } else {
            setPasswordError(false);
        }

        if (password !== conPassword || !conPassword) {
            setConPasswordError(true);
        } else {
            setConPasswordError(false);
        }

        const isValidated =
            min8 &&
            upp1 &&
            low1 &&
            num1 &&
            special1 &&
            password === conPassword;

        if (isValidated) {
            const { payload } = await dispatch(
                resetPasswordAction({ token, password, conPassword })
            );
            if (payload?.success) {
                navigate("/auth");
            }
        }
    };

    const checkValidation = (e) => {
        // For length
        if (e.target.value?.length >= 8) {
            setMin8(true);
        } else {
            setMin8(false);
        }
        // For Upper
        if (e.target.value?.toLowerCase() !== e.target.value) {
            setUpp1(true);
        } else {
            setUpp1(false);
        }
        // For Lower
        if (e.target.value?.toUpperCase() !== e.target.value) {
            setLow1(true);
        } else {
            setLow1(false);
        }
        // For Number
        const regexNum = /\d/;
        if (regexNum.test(e.target.value)) {
            setNum1(true);
        } else {
            setNum1(false);
        }
        // For Number
        const regexSpecial = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
        if (regexSpecial.test(e.target.value)) {
            setSpecial1(true);
        } else {
            setSpecial1(false);
        }
    };

    useEffect(() => {
        if (error) {
            console.log("Error = " + error);
            toast({
                title: "Error",
                description: error,
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
            dispatch(authActions.reset());
        }
    }, [dispatch, toast, error]);

    return (
        <>
            <Helmet>
                <link rel="icon" type="image/png" href={favi} sizes="16x16" />
            </Helmet>
            <div className="reset-container">
                <div className="reset-left">
                    <img src={Login_Left_Frame_2} alt="Login Frame" />
                </div>
                <div className="reset-right z-20">
                    <div className="logo-main">
                        <img src={logo} alt="" />
                    </div>
                    <div className="rest-body">
                        <div className="reset-password-heading my-4">
                            <h1>Forgot Password</h1>
                        </div>
                        <form onSubmit={onSubmit}>
                            <div className="reset-right-form">
                                <div className="form-group">
                                    <label htmlFor="password">
                                        New Password*
                                    </label>
                                    <div className="reset-form-input">
                                        <input
                                            type={show2 ? "text" : "password"}
                                            placeholder="New Password"
                                            className={
                                                passwordError
                                                    ? "error-input"
                                                    : ""
                                            }
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                                checkValidation(e);
                                            }}
                                        />
                                        {show2 ? (
                                            <AiOutlineEye
                                                fontSize={24}
                                                color="#8d8d8d"
                                                onClick={() => setShow2(!show2)}
                                            />
                                        ) : (
                                            <AiOutlineEyeInvisible
                                                fontSize={24}
                                                color="#8d8d8d"
                                                onClick={() => setShow2(!show2)}
                                            />
                                        )}
                                    </div>
                                    <div className="password-validation">
                                        <p>
                                            <GoPrimitiveDot
                                                color={
                                                    min8 ? "#5AB659" : "#A6A6A6"
                                                }
                                                fontSize={16}
                                            />
                                            Minimum 8 characters
                                        </p>
                                        <p>
                                            <GoPrimitiveDot
                                                color={
                                                    upp1 ? "#5AB659" : "#A6A6A6"
                                                }
                                                fontSize={16}
                                            />
                                            Atleast 1 uppercase
                                        </p>
                                        <p>
                                            <GoPrimitiveDot
                                                color={
                                                    low1 ? "#5AB659" : "#A6A6A6"
                                                }
                                                fontSize={16}
                                            />
                                            Atleast 1 lower case
                                        </p>
                                        <p>
                                            <GoPrimitiveDot
                                                color={
                                                    num1 ? "#5AB659" : "#A6A6A6"
                                                }
                                                fontSize={16}
                                            />
                                            Atleast 1 number
                                        </p>
                                        <p>
                                            <GoPrimitiveDot
                                                color={
                                                    special1
                                                        ? "#5AB659"
                                                        : "#A6A6A6"
                                                }
                                                fontSize={16}
                                            />
                                            Atleast 1 special character
                                        </p>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="password">
                                        Confirm New Password*
                                    </label>
                                    <div className="reset-form-input">
                                        <input
                                            type={show3 ? "text" : "password"}
                                            placeholder="Confirm New Password"
                                            className={
                                                conPasswordError
                                                    ? "error-input"
                                                    : ""
                                            }
                                            onChange={(e) => {
                                                setConPassword(e.target.value);
                                            }}
                                        />
                                        {show3 ? (
                                            <AiOutlineEye
                                                fontSize={24}
                                                color="#8d8d8d"
                                                onClick={() => setShow3(!show3)}
                                            />
                                        ) : (
                                            <AiOutlineEyeInvisible
                                                fontSize={24}
                                                color="#8d8d8d"
                                                onClick={() => setShow3(!show3)}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div
                                    className="reset-submit-button"
                                    style={{
                                        paddingBottom: "1rem",
                                    }}
                                >
                                    <Button
                                        color="blue"
                                        buttonType="filled"
                                        size="regular"
                                        rounded={false}
                                        type="submit"
                                        onClick={() => onSubmit()}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;
