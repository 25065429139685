import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { getSearchedCompaniesAction } from "../../data/reducers/home/content";
import { BsSearch } from "react-icons/bs";
import EventCard from "./Components/EventCard";
import {
    getLikedEvents,
    getLikedSearchEvents,
} from "../../data/reducers/events/events";
import LikedEventIcon from "../../assets/liked-event-icon.svg";
import Pagination from "../../components/Pagination";
import EmptyState from "../Errors/EmptyState";

const LikedEvents = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { likedEvents, likedEventsCount, success, message } = useSelector(
        (state) => state.events
    );
    console.log("like", likedEventsCount, likedEvents);

    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const count = 5;
    const eventsList = likedEventsCount;
    const pageCount = new Array(Math.ceil(eventsList / count) || 1).fill(0);
    const pageLength = pageCount?.length;
    const token = localStorage.getItem("token");

    const [searchParams] = useSearchParams();

    const searchCompanies = async () => {
        dispatch(getSearchedCompaniesAction(search));
    };

    useEffect(() => {
        if (search === "") {
            dispatch(getLikedEvents({ page: page }));
        }
    }, [dispatch, search]);

    useEffect(() => {
        if (success && message) {
            dispatch(getLikedEvents({ page: page }));
        }
    }, [dispatch, success, message]);

    const handlePagination = (val) => {
        setPage(val);
        dispatch(getLikedEvents({ page: val }));
    };

    const handleSearch = (e) => {
        e.preventDefault();
        if (search !== "") {
            setTimeout(() => {
                dispatch(getLikedSearchEvents({ searchText: search }));
            }, 600);
        } else {
            setTimeout(() => {
                dispatch(getLikedEvents({ page: page }));
            }, 600);
        }
    };

    return (
        <div className="upcoming-events position-relative">
            <div className="main-div">
                <div className="row">
                    <div className="col-xl-1"></div>
                    <div className="col-xl-8">
                        <div className="event-list">
                            <div className="fav-heading-search">
                                <h1>Favourites</h1>
                                <div className="text-center">
                                    <form className="form-group d-flex align-items-center me-md-4">
                                        <input
                                            type="text"
                                            className="form-control me-4"
                                            id="search-events"
                                            placeholder="Enter Event Name"
                                            value={search}
                                            onChange={(e) =>
                                                setSearch(e.target.value)
                                            }
                                        />
                                        <button
                                            type="submit"
                                            onClick={(e) => {
                                                handleSearch(e);
                                            }}
                                        >
                                            <BsSearch
                                                className="search-icon"
                                                color="darkblue"
                                                fontSize={28}
                                            />
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4"></div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-xl-7">
                        <div className="event-list">
                            {/* <div className="d-flex justify-content-between align-items-center">
                                <h1>Liked events</h1>
                                <div className="text-center">
                                    <BsSearch
                                        className="search-icon"
                                        fontSize={18}
                                    />
                                    <p>Search</p>
                                </div>
                            </div> */}
                            {likedEvents &&
                                likedEvents?.map((event, index) => {
                                    return (
                                        <EventCard
                                            key={index}
                                            event={event}
                                            hasEditIcon={false}
                                        />
                                    );
                                })}
                        </div>
                        {pageCount.length > 1 && (
                            <Pagination
                                pages={pageCount}
                                page={page}
                                handlePagination={handlePagination}
                                pageLength={pageLength}
                            />
                        )}
                        {!likedEvents ||
                            (likedEvents?.length === 0 && <EmptyState />)}
                    </div>
                    <div className="col-xl-3"></div>
                </div>
            </div>
        </div>
    );
};

export default LikedEvents;
