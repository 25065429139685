import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getIndustriesService,
    addCompanyDetails,
    makePaymentService,
    getStripeKeyService,
    savePaymentService,
    editCompanyInfo,
    getAllServices,
    deleteService,
    deletePrefernce,
} from "../../services/details/details";

const initialState = {
    isFree: false,
    error: null,
    message: null,
    success: false,
    activeStep: 1,
    industries: [],
    serv: [],
    basicDetails1: {},
    basicDetails2: {},
    companyInfoUpdateSuccess: false,
    companyInfoUpdateFailure: false,
    launchForm: {},
    type: "",
    stripeAPIKey: "",
    subscriptionCredit: 0,
    applyPromoCodeStatus:false
};

export const getIndustries = createAsyncThunk(
    "details/get-industries",
    async () => {
        const response = await getIndustriesService();
        if (response?.error) {
            return { error: response?.error };
        }
        return response;
    }
);

export const getUserSubscriptionCreditAction = createAsyncThunk(
    "details/get-subscription-credit",
    async () => {
        const response = await getIndustriesService();
        if (response?.error) {
            return { error: response?.error };
        }
        return response;
    }
);

export const getServicesAction = createAsyncThunk("get/tags", async () => {
    const response = await getAllServices();
    if (response?.error) {
        return { error: response };
    }
    return response;
});

export const deleteServicesAction = createAsyncThunk(
    "delete/tags",
    async (chip) => {
        const response = await deleteService(chip);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const deletePrefernceAction = createAsyncThunk(
    "delete/prefernce",
    async (chip) => {
        const response = await deletePrefernce(chip);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const updateCompanyInfoAction = createAsyncThunk(
    "update/company-info",
    async (payload) => {
        const response = await editCompanyInfo(payload);
        if (response?.error) {
            return { error: response?.error };
        }
        return response;
    }
);

export const addCompany = createAsyncThunk(
    "details/add-company",
    async (data) => {
        const response = await addCompanyDetails(data);
        if (response?.error) {
            return { error: response?.error };
        }
        return response;
    }
);


export const makePayment = createAsyncThunk(
    "details/makePayment",
    async (payload) => {
        const response = await makePaymentService(payload);
        if (response?.error) {
            return { error: response.error };
        }
        return response;
    }
);

export const savePayment = createAsyncThunk("save/payment", async (payload) => {
    const response = await savePaymentService(payload);
    if (response?.error) {
        return { error: response.error };
    }
    return response;
});

export const getStripeKey = createAsyncThunk(
    "details/stripeAPIKey",
    async () => {
        const response = await getStripeKeyService();
        if (response?.error) {
            return { error: response.error };
        }
        return response;
    }
);

export const detailsSlice = createSlice({
    name: "details",
    initialState,
    reducers: {
        reset: (state, action) => {
            state.message = null;
            state.error = null;
            state.success = false;
        },
        setIsFree: (state, action) => {
            state.isFree = action.payload;
        },
        setActiveStep: (state, action) => {
            state.activeStep = action.payload;
        },
        setBasicDetails1: (state, action) => {
            state.basicDetails1 = action.payload;
        },
        setBasicDetails2: (state, action) => {
            state.basicDetails2 = action.payload;
        },
        setPlan: (state, action) => {
            state.type = action.payload;
        },
        setLaunchForm: (state, action) => {
            state.launchForm = action.payload;
        },
        advertisementReset: (state, action) => {
            state.launchForm = {};
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        applyPromoCodeStatus:(state,action)=>{
            state.applyPromoCodeStatus=true
        }
    },
    extraReducers: {
        // Get Industries
        [getIndustries.pending]: (state, action) => {
            state.loading = true;
        },
        [getIndustries.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            state.loading = false;
            if (action.payload?.error) {
                state.error = action.payload?.error;
            }
            state.industries = action.payload?.industries;
        },
        [getServicesAction.pending]: (state, action) => {
            state.loading = true;
        },
        [getServicesAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.serv = action.payload?.result;
            }
        },

        [deleteServicesAction.pending]: (state, action) => {
            state.loading = true;
        },
        [deleteServicesAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.serv = action.payload?.message;
                state.loading = false;
            }
        },

        // Add Company
        [addCompany.pending]: (state, action) => {
            state.loading = true;
        },
        [addCompany.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            console.log("stateSuccess", state.success);
            if (action.payload?.error) {
                state.error = action.payload?.error;
            }
            if (action.payload?.message) {
                state.message = action.payload?.message;
            }
            state.industries = action.payload?.industries;
        },

        // Get Stripe Key
        [getStripeKey.pending]: (state, action) => {
            state.loading = true;
        },
        [getStripeKey.fulfilled]: (state, action) => {
            state.stripeAPIKey = action.payload?.stripeApiKey;
            state.loading = false;
            state.error = action.payload?.error;
        },

        // Make Payment
        [makePayment.pending]: (state, action) => {
            state.loading = true;
        },
        [makePayment.fulfilled]: (state, action) => {
            state.order = action.payload?.order;
            state.loading = false;
            if (action.payload?.error) {
                state.error = action.payload.error;
            } else {
                state.success = action.payload?.message;
            }
        },
        [updateCompanyInfoAction.pending]: (state, action) => {
            state.loading = true;
        },
        [updateCompanyInfoAction.fulfilled]: (state, action) => {
            state.loading = false;
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error;
            }
            if (action.payload?.message) {
                state.message = action.payload?.message;
            }
        },
    },
});

export const detailsActions = detailsSlice.actions;

export default detailsSlice.reducer;
