import {
    Routes as Switch,
    Route,
    useNavigate,
    useSearchParams,
    Navigate,
} from "react-router-dom";
import "./Layout.css";

import Auth from "../pages/Auth/Auth";
import Routes from "./Routes";
import PrivacyPolicy from "../pages/Policies/PrivacyPolicy/PrivacyPolicy";
import CookiesPolicy from "../pages/Policies/CookiesPolicy/CookiesPolicy";
import Pricing from "../pages/Policies/Pricing/Pricing";
import FaqFooter from "../pages/Policies/FaqFooter/Faqfooter";

import VerifyAccount from "../pages/Auth/Components/VerifyAccount";
import ResetPassword from "../pages/Auth/Components/ResetPassword";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { MdPeople } from "react-icons/md";
import { Elements } from "@stripe/react-stripe-js";
import { getStripeKey } from "../data/reducers/details/details";
import { loadStripe } from "@stripe/stripe-js";
import { authActions, getUserProfile } from "../data/reducers/auth/auth";
import Loader from "../shared/Loader/Loader";
import Landing from "../pages/Landing/Landing";
import TermsConditions from "../pages/Policies/TermsConditions/TermsConditions";
import ForgotPassword from "../pages/Auth/Components/ForgotPassword";
import PaymentReject from "../pages/PaymentReject/PaymentReject";
import PaymentSuccess from "../pages/PaymentSuccess/PaymentSuccess";
import UserProfile from "../pages/Home/UserProfile/UserProfile";
import { GOOGLE_CAPTCHA_KEY } from "../config/api";
import AllReviews from "../pages/Home/Reviews/AllReviews";
import Navbar from "../pages/Home/Navbar/Navbar";
import NotFound from "../pages/Errors/NotFound";
import Server from "../pages/Errors/Server";
import UpcomingEvents from "../pages/UpcomingEvents/UpcomingEvents";
import { addCompany, detailsActions } from "../data/reducers/details/details";
import moment from "moment";
import { getSubsCheck } from "../data/api";
const Layout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user, loading, isReset } = useSelector((state) => state.auth);

    const { stripeAPIKey } = useSelector((state) => state.details);
    const { error } = useSelector((state) => state.details);
    useEffect(() => {
        if (localStorage.getItem("token")) {
            dispatch(getUserProfile());
        }
    }, [dispatch]);

    useEffect(() => {
        console.log("hello i am in layout");
        if (!stripeAPIKey || stripeAPIKey === "") {
            dispatch(getStripeKey());
        } else {
            console.log(
                "1",
                user?.subscriptionValidity,
                user,
                moment(user?.subscriptionValidity).diff(moment(), "days")
            );
            if (user && !user?.hasCompany) {
                if (isReset === true) {
                    navigate("/auth/change-password");
                } else {
                    const isPaymentReject =
                        window.location.pathname === "/paymentreject";
                    if (isPaymentReject) {
                        navigate("/paymentreject");
                    } else {
                        navigate("/details");
                    }
                }
            } else if (user && user?.hasCompany) {
                if (getCheck() == true) {
                    console.log("in check", getCheck());
                    dispatch(authActions.setIsAuthenticated(true));
                    if (window.location.pathname?.includes("/details"))
                        navigate("/");
                }
                // check if subscription is expired
                else if (
                    moment(user?.subscriptionValidity).diff(moment(), "days") <
                    0
                ) {
                    dispatch(detailsActions.setActiveStep(2));
                    navigate("/details", {
                        state: { choseSubs: true },
                    });
                }
                // check if the user doesn't have a subscription plan
                else if (!user?.subscription) {
                    console.log("2");
                    dispatch(detailsActions.setActiveStep(2));
                    navigate("/details", {
                        state: { choseSubs: true },
                    });
                } else if (
                    user?.subscription &&
                    window.location.pathname?.includes("/details")
                ) {
                    dispatch(authActions.setIsAuthenticated(true));
                    navigate("/");
                    console.log("hello can you see me2");
                }
            }
        }
    }, [dispatch, user, navigate, stripeAPIKey]);

    const getCheck = async () => {
        try {
            let res = await getSubsCheck(user?.id);
            let check = res?.data?.success;
            console.log("check", check);
            return check;
        } catch (e) {
            console.log(e);
            return false;
        }
    };

    useEffect(() => {
        let userData = localStorage.getItem("userInfo");
        let url = window.location.href;
        let id = unescape(url).split("?id=")[1];
        if (id) {
            localStorage.setItem("event", id);
        }
        if (!userData && id) {
            navigate("/auth");
        }
    }, []);

    return (
        <>
            {!user && !loading ? (
                <Switch>
                    <Route exact path="/" element={<Landing />} />
                    {GOOGLE_CAPTCHA_KEY && (
                        <Route exact path="/auth" element={<Auth />} />
                    )}
                    <Route
                        exact
                        path="/privacy-policy"
                        element={<PrivacyPolicy />}
                    />
                    <Route
                        exact
                        path="/cookies-policy"
                        element={<CookiesPolicy />}
                    />
                    <Route
                        exact
                        path="/terms-conditions"
                        element={<TermsConditions />}
                    />
                    <Route
                        exact
                        path="/auth/verify-account/:token"
                        element={<VerifyAccount />}
                    />
                    <Route
                        exact
                        path="/auth/reset-password/:token"
                        element={<ResetPassword />}
                    />
                    <Route exact path="/faqfooter" element={<FaqFooter />} />
                    <Route exact path="/pricing" element={<Pricing />} />
                    <Route
                        exact
                        path="/paymentreject"
                        element={<PaymentReject />}
                    />
                    <Route
                        exact
                        path="/paymentsuccess"
                        element={<PaymentSuccess />}
                    />
                    <Route
                        exact
                        path="/company/:id"
                        element={<UserProfile />}
                    />
                    <Route exact path="/reviews/:id" element={<AllReviews />} />
                </Switch>
            ) : loading && error != "Unable to get Stripe API Key" ? (
                <Loader />
            ) : error === "Unable to get Stripe API Key" ? (
                <>
                    <Server />
                </>
            ) : (
                <>
                    <Switch>
                        <Route
                            exact
                            path="/terms-conditions"
                            element={<TermsConditions />}
                        />
                    </Switch>
                    {stripeAPIKey && (
                        <Elements stripe={loadStripe(stripeAPIKey)}>
                            <Routes />
                        </Elements>
                    )}
                </>
            )}
        </>
    );
};

export default Layout;
