import { sendContactDetailsAPI } from "../../api";

export const sendContactDetails = async (payload) => {
    const response = await sendContactDetailsAPI(payload);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};
