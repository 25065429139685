import React, { useEffect, useState } from "react";
import "./ReferFriend.css";
import Modal from "@material-tailwind/react/Modal";
import ModalBody from "@material-tailwind/react/ModalBody";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ENDORSE_BASE_URL } from "../../../config/api";
import { AiOutlineLink } from "react-icons/ai";
import { nanoid } from "nanoid";
import { referAFriendAction } from "../../../data/reducers/auth/auth";
import { useDispatch } from "react-redux";

const ReferFriend = ({ id, openModel, setOpenModel }) => {
    const dispatch = useDispatch();
    // const { user } = useSelector((state) => state.auth);
    const ReferFriendConstent = " Refer a friend";
    const [getLink, setGetLink] = useState(false);
    const randomString = nanoid(48);
    const copyDetailAction = () => {
        try {
            dispatch(referAFriendAction({ token: randomString }));
            setGetLink(!getLink);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        setGetLink(false);
    }, [openModel]);

    return (
        <>
            <div className="modal-wrapper">
                <Modal
                    size="lg"
                    active={openModel}
                    toggler={() => {
                        setOpenModel(false);
                    }}
                >
                    <ModalBody>
                        <div className="refer-body">
                            <div className="top-heading">
                                <div className="icon-wrapper">
                                    <AiOutlineLink />
                                </div>
                                <h1>Get link</h1>
                            </div>
                            <div className="credit-free">
                                Use this unique link to have another business
                                sign up to BackBone and earn 1 month free
                                subscription credit, when they do{" "}
                            </div>
                            <div className="bottom-share d-flex justify-content-between gap-4">
                                <p className="share-this-link">
                                    Anyone on the internet with this link can
                                    view
                                </p>
                                <CopyToClipboard
                                    text={`${ENDORSE_BASE_URL}/auth?signup=true&referToken=${randomString}`}
                                >
                                    <button
                                        
                                    >
                                        {!getLink ? (
                                            <h1 onClick={() => {
                                                // navigator.clipboard.writeText(
                                                //     `${ENDORSE_BASE_URL}/auth?referToken=${randomString}`
                                                // );
    
                                                copyDetailAction();
                                            }}>Copy Link</h1>
                                        ) : (
                                            <h1 style={{cursor:"auto"}}>Link Copied</h1>
                                        )}
                                    </button>
                                </CopyToClipboard>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        </>
    );
};
export default ReferFriend;
