import React, { useEffect } from "react";
import "./PrivacyPolicy.css";
import Footer from "../../../shared/Footer/Footer";
import PolicyNavbar from "../Navbar/PolicyNavbar";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/backbone-logo.svg";

const PrivacyPolicy = () => {
    const navigate = useNavigate();
    return (
        <>
            <div onClick={() => navigate("/")}>
                <img
                    style={{
                        width: "4rem",
                        marginLeft: "3.5rem",
                        marginTop: "1rem",
                    }}
                    src={Logo}
                    alt="logo"
                    className="logo"
                />
            </div>
            <div className="privacy-policy ">
                <div className="container">
                    <h2 className="privacy-header pt-10">PRIVACY POLICY</h2>
                    <br />
                    <br />
                    <div
                        className="row"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <div className="col-md-9 col-sm-8 col-12 ml-3 ">
                            <div className="d-flex ">
                                <p className="paragraph">
                                    <span className="privacy-subheader">
                                        1 &emsp;INTRODUCTION
                                    </span>{" "}
                                    <br />
                                    <br />
                                    This document sets out the privacy policy of
                                    BackBone Global Services Pty Ltd ABN 33 656
                                    180 504 <br />
                                    (referred to in this privacy policy as ‘we’,
                                    ‘us’, or ‘our’). <br />
                                    We take our privacy obligations seriously
                                    and we’ve created this privacy policy to
                                    explain how we store, maintain, use and
                                    disclose personal information.
                                    <br />
                                    By providing personal information to us, you
                                    consent to our storage, maintenance, use and
                                    disclosing of personal information in
                                    accordance with this privacy policy.
                                    <br />
                                    We may change this privacy policy from time
                                    to time by posting an updated copy on our
                                    website and we encourage you to check our
                                    website regularly to ensure that you are
                                    aware of our most current privacy policy.
                                    <br />
                                    <br />
                                    <span className="privacy-subheader">
                                        2 &emsp;TYPES OF PERSONAL INFORMATION WE
                                        COLLECT
                                    </span>
                                    <br />
                                    <br />
                                    The personal information we collect may
                                    include the following:
                                    <br />
                                    (a)&emsp; name;
                                    <br />
                                    (b)&emsp; mailing or street address;
                                    <br />
                                    (c)&emsp; email address;
                                    <br />
                                    (d)&emsp; social media information;
                                    <br />
                                    (e)&emsp; telephone number and other contact
                                    details;
                                    <br />
                                    (f)&emsp; age;
                                    <br />
                                    (g)&emsp; date of birth;
                                    <br />
                                    (h)&emsp; credit card or other payment
                                    information;
                                    <br />
                                    (i)&emsp; information about your business or
                                    personal circumstances;
                                    <br />
                                    (j)&emsp; information in connection with
                                    client surveys, questionnaires and
                                    promotions;
                                    <br />
                                    (k)&emsp; your device identity and type,
                                    I.P. address, geo-location information, page
                                    view statistics, advertising data and
                                    standard web log information;
                                    <br />
                                    (l)&emsp; information about third parties;
                                    and
                                    <br />
                                    (m)&emsp; any other information provided by
                                    you to us via our website or our online
                                    presence, or otherwise required by us or
                                    provided by you.
                                    <br />
                                    <br />
                                    <span className="privacy-subheader">
                                        3&emsp; HOW WE COLLECT PERSONAL
                                        INFORMATION
                                    </span>
                                    <br />
                                    <br />
                                    We may collect personal information either
                                    directly from you, or from third parties,
                                    including where you:
                                    <br />
                                    (a)&emsp; contact us through our website;
                                    <br />
                                    (b)&emsp; receive goods or services from us;
                                    <br />
                                    (c)&emsp; submit any of our online sign up
                                    forms;
                                    <br />
                                    (d)&emsp; communicate with us via email,
                                    telephone, SMS, social applications (such as
                                    LinkedIn, Facebook or Twitter) or otherwise;
                                    <br />
                                    (e)&emsp; interact with our website, social
                                    applications, services, content and
                                    advertising; and
                                    <br />
                                    (f)&emsp; invest in our business or enquire
                                    as to a potential purchase in our business.
                                    <br />
                                    We may also collect personal information
                                    from you when you use or access our website
                                    or our social media pages. This may be done
                                    through use of web analytics tools,
                                    ‘cookies’ or other similar tracking
                                    technologies that allow us to track and
                                    analyse your website usage. Cookies are
                                    small files that store information on your
                                    computer, mobile phone or other device and
                                    enable and allow the creator of the cookie
                                    to identify when you visit different
                                    websites. If you do not wish information to
                                    be stored as a cookie, you can disable
                                    cookies in your web browser. <br />
                                    <span className="privacy-subheader">
                                        Optional
                                    </span>
                                    : We may use Google Analytics to collect and
                                    process data, including when you use third
                                    party websites or apps. To find out more see
                                    How Google uses data when you use our
                                    partners’ sites or apps.
                                    <br />
                                    <br />
                                    <span className="privacy-subheader">
                                        4&emsp; USE OF YOUR PERSONAL INFORMATION
                                    </span>
                                    <br />
                                    <br />
                                    We collect and use personal information for
                                    the following purposes:
                                    <br />
                                    (a)&emsp; to provide goods, services or
                                    information to you;
                                    <br />
                                    (b)&emsp; for record keeping and
                                    administrative purposes;
                                    <br />
                                    (c)&emsp; to provide information about you
                                    to our contractors, employees, consultants,
                                    agents or other third parties for the
                                    purpose of providing goods or services to
                                    you;
                                    <br />
                                    (d)&emsp; to improve and optimise our
                                    service offering and customer experience;
                                    <br />
                                    (e)&emsp; to comply with our legal
                                    obligations, resolve disputes or enforce our
                                    agreements with third parties;
                                    <br />
                                    (f)&emsp; to send you marketing and
                                    promotional messages and other information
                                    that may be of interest to you and for the
                                    purpose of direct marketing (in accordance
                                    with the Spam Act).
                                    <br />
                                    In this regard, we may use email, SMS,
                                    social media or mail to send you direct
                                    marketing communications. You can opt out of
                                    receiving marketing materials from us by
                                    using the opt-out facility provided (e.g. an
                                    unsubscribe link);
                                    <br />
                                    (g)&emsp; to send you administrative
                                    messages, reminders, notices, updates,
                                    security alerts, and other information
                                    requested by you; and
                                    <br />
                                    (h)&emsp; to consider an application of
                                    employment from you.
                                    <br />
                                    We may disclose your personal information to
                                    cloud-providers, contractors and other third
                                    parties located inside or outside of
                                    Australia. If we do so, we will take
                                    reasonable steps to ensure that any overseas
                                    recipient deals with such personal
                                    information in a manner consistent with how
                                    we deal with it.
                                    <br />
                                    <br />
                                    <span className="privacy-subheader">
                                        5&emsp; SECURITY
                                    </span>
                                    <br />
                                    <br />
                                    We take reasonable steps to ensure your
                                    personal information is secure and protected
                                    from misuse or unauthorised access. Our
                                    information technology systems are password
                                    protected, and we use a range of
                                    administrative and technical measures to
                                    protect these systems. However, we cannot
                                    guarantee the security of your personal
                                    information.
                                    <br />
                                    <br />
                                    <span className="privacy-subheader">
                                        6&emsp; LINKS
                                    </span>
                                    <br />
                                    <br />
                                    Our website may contain links to other
                                    websites. Those links are provided for
                                    convenience and may not remain current or be
                                    maintained. We are not responsible for the
                                    privacy practices of those linked websites
                                    and we suggest you review the privacy
                                    policies of those websites before using
                                    them.
                                    <br />
                                    <br />
                                    <span className="privacy-subheader">
                                        7&emsp; REQUESTING ACCESS OR CORRECTING
                                        YOUR PERSONAL INFORMATION
                                    </span>
                                    <br />
                                    <br />
                                    If you wish to request access to the
                                    personal information we hold about you,
                                    please contact us using the contact details
                                    set out below including your name and
                                    contact details. We may need to verify your
                                    identity before providing you with your
                                    personal information. In some cases, we may
                                    be unable to provide you with access to all
                                    your personal information and where this
                                    occurs, we will explain why. We will deal
                                    with all requests for access to personal
                                    information within a reasonable timeframe.
                                    <br />
                                    If you think that any personal information
                                    we hold about you is inaccurate, please
                                    contact us using the contact details set out
                                    below and we will take reasonable steps to
                                    ensure that it is corrected.
                                    <br />
                                    <br />
                                    <span className="privacy-subheader">
                                        8&emsp; COMPLAINTS
                                    </span>
                                    <br />
                                    <br />
                                    If you wish to complain about how we handle
                                    your personal information held by us, please
                                    contact us using the details set out below
                                    including your name and contact details. We
                                    will investigate your complaint promptly and
                                    respond to you within a reasonable
                                    timeframe.
                                    <br />
                                    <br />
                                    <span className="privacy-subheader">
                                        9&emsp; CONTACT US
                                    </span>
                                    <br />
                                    <br />
                                    For further information about our privacy
                                    policy or practices, or to access or correct
                                    your personal information, or make a
                                    complaint, please contact us using the
                                    details set out below:
                                    <br />
                                    Name: Kavita Herbert
                                    <br />
                                    Email: Contactus@bizbackbone.com
                                    <br />
                                    Our privacy policy was last updated on April
                                    8, 2022.
                                    <br />
                                    <br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PrivacyPolicy;
