import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./SettingPage.css";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import EarnCreditModal from "./EarnCreditModal/EarnCreditModal";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import { Add, Minimize } from "@material-ui/icons";
import CancelMembershipModal from "./CancelMembershipModal/CancelMembershipModal";
import {
    authActions,
    changePasswordAction,
    getUserProfile,
} from "../../data/reducers/auth/auth";
import { useToast } from "@chakra-ui/react";
import { GoPrimitiveDot } from "react-icons/go";
import RequestEndorse from "../../pages/Profile/Components/RequestEndorse";

import moment from "moment";
import {
    applyPromoCodeAPI,
    getUserInvoice,
    getUserInvoices,
} from "../../data/api";
import { BsDownload } from "react-icons/bs";
import { getBizBucksByForCompanyById } from "../../data/reducers/home/content";

const Container = styled.div`
    width: 100%;
    margin-top: 100px;
`;
const ImgContainer = styled.div`
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 50px;
    margin-right: 100px;
    @media (max-width: 770px) {
        margin-left: 30px;
        margin-right: 30px;
    }
`;

const CreditsButton = styled.button`
    background: #067eed;
    border-radius: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 36px;
    cursor: pointer;
    color: #ffffff;
    padding: 0px 40px 0px 40px;
    align-self: flex-end;
    @media (max-width: 920px) {
        line-height: 20px;
    }
    @media (max-width: 768px) {
        line-height: 36px;
        width: 100%;
        margin-bottom: 8px;
    }
`;

const SettingDiv = styled.div`
    display: flex;
    gap: 45px;
`;
const LogoutDiv = styled.button`
    background: #ffffff;
    border: 1px solid #00aaf5;
    border-radius: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #00aaf5;
    padding: 5px 30px 5px 30px;
`;
const Setting = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    color: #333d43;
`;

const CancelMembership = styled.div`
    margin-top: 20px;
    margin-left: 20px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #7d7d7d;
`;

const CancelLink = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */

    text-decoration-line: underline;
    cursor: pointer;
    color: #067eed;
    display: flex;
    justify-content: center;
    @media (max-width: 920px) {
        font-size: 14px;
    }
`;

const HeadingDetail = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 36px;
    color: #000000;
`;

const SubHeadingDetail = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 36px;
    color: #000000;
`;

const RightHeadingDetail = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 36px;
    color: #aeaeae;
`;

const HeadingDetailAnswer = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 36px;
    color: #000000;
`;

const RightHeadingDetailAnswer = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 36px;
    color: #aeaeae;
`;

const PasswordBanner = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 36px;
    color: #000000;
`;

const SettingPage = () => {
    const { subscriptions } = useSelector((state) => state.subscription);
    const { myCompany } = useSelector((state) => state.content);
    const [earnCreditModal, setEarnCreditModal] = useState(false);
    const [cancelMembershipModal, setCancelMembershipModal] = useState(false);
    const {
        myBizBucks
    } = useSelector((state) => state?.content);
    const [password, setPassword] = useState("");
    const [usersPlan, setUsersPlan] = useState();
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [promo, setpromo] = useState("");
    const [promoError, setpromoError] = useState("");
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [reviewModal, setReviewModal] = useState(false);
    const [subscriptionCreditsRemaining, setsubscriptionCreditsRemaining] =
        useState(0);
    const [passwordError, setPasswordError] = useState(false);
    const [newPasswordError, setNewPasswordError] = useState(false);
    const [conPasswordError, setConPasswordError] = useState(false);
    const { user, isInWaitlist } = useSelector((state) => state.auth);
    const [visible, setVisible] = useState({
        password1: false,
        password2: false,
    });
    const [min8, setMin8] = useState(false);
    const [upp1, setUpp1] = useState(false);
    const [low1, setLow1] = useState(false);
    const [num1, setNum1] = useState(false);
    const [special1, setSpecial1] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToast();
    const [expanded, setExpanded] = React.useState(false);
    useEffect(()=>{
        if(myCompany && myCompany?.id)
          dispatch(getBizBucksByForCompanyById(myCompany?.id));
    },[dispatch,myCompany])
    const cancelMembershipPoint = [
        "You can cancel at any time before the commencement of your next subscription cycle",
        "Your profile will be available until the end of your current subscription period",
        "As a sign of goodwill, all membership information and associated ratings & reviews are held for a maximum period of 3 months from cancellation",
        "Should you return within 3 months, your account will be restored.",
        "Should you not return within 3 months of cancellation, all membership data including customer reviews and ratings will be permanently deleted.",
    ];
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const onSubmit = async (e) => {
        if (password !== user.password) {
            setPasswordError(true);
        } else {
            setPasswordError(false);
        }

        if (!(min8 && upp1 && low1 && num1 && special1)) {
            setNewPasswordError(true);
        } else {
            setNewPasswordError(false);
        }
        if (newPassword !== confirmPassword || !confirmPassword) {
            setConPasswordError(true);
        } else {
            setConPasswordError(false);
        }

        const isValidated =
            min8 &&
            upp1 &&
            low1 &&
            num1 &&
            special1 &&
            newPassword === confirmPassword;

        if (isValidated) {
            const res = await dispatch(
                changePasswordAction({
                    user_id: user.id,
                    password,
                    newPassword,
                    confirmPassword,
                })
            );
            console.log(res);
            if (res.payload.success === true) {
                dispatch(
                    toast({
                        title: res?.payload?.message,
                        status: "success",
                        position: "top-right",
                        isClosable: true,
                        duration: 3000,
                    })
                );
            } else {
                dispatch(
                    toast({
                        title: res?.payload?.error,
                        status: "error",
                        position: "top-right",
                        isClosable: true,
                        duration: 3000,
                    })
                );
            }

            // console.log("password changed");
            // const res = await dispatch(
            //     changePasswordAction({
            //         user_id: user.id,
            //         password,
            //         newPassword,
            //         confirmPassword,
            //     })
            // );
            // console.log(res);

            // toast({
            //     title: res.payload.status
            //         ? res.payload.message
            //         : res.payload.error,
            // position: "top-right",
            // isClosable: true,
            // duration: 3000,
            // status: res.payload.status ? "success" : "error",
            // });
        } else {
            toast({
                title: "New password and Confirm password not matched",
                position: "top-right",
                isClosable: true,
                duration: 3000,
                status: "error",
            });
        }
    };

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            // if (!user.subCredit) {
            //     let tot = 0;
            //     user?.SubscriptionCredits?.map((item) => {
            //         tot = tot + parseInt(item?.monthCredit);
            //     });
            //     setsubscriptionCreditsRemaining(tot);
            // } else {
            setsubscriptionCreditsRemaining(user.subCredit);
            // }
        }

        (async () => {
            try {
                const { data } = await getUserInvoices();
                setInvoices(data.invoices);
            } catch (error) {
                console.log(error);
            }
        })();

        return () => {
            isMounted = false;
        };
    }, []);

    const getInvoiceLink = async (invoice) => {
        try {
            const response = await getUserInvoice(
                invoice?.transaction_invoice_id
            );
            if (response.status === 200) {
                let link = response?.data?.invoice?.hosted_invoice_url;
                window.open(link, "_blank");
            }
        } catch (e) {
            toast({
                title: "Error fetching link!",
                position: "top-right",
                isClosable: true,
                duration: 3000,
                status: "error",
            });
        }
    };
    const downloadInvoice = async (invoice) => {
        try {
            const response = await getUserInvoice(
                invoice?.transaction_invoice_id
            );
            if (response.status === 200) {
                let link = response?.data?.invoice?.invoice_pdf;
                window.open(link, "_blank");
            }
        } catch (e) {
            toast({
                title: "Error fetching pdf!",
                position: "top-right",
                isClosable: true,
                duration: 3000,
                status: "error",
            });
        }
    };

    const checkValidation = (e) => {
        // For length
        if (e.target.value?.length >= 8) {
            setMin8(true);
        } else {
            setMin8(false);
        }
        // For Upper
        if (e.target.value?.toLowerCase() !== e.target.value) {
            setUpp1(true);
        } else {
            setUpp1(false);
        }
        // For Lower
        if (e.target.value?.toUpperCase() !== e.target.value) {
            setLow1(true);
        } else {
            setLow1(false);
        }
        // For Number
        const regexNum = /\d/;
        if (regexNum.test(e.target.value)) {
            setNum1(true);
        } else {
            setNum1(false);
        }
        // For Number
        const regexSpecial = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
        if (regexSpecial.test(e.target.value)) {
            setSpecial1(true);
        } else {
            setSpecial1(false);
        }
    };

    const applyPromoCode = async (e) => {
        e.preventDefault();
        try {
            const response = await applyPromoCodeAPI({ coupon_code: promo });
            if (response.status === 200) {
                setpromo("");
                setpromoError("");
                dispatch(getUserProfile());
                toast({
                    title: response.data.message,
                    position: "top-right",
                    isClosable: true,
                    duration: 3000,
                    status: "success",
                });
            }
        } catch (e) {
            setpromoError(e.response.data.message);
        }
    };
    return (
        <Container>
            <ImgContainer>
                <SettingDiv>
                    <Setting>Settings</Setting>
                </SettingDiv>
                <LogoutDiv
                    onClick={(e) => {
                        localStorage.clear();
                        navigate("/auth");
                        window.location.reload();
                    }}
                >
                    Logout
                </LogoutDiv>
            </ImgContainer>
            <div className="faqsSetting">
                <Accordion
                    key={1}
                    expanded={expanded === `panel${1}`}
                    onChange={handleChange(`panel${1}`)}
                >
                    <AccordionSummary
                        expandIcon={
                            expanded === `panel${1}` ? <Minimize /> : <Add />
                        }
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography className="faqs-heading">
                            Manage Membership
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="detailMainCss">
                            <div>
                                <div className="detailCss">
                                    <HeadingDetail>
                                        Current membership plan :
                                    </HeadingDetail>
                                    <HeadingDetailAnswer>
                                        {user?.subscription === "free"
                                            ? "Free Trial"
                                            : user?.subscription}
                                    </HeadingDetailAnswer>
                                </div>
                                <div className="detailCss">
                                    <HeadingDetail>
                                        {" "}
                                        Date started :
                                    </HeadingDetail>
                                    <HeadingDetailAnswer>
                                        {moment(user?.subscriptionStart).format(
                                            "LL"
                                        )}
                                    </HeadingDetailAnswer>
                                </div>
                                <div className="detailCss">
                                    <HeadingDetail>
                                        Renewal date :
                                    </HeadingDetail>
                                    <HeadingDetailAnswer>
                                        {moment(
                                            user?.subscriptionValidity
                                        ).format("LL")}
                                    </HeadingDetailAnswer>
                                </div>
                            </div>
                            {user?.subscription === "free" &&
                                user?.upcomingplans && (
                                    <div>
                                        <div className="detailCss">
                                            <RightHeadingDetail>
                                                Upcoming Plan :
                                            </RightHeadingDetail>
                                            <RightHeadingDetailAnswer>
                                                {user?.upcomingplans}
                                            </RightHeadingDetailAnswer>
                                        </div>
                                        <div className="detailCss">
                                            <RightHeadingDetail>
                                                Date Starts :{" "}
                                            </RightHeadingDetail>
                                            <RightHeadingDetailAnswer>
                                                {moment(
                                                    user?.subscriptionValidity
                                                )
                                                    .add(1, "day")
                                                    .format("LL")}
                                            </RightHeadingDetailAnswer>
                                        </div>
                                        <div className="detailCss">
                                            <RightHeadingDetail>
                                                Discount :{" "}
                                            </RightHeadingDetail>
                                            <RightHeadingDetailAnswer>
                                                {isInWaitlist ? "6" : "3"}{" "}
                                                months discount
                                            </RightHeadingDetailAnswer>
                                        </div>
                                    </div>
                                )}
                        </div>
                        <div className="buttonDetail flex items-center">

                           
                            {user?.subscription === "free" ? (
                                 <div
                                 onClick={() => {
                                     // dispatch(detailsActions.setActiveStep(2));
                                     dispatch(
                                        authActions.resetActiveStep()
                                    );

                                     navigate("/upgradePayment", {
                                         state: { choseSubs: true },
                                     });
                                 }}
                             >
                                 <CreditsButton>
                                     Choose New Plan
                                 </CreditsButton>
                             </div>
                            ) : (
                                <div
                                    onClick={() => {
                                        dispatch(
                                            authActions.resetActiveStep()
                                        );
                                        // dispatch(detailsActions.setActiveStep(2));
                                        navigate("/upgradePayment", {
                                            state: { choseSubs: true },
                                        });
                                    }}
                                >
                                    <CreditsButton>
                                        Choose New Plan
                                    </CreditsButton>
                                </div>
                            )}
                            {user?.subscription === "free" ? (
                                <div />
                            ) : user?.Transaction?.is_active ? (
                                <CancelLink
                                    onClick={() =>
                                        setCancelMembershipModal(true)
                                    }
                                >
                                    Cancel Membership
                                </CancelLink>
                            ) : (
                                <div className="text-gray-700">
                                    {/* Membership Cancelled */}
                                </div>
                            )}
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    key={2}
                    expanded={expanded === `panel${2}`}
                    onChange={handleChange(`panel${2}`)}
                >
                    <AccordionSummary
                        expandIcon={
                            expanded === `panel${2}` ? <Minimize /> : <Add />
                        }
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography className="faqs-heading">
                            Download Invoices
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {invoices?.map((invoice, idx) => {
                            return (
                                <div className="invoice-accordion" key={idx}>
                                    <div className="detailCss invoice">
                                        <HeadingDetail>
                                            <div className="detailInner">
                                                {idx + 1}.
                                            </div>
                                        </HeadingDetail>
                                        <HeadingDetailAnswer className="invoice-wrapper">
                                            <div>
                                                <div className="invoice-link">
                                                    <span className="text">
                                                        Invoice Link:
                                                    </span>
                                                    <div
                                                        className="invoice-value link"
                                                        onClick={() => {
                                                            getInvoiceLink(
                                                                invoice
                                                            );
                                                        }}
                                                        rel="noreferrer"
                                                        target="_blank"
                                                    >
                                                        Click Here
                                                    </div>
                                                </div>
                                                <div className="invoice-date">
                                                    <span className="text">
                                                        Issue Date:
                                                    </span>
                                                    <div className="invoice-value">
                                                        {moment(
                                                            invoice.createdAt
                                                        ).format("LL")}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="icon"
                                                onClick={() => {
                                                    downloadInvoice(invoice);
                                                }}
                                            >
                                                <BsDownload />
                                                <span>Download</span>
                                            </div>
                                        </HeadingDetailAnswer>
                                    </div>
                                </div>
                            );
                        })}
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    key={3}
                    expanded={expanded === `panel${3}`}
                    onChange={handleChange(`panel${3}`)}
                >
                    <AccordionSummary
                        expandIcon={
                            expanded === `panel${2}` ? <Minimize /> : <Add />
                        }
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography className="faqs-heading">
                            Credits
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="detailCss">
                            <HeadingDetail>
                                Subscription credit :{" "}
                            </HeadingDetail>
                            <HeadingDetailAnswer>
                                {subscriptionCreditsRemaining} months remaining
                            </HeadingDetailAnswer>
                        </div>
                        {/* <div className="detailCss">
                            <HeadingDetail>BizBucks : </HeadingDetail>
                            <HeadingDetailAnswer>
                                ${myBizBucks?.amount>0 ? myBizBucks?.amount : 0}
                            </HeadingDetailAnswer>
                        </div> */}
                        <div className="buttonDetail">
                            <CreditsButton
                                onClick={() => {
                                    setEarnCreditModal(true);
                                }}
                            >
                                Earn credits
                            </CreditsButton>
                            <form
                                autoComplete="off"
                                className="flex flex-col promoCodeContainer"
                                onSubmit={applyPromoCode}
                            >
                                <SubHeadingDetail>
                                    Enter Promo Code
                                </SubHeadingDetail>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    placeholder="Enter code here"
                                    className="promoCode w-38 h-9"
                                    onChange={(e) => {
                                        setpromo(e.target.value);
                                    }}
                                    value={promo}
                                ></input>
                                <p
                                    style={{
                                        display:
                                            promoError.length > 0
                                                ? "block"
                                                : "none",
                                    }}
                                    className="poppins font-normal text-sm text-red"
                                >
                                    {promoError}
                                </p>
                            </form>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    key={4}
                    expanded={expanded === `panel${4}`}
                    onChange={handleChange(`panel${4}`)}
                >
                    <AccordionSummary
                        expandIcon={
                            expanded === `panel${3}` ? <Minimize /> : <Add />
                        }
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography className="faqs-heading">
                            Change password
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PasswordBanner>Enter current password</PasswordBanner>
                        <input
                            type="password"
                            className="Inputdetail"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <PasswordBanner>Enter new password</PasswordBanner>
                        <input
                            type="password"
                            className="Inputdetail"
                            onChange={(e) => {
                                setNewPassword(e.target.value);
                                checkValidation(e);
                            }}
                        />
                        <div className="password-validation">
                            <p>
                                <GoPrimitiveDot
                                    color={min8 ? "#5AB659" : "#A6A6A6"}
                                    fontSize={16}
                                />
                                Minimum 8 characters
                            </p>
                            <p>
                                <GoPrimitiveDot
                                    color={upp1 ? "#5AB659" : "#A6A6A6"}
                                    fontSize={16}
                                />
                                Atleast 1 uppercase
                            </p>
                            <p>
                                <GoPrimitiveDot
                                    color={low1 ? "#5AB659" : "#A6A6A6"}
                                    fontSize={16}
                                />
                                Atleast 1 lower case
                            </p>
                            <p>
                                <GoPrimitiveDot
                                    color={num1 ? "#5AB659" : "#A6A6A6"}
                                    fontSize={16}
                                />
                                Atleast 1 number
                            </p>
                            <p>
                                <GoPrimitiveDot
                                    color={special1 ? "#5AB659" : "#A6A6A6"}
                                    fontSize={16}
                                />
                                Atleast 1 special character
                            </p>
                        </div>
                        <PasswordBanner>Confirm new password</PasswordBanner>
                        <div className="button-password">
                            <input
                                type="password"
                                className="Inputdetail"
                                onChange={(e) => {
                                    setConfirmPassword(e.target.value);
                                }}
                            />
                            <CreditsButton onClick={() => onSubmit()}>
                                Save changes
                            </CreditsButton>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            {earnCreditModal && (
                <EarnCreditModal
                    setReviewModal={setReviewModal}
                    earnCreditModal={earnCreditModal}
                    setEarnCreditModal={setEarnCreditModal}
                />
            )}

            {reviewModal && (
                <RequestEndorse
                    id={myCompany?.id}
                    showModal={reviewModal}
                    setShowModal={setReviewModal}
                />
            )}
            {cancelMembershipModal && (
                <CancelMembershipModal
                    setCancelMembershipModal={setCancelMembershipModal}
                    cancelMembershipModal={cancelMembershipModal}
                />
            )}
        </Container>
    );
};
export default SettingPage;
