import React, { Fragment, memo, useEffect, useState } from "react";
import { IoEllipsisHorizontal } from "react-icons/io5";

import moment from "moment";

const RegisteredSpotlightEvent = ({ item, index }) => {
    return (
        <div >
            <div
                className="my-2 w-100"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px 25px",
                    borderRadius: "30px",
                    background: "#0078BE",
                }}
            >
                <div>
                    <h4 className="text-white fs-5">{item?.Event?.eventName}</h4>
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="text-white" style={{ fontSize: "12px" }}>
                            {moment(item?.Event?.eventDate).format('MMMM D')} at {moment(item?.Event?.eventDate).format('h:mm a')}
                        </h6>
                        <span className="text-white px-2 mx-end d-block ms-2 " style={{ fontSize: "12px",borderRadius:"2px" }}>{item?.status}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(RegisteredSpotlightEvent);
