import React, { memo } from "react";
import BackboneLogo from "../../../assets/backbone-logo.svg";
import Moment from "react-moment";
import { useNavigate } from "react-router";
import freeText from "../../../assets/CustomIcons/freeText.png";
import meet from "../../../assets/CustomIcons/meet.png";
import outstanding from "../../../assets/CustomIcons/outstanding.png";
import partner from "../../../assets/CustomIcons/partner.png";

const HomeCardFive = ({
    item,
    index,
    backed_company_detail,
    backer_company_detail,
}) => {
    const navigate = useNavigate();
    return (
        <div className="my-3 px-3">
            <div>
                <div
                    className="p-4"
                    style={{ backgroundColor: "#deebff", borderRadius: "25px" }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                        }}
                    >
                        <div>
                            <div>
                                <img
                                    style={{
                                        minWidth: "35px",
                                        maxWidth: "35px",
                                        height: "35px",
                                        borderRadius: "100%",
                                    }}
                                    src={
                                        backed_company_detail?.find(
                                            (itemData) =>
                                                itemData?.id ==
                                                item?.backed_company_id
                                        )?.company_logo
                                            ? backed_company_detail?.find(
                                                  (itemData) =>
                                                      itemData?.id ==
                                                      item?.backed_company_id
                                              )?.company_logo
                                            : "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"
                                    }
                                />
                            </div>
                        </div>
                        <h3
                            className="text-primary fs-5 ms-3 text-center mx-auto cursor-pointer sliderCompLink"
                            onClick={() => {
                                navigate(
                                    `/company/${
                                        backed_company_detail?.find(
                                            (itemData) =>
                                                itemData?.id ==
                                                item?.backed_company_id
                                        )?.id
                                    }`
                                );
                            }}
                        >
                            {
                                backed_company_detail?.find(
                                    (itemData) =>
                                        itemData?.id == item?.backed_company_id
                                )?.company_name
                            }
                        </h3>
                    </div>
                    <div className="my-4 d-flex gap-2 align-items-center">
                        <span style={{ fontSize: "16px" }}>Backed by</span>
                        <p style={{ fontSize: "20px", fontWeight: "500" }}>
                            {
                                backer_company_detail?.find(
                                    (itemData) =>
                                        itemData?.id == item?.backer_company_id
                                )?.company_name
                            }
                        </p>
                        <div>
                            <img
                                style={{
                                    minWidth: "30px",
                                    height: "30px",
                                    borderRadius: "100%",
                                }}
                                src={
                                    backer_company_detail?.find(
                                        (itemData) =>
                                            itemData?.id ==
                                            item?.backer_company_id
                                    )?.company_logo
                                        ? backer_company_detail?.find(
                                              (itemData) =>
                                                  itemData?.id ==
                                                  item?.backer_company_id
                                          )?.company_logo
                                        : "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"
                                }
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            margin: "auto",
                            position: "relative",
                            background: "white",
                            width: "100%",
                            padding: "15px 10px",
                            borderRadius: "40px",
                            minHeight: "78px",
                        }}
                    >
                        <div
                            style={{
                                width: "70%",
                                margin: "auto",
                                textAlign: "center",
                            }}
                        >
                            {item?.interaction_type ==
                            "Other: Enter Free Text (200 max)"
                                ? `${item?.free_text?.substring(0,30)} ${item?.free_text?.length>30 ? "...":""}`
                                : item?.interaction_type}
                        </div>
                        <span
                            style={{
                                position: "absolute",
                                top: "-10px",
                                left: "-8px",
                            }}
                        >
                            {item?.interaction_type ==
                                "Met at an event & highly rate" && (
                                <img
                                    style={{
                                        width: "60px",
                                        height: "auto",
                                    }}
                                    src={meet}
                                />
                            )}
                            {item?.interaction_type ==
                                "Partnered together & highly rate" && (
                                <img
                                    style={{
                                        width: "60px",
                                        height: "auto",
                                    }}
                                    src={partner}
                                />
                            )}
                            {item?.interaction_type ==
                                "Outstanding Partnership Outcome" && (
                                <img
                                    style={{
                                        width: "60px",
                                        height: "auto",
                                    }}
                                    src={outstanding}
                                />
                            )}
                            {item?.interaction_type ==
                                "Other: Enter Free Text (200 max)" && (
                                <img
                                    style={{
                                        width: "60px",
                                        height: "auto",
                                    }}
                                    src={freeText}
                                />
                            )}
                        </span>
                    </div>
                    <div
                        style={{
                            marginLeft: "auto",
                            marginTop: "10px",
                            textAlign: "end",
                        }}
                    >
                        <span style={{ textAlign: "end", fontSize: "12px" }}>
                            {
                                <Moment fromNow>
                                    {new Date(item?.created_at)}
                                </Moment>
                            }
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(HomeCardFive);
