import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    addLeader,
    editProfile,
    updateIndustryType,
    addSocial,
    updateSocial,
    addBanner,
    addServices,
    updateBanner,
    sendEndorse,
    updateDemoBanner,
    addTitle,
    updateTitle,
    getTitle,
    addImages,
    getImages,
    addLink,
    getLinks,
    addPdfLink,
    getPdfLinks,
    getAllSocials,
    deleteImage,
    deleteLink,
    deletePdf,
    getAllServices,
    addDemoBanner,
    deleteLeader,
    deleteSocial,
    deleteTitle,
    addPrefernce,
    getPrefernce,
} from "../../services/profile/profile";

const initialState = {
    success: false,
    error: null,
    loading: true,
    message: null,
    editProfileSuccess: false,
    editProfileFailure: false,
    editProfileLoading: false,
    title: [],
    images: [],
    services: [],
    links: [],
    pdfLinks: [],
    socials: [],
    companyPrefernces:[]
};

export const updateIndustryTypeAction = createAsyncThunk(
    "update/industry",
    async (industry) => {
        const response = await updateIndustryType(industry);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const addServicesAction = createAsyncThunk(
    "add/services",
    async (services) => {
        const response = await addServices(services);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const addPreferenceAction = createAsyncThunk(
    "add/prefernce",
    async (id,services) => {
         console.log(services,"services Reducer")
        const response = await addPrefernce(id,services);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const myCompanyPreferences = createAsyncThunk(
    "get/getPreference",
    async () => {
        const response = await getPrefernce();
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);


export const addSocialAction = createAsyncThunk(
    "add/social",
    async (social) => {
        const response = await addSocial(social);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const deleteSocialAction = createAsyncThunk(
    "delete/social",
    async (social) => {
        const response = await deleteSocial(social);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const updateSocialAction = createAsyncThunk(
    "update/social",
    async (updatedSocial) => {
        const response = await updateSocial(updatedSocial);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const getSocialAction = createAsyncThunk("get/social", async () => {
    const response = await getAllSocials();
    if (response?.error) {
        return { error: response };
    }
    return response;
});

export const addLeaderAction = createAsyncThunk(
    "add/leader",
    async (leader) => {
        const response = await addLeader(leader);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const deleteLeaderAction = createAsyncThunk(
    "delete/leader",
    async (id) => {
        const response = await deleteLeader(id);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const addBannerAction = createAsyncThunk(
    "add/banner",
    async (banner) => {
        const response = await addBanner(banner);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);
export const updateBannerAction = createAsyncThunk(
    "update/banner",
    async (banner) => {
        const response = await updateBanner(banner);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

// api for add and update demo banner

export const addDemoBannerAction = createAsyncThunk(
    "add/banner",
    async (bannerLink) => {
        const response = await addDemoBanner(bannerLink);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const updateDemoBannerAction = createAsyncThunk(
    "update/bannerLink",
    async (bannerLink) => {
        const response = await updateDemoBanner(bannerLink);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const addTitleAction = createAsyncThunk("add/title", async (data) => {
    const response = await addTitle(data);
    if (response?.error) {
        return { error: response };
    }
    return response;
});
export const updateTitleAction = createAsyncThunk(
    "update/title",
    async (data) => {
        const response = await updateTitle(data);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);
export const getTitleAction = createAsyncThunk("get/title", async () => {
    const response = await getTitle();
    if (response?.error) {
        return { error: response };
    }
    return response;
});

// otherInfo Images
export const addImagesAction = createAsyncThunk("add/images", async (img) => {
    const response = await addImages(img);
    if (response?.error) {
        return { error: response };
    }
    return response;
});
export const getImagesAction = createAsyncThunk("get/images", async () => {
    const response = await getImages();
    if (response?.error) {
        return { error: response };
    }
    return response;
});

export const addLinkAction = createAsyncThunk("add/link", async (link) => {
    const response = await addLink(link);
    if (response?.error) {
        return { error: response };
    }
    return response;
});
export const getLinksAction = createAsyncThunk("get/links", async () => {
    const response = await getLinks();
    if (response?.error) {
        return { error: response };
    }
    return response;
});
export const addPdfLinkAction = createAsyncThunk(
    "add/pdf-link",
    async (link) => {
        const response = await addPdfLink(link);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);
export const getPdfLinksAction = createAsyncThunk("get/pdf-links", async () => {
    const response = await getPdfLinks();
    if (response?.error) {
        return { error: response };
    }
    return response;
});

export const editProfileAction = createAsyncThunk(
    "edit/profile",
    async (profile) => {
        const response = await editProfile(profile);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const sendEndorseAction = createAsyncThunk(
    "add/endorse",
    async (data) => {
        const response = await sendEndorse(data);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);
export const deleteImageAction = createAsyncThunk(
    "delete/image",
    async (id) => {
        const response = await deleteImage(id);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);
export const deletePdfAction = createAsyncThunk("delete/pdf", async (id) => {
    const response = await deletePdf(id);
    if (response?.error) {
        return { error: response };
    }
    return response;
});
export const deleteLinkAction = createAsyncThunk("delete/link", async (id) => {
    const response = await deleteLink(id);
    if (response?.error) {
        return { error: response };
    }
    return response;
});

export const deleteTitleAction = createAsyncThunk(
    "delete/title",
    async (id) => {
        const response = await deleteTitle(id);
        if (response?.error) {
            return { error: response };
        }
        return response;
    }
);

export const getServicesAction = createAsyncThunk("get/tags", async () => {
    const response = await getAllServices();
    if (response?.error) {
        return { error: response };
    }
    return response;
});

// otherInfo Images
const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        reset: (state, action) => {
            state.error = null;
            state.message = null;
            state.loading = false;
            state.success = false;
        },
    },

    extraReducers: {
        [updateIndustryTypeAction.pending]: (state, action) => {
            state.loading = true;
        },
        [updateIndustryTypeAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [updateTitleAction.pending]: (state, action) => {
            state.loading = true;
        },
        [updateTitleAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [getTitleAction.pending]: (state, action) => {
            state.loading = true;
        },
        [getTitleAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.title = action.payload?.result;
            }
        },
        [updateTitleAction.pending]: (state, action) => {
            state.loading = true;
        },
        [updateTitleAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [deleteTitleAction.pending]: (state, action) => {
            state.loading = true;
        },
        [deleteTitleAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [addLeaderAction.pending]: (state, action) => {
            state.loading = true;
        },
        [addLeaderAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [deletePdfAction.pending]: (state, action) => {
            state.loading = true;
        },
        [deletePdfAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [deleteLinkAction.pending]: (state, action) => {
            state.loading = true;
        },
        [deleteLinkAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [deleteImageAction.pending]: (state, action) => {
            state.loading = true;
        },
        [deleteImageAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [deleteLeaderAction.pending]: (state, action) => {
            state.loading = true;
        },
        [deleteLeaderAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [addTitleAction.pending]: (state, action) => {
            state.loading = true;
        },
        [addTitleAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [addServicesAction.pending]: (state, action) => {
            state.loading = true;
        },
        [addServicesAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [myCompanyPreferences.pending]: (state, action) => {
            state.loading = true;
        },
        [myCompanyPreferences.fulfilled]: (state, action) => {
            if (action.payload?.success) {
                state.companyPrefernces=action.payload?.result
            }
        },
        [addBannerAction.pending]: (state, action) => {
            state.loading = true;
        },
        [addBannerAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [addDemoBannerAction.pending]: (state, action) => {
            state.loading = true;
        },
        [addDemoBannerAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [addImagesAction.pending]: (state, action) => {
            state.loading = true;
        },
        [addImagesAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [getImagesAction.pending]: (state, action) => {
            state.loading = true;
        },
        [getImagesAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.images = action.payload?.result;
            }
        },
        [addLinkAction.pending]: (state, action) => {
            state.loading = true;
        },
        [addLinkAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [getLinksAction.pending]: (state, action) => {
            state.loading = true;
        },
        [getLinksAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.links = action.payload?.result;
            }
        },
        [addPdfLinkAction.pending]: (state, action) => {
            state.loading = true;
        },
        [addPdfLinkAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [getPdfLinksAction.pending]: (state, action) => {
            state.loading = true;
        },
        [getPdfLinksAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.pdfLinks = action.payload?.result;
            }
        },
        [updateBannerAction.pending]: (state, action) => {
            state.loading = true;
        },
        [updateBannerAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [updateDemoBannerAction.pending]: (state, action) => {
            state.loading = true;
        },
        [updateDemoBannerAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [addDemoBannerAction.pending]: (state, action) => {
            state.loading = true;
        },
        [addDemoBannerAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [editProfileAction.pending]: (state, action) => {
            state.loading = true;
        },
        [editProfileAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [sendEndorseAction.pending]: (state, action) => {
            state.loading = true;
        },
        [sendEndorseAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
                state.loading = false;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.message = action.payload?.message;
                state.loading = false;
            }
        },
        [getSocialAction.pending]: (state, action) => {
            state.loading = true;
        },
        [getSocialAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.socials = action.payload?.result;
            }
        },
        [getServicesAction.pending]: (state, action) => {
            state.loading = true;
        },
        [getServicesAction.fulfilled]: (state, action) => {
            state.success = action.payload?.success;
            if (action.payload?.error) {
                state.error = action.payload?.error.error;
            }
            if (action.payload?.success) {
                state.success = action.payload?.success;
                state.services = action.payload?.result;
            }
        },
    },
});

export const profileActions = profileSlice.actions;
export default profileSlice.reducer;
