import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom";
import {
    getBizBucksByConfigDetailsData,
    getCompaniesAction,
    getSearchedCompaniesAction,
} from "../../data/reducers/home/content";
import {
    getEvents,
    getInvitedEvent,
    getMyEvents,
    getSearchEvents,
    getUserRegistredEvents,
    getUserRegistredPodcastEvents,
    getUserRegistredSpotlightEvents
} from "../../data/reducers/events/events";
import EventCard from "./Components/EventCard";
import UpcomingEventsIllustration from "../../assets/upcoming-events-illustration.svg";
import LikedEventIcon from "../../assets/liked-event-icon.svg";
import { BsSearch } from "react-icons/bs";
import { Routes as Switch, Route, Outlet } from "react-router-dom";
import "./upcomingEvents.css";
import AddEvent from "./Components/AddEvent";
import EmptyState from "../Errors/EmptyState";
import NotFound from "../Errors/NotFound";
import Pagination from "../../components/Pagination";
import RegisteredEvent from "./Components/RegisteredEvent";
import RegisteredPodcastEvent from "./Components/RegisteredPodcastEvent";
import RegisteredSpotlightEvent from "./Components/RegisteredSpotlightEvent";

const UpcomingEvents = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const {
        events,
        myEvents,
        searchEvents,
        success,
        message,
        eventsCount,
        myEventsCount,
        invitedEvent,
        loading,
        registeredEvent,
        totalRegisteredEvent,
        registeredPodcastEvent,
        totalCountPodcastEvent,
        totalCountSpotlightEvent,
        registeredSpotlightEvent,
    } = useSelector((state) => state.events);
    const [initialState,setInitialState]=useState(0)
    const [endState,setEndState]=useState(10)
    const [initialState2,setInitialState2]=useState(0)
    const [endState2,setEndState2]=useState(10)

    const [initialState3,setInitialState3]=useState(0)
    const [endState3,setEndState3]=useState(5)


    const [initialState4,setInitialState4]=useState(0)
    const [endState4,setEndState4]=useState(5)


    const [initialState5,setInitialState5]=useState(0)
    const [endState5,setEndState5]=useState(5)

    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [eventsPage, setEventsPage] = useState(1);
    const [isVisible, setIsVisible] = useState(false);
    const [isSearched, setIsSearched] = useState(false);
    const [showBtn, setShowBtn] = useState(false);
    const [activePage,setActivePage]=useState(1)
    const [activePagePodcast,setActivePagePodcast]=useState(1)
    const [activePageSpotlight,setActivePageSpotlight]=useState(1)


    const getUserRegisteredEventFunction=()=>{
        dispatch(getUserRegistredEvents(`?page=${activePage?activePage:1}`))
    }
    const memoizedRegisteredEvent=useMemo(()=>{
        if(registeredEvent && registeredEvent?.length>0){
            return registeredEvent?.map((item,index)=>{
                return <RegisteredEvent key={index} item={item} index={index} getUserRegisteredEventFunction={getUserRegisteredEventFunction}/>
           })
        }
    },[registeredEvent,getUserRegisteredEventFunction])

    const memoizedRegisteredPodcastEvent=useMemo(()=>{
        if(registeredPodcastEvent && registeredPodcastEvent?.length>0){
            return registeredPodcastEvent?.map((item,index)=>{
                return <RegisteredPodcastEvent key={index} item={item} index={index} />
           })
        }
    },[registeredPodcastEvent])

    const memoizedRegisteredSpotlightEvent=useMemo(()=>{
        if(registeredSpotlightEvent && registeredSpotlightEvent?.length>0){
            return registeredSpotlightEvent?.map((item,index)=>{
                return <RegisteredSpotlightEvent key={index} item={item} index={index} />
           })
        }
    },[registeredSpotlightEvent])


    useEffect(()=>{
        dispatch(getUserRegistredEvents(`?page=${activePage}`))
    },[dispatch,activePage])

    useEffect(()=>{
      dispatch(getUserRegistredPodcastEvents(`?page=${activePagePodcast}`))
    },[dispatch,activePagePodcast])

    useEffect(()=>{
        dispatch(getUserRegistredSpotlightEvents(`?page=${activePageSpotlight}`))
      },[dispatch,activePageSpotlight])

    useEffect(() => {
        dispatch(getBizBucksByConfigDetailsData());
    }, [dispatch]);
    
    const count = 5;
    const myEventCount = 5;
    const eventsList = eventsCount;
    const myEventsList = myEventsCount;

    const pageCount = new Array(Math.ceil(eventsList / count) || 1).fill(0);
    const eventsPageCount = new Array(
        Math.ceil(myEventsList / myEventCount) || 1
    ).fill(0);

    const pageLength = pageCount?.length;
    const eventsPageLength = eventsPageCount?.length;
    const token = localStorage.getItem("token");

    useEffect(() => {
        let url = window.location.href;
        let id = unescape(url).split("?id=")[1];
        if (token) {
            id && dispatch(getInvitedEvent({ id: id }));
            let data = {
                page: page,
                company_id: JSON.parse(localStorage.getItem("userInfo"))
                    .company_id,
            };
            dispatch(getMyEvents(data));
        }
    }, [dispatch]);

    useEffect(() => {
        if (success && message && !loading) {
            dispatch(getEvents({ page: page }));
            let data = {
                page: page,
                company_id: JSON.parse(localStorage.getItem("userInfo"))
                    .company_id,
            };
            dispatch(getMyEvents(data));
        }
    }, [dispatch, success, message, loading]);

    useEffect(() => {
        if (search === "") {
            dispatch(getEvents({ page: page }));
            setIsSearched(false);
        }
    }, [search]);

    const handlePagination = (val) => {
        setPage(val);
        dispatch(getEvents({ page: val }));
    };

    const handleMyEventsPagination = (val) => {
        setEventsPage(val);
        let data = {
            page: val,
            company_id: JSON.parse(localStorage.getItem("userInfo")).company_id,
        };
        dispatch(getMyEvents(data));
    };

    const handleSearch = (e) => {
        e.preventDefault();
        if (search) {
            setIsSearched(true);
            dispatch(getSearchEvents({ searchText: search }));
        }
    };

    return (
        <>
            <div className="upcoming-events position-relative">
                {token && (
                    <div className="topnav">
                        <div className="  mt-2 items-center flex">
                            <div className="col-auto">
                                <form className="form-group d-flex align-items-center me-md-4">
                                    <input
                                        type="text"
                                        className="form-control "
                                        id="search-events"
                                        placeholder="Enter Event Name"
                                        value={search}
                                        onChange={(e) =>
                                            setSearch(e.target.value)
                                        }
                                    />
                                    <button
                                        type="submit"
                                        onClick={(e) => handleSearch(e)}
                                    >
                                        <BsSearch
                                            className="search-icon"
                                            color="darkblue"
                                            fontSize={22}
                                        />
                                    </button>
                                </form>
                            </div>
                            <div className="col-auto ml-1  ">
                                <Link to="/upcoming-events/liked">
                                    <div className="text-center liked-event">
                                        <img
                                            src={LikedEventIcon}
                                            className="d-inline"
                                            alt=""
                                        />
                                        <p>Liked event</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
                <div className="header">
                    {/* <button
                        className="add-btn"
                        onClick={() => setIsVisible(true)}
                    >
                        Add New Event
                    </button> */}
                    {/* } */}
                </div>
                <div className="main-div mt-4 ">
                    <div className="row justify-content-center">
                        {!isSearched && (
                            <div className="col-xl-7 p-0">
                                {Object.keys(invitedEvent).length > 0 && (
                                    <div className="event-list">
                                        <h1>
                                            You have been invited to this event
                                        </h1>
                                        <EventCard
                                            event={invitedEvent}
                                            hasEditIcon={false}
                                        />
                                    </div>
                                )}
                                <div className="event-list">
                                    <p
                                        className="font-semibold text-3xl poppins"
                                        style={{ color: "#565656" }}
                                    >
                                        Upcoming events
                                    </p>
                                    {events?.length === 0 ? (
                                        <EmptyState />
                                    ) : (
                                        <>
                                            {events &&
                                                events?.map((event, index) => {
                                                    return (
                                                        <EventCard
                                                            key={index}
                                                            event={event}
                                                            hasEditIcon={false}
                                                        />
                                                    );
                                                })}
                                        </>
                                    )}
                                </div>
                                {eventsList > 0 && (
                                    // <Pagination
                                    //     pages={pageCount}
                                    //     page={page}
                                    //     handlePagination={handlePagination}
                                    //     pageLength={pageLength}
                                    // />
                                    <div className="mx-auto text-center my-4 w-100">
                                            <ul className="pagination">
                                                
                                                <li className="page-item cursor-pointer"><span className="page-link" onClick={()=>{
                                                     if(initialState>0){
                                                        setEndState(initialState)
                                                        setInitialState((pre)=>pre-10)
                                                    }
                                                }}>
                                                    <img  style={{width:"18px",margin:"3px"}} src="https://cdn-icons-png.flaticon.com/512/11502/11502464.png"/>
                                                </span></li>
                                                {
                                                    Array.from({ length:Math.ceil((eventsList)/10) }, (_, i) => i + 1)?.slice(initialState,endState)?.map((item,index)=>{
                                                    return <li key={index} className="page-item cursor-pointer"><span className={page==item?`page-link text-primary fw-bold`:`page-link text-dark fw-bold`} onClick={()=>{
                                                        if(page!=item){
                                                            handlePagination(item)
                                                        }
                                                    }}>{item}</span></li>
                                                })
                                                }
                                                <li className="page-item cursor-pointer"><span className="page-link" onClick={()=>{
                                                   if(endState<(Math.ceil((eventsList)/10))){
                                                    setInitialState(endState)
                                                    setEndState((pre)=>pre+10)
                                                }
                                                }}>
                                                    <img style={{width:"24px"}} src="https://cdn-icons-png.flaticon.com/512/2722/2722998.png"/></span></li>
                                            </ul>
                                    </div>
                                )}
                                <div className="event-list mt-10">
                                    <p
                                        className="font-semibold text-3xl poppins"
                                        style={{ color: "#565656" }}
                                    >
                                        Events added by me
                                    </p>
                                    {myEvents?.length === 0 ? (
                                        <EmptyState />
                                    ) : (
                                        <>
                                            {myEvents &&
                                                myEvents.length > 0 &&
                                                myEvents?.map(
                                                    (event, index) => {
                                                        return (
                                                            <EventCard
                                                                key={index}
                                                                event={event}
                                                                hasEditIcon={
                                                                    true
                                                                }
                                                            />
                                                        );
                                                    }
                                                )}
                                        </>
                                    )}
                                </div>
                                
                                {myEventsList > 0 && (
                                    // <Pagination
                                    //     pages={eventsPageCount}
                                    //     page={eventsPage}
                                    //     handlePagination={
                                    //         handleMyEventsPagination
                                    //     }
                                    //     pageLength={eventsPageLength}
                                    // />
                                    <div className="mx-auto text-center my-4 w-100">
                                                <ul className="pagination">
                                                    
                                                    <li className="page-item cursor-pointer"><span className="page-link" onClick={()=>{
                                                         if(initialState2>0){
                                                            setEndState2(initialState2)
                                                            setInitialState2((pre)=>pre-10)
                                                        }
                                                    }}>
                                                        <img  style={{width:"18px",margin:"3px"}} src="https://cdn-icons-png.flaticon.com/512/11502/11502464.png"/>
                                                    </span></li>
                                                    {
                                                        Array.from({ length:Math.ceil((myEventsList)/10) }, (_, i) => i + 1)?.slice(initialState2,endState2)?.map((item,index)=>{
                                                        return <li key={index} className="page-item cursor-pointer"><span className={eventsPage==item?`page-link text-primary fw-bold`:`page-link text-dark fw-bold`} onClick={()=>{
                                                            if(eventsPage!=item){
                                                                handleMyEventsPagination(item)
                                                            }
                                                        }}>{item}</span></li>
                                                    })
                                                    }
                                                    <li className="page-item cursor-pointer"><span className="page-link" onClick={()=>{
                                                        
                                                        if(endState2<(Math.ceil((myEventsList)/10))){
                                                            setInitialState2(endState2)
                                                            setEndState2((pre)=>pre+10)
                                                        }
                                                    }}>
                                                        <img style={{width:"24px"}} src="https://cdn-icons-png.flaticon.com/512/2722/2722998.png"/></span></li>
                                                </ul>
                                    </div>
                                )}
                            </div>
                        )}
                        {/* {searchEvents.length === 0 ? (

                        ):(

                        )

                        } */}
                        {searchEvents.length > 0 && isSearched && (
                            <div className="col-xl-7 mt-2">
                                <div className="event-list">
                                    {searchEvents &&
                                        searchEvents?.map((event, index) => {
                                            return (
                                                <EventCard
                                                    key={index}
                                                    event={event}
                                                    hasEditIcon={false}
                                                />
                                            );
                                        })}
                                </div>
                            </div>
                        )}

                        {isSearched && searchEvents.length === 0 && (
                            <>
                                <NotFound showBtn={showBtn} />
                            </>
                        )}

                        <div className="col-xl-3 py-5 ms-0 ms-xl-5">
                            <div>
                                <button
                                    className=""
                                    style={{
                                        width: "100%",
                                        background: "#0078BE",
                                        color: "white",
                                        padding: "12px",
                                        borderRadius: "30px",
                                        fontSize: "20px",
                                        fontWeight: "500",
                                    }}
                                    onClick={() => {
                                        setIsVisible(true);
                                    }}
                                >
                                    Post an Event
                                </button>
                            </div>

                            <div
                                style={{
                                    background: "#f2f2f2",
                                    padding: "15px",
                                    marginTop: "20px",
                                    borderRadius: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        background: "white",
                                        padding: "5px",
                                        borderRadius: "30px",
                                    }}
                                >
                                    <h4 className="fs-4 fw-bolder text-center">
                                        Your Registrations
                                    </h4>
                                </div>

                                <div className="my-4">
                                    {memoizedRegisteredEvent?memoizedRegisteredEvent:<h2 className="text-center mx-auto my-2 fw-bold">No event found</h2>}
                                </div>
                                  { registeredEvent?.length>0  && <div className="mx-auto text-center my-4 w-100">
                                        <ul className="pagination justify-content-center">
                                            
                                            <li className="page-item cursor-pointer"><span className="page-link" onClick={()=>{
                                                if(initialState3>0){
                                                            setEndState3(initialState3)
                                                            setInitialState3((pre)=>pre-5)
                                                }
                                            }}>
                                                <img  style={{width:"18px",margin:"3px"}} src="https://cdn-icons-png.flaticon.com/512/11502/11502464.png"/>
                                            </span></li>
                                            {
                                                Array.from({ length:Math.ceil((totalRegisteredEvent)/5) }, (_, i) => i + 1)?.slice(initialState3,endState3)?.map((item,index)=>{
                                                return <li key={index} className="page-item cursor-pointer"><span className={activePage==item?`page-link text-primary fw-bold`:`page-link text-dark fw-bold`} onClick={()=>{
                                                    if(activePage!=item){
                                                        setActivePage(item)
                                                    }
                                                }}>{item}</span></li>
                                            })
                                            }
                                            <li className="page-item cursor-pointer"><span className="page-link" onClick={()=>{
                                                 if(endState3<((totalRegisteredEvent)/5)){
                                                    setInitialState3(endState3)
                                                    setEndState3((pre)=>pre+5)
                                                }
                                            }}>
                                                <img style={{width:"24px"}} src="https://cdn-icons-png.flaticon.com/512/2722/2722998.png"/></span></li>
                                        </ul>
                                    </div>}
                            </div>





                            <div
                                style={{
                                    background: "#f2f2f2",
                                    padding: "15px",
                                    marginTop: "20px",
                                    borderRadius: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        background: "white",
                                        padding: "5px",
                                        borderRadius: "30px",
                                    }}
                                >
                                    <h4 className="fs-4 fw-bolder text-center">
                                        Your Podcast Requests
                                    </h4>
                                </div>

                                <div className="my-4">
                                    {memoizedRegisteredPodcastEvent?memoizedRegisteredPodcastEvent:<h2 className="text-center mx-auto my-2 fw-bold">No event found</h2>}
                                </div>
                                  { registeredPodcastEvent?.length>0  && <div className="mx-auto text-center my-4 w-100">
                                        <ul className="pagination justify-content-center">
                                            
                                            <li className="page-item cursor-pointer"><span className="page-link" onClick={()=>{
                                               if(initialState4>0){
                                                setEndState4(initialState4)
                                                setInitialState4((pre)=>pre-5)
                                              }
                                            }}>
                                                <img  style={{width:"18px",margin:"3px"}} src="https://cdn-icons-png.flaticon.com/512/11502/11502464.png"/>
                                            </span></li>
                                            {
                                                Array.from({ length:Math.ceil((totalCountPodcastEvent)/5) }, (_, i) => i + 1)?.slice(initialState4,endState4)?.map((item,index)=>{
                                                return <li key={index} className="page-item cursor-pointer"><span className={activePagePodcast==item?`page-link text-primary fw-bold`:`page-link text-dark fw-bold`} onClick={()=>{
                                                    if(activePagePodcast!=item){
                                                        setActivePagePodcast(item)
                                                    }
                                                }}>{item}</span></li>
                                            })
                                            }
                                            <li className="page-item cursor-pointer"><span className="page-link" onClick={()=>{
                                                 if(endState4<((totalCountPodcastEvent)/5)){
                                                    setInitialState4(endState4)
                                                    setEndState4((pre)=>pre+5)
                                                }
                                            }}>
                                                <img style={{width:"24px"}} src="https://cdn-icons-png.flaticon.com/512/2722/2722998.png"/></span></li>
                                        </ul>
                                    </div>}
                            </div>






                            <div
                                style={{
                                    background: "#f2f2f2",
                                    padding: "15px",
                                    marginTop: "20px",
                                    borderRadius: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        background: "white",
                                        padding: "5px",
                                        borderRadius: "30px",
                                    }}
                                >
                                    <h4 className="fs-4 fw-bolder text-center">
                                        Your Spotlight Requests
                                    </h4>
                                </div>

                                <div className="my-4">
                                    {memoizedRegisteredSpotlightEvent?memoizedRegisteredSpotlightEvent:<h2 className="text-center mx-auto my-2 fw-bold">No event found</h2>}
                                </div>
                                  { registeredSpotlightEvent?.length>0  && <div className="mx-auto text-center my-4 w-100">
                                        <ul className="pagination justify-content-center">
                                            
                                            <li className="page-item cursor-pointer"><span className="page-link" onClick={()=>{
                                                if(initialState5>0){
                                                    setEndState5(initialState5)
                                                    setInitialState5((pre)=>pre-5)
                                                }
                                            }}>
                                                <img  style={{width:"18px",margin:"3px"}} src="https://cdn-icons-png.flaticon.com/512/11502/11502464.png"/>
                                            </span></li>
                                            {
                                                Array.from({ length:Math.ceil((totalCountSpotlightEvent)/5) }, (_, i) => i + 1)?.slice(initialState5,endState5)?.map((item,index)=>{
                                                return <li key={index} className="page-item cursor-pointer"><span className={activePageSpotlight==item?`page-link text-primary fw-bold`:`page-link text-dark fw-bold`} onClick={()=>{
                                                    if(activePageSpotlight!=item){
                                                        setActivePage(item)
                                                    }
                                                }}>{item}</span></li>
                                            })
                                            }
                                            <li className="page-item cursor-pointer"><span className="page-link" onClick={()=>{
                                                 if(endState5<((totalCountSpotlightEvent)/5)){
                                                    setInitialState5(endState5)
                                                    setEndState5((pre)=>pre+5)
                                                }
                                            }}>
                                                <img style={{width:"24px"}} src="https://cdn-icons-png.flaticon.com/512/2722/2722998.png"/></span></li>
                                        </ul>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddEvent visible={isVisible} setVisible={setIsVisible} />
        </>
    );
};

export default UpcomingEvents;
