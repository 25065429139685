import {
    addLeaderAPI,
    editProfileAPI,
    updateIndustryTypeAPI,
    addSocialAPI,
    updateSocialAPI,
    addBannerAPI,
    addServicesAPI,
    updateBannerAPI,
    sendEndorseAPI,
    addDemoBannerAPI,
    updateDemoBannerAPI,
    addTitleAPI,
    getTitleAPI,
    updateTitleAPI,
    addImagesAPI,
    getImagesAPI,
    getLinksAPI,
    addLinkAPI,
    addPdfLinkAPI,
    getPdfLinksAPI,
    deletePdfAPI,
    deleteLinkAPI,
    deleteImageAPI,
    getSocial,
    getServices,
    deleteLeaderAPI,
    deleteSocialAPI,
    deleteTitleAPI,
    addPrefernceAPI,
    getMyCompanyIndustryPreference,
} from "../../api";

export const updateIndustryType = async (industry) => {
    const response = await updateIndustryTypeAPI(industry);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const addSocial = async (social) => {
    const response = await addSocialAPI(social);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const deleteSocial = async (id) => {
    const response = await deleteSocialAPI(id);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const addServices = async (services) => {
    const response = await addServicesAPI(services);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};
export const addPrefernce = async (id,services) => {
    const response = await addPrefernceAPI(id,services);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};
export const getPrefernce = async () => {
    const response = await getMyCompanyIndustryPreference();
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};
export const updateSocial = async (updatedSocial) => {
    const response = await updateSocialAPI(updatedSocial);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getAllSocials = async () => {
    const response = await getSocial();
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const addLeader = async (leader) => {
    const response = await addLeaderAPI(leader);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const deleteLeader = async (id) => {
    const response = await deleteLeaderAPI(id);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};
export const addBanner = async (banner) => {
    const response = await addBannerAPI(banner);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};
export const updateBanner = async (banner) => {
    const response = await updateBannerAPI(banner);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

// api for add and update demo banner

export const addDemoBanner = async (bannerLink) => {
    const response = await addDemoBannerAPI(bannerLink);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const updateDemoBanner = async (bannerLink) => {
    const response = await updateDemoBannerAPI(bannerLink);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const editProfile = async (profile) => {
    const response = await editProfileAPI(profile);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const sendEndorse = async (data) => {
    const response = await sendEndorseAPI(data);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const addTitle = async (data) => {
    const response = await addTitleAPI(data);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};
export const updateTitle = async (data) => {
    const response = await updateTitleAPI(data);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getTitle = async () => {
    const response = await getTitleAPI();
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

// OtherInfo Images
export const addImages = async (img) => {
    const response = await addImagesAPI(img);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getImages = async () => {
    const response = await getImagesAPI();
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const addLink = async (link) => {
    const response = await addLinkAPI(link);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getLinks = async () => {
    const response = await getLinksAPI();
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};
export const addPdfLink = async (link) => {
    const response = await addPdfLinkAPI(link);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getPdfLinks = async () => {
    const response = await getPdfLinksAPI();
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};
export const deleteImage = async (id) => {
    const response = await deleteImageAPI(id);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};
export const deletePdf = async (id) => {
    const response = await deletePdfAPI(id);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};
export const deleteLink = async (id) => {
    const response = await deleteLinkAPI(id);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const deleteTitle = async (id) => {
    const response = await deleteTitleAPI(id);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getAllServices = async () => {
    const response = await getServices();
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};
