import React from "react";
import "./Hero.css";
import Button from "@material-tailwind/react/Button";
import { Link } from "react-router-dom";
import HeroIllustration from "../../../assets/hero-illustration.svg";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";

const Hero = () => {
    const { user } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    return (
        <div id="home">
            <div className="hero-bg" />
            <div className="hero">
                <div className="hero-background">
                    <div className="container">
                        <div className="col-padding">
                            <div className="row">
                                <div className="col-xxl-8 col-xl-7 col-lg-6 col-md-12 col-12">
                                    <div className="head">
                                        <h1 className="heading text-left">
                                            LET’S CREATE SYNERGIES TOGETHER
                                        </h1>
                                    </div>
                                    <p className="description">
                                        BackBone is a community for small
                                        professional services businesses
                                        worldwide. We believe there is something
                                        special about professional businesses{" "}
                                        <br /> <br />
                                        <ul className="lists">
                                            <li>Helping one another</li>
                                            <li>Vouching for each other &</li>
                                            <li>
                                                Uplifting other businesses for
                                                mutual benefit!
                                            </li>
                                        </ul>
                                        <br />
                                        <span>
                                            That’s our mission! We empower
                                            natural marketing of your Business
                                            services without the huge price tag!
                                        </span>
                                    </p>
                                    {!user && (
                                        <div className="button mr-2 join-button">
                                            <Link to={"/auth?signup=true"}>
                                                <Button
                                                    color="blue"
                                                    buttonType="filled"
                                                    size="regular"
                                                    rounded={false}
                                                >
                                                    Sign up for a 3 Month Free
                                                    Trial
                                                </Button>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                                <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-12 mx-auto">
                                    <div className="d-flex hero-video justify-content-center align-items-center">
                                        <ReactPlayer
                                            playing={true}
                                            muted={true}
                                            loop={true}
                                            className="react-player"
                                            url="/assets/hero.mp4"
                                            controls={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;
