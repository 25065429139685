import Button from "@material-tailwind/react/Button";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import HomeIllustration from "../../../assets/panoramic-woman.svg";
import HomeIllustration from "../../../assets/panoramic business woman.png";
import Service from "./Components/Service";
import Advertisement from "../Advertisement/Advertisement";
import NewOnBackbone from "../New/NewOnBackbone";
import SettingIcon from "../../../assets/Setting-Icon.svg";
import SoundIcon from "../../../assets/ReviewEmpty.svg";
import ColorSound from "../../../assets/Color-Sound.svg";
import handeShake from "../../../assets/hand-shake.svg";
import {
    contentActions,
    getAllCompaniesAction,
    getCompaniesAction,
    getIndustriesAction,
    getSearchedCompaniesAction,
} from "../../../data/reducers/home/content";

import { BsSearch } from "react-icons/bs";
import dropButton from "../../../assets/dropButton.png";
import "./Main.css";
import Blog from "../Blog/Blog";
import { getUserProfile } from "../../../data/reducers/auth/auth";
import ReferFriend from "../ReferFriend/ReferFriend";
import { Link } from "react-router-dom";
import RequestEndorse from "../../Profile/Components/RequestEndorse";
import NotFound from "../../Errors/NotFound";
import Pagination from "../../../components/Pagination";
import ReactivateModal from "../../Auth/Components/ReactivateModal/ReactivateModal";
import { useRef } from "react";
import {
    Modal,
    ModalContent,
    ModalOverlay,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import styled from "styled-components";

const ButtonCancel = styled.button`
    background: #cfd8dc;
    border-radius: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #455a64;
    padding: 5px 30px;
    margin-top: 50px;
`;

const ButtonUpgrade = styled.button`
    background: #039be5;
    border-radius: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    padding: 5px 30px;
    margin-top: 50px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: "center";
    gap: 50px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

const Main = ({ isExpanded }) => {
    const dispatch = useDispatch();
    const uLocation = useLocation();
    const navigate = useNavigate();
    const toast = useToast();
    const { notFound } = useSelector((state) => state.content);
    const [showSuggestion, setShowSuggestions] = useState(false);
    const {
        companies,
        companyCount,
        myCompany,
        allUniqueLocation,
        industries,
        allCompanies,
    } = useSelector((state) => state.content);
    const [search, setSearch] = useState("");
    const [initialState,setInitialState]=useState(0)
    const [endState,setEndState]=useState(10)
    const [sort, setSort] = useState("");
    const [location, setLocation] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    const [page, setPage] = useState(searchParams.get("page") || 1);
    const [refresh, setRefresh] = useState(false);
    const [showModal1, setShowModal1] = useState(
        searchParams.get("review") || false
    );
    const [openModel, setOpenModel] = useState(false);
    const [showBtn, setShowBtn] = useState(false);
    const [reactive, setReactive] = useState(uLocation?.state?.deactivate);
    const inputRef = useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();

    let newAllUniqueLocation = [];

    for (let a = 0; a < allUniqueLocation.length; a++) {
        if (
            allUniqueLocation[a] !== "Australia" &&
            allUniqueLocation[a] !== "New Zealand"
        ) {
            newAllUniqueLocation.push(allUniqueLocation[a]);
        }
    }
    newAllUniqueLocation.sort();
    newAllUniqueLocation.unshift("New Zealand");
    newAllUniqueLocation.unshift("Australia");

    const onReactivate = () => {
        navigate("/upgradePayment", {
            state: {
                deactivate: reactive,
            },
        });
    };

    const onCancel = () => {
        localStorage.clear();
        navigate("/auth");
        window.location.reload();
    };

    // useEffect(()=>{
    //     dispatch(getCompaniesAction(1));
    // },[dispatch])

    

    useEffect(() => {
        if (search === "") {
            !user && dispatch(getUserProfile());
            let getPage = 1;
            // dispatch(getCompaniesAction(getPage));
            // dispatch(getSearchedCompaniesAction({ getPage }));
            // dispatch(getIndustriesAction());
            setPage(getPage);
            setSearchParams(`?page=${getPage}`);
        }
    }, [search]);

    // useEffect(() => {
    //     dispatch(getAllCompaniesAction(`?search=""`));
    // }, [dispatch]);

    const searchCompanies = async (e, r) => {
        e?.preventDefault();
        // if (search === "") {

        //     !user && dispatch(getUserProfile());
        //     let getPage = searchParams.get("page") || 1;
        //     dispatch(getCompaniesAction(getPage));
        //     dispatch(getIndustriesAction());

        //     setPage(getPage);
        // } else {

        let q = { getPage: page };
        if (search && search !== "") {
            q["search"] = search;
        }
        if (location) {
            q["location"] = location;
        }
        if (sort) {
            q["sort"] = sort;
        }
        if (refresh || r) {
            q.getPage = 1;
            setPage(1);
            setSearchParams(`?page=1`);
            setRefresh(false);
        }
        dispatch(getSearchedCompaniesAction(q));
        // }
        if (!companies) {
            dispatch(contentActions.isNotFound(true));
        }
    };

    const { user } = useSelector((state) => state.auth);

    const count = 10;
    const services = companyCount;
    const pageCount = new Array(Math.ceil(services / count) || 1).fill(0);
    const pageLength = pageCount?.length;

    // let getPage = searchParams.get("page") || 1;
    useEffect(()=>{
        dispatch(getIndustriesAction());

    },[dispatch])
    useEffect(() => {
        !user && dispatch(getUserProfile());

        // dispatch(getSearchedCompaniesAction({ search, getPage }));
    }, [dispatch, searchParams]);

    useEffect(() => {
        !user && dispatch(getUserProfile());
    }, []);

    useEffect(() => {
        if(page){
            searchCompanies();
        }
    }, [page]);

    const handlePagination = (val) => {
        setPage(val);
        setSearchParams(`?page=${val}`);
    };
    useEffect(() => {}, [showBtn]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [searchParams]);

    // To Show upgrade modal if a week is left for expiring subscription
    // useEffect(() => {
    //     const endDate = user.subscriptionValidity.split("T")[0];
    //     console.log(endDate);
    //     const now = new Date();
    //     const validityDate = new Date(endDate);
    //     const lastWeekDate = new Date(
    //         validityDate.getFullYear(),
    //         validityDate.getMonth(),
    //         validityDate.getDate() - 7
    //     );
    //     if (
    //         now.getTime() >= lastWeekDate.getTime() &&
    //         now.getTime() <= validityDate.getTime()
    //     ) {
    //         onOpen();
    //     }
    // }, []);

    let searchData = [];
    for (var i = 0; i < allCompanies.length; i++) {
        searchData.push(allCompanies[i].company_name);
    }
    for (var i = 0; i < industries.length; i++) {
        searchData.push(industries[i].industry_name);
    }

    return (
        <>
           
            {reactive && (
                <ReactivateModal
                    modal={reactive}
                    setModal={setReactive}
                    onReactivate={onReactivate}
                    onCancel={onCancel}
                />
            )}
            <div
                className={
                    isExpanded
                        ? "header-expanded home-header relative"
                        : "home-header header-shrinked relative"
                }
            >
                <Modal
                    onClose={onClose}
                    motionPreset="slideInBottom"
                    isOpen={isOpen}
                    isCentered
                    size="xl"
                >
                    <ModalOverlay />
                    <ModalContent>
                        <div
                            className="bg-white px-4 py-4 text-center rounded-lg"
                            style={{
                                zIndex: 1000,
                                maxWidth: "585px",
                            }}
                        >
                            <p className="font-medium">Yay! We made it</p>
                            <p
                                className="font-medium"
                                style={{
                                    color: "#039BE5",
                                }}
                            >
                                Your free trial is about to end in a week.
                            </p>
                            <p className="font-medium">
                                Choose the Annual Subscription and get a 3 month
                                discount off on the Annual Subscription Plan.
                            </p>

                            <ButtonWrapper>
                                <ButtonCancel onClick={onClose}>
                                    Cancel
                                </ButtonCancel>
                                <ButtonUpgrade
                                    onClick={() => {
                                        navigate("/upgradePayment", {
                                            state: { choseSubs: true },
                                        });
                                    }}
                                >
                                    Upgrade Membership
                                </ButtonUpgrade>
                            </ButtonWrapper>
                        </div>
                    </ModalContent>
                </Modal>

                <div className="home-heading">We've got your back</div>

                <div className="container form-container ">
                    <div className="row input-letter align-items-center">
                        <form className="col-xl-7 col-lg-8 col-md-12 col-sm-12 col-12 main-top-form">
                            <div className="w-100">
                                <div className="header-form">
                                    <div className="flex flex-col w-full relative">
                                        <input
                                            onChange={(e) => {
                                                if (e.target.value === "") {
                                                    setShowSuggestions(false);
                                                } else {
                                                    setShowSuggestions(true);
                                                }
                                                setSearch(e.target.value);
                                            }}
                                            onKeyUp={(e) => {
                                                if (e.key === "Enter") {
                                                    setShowSuggestions(false);
                                                    setPage(1);
                                                }
                                            }}
                                            type="text"
                                            placeholder="Search by company name or industry type"
                                            value={search}
                                            ref={inputRef}
                                        />
                                        {showSuggestion ? (
                                            <div
                                                className="absolute w-full suggestion-container "
                                                style={{
                                                    top: "45px",
                                                }}
                                            >
                                                {searchData
                                                    .filter(
                                                        (company) =>
                                                            company
                                                                ?.toLowerCase()
                                                                ?.includes(
                                                                    search?.toLowerCase()
                                                                ) &&
                                                            company !==
                                                                "undefined"
                                                    )
                                                    .map((company,index) => (
                                                        <div
                                                           key={index}
                                                            onClick={() => {
                                                                setSearch(
                                                                    company
                                                                );
                                                                inputRef.current.blur();
                                                                setShowSuggestions(
                                                                    false
                                                                );
                                                            }}
                                                            className="suggestion-box cursor-pointer"
                                                        >
                                                            <p className="px-4 py-2">
                                                                {company}
                                                            </p>
                                                        </div>
                                                    ))}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="submit-button laptop-hide">
                                        <Button
                                            color={"blue"}
                                            buttonType="filled"
                                            size="regular"
                                            rounded={false}
                                            type="submit"
                                            onClick={searchCompanies}
                                        >
                                            <BsSearch
                                                color="white"
                                                fontSize={24}
                                            />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="w-100 d-flex mt-3 items-center">
                                <div className="w-40">
                                    <div className="header-form rounded-lg bg-white items-center">
                                        <select
                                            name="location"
                                            id="location"
                                            onChange={(e) => {
                                                setLocation(
                                                    e.target.value === "main"
                                                        ? null
                                                        : e.target.value
                                                );

                                                setRefresh(true);
                                            }}
                                        >
                                            <option value="main">
                                                Sort by Location
                                            </option>


                                            {newAllUniqueLocation?.map(
                                                (location,index) => {
                                                    return location?.trim()!="" && location?.trim()!="undefined" && location?.trim()!="null" && location!=null && location!= undefined && typeof location !="null" && typeof location!="undefined" &&
                                                     (
                                                        <option
                                                           key={index}
                                                            value={location}
                                                        >
                                                            {location}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="w-40">
                                    <div className="header-form rounded-lg bg-white items-center">
                                        <select
                                            name="order"
                                            id="order"
                                            onChange={(e) => {
                                                setSort(
                                                    e.target.value === ""
                                                        ? null
                                                        : e.target.value
                                                );
                                                setRefresh(true);
                                            }}
                                        >
                                            <option value="">
                                                Sort by order
                                            </option>
                                            <option value={"DESC"}>
                                                Recent
                                            </option>
                                            <option value={"ASC"}>Old</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="w-20 mobile-hide">
                                    <div className="submit-button">
                                        <Button
                                            color={"blue"}
                                            buttonType="filled"
                                            size="regular"
                                            rounded={false}
                                            type="submit"
                                            onClick={(e) => {
                                                searchCompanies(e, true);
                                            }}
                                        >
                                            Search
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="col-xl-5 col-lg-4">
                            <img
                                className="home-illustration"
                                src={HomeIllustration}
                                alt="Home"
                            />
                        </div>
                    </div>
                </div>
            </div>


            <div className="home-header-blank" />

            <div className="services-main mb-4">
                <div className="row services-row mx-2">
                    <div className="col-lg-8 col-md-12 col-sm-12 col-12 service-divide">
                        <div className="col-12 browse-services ps-5 mb-5 m-t-34px">
                            <h1>Browse services {">>"}</h1>
                        </div>
                        <div className="home-services-section">
                            <div className="row">
                                <div className="col-xl-11 col-lg-12 col-md-12 col-12 mx-auto services-col">
                                    {companies.length == 0 ? (
                                        <NotFound
                                            showBtn={showBtn}
                                            search={search}
                                        />
                                    ) : (
                                        <>
                                            {companies?.map((service,index) => (
                                                <Service
                                                    key={index}
                                                    service={service}
                                                    myCompany={myCompany}
                                                />
                                            ))}
                                        </>
                                    )}
                                </div>
                                
                                {companyCount > 5 && (
                                    <div className="col-xl-11 col-lg-12 col-md-12 col-12 m-auto services-col">
                                        <div className="mx-auto text-center my-4 w-100">
                                                <ul className="pagination justify-content-center">
                                                    
                                                    <li className="page-item cursor-pointer"><span className="page-link" onClick={()=>{
                                                         if(initialState>0){
                                                            setEndState(initialState)
                                                            setInitialState((pre)=>pre-10)
                                                        }
                                                    }}>
                                                        <img  style={{width:"18px",margin:"3px"}} src="https://cdn-icons-png.flaticon.com/512/11502/11502464.png"/>
                                                    </span></li>
                                                    {
                                                        Array.from({ length:Math.ceil((companyCount)/10) }, (_, i) => i + 1)?.slice(initialState,endState)?.map((item,index)=>{
                                                        return <li key={index} className="page-item cursor-pointer"><span className={page==item?`page-link text-primary fw-bold`:`page-link text-dark fw-bold`} onClick={()=>{
                                                            if(page!=item){
                                                                setSearchParams(`?page=${item}`);
                                                                setPage(item)
                                                            }
                                                        }}>{item}</span></li>
                                                    })
                                                    }
                                                    <li className="page-item cursor-pointer"><span className="page-link" onClick={()=>{
                                                        if(endState<(companyCount/10)){
                                                            setInitialState(endState)
                                                            setEndState((pre)=>pre+10)
                                                        }
                                                    }}>
                                                        <img style={{width:"24px"}} src="https://cdn-icons-png.flaticon.com/512/2722/2722998.png"/></span></li>
                                                </ul>
                                            </div>
                                        {/* <Pagination
                                            pages={pageCount}
                                            page={page}
                                            handlePagination={handlePagination}
                                            pageLength={pageLength}
                                        /> */}


                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* <span className="border-right"></span> */}
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12 main-right-section">
                        <div className="Earn-Credit m-t-34px">
                            <span>Earn credits</span>
                        </div>
                        <div className="m-b-30px">
                            <div
                                className="display-flex refer-friend"
                                onClick={() => setOpenModel(true)}
                            >
                                <div>
                                    <img src={handeShake} />
                                </div>
                                <div className="ReferFriendConstent">
                                    Refer a friend
                                </div>
                            </div>
                        </div>
                        <ReferFriend
                            id={myCompany?.id}
                            openModel={openModel}
                            setOpenModel={setOpenModel}
                        />

                        <div className="m-b-30px">
                            <div
                                className="display-flex refer-friend"
                                onClick={() => setShowModal1(true)}
                            >
                                <div>
                                    <img
                                        src={SoundIcon}
                                        className="EndorsementImg"
                                    />
                                </div>
                                <div className="Endorsements">
                                    Request a review
                                </div>
                            </div>
                        </div>
                        <RequestEndorse
                            id={myCompany?.id}
                            showModal={showModal1}
                            setShowModal={setShowModal1}
                        />

                        <Advertisement />
                        <div
                            className="display-flex Advertise-css"
                            // onClick={() => setOpenModel(true)}
                        >
                            <div>
                                <img src={ColorSound} />
                            </div>
                            <div className="Advertise">
                                <Link to={`/ad`} className="company_visit">
                                    Advertise with us
                                </Link>
                            </div>
                        </div>
                        <NewOnBackbone />
                    </div>
                </div>
            </div>
            <hr />
            <div className="col-xl-10 col-lg-11 col-md-12 col-12 mx-auto">
                <Blog />
            </div>
        </>
    );
};

export default Main;

// const Main = ({ isExpanded }) => {
//     const dispatch = useDispatch();
//     const uLocation = useLocation();
//     const navigate = useNavigate();
//     const toast = useToast();
//     const { notFound } = useSelector((state) => state.content);
//     const [showSuggestion, setShowSuggestions] = useState(false);
//     const {
//         companies,
//         companyCount,
//         myCompany,
//         allUniqueLocation,
//         industries,
//         allCompanies,
//     } = useSelector((state) => state.content);
//     const [search, setSearch] = useState("");
//     const [sort, setSort] = useState("");
//     const [location, setLocation] = useState();
//     const [searchParams, setSearchParams] = useSearchParams();
//     const [page, setPage] = useState(searchParams.get("page") || 1);
//     const [refresh, setRefresh] = useState(false);
//     const [showModal1, setShowModal1] = useState(
//         searchParams.get("review") || false
//     );
//     const [openModel, setOpenModel] = useState(false);
//     const [showBtn, setShowBtn] = useState(false);
//     const [reactive, setReactive] = useState(uLocation?.state?.deactivate);
//     const inputRef = useRef();
//     const { isOpen, onOpen, onClose } = useDisclosure();

//     let newAllUniqueLocation = [];

//     for (let a = 0; a < allUniqueLocation.length; a++) {
//         if (
//             allUniqueLocation[a] !== "Australia" &&
//             allUniqueLocation[a] !== "New Zealand"
//         ) {
//             newAllUniqueLocation.push(allUniqueLocation[a]);
//         }
//     }
//     newAllUniqueLocation.sort();
//     newAllUniqueLocation.unshift("New Zealand");
//     newAllUniqueLocation.unshift("Australia");

//     const onReactivate = () => {
//         navigate("/upgradePayment", {
//             state: {
//                 deactivate: reactive,
//             },
//         });
//     };

//     const onCancel = () => {
//         localStorage.clear();
//         navigate("/auth");
//         window.location.reload();
//     };

//     useEffect(()=>{
//         dispatch(getCompaniesAction(1));
//     },[dispatch])

    

//     useEffect(() => {
//         if (search === "") {
//             !user && dispatch(getUserProfile());
//             let getPage = 1;
//             // dispatch(getCompaniesAction(getPage));
//             dispatch(getSearchedCompaniesAction({ getPage }));
//             dispatch(getIndustriesAction());
//             setPage(getPage);
//             setSearchParams(`?page=${getPage}`);
//         }
//     }, [search]);

//     useEffect(() => {
//         dispatch(getAllCompaniesAction(`?search=""`));
//     }, [dispatch]);

//     const searchCompanies = async (e, r) => {
//         e?.preventDefault();
//         // if (search === "") {

//         //     !user && dispatch(getUserProfile());
//         //     let getPage = searchParams.get("page") || 1;
//         //     dispatch(getCompaniesAction(getPage));
//         //     dispatch(getIndustriesAction());

//         //     setPage(getPage);
//         // } else {

//         let q = { getPage: page };
//         if (search && search !== "") {
//             q["search"] = search;
//         }
//         if (location) {
//             q["location"] = location;
//         }
//         if (sort) {
//             q["sort"] = sort;
//         }
//         if (refresh || r) {
//             q.getPage = 1;
//             setPage(1);
//             setSearchParams(`?page=1`);
//             setRefresh(false);
//         }
//         dispatch(getSearchedCompaniesAction(q));
//         // }
//         if (!companies) {
//             dispatch(contentActions.isNotFound(true));
//         }
//     };

//     const { user } = useSelector((state) => state.auth);

//     const count = 10;
//     const services = companyCount;
//     const pageCount = new Array(Math.ceil(services / count) || 1).fill(0);
//     const pageLength = pageCount?.length;

//     // let getPage = searchParams.get("page") || 1;
//     useEffect(() => {
//         !user && dispatch(getUserProfile());

//         // dispatch(getSearchedCompaniesAction({ search, getPage }));
//     }, [dispatch, searchParams]);

//     useEffect(() => {
//         !user && dispatch(getUserProfile());
//     }, []);

//     useEffect(() => {
//         searchCompanies();
//     }, [page]);

//     const handlePagination = (val) => {
//         setPage(val);
//         setSearchParams(`?page=${val}`);
//     };
//     useEffect(() => {}, [showBtn]);

//     useEffect(() => {
//         window.scrollTo({ top: 0, behavior: "smooth" });
//     }, [searchParams]);

//     // To Show upgrade modal if a week is left for expiring subscription
//     // useEffect(() => {
//     //     const endDate = user.subscriptionValidity.split("T")[0];
//     //     console.log(endDate);
//     //     const now = new Date();
//     //     const validityDate = new Date(endDate);
//     //     const lastWeekDate = new Date(
//     //         validityDate.getFullYear(),
//     //         validityDate.getMonth(),
//     //         validityDate.getDate() - 7
//     //     );
//     //     if (
//     //         now.getTime() >= lastWeekDate.getTime() &&
//     //         now.getTime() <= validityDate.getTime()
//     //     ) {
//     //         onOpen();
//     //     }
//     // }, []);

//     let searchData = [];
//     for (var i = 0; i < allCompanies.length; i++) {
//         searchData.push(allCompanies[i].company_name);
//     }
//     for (var i = 0; i < industries.length; i++) {
//         searchData.push(industries[i].industry_name);
//     }

//   return (
//     <>      
//         {reactive && (
//             <ReactivateModal
//                 modal={reactive}
//                 setModal={setReactive}
//                 onReactivate={onReactivate}
//                 onCancel={onCancel}
//             />
//         )}
//          <div
//             className={
//                     isExpanded
//                         ? "header-expanded home-header relative"
//                         : "home-header header-shrinked relative"
//                 }
//         >
//              <Modal
//                     onClose={onClose}
//                     motionPreset="slideInBottom"
//                     isOpen={isOpen}
//                     isCentered
//                     size="xl"
//                 >
//                     <ModalOverlay />
//                     <ModalContent>
//                         <div
//                             className="bg-white px-4 py-4 text-center rounded-lg"
//                             style={{
//                                 zIndex: 1000,
//                                 maxWidth: "585px",
//                             }}
//                         >
//                             <p className="font-medium">Yay! We made it</p>
//                             <p
//                                 className="font-medium"
//                                 style={{
//                                     color: "#039BE5",
//                                 }}
//                             >
//                                 Your free trial is about to end in a week.
//                             </p>
//                             <p className="font-medium">
//                                 Choose the Annual Subscription and get a 3 month
//                                 discount off on the Annual Subscription Plan.
//                             </p>

//                             <ButtonWrapper>
//                                 <ButtonCancel onClick={onClose}>
//                                     Cancel
//                                 </ButtonCancel>
//                                 <ButtonUpgrade
//                                     onClick={() => {
//                                         navigate("/upgradePayment", {
//                                             state: { choseSubs: true },
//                                         });
//                                     }}
//                                 >
//                                     Upgrade Membership
//                                 </ButtonUpgrade>
//                             </ButtonWrapper>
//                         </div>
//                     </ModalContent>
//                 </Modal>



//                 <div className="home-heading">We've got your back</div>

//                         <div className="container form-container ">
//                             <div className="row input-letter align-items-center">
//                                 <form className="col-xl-7 col-lg-8 col-md-12 col-sm-12 col-12 main-top-form">
//                                     <div className="w-100">
//                                         <div className="header-form">
//                                             <div className="flex flex-col w-full relative">
//                                                 <input
//                                                     onChange={(e) => {
//                                                         if (e.target.value === "") {
//                                                             setShowSuggestions(false);
//                                                         } else {
//                                                             setShowSuggestions(true);
//                                                         }
//                                                         setSearch(e.target.value);
//                                                     }}
//                                                     onKeyUp={(e) => {
//                                                         if (e.key === "Enter") {
//                                                             setShowSuggestions(false);
//                                                             setPage(1);
//                                                         }
//                                                     }}
//                                                     type="text"
//                                                     placeholder="Search by company name or industry type"
//                                                     value={search}
//                                                     ref={inputRef}
//                                                 />
//                                                 {showSuggestion ? (
//                                                     <div
//                                                         className="absolute w-full suggestion-container "
//                                                         style={{
//                                                             top: "45px",
//                                                         }}
//                                                     >
//                                                         {searchData
//                                                             .filter(
//                                                                 (company) =>
//                                                                     company
//                                                                         ?.toLowerCase()
//                                                                         ?.includes(
//                                                                             search?.toLowerCase()
//                                                                         ) &&
//                                                                     company !==
//                                                                         "undefined"
//                                                             )
//                                                             .map((company,index) => (
//                                                                 <div
//                                                                 key={index}
//                                                                     onClick={() => {
//                                                                         setSearch(
//                                                                             company
//                                                                         );
//                                                                         inputRef.current.blur();
//                                                                         setShowSuggestions(
//                                                                             false
//                                                                         );
//                                                                     }}
//                                                                     className="suggestion-box cursor-pointer"
//                                                                 >
//                                                                     <p className="px-4 py-2">
//                                                                         {company}
//                                                                     </p>
//                                                                 </div>
//                                                             ))}
//                                                     </div>
//                                                 ) : null}
//                                             </div>
//                                             <div className="submit-button laptop-hide">
//                                                 <Button
//                                                     color={"blue"}
//                                                     buttonType="filled"
//                                                     size="regular"
//                                                     rounded={false}
//                                                     type="submit"
//                                                     onClick={searchCompanies}
//                                                 >
//                                                     <BsSearch
//                                                         color="white"
//                                                         fontSize={24}
//                                                     />
//                                                 </Button>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="w-100 d-flex mt-3 items-center">
//                                         <div className="w-40">
//                                             <div className="header-form rounded-lg bg-white items-center">
//                                                 <select
//                                                     name="location"
//                                                     id="location"
//                                                     onChange={(e) => {
//                                                         setLocation(
//                                                             e.target.value === "main"
//                                                                 ? null
//                                                                 : e.target.value
//                                                         );

//                                                         setRefresh(true);
//                                                     }}
//                                                 >
//                                                     <option value="main">
//                                                         Sort by Location
//                                                     </option>


//                                                     {newAllUniqueLocation?.map(
//                                                         (location,index) => {
//                                                             return location?.trim()!="" && location?.trim()!="undefined" && location?.trim()!="null" && location!=null && location!= undefined && typeof location !="null" && typeof location!="undefined" &&
//                                                             (
//                                                                 <option
//                                                                 key={index}
//                                                                     value={location}
//                                                                 >
//                                                                     {location}
//                                                                 </option>
//                                                             );
//                                                         }
//                                                     )}
//                                                 </select>
//                                             </div>
//                                         </div>
//                                         <div className="w-40">
//                                             <div className="header-form rounded-lg bg-white items-center">
//                                                 <select
//                                                     name="order"
//                                                     id="order"
//                                                     onChange={(e) => {
//                                                         setSort(
//                                                             e.target.value === ""
//                                                                 ? null
//                                                                 : e.target.value
//                                                         );
//                                                         setRefresh(true);
//                                                     }}
//                                                 >
//                                                     <option value="">
//                                                         Sort by order
//                                                     </option>
//                                                     <option value={"DESC"}>
//                                                         Recent
//                                                     </option>
//                                                     <option value={"ASC"}>Old</option>
//                                                 </select>
//                                             </div>
//                                         </div>
//                                         <div className="w-20 mobile-hide">
//                                             <div className="submit-button">
//                                                 <Button
//                                                     color={"blue"}
//                                                     buttonType="filled"
//                                                     size="regular"
//                                                     rounded={false}
//                                                     type="submit"
//                                                     onClick={(e) => {
//                                                         searchCompanies(e, true);
//                                                     }}
//                                                 >
//                                                     Search
//                                                 </Button>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </form>
//                                 <div className="col-xl-5 col-lg-4">
//                                     <img
//                                         className="home-illustration"
//                                         src={HomeIllustration}
//                                         alt="Home"
//                                     />
//                                 </div>
//                             </div>
//                         </div>
                





//                         <div className="home-header-blank" />

//                 <div className="services-main mb-4">
//                     <div className="row services-row mx-2">
//                         <div className="col-lg-8 col-md-12 col-sm-12 col-12 service-divide">
//                             <div className="col-12 browse-services ps-5 mb-5 m-t-34px">
//                                 <h1>Browse services {">>"}</h1>
//                             </div>
//                             <div className="home-services-section">
//                                 <div className="row">
//                                     <div className="col-xl-11 col-lg-12 col-md-12 col-12 mx-auto services-col">
//                                         {companies.length == 0 ? (
//                                             <NotFound
//                                                 showBtn={showBtn}
//                                                 search={search}
//                                             />
//                                         ) : (
//                                             <>
//                                                 {companies?.map((service,index) => (
//                                                     <Service
//                                                         key={index}
//                                                         service={service}
//                                                         myCompany={myCompany}
//                                                     />
//                                                 ))}
//                                             </>
//                                         )}
//                                     </div>
//                                     {companyCount > 5 && (
//                                         <div className="col-xl-11 col-lg-12 col-md-12 col-12 m-auto services-col">
//                                             <Pagination
//                                                 pages={pageCount}
//                                                 page={page}
//                                                 handlePagination={handlePagination}
//                                                 pageLength={pageLength}
//                                             />
//                                         </div>
//                                     )}
//                                 </div>
//                             </div>
//                         </div>
//                         {/* <span className="border-right"></span> */}
//                          <div className="col-lg-4 col-md-12 col-sm-12 col-12 main-right-section">
//                             <div className="Earn-Credit m-t-34px">
//                                 <span>Earn credits</span>
//                             </div>
//                             <div className="m-b-30px">
//                                 <div
//                                     className="display-flex refer-friend"
//                                     onClick={() => setOpenModel(true)}
//                                 >
//                                     <div>
//                                         <img src={handeShake} />
//                                     </div>
//                                     <div className="ReferFriendConstent">
//                                         Refer a friend
//                                     </div>
//                                 </div>
//                             </div>
//                             <ReferFriend
//                                 id={myCompany?.id}
//                                 openModel={openModel}
//                                 setOpenModel={setOpenModel}
//                             />

//                             <div className="m-b-30px">
//                                 <div
//                                     className="display-flex refer-friend"
//                                     onClick={() => setShowModal1(true)}
//                                 >
//                                     <div>
//                                         <img
//                                             src={SoundIcon}
//                                             className="EndorsementImg"
//                                         />
//                                     </div>
//                                     <div className="Endorsements">
//                                         Request a review
//                                     </div>
//                                 </div>
//                             </div>
//                              <RequestEndorse
//                                 id={myCompany?.id}
//                                 showModal={showModal1}
//                                 setShowModal={setShowModal1}
//                             />

//                             {/* <Advertisement /> */}
//                             <div
//                                 className="display-flex Advertise-css"
//                                 // onClick={() => setOpenModel(true)}
//                             >
//                                 <div>
//                                     <img src={ColorSound} />
//                                 </div>
//                                 <div className="Advertise">
//                                     <Link to={`/ad`} className="company_visit">
//                                         Advertise with us
//                                     </Link>
//                                 </div>
//                             </div>
//                             <NewOnBackbone />
//                         </div> 
//                     </div>
//                 </div>
//         </div>
//         <hr />
//             <div className="col-xl-10 col-lg-11 col-md-12 col-12 mx-auto">
//                 <Blog />
//             </div>
//     </>
//   )
// }

// export default Main