import React from "react";
import { useNavigate } from "react-router-dom";
import Errors from "../../assets/Errors.svg";

const Server = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="cont">
                <div>
                    <img src={Errors} alt="Errors" />
                </div>
                {/* <div className="back-btn" onClick={() => navigate(-1)}>
                    <button>Back to Previous Screen</button>
                </div> */}
            </div>
        </>
    );
};

export default Server;
