import {
    addCompanyDetailsAPI,
    editCompanyInfoAPI,
    getIndustriesAPI,
    getStripeKeyAPI,
    makePaymentAPI,
    savePaymentAPI,
    getServices,
    deleteServicesAPI,
    deletePrefernceAPI,
} from "../../api";

export const getIndustriesService = async () => {
    const response = await getIndustriesAPI();
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getUserSubscriptionCreditService = async () => {
    const response = await getIndustriesAPI();
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getAllServices = async () => {
    const response = await getServices();
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const deleteService = async (chip) => {
    const response = await deleteServicesAPI(chip);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};
export const deletePrefernce = async (chip) => {
    const response = await deletePrefernceAPI(chip);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};
export const addCompanyDetails = async (payload) => {
    const response = await addCompanyDetailsAPI(payload);
    if (response?.data) {
        return response?.data;
    } else {
        return null;
    }
};

export const getStripeKeyService = async () => {
    try {
        const response = await getStripeKeyAPI();
        return response.data;
    } catch (error) {
        console.log(error);
        return { error: "Unable to get Stripe API Key" };
    }
};

export const makePaymentService = async (payload) => {
    try {
        const response = await makePaymentAPI(payload);
        return response.data;
    } catch (error) {
        console.log(error);
        return { error: error.response.data.message };
    }
};

export const savePaymentService = async (payload) => {
    try {
        const response = await savePaymentAPI(payload);
        return response.data;
    } catch (error) {
        console.log(error);
        return { error: error.response.data.message };
    }
};

export const editCompanyInfo = async (payload) => {
    try {
        const response = await editCompanyInfoAPI(payload);
        return response.data;
    } catch (error) {
        console.log(error);
        return { error: error.response.data.message };
    }
};
