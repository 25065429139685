import React from "react";
import Modal from "@material-tailwind/react/Modal";
import ModalBody from "@material-tailwind/react/ModalBody";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { makePayment } from "../../../data/reducers/details/details";

const HeadingDetail = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    padding: 20px;
    color: #3f3f3f;
`;

const ButtonCancel = styled.button`
    background: #039be5;
    border-radius: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    padding: 5px 30px;
    margin-top: 50px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const ConfirmModal = ({ modal, setModal, type }) => {
    const stripe = useStripe();
    const dispatch = useDispatch();
    const elements = useElements();
    const { user } = useSelector((state) => state?.auth);
    const submitHandler = async () => {
        if (!stripe || !elements) {
            return;
        }
        console.log("Hello");

        // const result = await stripe.createPaymentMethod({
        //     type: "card",
        //     card: elements.getElement(CardNumberElement),
        //     billing_details: {
        //         name: `${user?.firstName} ${user?.lastName}`,
        //         email: `${user?.email}`,
        //     },
        // });

        const { payload } = await dispatch(
            makePayment({
                // payment_method: result?.paymentMethod?.id,
                email: user?.email,
                type: type,
                upgrade: true,
            })
        );

        setModal(false);
    };
    return (
        <div className="modal-wrapper">
            <Modal
                size="lg"
                active={modal}
                toggler={() => {
                    setModal(false);
                }}
            >
                <ModalBody>
                    <HeadingDetail>
                        Are you sure you want to upgrade your membership ?
                    </HeadingDetail>

                    <ButtonWrapper onClick={submitHandler}>
                        <ButtonCancel>Upgrade Membership</ButtonCancel>
                    </ButtonWrapper>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default ConfirmModal;
