import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    cost: 0,
};

export const paymentSlice = createSlice({
    name: "payment",
    initialState,
    reducers: {
        setCost: (state, action) => {
            state.cost = action.payload?.cost;
        },
        reset: (state, action) => {
            state.cost = 0;
        },
    },
});

export const { reset, setCost } = paymentSlice.actions;
export default paymentSlice.reducer;
