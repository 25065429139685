import React, { useEffect, useState } from "react";
import "./Testimonial.css";

import Testimonial1 from "../../../assets/testimonial.svg";
import nsw from "../../../assets/England.png";
import Flag_of_Australia from "../../../assets/Flag_of_Australia.svg.webp";
import US from "../../../assets/flag.JPG";

import Leftarrow from "../../../assets/left-arrow.svg";
import Rightarrow from "../../../assets/right-arrow.svg";
import { isMobile } from "react-device-detect";
import { Carousel } from "react-responsive-carousel";
import ComingSoon from "../../../assets/coming-soon.svg";

const Testimonial = () => {
    const [start, setStart] = useState(2);
    const [end, setEnd] = useState(4);
    const [testimonial, setTestimonial] = useState([]);
    const [data, setData] = useState([
        {
            name: "Australia",
            image: Flag_of_Australia,
            description:
                "It gives you the trust and reassurance of finding businesses that have been referred by other people.",
        },
        {
            name: "England",
            image: nsw,
            description:
                "I really love where you refer a certain number of people and you get it for free for the rest of the year. It’s an excellent idea since there are a lot of businesses that know other businesses. That would definitely make me sign up.",
        },
        {
            name: "US",
            image: US,
            description:
                "I really like the idea of having a website composed of all small businesses looking for other small businesses. I think it’s a lot easier to have one place to find that type of information as opposed to going through the entire internet.",
        },
        {
            name: "England",
            image: nsw,
            description:
                "Companies want other companies to recommend somebody that's reliable and that works well… Businesses involved on the platform will be tried and tested,which is reassuring",
        },
        {
            name: "Australia",
            image: Flag_of_Australia,
            description:
                "Helps me promote my business given my business is local",
        },
        {
            name: "US",
            image: US,
            description:
                "I like that my business can be viewed by potentially thousands of other businesses that might wish to use my services.",
        },
        {
            name: "England",
            image: nsw,
            description:
                "Gives me the option to invite other people and have my fees waived",
        },
        {
            name: "Australia",
            image: Flag_of_Australia,
            description:
                "I do think they unique and different to other products that are out there.",
        },
        {
            name: "US",
            image: US,
            description:
                "Gives me the opportunity to promote my services to other businesses that might be able to utilize them.",
        },
    ]);

    useEffect(() => {
        for (let i = 0; i <= 2; i++) {
            testimonial.push(data[i]);
        }
    }, []);

    const handleCarousel = (name) => {
        console.log("start", start);
        let carousel = [];
        if (name === "forward") {
            if (end === data.length - 1) {
                setStart(-1);
                setEnd(1);
            }
            setStart((prevState) => {
                prevState = prevState > data.length ? 0 : prevState + 1;
                return prevState;
            });
            setEnd((prevState) => {
                prevState = prevState > data.length ? 0 : prevState + 1;
                return prevState;
            });

            for (let i = start; i <= end; i++) {
                carousel.push(data[i]);
            }

            setTestimonial(carousel);
        } else {
            if (start === 0) {
                setStart(data.length - 2);
                setEnd(data.length);
            }
            setStart((prevState) => {
                prevState = prevState > 0 ? prevState - 1 : data.length - 1;
                return prevState;
            });
            setEnd((prevState) => {
                prevState = prevState > 0 ? prevState - 1 : data.length - 1;
                return prevState;
            });
            for (let i = start; i <= end; i++) {
                carousel.push(data[i]);
                setTestimonial(carousel);
            }
        }
    };
    console.log("end", end);
    return (
        <div className="testimonial" id="testimonial">
            <div className="testimonial-box bg-white">
                <div className="container py-5 max-w-full bg-white">
                    <p className="text-4xl font-semibold">
                        Feedback on BackBone
                    </p>

                    <div
                        style={{ marginTop: "45px" }}
                        className="testimonial-row"
                    >
                        {!isMobile ? (
                            <>
                                <div className="col-md-4 col-sm-8 col-12 mx-auto left-arrow">
                                    <img
                                        src={Leftarrow}
                                        alt="left"
                                        onClick={() =>
                                            handleCarousel("backward")
                                        }
                                    />
                                </div>
                                <div className="arrow-image-map mx-4">
                                    {testimonial.map((item, index) => {
                                        return (
                                            <div key={index} className="col-md-4 col-sm-8 col-12 mx-auto testimonial-col ">
                                                <div className="d-flex justify-content-center image-box">
                                                    <img
                                                        src={item.image}
                                                        alt="Testimonial 1"
                                                    />
                                                </div>
                                                <br />
                                                <span className="testimonial-span pb-2 text-sm poppins font-normal text-justify">
                                                    "{item.description}"
                                                </span>

                                                <p
                                                    className="m-0 text-lg font-semibold poppins"
                                                    style={{ color: "#979696" }}
                                                >
                                                    - Business Owner,{" "}
                                                    {item.name}
                                                </p>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="col-md-4 col-sm-8 col-12 mx-auto right-arrow">
                                    <img
                                        src={Rightarrow}
                                        alt="right"
                                        onClick={() =>
                                            handleCarousel("forward")
                                        }
                                    />
                                </div>
                            </>
                        ) : (
                            <div className="carousel-wrapper">
                                <Carousel
                                    showIndicators={true}
                                    showArrows={false}
                                    showStatus={false}
                                    infiniteLoop={true}
                                    interval={5000}
                                >
                                    {data.map((item, index) => {
                                        return (
                                            <div key={index} className="col-md-4 col-sm-8 col-12 testimonial-col mx-auto">
                                                <div className="d-flex justify-content-center image-box">
                                                    <img
                                                        src={item.image}
                                                        alt="Testimonial 1"
                                                    />
                                                </div>

                                                <br />
                                                <span className="testimonial-span pb-2 text-sm poppins font-normal text-justify">
                                                    "{item.description}"
                                                </span>

                                                <p
                                                    className="m-0 text-lg font-semibold poppins"
                                                    style={{ color: "#979696" }}
                                                >
                                                    - Business Owner,{" "}
                                                    {item.name}
                                                </p>
                                            </div>
                                        );
                                    })}
                                </Carousel>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonial;
