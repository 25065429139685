import React, { memo, useEffect, useState } from 'react'
import HomeCardFive from '../../dashboard/component/HomeCardFive'
import ProfileActivityCard from './ProfileActivityCard'

const ProfileActivity = ({myCompany,activity,companyBackedUs,companyBackedUsDetail,deleteBackingDetails}) => {
   const [totalShow,setTotalShow]=useState(2)
   
  return (
    <>
       <div className='container'>
            <div className='row my-4'>
                  <div className='col-12 profile-industry-type-section'>
                      <h3 className='fs-4 fw-bolder text-secondary'>Activity</h3>

                      <div className='row my-3' style={{borderRadius:"10px",padding:"10px"}}>
                           {myCompany?.Backings?.length>0 && <div className='col-12 col-lg-6'>
                              <div className=''>
                                    <div className='my-3'>
                                        <h3 className='fs-4 text-center mx-auto fw-bold'>Companies we’ve <br/> backed</h3>
                                    </div>
                                    <div>
                                    <div>
                                          {
                                              myCompany?.Backings?.length>0 && myCompany?.Backings?.slice(0,totalShow)?.map((item,index)=>{
                                                return <ProfileActivityCard key={index}  type="We" activity={activity} item={item} index={index} deleteBackingDetails={deleteBackingDetails}/>
                                              })
                                          }
                                    </div>
                                    </div>
                              </div>
                           </div>}
                           {companyBackedUs?.length>0 && <div className='col-12 col-lg-6'>
                              <div className=''>
                                    <div className='my-3'>
                                        <h3 className='fs-4 text-center mx-auto fw-bold'>Companies that <br/>have backed us</h3>
                                    </div>
                                    <div>
                                       {
                                          companyBackedUs?.slice(0,totalShow)?.map((item,index)=>{
                                               return <ProfileActivityCard key={index} type="Us" activity={companyBackedUsDetail} item={item} index={index} deleteBackingDetails={deleteBackingDetails} />
                                          })
                                       }
                                       
                                    </div>
                              </div>
                           </div>}
                           <div className='col-12'>
                             {
                               (  myCompany?.Backings?.length>totalShow || companyBackedUs?.length>totalShow) && <p className='my-2 fs-5 fw-bold text-dark text-center mx-auto cursor-pointer' onClick={()=>{
                              setTotalShow((pre)=>{
                                    return pre+2
                              })
                             }}>See more +</p>}
                           </div>
                      </div>

                      
                  </div>
            </div>
       </div>
    </>
  )
}

export default memo(ProfileActivity)